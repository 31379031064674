import { Api } from '../../config/request'

export const requestEmailSent = async (data) => {
  return Api.PostRequest('/user/sendSignupUrl', data)
}
export const requestReferral = async (data) => {
  //console.log(data)
  return Api.PostRequest('/user/getReferralLink', data)
}
export const requestCancelSubscription = async (data) => {
  return Api.PostRequest('/stripe/cancelSubscription', data)
}
export const requestUpgradeMembership = async (data) => {
  return Api.PostRequest('/stripe/upgradeSubscription', data)
}
export const requestSubscriptionList = async (data) => {
  return Api.PostRequest('/stripe/subscriptionList', data)
}
export const requestGetReferalLink = async (data) => {
  return Api.PostRequest('/user/getReferralLink', data)
}
export const requestSendReferalLink = async (data) => {
  return Api.PostRequest('/user/sendReferralMail', data)
}
const MyAccountApiRequests = {
  requestEmailSent,
  requestCancelSubscription,
  requestUpgradeMembership,
  requestSubscriptionList,
  requestGetReferalLink,
  requestSendReferalLink,
}
export default MyAccountApiRequests
