import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Footer from '../Components/Footer/Footer'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getLoadingStatus, getVerificationRequest } from '../redux/login/action'
import Header from '../../src/Components/Header'
import Toastify from '../Components/SnackBar/Toastify'
import { useSelector } from 'react-redux'
import LoaderComponent from '../Components/Loader/LoaderComponent'

const Verification = () => {
  const newState = useSelector((state) => state?.LoginData?.verifyStatus)
  const urlParams = new URLSearchParams(window.location.search);
  const user_id = urlParams.get('user_id');

  let loadingStatus = useSelector((state) => state?.LoginData) || false

  const dispatch = useDispatch();

  const callApi = () => {
    dispatch(getVerificationRequest({ user_id: user_id })); // Call the API
    dispatch(getLoadingStatus(true)); // Set loading status to true
  };

  useEffect(() => {
    callApi()
  }, [user_id])

  //console.log("NewSatte", newState);

  if(newState?.status===true)
    loadingStatus=false;
  else if(newState?.status===false)
    loadingStatus=false;

  return (

    <>
      <Grid sx={{ pl: '3%', mt: '1%' }}>
        <Header />
        <Toastify />
      </Grid>
      <LoaderComponent open={loadingStatus.loadingStatus} />
      <Grid sx={{ mt: { xs: 30, md: 30 } }}>
        {newState?.status === true ? (
          <>
            <Grid sx={Style.Thankyou}>
              <Typography sx={Style.heading}>
                Your email has been verified successfully!
              </Typography>
            </Grid>
            <Grid sx={Style.box}>
              <Grid sx={Style.msg}>
                <Typography
                  sx={{
                    color: '#27245E',
                  }}
                >
                  Thank you for verifying your email address.
                </Typography>
              </Grid>
            </Grid>
          </>
    ) :(
          <>
            <Grid sx={Style.Thankyou}>
              <Typography sx={Style.heading}>
              Your email has not been verified. Please try again!
              </Typography>
            </Grid>
            {/* <Grid sx={Style.box}>
              <Grid sx={Style.msg}>
                <Typography
                  sx={{
                    color: '#27245E',
                  }}
                >
                  Thank you for verifying your email .
                </Typography>
              </Grid>
            </Grid> */}
          </>
    )}
        <Footer />
      </Grid>
    </>
  )
}

export default Verification
const Style = {
  Thankyou: {
    width: '100%',
    textAlign: 'center',
    mt: { xs: 0, md: 5 },
    // mb: 2,
  },
  heading: {
    color: '#27245E',
    fontSize: '24px',
    fontWeight: 700,
    mt: 2,
  },
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    mb: { xs: 10, md: 0 },
  },

  msg: {
    bgcolor: '#B6DBE7',
    borderRadius: '10px',
    opacity: 0.5,
    minHeight: '70px',
    mb: { xs: 0, md: 10 },
    p: 2,
    width: { xs: '90%', md: '43%' },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
