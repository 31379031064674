import { Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import Footer from '../../Components/Footer/Footer'
import Subscription from '../../Components/Subscription/Subscription'
import { storage } from '../../config/storage'
import customerSignup from '../../assets/CustomerSignup.png'
import Logo33 from '../../assets/logo-33.png'
import membership from '../../assets/membership.png'
import Box from '@mui/material/Box'
import { BorderBottom } from '@mui/icons-material'
import Logo11 from '../../assets/logo-11.png'
import TransformButton from '../../Components/CustomButton/TransformButton'
import { CUSTOMER_LOGIN } from '../../routes/constURL'
import { useNavigate } from 'react-router-dom'
import Header from '../../Components/Header'

const Thankyou = () => {
  const url = new URL(window.location.href)
  const type = url.searchParams.get('type')
  const email = url.searchParams.get('email')
  const navigate = useNavigate()

  const doRender = () => {
    navigate(CUSTOMER_LOGIN)
  }
  return (
    <Grid>
      <Grid sx={{ padding: { xs: '30px 6px', md: '30px 80px' }, mt: '%' }}>
        <Header email={email} />

        <Grid>
          <Box
            sx={{
              // position: 'absolute',
              left: '100%',
              // transform: 'translate(-50%, -50%)',
              zIndex: 2, // Ensure membership image appears above the color overlay
              textAlign: 'center',
              // ml: '36.5%',
              mt: '5%',
            }}
          >
            {/*  membership component or image */}
            {/* <img src={membership} alt="Membership" width={'75%'}  /> */}
            <Typography
              sx={{
                color: '#27245E',
                fontFamily: 'Hind',
                fontSize: { sm: '18px', md: '38px' },
                fontWeight: '600',
                textTransform: 'uppercase',
                mt: '2%',
              }}
            >
              SUBSCRIPTION COMPLETED
            </Typography>
          </Box>
        </Grid>

        {/* </Box> */}

        {/* <Grid
            sx={{
              width: { sm: '90%', md: '90%' },
              ml: { sm: '5%', md: '5%' },
              mt: '10%',
              borderBottom: '0px solid white',
            }}
          >
            <Subscription
              success={true}
              type={type}
              sx={{ borderBottom: 'none' }}
            />
          </Grid> */}

        <Grid sx={Style.Thankyou} lg={6} textAlign={'center'}>
          <Typography sx={{ ...Style.heading, mt: '', width: '100%' }}>
            Thank you for your purchase
          </Typography>
          <img
            src={require('../../assets/success.png')}
            style={{ margin: '30px' }}
          />
          <Typography>Payment Successful</Typography>
          <Typography>Payment id: {storage.fetch.oneTime_id()}</Typography>
          <Typography>Subscription id: {storage.fetch.subs_id()}</Typography>
          <Typography sx={Style.heading}>
            {' '}
            {type == 'basic' ? '$92 AUD' : '$130 AUD'}
          </Typography>
          <Typography sx={Style.gst}>(inclusive of GST)</Typography>
        </Grid>

        <Grid sx={{ ...Style.box, mt: '2%' }}>
          <Grid
            sx={{ ...Style.msg, backgroundColor: 'rgba(182, 219, 231, 0.5)' }}
          >
            {/* <Typography
            sx={{
              color: '#27245E',
              
            }}
          >
            Please check your email inbox. We have sent login details on your
            email address.{' '}
          </Typography> */}
            <Typography
              sx={{
                color: '#27245E',
                // ml:{xs:'5%', sm: '%', md: '%', lg: '%'}
                textAlign: 'center',
              }}
            >
              {' '}
              We’ve sent you an email so you can verify your email address.{' '}
            </Typography>
          </Grid>
        </Grid>

        {/* <Grid
      xs={12}
      container
      spacing={0}
      // direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginBottom:'5%' }}
      onClick={()=> doRender()}
    >
<Grid >
      <TransformButton  button_name={'Click here to Login'} transform={'skew(-22deg) translate(-8%, -50%)'}  />
</Grid>
</Grid> */}
      </Grid>
      <Footer />
    </Grid>
  )
}

export default Thankyou
const Style = {
  Thankyou: {
    width: '100%',
    textAlign: 'center',
    // mt: { xs: 0, md: 5 },
    ml: '',
    mb: 6,
  },
  heading: {
    color: '#27245E',
    fontSize: '34px',
    fontWeight: 700,
    mt: 2,
  },
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    mb: { xs: 6, md: 0 },
  },
  gst: {
    color: '#ACACAC',
    fontSize: '12px',
  },
  msg: {
    bgcolor: '#B6DBE7',
    borderRadius: '10px',
    minHeight: '70px',
    mb: { xs: 0, md: 6 },
    p: '4',
    width: { xs: '70%', md: '60%', lg: '43%' },
    // width:{xs:'70%', sm: '45%', md: '45%', lg: '45%'},
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textWrap: { xs: '', md: 'nowrap', lg: 'nowrap' },
    pl: { xs: '15px' },
  },
}
