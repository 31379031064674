import { Api } from '../../config/request'

export const requestCustomerList = async (data) => {
  return Api.PostRequest('/user/list', data)
}
export const requestCustomerDetails = async (data) => {
  return Api.PostRequest('/user/details', data)
}
export const requestCustomerAddUpdate = async (data) => {
  return Api.PostRequest('/user/edit', data)
}
export const requestCustomerDelete = async (data) => {
  // console.log('hello')
  return Api.PostRequest('/user/delete', data)
}
const CustomersApiRequests = {
  requestCustomerList,
  requestCustomerDetails,
  requestCustomerAddUpdate,
  requestCustomerDelete,
}
export default CustomersApiRequests
