import { call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_CLUB_ADMIN_PAYMENT,
  GET_EMPTY_STATUS,
  GET_ERROR_STATUS,
  GET_LOADING_STATUS,
  GET_NOTIFICATION_NUMBER,
  GET_PLAYER_DELETE,
  GET_PLAYER_DETAILS,
  GET_PLAYER_LIST,
  GET_PLAYER_UPDATE,
  SET_NOTIFICATION_NUMBER,
  SET_PLAYER_DELETE,
  SET_PLAYER_DETAILS,
  SET_PLAYER_LIST,
  SET_PLAYER_UPDATE,
} from '../ConstAction'
import { toast } from 'react-toastify'
import { storage } from '../../config/storage'
import PlayerApiRequests from '../../services/Player'
import PaymentApiRequests from '../../services/payments'
///-----*Functions*-----///
function* HandleApiResponse(
  response,
  successAction,
  errorMessage,
  toaster,
  nav,
  data1,
) {
  // const navigate=useNavigate()
  //console.log(response, successAction)

  if (response.data.status) {
    // navigate(CUSTOMER_LISTING)
    // if (successAction !== SET_NOTIFICATION_NUMBER) {

    // }
    console.log(data1)
    if (nav?.length > 0) {
      yield put({
        type: nav,
        data: data1,
      })
    }
    const { data } = response?.data || {}
    if (data?.token) {
      storage.set.authToken(data.token)
    }
    if (data?.role) {
      storage.set.role(data.role)
    }
    if (toaster) {
      toast.success(response?.data?.message)
    }
    yield put({
      type: successAction,
      response,
    })
  } else if (response.data.code == 303) {
    yield put({
      type: GET_EMPTY_STATUS,
      data: true,
    })
  } else if (response == 500) {
    yield put({
      type: GET_ERROR_STATUS,
      data: true,
    })
  } else {
    toast.error(response?.data?.errors[0] || errorMessage)
  }
  yield put({
    type: GET_LOADING_STATUS,
    data: false,
  })
  yield put({
    type: GET_NOTIFICATION_NUMBER,
    // response,
  })
}

function* getPlayerList(data) {
  //console.log('ok')
  try {
    let response = yield PlayerApiRequests.requestPlayerList(data.data)
    yield HandleApiResponse(
      response,
      SET_PLAYER_LIST,
      'Failed to fetch player list',
    )
    // //console.warn(response.code)
    // if (response.status) {
    //   toast.success(response?.data?.message)
    //   yield put({
    //     type: SET_PLAYER_LIST,
    //     response,
    //   })
    //   // yield put({
    //   //   type: GET_PLAYER_LIST,
    //   // })
    //   yield put({
    //     type: GET_LOADING_STATUS,
    //     data: true,
    //   })
    // } else if (response.code == 303) {
    //   yield put({
    //     type: GET_EMPTY_STATUS,
    //     data: true,
    //   })

    //   yield put({
    //     type: GET_LOADING_STATUS,
    //     data: true,
    //   })
    // } else {
    //   toast.error(response?.errors[0])
    //   yield put({
    //     type: GET_LOADING_STATUS,
    //     data: true,
    //   })
    // }
  } catch (err) {
    HandleApiResponse(err)
    //console.log('wrong')
  }
}
function* getPlayerDetails(data) {
  try {
    let response = yield PlayerApiRequests.requestPlayerDetails(data.data)
    yield HandleApiResponse(
      response,
      SET_PLAYER_DETAILS,
      'Failed to fetch player',
    )
  } catch (err) {
    //console.log('wrong')
  }
}
function* getPlayerAddUpdate(data) {
  try {
    let response = yield PlayerApiRequests.requestPlayerAddUpdate(data.data)
    let toaster = true
    // if(response.status){
    //   data.navigate(CUSTOMERS_INFO_DETAILS + '/' + data.users_id)

    // }
    yield HandleApiResponse(
      response,
      SET_PLAYER_UPDATE,
      'Failed to update player list',
      toaster,
      GET_PLAYER_DETAILS,
      data.data,
    )
    // if (response.status) {
    //   console.log('details')
    //   const formdata = Object.fromEntries(data.data)?.users_id
    //   // console.log('hi', data.data, Object.fromEntries(data.data).users_id)
    //   yield toast.success(response?.data?.message)
    //   yield put({
    //     type: SET_PLAYER_UPDATE,
    //     response,
    //   })
    //   if (formdata) {
    //     yield put({
    //       type: GET_PLAYER_DETAILS,
    //       data: { users_id: formdata },
    //     })
    //   }
    // } else {
    //   toast.error(response?.errors[0])
    // }
    // yield put({
    //   type: GET_LOADING_STATUS,
    //   data: true,
    // })
  } catch (err) {}
}
function* getPlayerDelete(data) {
  let params = {
    start: 0,
    length: 5,
    sort_column: '',
    sort_by: '',
    search_val: '',
    listType: 'club_palyers',
  }
  try {
    let response = yield PlayerApiRequests.requestPlayerDelete(data.data)
    if (response.status) {
      toast.success(response?.data?.message)
      yield put({
        type: SET_PLAYER_DELETE,
        response,
      })
      yield put({
        type: GET_PLAYER_LIST,
        data: params,
      })
      yield put({
        type: GET_LOADING_STATUS,
        data: true,
      })
    } else if (response.code == 303) {
      yield put({
        type: GET_EMPTY_STATUS,
        data: true,
      })
      yield put({
        type: GET_PLAYER_LIST,
        data: params,
      })
      yield put({
        type: GET_LOADING_STATUS,
        data: true,
      })
    } else {
      toast.error(response?.errors[0])
      yield put({
        type: GET_LOADING_STATUS,
        data: true,
      })
      yield put({
        type: GET_PLAYER_LIST,
        data: params,
      })
    }
  } catch (err) {}
}
function* customNavigation(action) {
  if (action.URL) {
    action.navigate(action.URL)
  }
}

function* PlayerSaga() {
  yield takeEvery(GET_PLAYER_LIST, getPlayerList)
  yield takeEvery(GET_PLAYER_DETAILS, getPlayerDetails)
  yield takeEvery(GET_PLAYER_UPDATE, getPlayerAddUpdate)
  yield takeEvery(GET_PLAYER_DELETE, getPlayerDelete)
  yield takeEvery('NAVIGATE', customNavigation)
}

export default PlayerSaga
