import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { storage as LocalStorage } from '../config/storage'
import { toast } from 'react-toastify'
import Toastify from '../Components/SnackBar/Toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CLUB_LOGIN, LOGIN } from './constURL'

const ProtectedRoute = ({ children }) => {
  const acc_Token = LocalStorage.fetch.authToken()
  const type = LocalStorage.fetch.role()
  if (acc_Token) {
  } else {
    setTimeout(() => {
      localStorage.clear()
    }, 2000)

    return <Navigate to={type === 'club_admin' ? CLUB_LOGIN : LOGIN} replace />
  }
  return children
}

export default ProtectedRoute
