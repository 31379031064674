/* eslint-disable jsx-a11y/alt-text */
import { Grid, TableRow, Typography } from '@mui/material'
import React from 'react'
import Signup from '../../Components/Forms/Signup'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header'
// import Header from '../../'

const CustomerSignup = () => {
  return (
    <>
      <Grid
        sx={{ padding: { xs: '30px 6px', md: '30px 80px' }, mt: '0%', mb: 3 }}
      >
        <Header />
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '16px', sm: '30px' },
            // mt: { xs: 4, sm: 10 },
            mb: { xs: 0, sm: 2 },
            mt: '2%',
            fontStyle: 'Bold',
            fontWeight: '600',
            color: '#27245E',
          }}
        >
          CHECKOUT
        </Typography>
        <Grid>
          <Grid>
            <Signup />
          </Grid>
        </Grid>
        <Grid></Grid>
      </Grid>
      <Footer />
    </>
  )
}

export default CustomerSignup
