import { Box, Table, TableBody } from '@mui/material'
import React, {
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { EnhancedTableHead } from '../../Components/TableHeader/TableHeader'
import { Style } from '../CSS/Table'
import Toastify from '../../Components/SnackBar/Toastify'
import { storage } from '../../config/storage'
import { getClubDelete, getClubListRequest } from '../../redux/clubs/clubAction'
import TopBox from '../../Components/TableHeader/TopBox'
import { ADD_BULK_ORDERS, BULK_ORDERS_INFO_EDIT } from '../../routes/constURL'
import { getLoadingStatus } from '../../redux/login/action'
import NoDataFound from './NoDataFound'
import ListSkelton from '../../Components/Loader/ListSkelton'
import CustomTableBody from '../../Components/TableBox/CustomTableBody'
import CustomPagination from '../../Components/Pagination'
import AlertDialog from '../../Components/Dialog/Dialog'
import { status_arr } from '../../config/mockData'
import ModalBox from '../../Components/Modal/ModalBox'
import PaymentModel from '../../Components/Modal/PaymentModel'
import LoaderComponent from '../../Components/Loader/LoaderComponent'

//---State for handling data & payloads---//
const initialState = {
  order: 'asc',
  orderBy: '',
  page: 1,
  pages: 0,
  total: '',
  rows: [],
  status: 'Select Status',
  membership: 'Select Program',
  columns: [],
  startFrom: 0,
  length: 5,
  alert: false,
  open: false,
  id: '',
  details: {},
  statusNew:'Select Status',
  memberNew:'Select Program',
  searched:false
}

//---Setter function---//
function reducerFunction(prevState, nextState) {
  return { ...prevState, ...nextState }
}
const BulkOrders = () => {
  const { list, empty, loadingStatus } = useSelector(
    (state) => state?.ClubData || {},
  )
  const initialState__ = useSelector((state) => state)
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const [payloadForPagination, setPagination] = useState({
    start: 0,
    length: state?.length,
    sort_column: '',
    sort_by: '',
    search_val: '',
    role: 'club_admin',
  })
  const navigate = useNavigate()
  const myDispatch = useDispatch()
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  //const [isResetDisabled, setResetDisabled] = useState(true);
  let data = {
    start: 0,
    length: state?.length,
    sort_column: '',
    sort_by: '',
    search_val: '',
    role: 'club_admin',
  }
  ///---Side effect function when change in list length(Pagination)---///

  // //////console.log('list', list, loadingStatus)

  useEffect(() => {
    document.title = 'OG Sports - Bulk Orders'
        setStateReducer({ memberNew: state.membership,statusNew:state.status })
      },[state.searched])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    // console.log(loadingStatus)
    myDispatch(getClubListRequest(data))
    myDispatch(getLoadingStatus(true))
    const headCells = [
      { label: 'Club Name', name: 'club_name' },
      { label: 'Club type', name: 'club_type' },
      { label: 'Licences', name: 'num_of_licenses' },
      { label: 'Used/Remaining Licences', name: 'Membership' },
      { label: 'Amount', name: 'amount' },
      { label: 'Status', name: 'membership_status' },
      { label: 'Payment Status', name: 'payment_status' },
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    setStateReducer({ columns: headCells })
  }, [state?.length])
  ///---Side effect function when change in list---///
  useLayoutEffect(() => {
    setStateReducer({ pages: list?.pages, total: list?.total_records })
  }, [list])
  ///---Sorting function---///
  const handleRequestSort = (event, property) => {
    
    setStateReducer({ page: state.page })
    let a = (state.page - 1) * state.length
    data = {
      start: a,
      length: state.length,
    }

    let isAsc = state.orderBy === property && state.order === 'asc'
    if (state.orderBy !== property) {
      setStateReducer({ order: 'desc', orderBy: property })
      data.sort_by = 'asc'
      data.sort_column = property
    } else {
      setStateReducer({ order: isAsc ? 'desc' : 'asc', orderBy: property })
      data.sort_by = state.order
      data.sort_column = property
    }
    Object.assign(data, {
      membership: state.memberNew === 'Select Program' ? '' : state?.membership,
      membership_status: state.statusNew === 'Select Status' ? '' : state?.status,
    })
    myDispatch(getClubListRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  ///---Pagination Function---///
  const handlePageChange = (event, value) => {

    console.log("Data--",data);
    setStateReducer({ page: value })
    let a = (value - 1) * state.length
    data = {
      start: a,
      length: state.length,
    }
    setPagination(data)
    if (state.order && state.orderBy) {
      data.sort_by = state.order === 'asc' ? 'desc' : 'asc'
      data.sort_column = state.orderBy
    }
    Object.assign(data, {
      membership: state.memberNew == 'Select Program' ? '' : state?.member,
      membership_status: state.statusNew == 'Select Status' ? '' : state?.status,
    })
    myDispatch(getClubListRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  ///---Seach & Filter Function---///
  const handleChangeSelect = (e, type) => {
    switch (type) {
      case 'status':
        return setStateReducer({ status: e.target.value })
      case 'member':
        return setStateReducer({ membership: e.target.value })
      case 'search':
        setStateReducer({ memberNew: state.membership,statusNew:state.status })

        Object.assign(data, {
          membership:
            state.membership == 'Select Program' ? '' : state.membership,
          membership_status:
            state.status == 'Select Status' ? '' : state.status,
        })
        if (state.order && state.orderBy) {
          data.sort_by = state.order === 'asc' ? 'desc' : 'asc'
          data.sort_column = state.orderBy
        }
        myDispatch(getClubListRequest(data))
        myDispatch(getLoadingStatus(true))
        return setStateReducer({ page: 1 })
      default:
        return null
    }
  }

  useEffect(() => {
    // Check if any two drop-downs have default values
    const isDefaultStatus = state.status === 'Select Status';
    const isDefaultMembership = state.membership === 'Select Program';
    // If any two drop-downs have default values, disable the button
    setButtonDisabled(isDefaultStatus && isDefaultMembership);
  }, [state.status, state.membership]);

  // useEffect(() => {
  //   // Check if both drop-downs have default values
  //   const isDefaultStatus = state.status === 'Select Status';
  //   const isDefaultMembership = state.membership === 'Select Program';
  //   // If both drop-downs have default values, disable the reset button
  //   setResetDisabled(isDefaultStatus && isDefaultMembership);
  // }, [state.status, state.membership]);

//   useEffect(()=>{
//   const isNonDefaultSelections = state.status !== 'Select Status' && state.membership !== 'Select Program';
//   const isNonDefaultSelectionsReset = state.status === 'Select Status' || state.membership === 'Select Program';
//   // Enable/disable both buttons accordingly
//   setButtonDisabled(!isNonDefaultSelections);
//   // Enable/disable reset button accordingly
//   setResetDisabled(!isNonDefaultSelections);
// })
  ///---Navigation to edit page---///
  const navigateTo = (id) => {
    storage.set.customerId(id)
    navigate(BULK_ORDERS_INFO_EDIT + '/' + id, {
      state: id,
    })
  }
  const handleDelete = (id) => {
    setStateReducer({ id: id })
    setStateReducer({ alert: true })
  }
  const handlePay = (details) => {
    setStateReducer({ open: true })
    setStateReducer({ details: details })
  }
  const handleClose = () => {
    setStateReducer({ open: false })
    // myDispatch(getClubListRequest(data))
    // myDispatch(getLoadingStatus(true))
  }
  const confirmDelete = async (id) => {
    myDispatch(getClubDelete({ users_id: id }))
    myDispatch(getLoadingStatus(true))
    setStateReducer({ alert: false })
  }

  const handleReset = () => {
    window.scrollTo(0, 0)
    myDispatch(getClubListRequest(data))
    myDispatch(getLoadingStatus(true))

    setStateReducer({
      status: 'Select Status',
      membership: 'Select Program', order: 'asc',
      orderBy: '',
      page: 1,
      pages: 0,
      statusNew:'Select Status',
      membershipNew:'Select Program'
    })
  }
  return (
    <>
      <TopBox
        headerText={'Bulk Orders'}
        button_one={'Add +'}
        searchText={'Search Bulk Orders'}
        handleChangeFirst={(e) => handleChangeSelect(e, 'status')}
        handleChangeSecond={(e) => handleChangeSelect(e, 'member')}
        sortingText={'Filter by'}
        initialValue={'Select Program'}
        menuData={['1 Month Program', '3 Months Program']}
        value1={state.status}
        value2={state.membership}
        button_two={() => handleChangeSelect('', 'search')}
        button_one_onClick={() => {
          navigate(ADD_BULK_ORDERS)
        }}
        width={'20%'}
        select_arr1={status_arr}
        onReset={handleReset}
        disabledVal={isButtonDisabled}
        // disabledReset={isResetDisabled}
      />
      <AlertDialog
        title={'Are you sure you want to delete this Bulk order?'}
        open={state.alert}
        onClickButton={() => confirmDelete(state.id)}
        onClickButtonCancel={() => setStateReducer({ alert: false })}
      />
      {/* <Toastify /> */}
      <PaymentModel
        handleClose={handleClose}
        open={state.open}
        details={state.details}
        payloadForPagination={payloadForPagination}
      />

      <LoaderComponent open={loadingStatus} />
      {empty && <NoDataFound />}
      <Box
        sx={[Style.table.tableWrapBox, { display: empty ? 'none' : 'flex' }]}
      >
        <Table sx={Style.table.tableBox} aria-labelledby="tableTitle">
          <EnhancedTableHead
            columns={state.columns}
            setColumns={setStateReducer}
            order={state.order}
            orderBy={state.orderBy}
            onRequestSort={handleRequestSort}
            rowCount={state.rows.length}
          />
          <TableBody sx={{ border: 0 }}>
            {list &&
              !loadingStatus &&
              list.user_list?.map((row, ind) => (
                <CustomTableBody
                  key={row?.users_id}
                  URL={BULK_ORDERS_INFO_EDIT}
                  navigateTo={navigateTo}
                  row={row}
                  loadingStatus={loadingStatus}
                  ind={ind}
                  columns={[
                    row?.club_name,
                    row?.club_type,
                    row?.num_of_licenses,
                    row?.used_license,
                    row?.unuse_license,
                    `$${row?.amount}`,
                    row?.membership_status.length != 0
                      ? row?.membership_status
                      : 'none',
                  ]}
                  handleDelete={handleDelete}
                  handlePay={handlePay}
                />
              ))}
            {loadingStatus && (
              <ListSkelton
                loadingStatus={loadingStatus}
                rows={5}
                columns={8}
                width={'100px'}

              />
            )}
          </TableBody>
        </Table>
        <Toastify />
      </Box>
      <CustomPagination
        state={state}
        empty={empty}
        handlePageChange={handlePageChange}
      />
    </>
  )
}

export default BulkOrders
