import React from 'react'
import '../../App.css'
const WorkInProgress = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        alt="Workinprogress"
        className="workInProgress"
        // src={vehicData?.photo_links[selected]?.image_url}
        src={require('../../assets/work.jpg')}

        // onClick={() => setOpen(true)}
      />
    </div>
  )
}

export default WorkInProgress
