import { put, takeEvery } from 'redux-saga/effects'
import {
  GET_CLUB_ADMIN_PAYMENT,
  GET_CLUB_DASHBOARD_DATA,
  GET_DASHBOARD_DATA,
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  GET_NOTIFICATION_NUMBER,
  SET_CLUB_DASHBOARD_DATA,
  SET_DASHBOARD_DATA,
  GET_EXPIRY_LINK,
  SET_EXPIRY_LINK
} from '../ConstAction'
import { toast } from 'react-toastify'
import DashboardApiRequests from '../../services/dashboard'
import PaymentApiRequests from '../../services/payments'

function* getDashboardData(data) {
  try {
    let response = yield DashboardApiRequests.requestAdminDashboard(data.data)
    if (response.status) {
      // if (successAction !== SET_NOTIFICATION_NUMBER) {

      // }
      yield put({
        type: SET_DASHBOARD_DATA,
        response,
      })
      yield put({
        type: GET_LOADING_STATUS,
        data: false,
      })
    } else if (response.status === 303) {
      yield put({
        type: GET_EMPTY_STATUS,
        data: true,
      })
    } else {
      yield put({
        type: GET_EMPTY_STATUS,
        data: true,
      })
    }
    yield put({
      type: GET_LOADING_STATUS,
      data: false,
    })
  } catch (err) {}
}
function* getClubDashboardData(data) {
  try {
    let response = yield DashboardApiRequests.requestClubAdminDashboard(
      data.data,
    )
    if (response.data.status) {
      yield put({
        type: SET_CLUB_DASHBOARD_DATA,
        response,
      })
    } else if (response.status === 303) {
      yield put({
        type: GET_EMPTY_STATUS,
        data: true,
      })
    } else {
      yield put({
        type: GET_EMPTY_STATUS,
        data: true,
      })
    }
    yield put({
      type: GET_LOADING_STATUS,
      data: false,
    })
  } catch (err) {}
}
function* getClubOnlinePayment(data) {
  //console.log("club_payment",data)
  try {
    let response = yield PaymentApiRequests.requestbulkpayments(data.data)
    if (response.data.status) {
      toast.success(response?.data?.message)
      setTimeout(() => {
        data.handleClose()
      }, 2000)

      yield put({
        type: GET_CLUB_DASHBOARD_DATA,
      })
    } else if (response.data.code == 303) {
      yield put({
        type: GET_EMPTY_STATUS,
        data: true,
      })
      yield put({
        type: GET_CLUB_DASHBOARD_DATA,
      })
      toast.error(response.data.errors[0])
    } else {
      toast.error(response.data.errors[0])

      yield put({
        type: GET_CLUB_DASHBOARD_DATA,
      })
    }
  } catch (err) {
    //console.log('wrong')
  }
}
function* getExpiredLink(data) {

  try{
    let response = yield PaymentApiRequests.requestExpiryLink(data.data)

    if(response.data.status)
      {
        yield put({
          type: SET_EXPIRY_LINK,
          response,
        })
      }
  }catch (err) {

  }

}
function* DashboardSaga() {
  yield takeEvery(GET_DASHBOARD_DATA, getDashboardData)
  yield takeEvery(GET_CLUB_DASHBOARD_DATA, getClubDashboardData)
  yield takeEvery(GET_CLUB_ADMIN_PAYMENT, getClubOnlinePayment)
  yield takeEvery(GET_EXPIRY_LINK, getExpiredLink)
}

export default DashboardSaga
