import {
  GET_PLAYER_LIST,
  GET_PLAYER_DETAILS,
  GET_PLAYER_UPDATE,
  GET_PLAYER_DELETE,
  GET_CLUB_ADMIN_PAYMENT,
} from '../ConstAction'
export const getPlayerListRequest = (data) => {
  return {
    type: GET_PLAYER_LIST,
    data: data,
  }
}
export const getPlayerDetailsRequest = (data) => {
  return {
    type: GET_PLAYER_DETAILS,
    data: data,
  }
}
export const getPlayerAddUpdateRequest = (data,navigate) => {
  return {
    type: GET_PLAYER_UPDATE,
    data: data,
    navigate:navigate
  }
}
export const getPlayerDelete = (data) => {
  return {
    type: GET_PLAYER_DELETE,
    data: data,
  }
}

