import {
  GET_EMPTY_STATUS,
  GET_ERROR_STATUS,
  GET_LOADING_STATUS,
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_NUMBER,
  SET_PLAYER_DETAILS,
  SET_PLAYER_LIST,
  SET_PLAYER_UPDATE,
} from '../ConstAction'
const initialState = {}
export const PlayerData = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAYER_LIST:
      return { list: action.response.data.data, empty: false }
    case SET_PLAYER_DETAILS:
      return {
        ...state,
        details: action.response.data.data,
      }
    case GET_LOADING_STATUS:
      //console.log("loading",action.data)
      return {
        ...state,
        loadingStatus: action.data,
      }
    case GET_EMPTY_STATUS:
      return {
        ...state,
        empty: action.data,
      }
    case GET_ERROR_STATUS:
      return {
        ...state,
        error: true,
      }
    case SET_PLAYER_UPDATE:
      return {
        ...state,
        URL: true,
      }
    case SET_NOTIFICATION_NUMBER:
      return {
        ...state,
      }
    case SET_NOTIFICATIONS:
      return {
        ...state,
      }
    default:
      return { ...state }
  }
}
