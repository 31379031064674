// Staging Url
// export const API_URL =
// 'https://projects.orientaloutsourcing.com/jho/backend/public/api'
export const API_URL = 'https://backend-staging.overnight-gains.com/api'
export const WP_URL = 'https://overnight-gains.com/'

// Live URL
// export const API_URL = "https://jump-higher-api.orientaloutsourcing.in/public/api"

// https://jump-higher-api.orientaloutsourcing.in/public/api
