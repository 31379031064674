import React, { useEffect } from 'react'
import { Style } from '../../Admin/CSS/Table'
import { Box, Pagination } from '@mui/material'
const CustomPagination = ({ state, handlePageChange, empty, error, page }) => {
  //console.log('state', state, page)
  useEffect(() => {}, [page])

  return (
    <>
      {(state?.total_records !== 0 || state?.total !== 0) &&
        (state?.total_records > 1 || state?.total > 1) &&
        state.pages > 1 && (
          <Box
            sx={[
              Style.paginationBox,
              {
                display: empty || error ? 'none' : 'flex',
                justifyContent: 'flex-end',
                mb: 2,
              },
            ]}
          >
            <Pagination
              count={state.pages}
              page={state?.page || page}
              boundaryCount={1}
              onChange={handlePageChange}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
              sx={{
                '& .MuiPaginationItem-root': {
                  '&.Mui-selected': {
                    background: '#27245E',
                    color: 'white',
                  },
                },
              }}
            />
          </Box>
        )}
    </>
  )
}

export default CustomPagination
