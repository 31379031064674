import {
  Box,
  Typography,
  Backdrop,
  Button,
  CircularProgress,
  Checkbox,
  Container,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputField from '../Components/InputField'
import Toastify from '../Components/SnackBar/Toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import './Login.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/Lock'
import IconLinkButton from '../Components/CustomButton/IconLinkButton'
import AdminImage from '../assets/admin-login.jpg'
import CustomerImage from '../assets/loginImg (1).png'
import ClubImage from '../assets/clubadmin.png'
import AdminImage2 from '../assets/jeremy.png'

import Grid from '@mui/material/Grid'

import {
  getAdminProfileRequest,
  getLoadingStatus,
  getRequest,
} from '../redux/login/action'
import {
  CLUB_LOGIN,
  CLUB_PROFILE_UPDATE,
  CUSTOMERSIGNUP,
  CUSTOMER_LOGIN,
  FORGOT_PASSWORD,
  LOGIN,
  PROFILE_UPDATE,
} from '../routes/constURL'
import { Schema } from '../Components/FormSchema/Schema'
import TransformButton from '../Components/CustomButton/TransformButton'
import { storage } from '../config/storage'
import AlertDialog from '../Components/Dialog/Dialog'
import Footer from '../Components/Footer/Footer'
import { StandardAdminData } from '../config/mockData'

const defaultValues = {
  email: '',
  password: '',
}

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const URL = location.pathname
  const newState = useSelector((state) => state?.LoginData)
  const [check, setCheck] = useState(false)
  const [open, setOpen] = useState(false)

  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramValue = urlParams.get('email');

  const myDispatch = useDispatch()
  useEffect(() => {
    const role = storage.fetch.role()

    if (role == 'Admin') document.title = 'OG Sports - Admin Login'
    else if (role == 'Club_admin')
      document.title = 'OG Sports - Club Admin Login'
    else if (role == 'Customer') document.title = 'OG Sports - Customer Login'
  }, [newState,paramValue])
  let role
  let pageName
  if (URL === LOGIN) {
    role = 'Admin'
    pageName = 'Admin'
    document.title = 'OG Sports - Admin Login'
  } else if (URL === CLUB_LOGIN) {
    role = 'Club_admin'
    pageName = 'Bulk Order Admin'
    document.title = 'OG Sports - Club Admin Login'
  } else if (URL === CUSTOMER_LOGIN) {
    role = 'Customer'
    pageName = ''
    document.title = 'OG Sports - Customer Login'
  }

  const { control, formState, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValues:{
      email:paramValue == 'undefined' ? '' : paramValue,
      password:''
    },
    resolver: yupResolver(Schema.ADMIN_LOGIN),
  })
  const { isValid, dirtyFields, errors } = formState

  const onSubmit = (model) => {
    //console.log(model)
    let item = model
    Object.assign(item, { remember_me: check })
    myDispatch(getRequest(item, navigate))
    myDispatch(getLoadingStatus(true))
  }
  const handleForgot = () => {
    navigate(FORGOT_PASSWORD)
  }
  return (
    <>
      <Box sx={style.outBox} className="form-screens">
        <Container maxWidth="xl">
          <Box sx={style.mainBox}>
            <Box sx={style.leftBox}>
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={newState?.loadingStatus}
              >
                <CircularProgress size={80} color="inherit" />
              </Backdrop>
              <form
                name="LoginForm"
                className="loginForm"
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '80%',
                  // justifyContent: 'space-evenly',
                }}
              >
                <Box sx={{ mt: { xs: '40px', md: '0px' } }}>
                  <img
                    alt="logo"
                    // className="logoSize"
                    style={{ width: '200px' }}
                    src={require('../assets/logo-11.png')}
                  />
                </Box>

                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: '16px', md: '20px' },
                    mt: { xs: 4, md: 10 },
                    mb: { xs: 0, md: 3 },

                    fontStyle: 'semiBold',
                    fontWeight: '600',
                    color: '#27245E',
                  }}
                >
                  {`Login to your ${pageName} Account`}
                </Typography>

                <InputField
                  control={control}
                  helperText={errors?.email?.message}
                  errors={!!errors.email}
                  type={'text'}
                  placeholder={'Enter Your Email Address'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name="email"
                  required={'*'}
                  IconName={PersonIcon}
                />

                <Box sx={{ height: '15px' }} />

                <Grid
                  sx={{
                    '& input[type="password"]::-ms-reveal': { display: 'none' },
                  }}
                >
                  <InputField
                    control={control}
                    helperText={errors?.password?.message}
                    errors={!!errors.password}
                    variant="filled"
                    placeholder={'Enter Your Password'}
                    size={{ xs: '18px', md: '18px', lg: '18px' }}
                    color={'#333333'}
                    name="password"
                    type={'password'}
                    required={'*'}
                    IconName={LockIcon}
                  />
                </Grid>

                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <label>
                      <Checkbox
                        sx={{
                          pl: 0,
                          color: '#D9D9D9',
                          '&.Mui-checked': {
                            color: '#27245E',
                          },
                          height: '10px',
                          marginTop: '26px',
                          float: 'left',
                        }}
                        value={check}
                        onChange={(e) => setCheck(!check)}
                      >
                        {' '}
                      </Checkbox>
                      <Typography
                        sx={{ mt: '23px', float: 'left', cursor: 'pointer' }}
                      >
                        Remember me
                      </Typography>
                    </label>
                  </Box>
                  <Box
                    sx={{
                      width: '90%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Link
                      to={FORGOT_PASSWORD}
                      className="fontlink1"
                      style={{
                        fontWeight: 400,
                        color: 'rgb(216, 0, 39)',
                        textDecoration: 'none',
                        marginTop: '24px',
                        marginBottom: '30px',
                        textTransform: 'none',
                      }}
                    >
                      Forgot Password ?
                    </Link>
                  </Box>
                </Box>

                {/* <Button
                disableRipple
                sx={style.button}
                variant="outlined"
                type="submit"
              >
                Submit
              </Button>
              <Box>
                <Typography>
                  Don't have an account?
                  <IconLinkButton buttonName={'Signup'} LinkColor={'#CB2128'} />
                </Typography>
              </Box> */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: { md: 'flex-start' },
                    mt: 2,
                    mb: 2,
                    pr: { xs: 1, md: 10 },
                    pl: { xs: 1, sm: 10 },
                  }}
                >
                  <TransformButton
                    textT={'uppercase'}
                    button_name={'Sign-In'}
                    typeSubmit={'submit'}
                  />
                </Box>
                {URL === CUSTOMER_LOGIN && (
                  <Typography>
                    Don't have an account?{' '}
                    <Link
                      to={
                        'https://overnight-gains.com/programs/jump-higher-overnight/'
                      }
                      style={{
                        textDecoration: 'none',
                        color: 'rgb(216, 0, 39)',
                      }}
                    >
                      Sign up
                    </Link>{' '}
                  </Typography>
                )}
              </form>
              <Toastify />
            </Box>

            <Box
              sx={{
                width: '50%',
                height: 'calc(100vh - 80px)',
                p: '35px 40px 40px 0px',
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <Box
                sx={{
                  height: 'calc(100vh - 150px)',
                  // display: { xs: 'none', md: 'flex' },
                  bgcolor: '#27245E',
                  position: 'relative',
                  left: '10%',
                }}
              >
                <img
                  alt="logo"
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    right: '10%',
                    top: '7%',
                    // boxShadow: '5px 5px 5px #A8A8A8',
                  }}
                  className="img"
                  // src={require('../assets/admin-login.jpg')}
                  src={
                    AdminImage2
                    // AdminImage
                  }
                />
              </Box>
            </Box>
          </Box>
        </Container>
        <Footer />
      </Box>
    </>
  )
}

export default Login

const style = {
  outBox: {
    width: '100%',
    //bgcolor: '#F3F0EE',
    overflow: 'hidden',
    // pt: 5,
    // pb: 5,
  },
  mainBox: {
    // maxWidth: '1530px',
    width: '100%',
    minHeight: '500px',
    ml: 'auto',
    mr: 'auto',
    // p: '40px',
    bgcolor: 'white',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: { xs: 'center', md: 'flex-end' },
    alignItems: 'center',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: { xs: '100%', md: '50%' },
  },
  button: {
    width: '30%',
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      border: '1.5px solid #FF8D2A',
    },
    bgcolor: '#CB2128',
    color: '#FFFFFF',
    border: '1.5px solid #FF8D2A',
    mb: 2,
    mt: 4,
    borderRadius: '5px',
    fontSize: { xs: '16px', md: '16px', lg: '16px' },
    fontWeight: 400,
    textTransform: 'none',
    transform: 'skewX(-20deg)',
    '& .MuiButton-label': {
      transform: 'skewX(20deg)',
    },
  },
}
