import React from 'react'
import '../../App.css'

const NoDataFound = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        style={{ maxWidth: '500px' }}
        alt="Workinprogress"
        className="Workinprogress"
        // src={vehicData?.photo_links[selected]?.image_url}
        src={require('../../assets/no-data-found.jpg')}

        // onClick={() => setOpen(true)}
      />
    </div>
  )
}

export default NoDataFound
