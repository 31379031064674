/* eslint-disable no-unreachable */
import axios from 'axios'
import { toast } from 'react-toastify'
import { API_URL } from './apiUrl'
import { storage } from './storage'
import { LOGIN } from '../routes/constURL'

export const getListRequest = async (url, data) => {
  let guest_api = { api_key: storage.fetch.guest_user_api_key() }
  if (storage.fetch.guest_user_api_key()) {
    let data1 = {}
    if (!data) {
      data = data1
      Object.assign(data1, guest_api)
    } else {
      Object.assign(data, guest_api)
    }
    try {
      const res = await axios({
        url: API_URL + url,
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: storage.fetch.authToken(),
        },
      })

      if (res.data.code == 401) {
        toast.error('Token is expired. Please login again')
        setTimeout(() => {
          localStorage.clear()
          // window.location.href = '/login'
        }, 2000)
      } else {
        return res ? res : res.data
      }
    } catch (err) {
      // toast.error("some error occured");
    }
  } else {
    try {
      const res = await axios({
        url: API_URL + '/get/key',
        method: 'POST',
        data,
      })
      if (res.data.status) {
        storage.set.guest_user_api_key(res.data.data.api_key)
        let data1 = {}
        if (!data) {
          data = data1
          Object.assign(data1, guest_api)
        } else {
          Object.assign(data, guest_api)
        }

        Object.assign(data, guest_api)
        try {
          const res = await axios({
            url: API_URL + url,
            method: 'POST',
            data,
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: storage.fetch.authToken(),
            },
          })

          if (res.data.code == 401) {
            toast.error('Token is expired. Please login again')
            setTimeout(() => {
              localStorage.clear()

              window.location.href = '/login'
            }, 2000)
          } else {
            return res ? res : res.data
          }
        } catch (err) {
          // toast.error("some error occured");
        }
      }
    } catch (err) {}
  }
}

export const PostRequest = async (url, data) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: 'POST',
      data,
      // body:JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        Authorization: url === '/login' ? '' : storage.fetch.authToken(),
      },
    })
    return res ? res : res
  } catch (err) {
    console.log(err)
    switch (err.response.status) {
      case 401:
        toast.error(err.response.data.message)
        setTimeout(() => {
          localStorage.clear()
          window.location.href = LOGIN
        }, 2000)
        return err.response.data
      case 0:
        if (url === '/post/create') {
          toast.error('Please upload content of less than 90 mb')
        } else
          toast.error(
            'Something went wrong ,Please reload this page or try again after sometime.',
          )
        return 500
      case 303:
        return err.response.data

      default:
        return err.response.data
    }
  }
}
export const GetRequest = async (url, data) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: 'GET',
      data,
      // body:JSON.stringify(data),
      headers: {
        Accept: 'application/json',
      },
    })
    return res ? res : res
  } catch (err) {
    //console.log(err.response.status)
    switch (err.response.status) {
      case 401:
        toast.error(err.response.data.message)
        setTimeout(() => {
          localStorage.clear()
          window.location.href = LOGIN
        }, 2000)
        return err.response.data
      case 0:
        toast.error(
          'Something went wrong ,Please reload this page or try again after sometime.',
        )
        return 500
      case 303:
        return err.response.data
      default:
        return null
    }
  }
}
export const Api = {
  getListRequest,
  PostRequest,
  GetRequest,
}
