import {
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  SET_ADMIN_DETAILS,
  SET_ADMIN_LIST,
  SET_ADMIN_PROFILE,
  SET_CUSTOMER_LIST,
  SET_JUMPING_DATA,
  SET_JUMPING_EDIT,
  SET_MEASUREMENT_DATA,
  SET_MEASUREMENT_EDIT,
  SET_PROFILE_DATA,
  SET_TIMELINE,
  SET_TIMELINE_POST,
} from '../ConstAction'
const initialState = {}
export const AdminData = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADMIN_LIST:
      return {
        ...state,
        list: action.response.data.data,
        empty: false,
        // loadingStatus: false,
      }
    case SET_ADMIN_DETAILS:
      return {
        ...state,
        details: action.response.data.data,
        empty: false,
      }
    case GET_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: action.data,
      }
    case GET_EMPTY_STATUS:
      return {
        ...state,
        empty: action.data,
      }

    default:
      return { ...state }
  }
}
