import {
  GET_ADMIN_PROFILE_DATA,
  GET_LOADING_STATUS,
  GET_SET_ADMINLOGIN,
  GET_STATUS_FALSE,
  SET_ADMIN_PROFILE,
  SET_ADMIN_PROFILE_UPDATE,
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_NUMBER,
  SET_VERIFY_REQUEST
} from '../ConstAction'
const initialState = {}
export const LoginData = (state = initialState, action) => {
  switch (action.type) {
    case GET_SET_ADMINLOGIN:
      return {
        ...state,
        route: true,
      }
    case SET_ADMIN_PROFILE:
      return { ...state, adminData: action.response.data.data }

    case SET_ADMIN_PROFILE_UPDATE:
      return action.response.data
    case GET_LOADING_STATUS:
      // console.log('GET_LOADING_STATUS', action.data)
      return {
        ...state,
        loadingStatus: action.data,
      }
    case SET_NOTIFICATION_NUMBER:
      return {
        ...state,
      }
    case SET_NOTIFICATIONS:
      return {
        ...state,
      }
    case SET_VERIFY_REQUEST:
      return {
        ...state,
        verifyStatus:action.response.data,
        loadingStatus:false
      }
    default:
      return { ...state }
  }
}
