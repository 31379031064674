import { all, fork } from 'redux-saga/effects'
import Saga from './login/saga'
import Saga2 from './signup/saga2'
import CustomerSaga from './customer/customerSaga'
import ClubSaga from './clubs/clubSaga'
import PlayerSaga from './players/playerSaga'
import DashboardSaga from './dashboard/dashboardSaga'
import PaymentSaga from './payments/paymentSaga'
import RevenueSaga from './revenue/revenueSaga'
import EventSaga from './event_logs/eventSaga'
import SagaCustomer from './customer_panel/sagaCustomer'
import ProgramSaga from './program/programSaga'
import SagaMyAccount from './myaccount/myaccountSaga'
import adminSaga from './admins/adminSaga'

export default function* rootSaga() {
  yield all([
    fork(Saga),
    fork(Saga2),
    fork(CustomerSaga),
    fork(ClubSaga),
    fork(DashboardSaga),
    fork(PlayerSaga),
    fork(PaymentSaga),
    fork(RevenueSaga),
    fork(EventSaga),
    fork(SagaCustomer),
    fork(ProgramSaga),
    fork(SagaMyAccount),
    fork(adminSaga),
  ])
}
