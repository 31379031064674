export const LOGIN = '/'
export const CLUB_LOGIN = '/club-login'
export const CUSTOMER_LOGIN = '/login'
export const NODATAFOUND = '/no-data-found'
export const WORKINPROGRESS = '/work-in-progress'
export const SOMETHING_WENT_WRONG = '/something-went-wrong-500'
export const DASHBOARD = '/dashboard'
export const RESET_PASSWORD = '/password-reset'
export const FORGOT_PASSWORD = '/forgot-password'
export const THANKYOU = '/thankyou'
export const VERIFICATION = '/email-verified'

///-----Admin Profile------//////
export const PROFILE = '/profile'
export const PROFILE_UPDATE = '/profile/update'
export const PASS_UPDATE = '/profile/change_password'
///-----Bulk Orders------///////
export const BULK_ORDERS = '/bulk_orders'
export const BULK_ORDERS_LISTING = '/bulk_orders/listing'
export const ADD_BULK_ORDERS = '/bulk_orders/add'
export const BULK_ORDERS_INFO_EDIT = '/bulk_orders/view_edit'
export const BULK_ORDER_PASS = '/bulk_orders/change_password'
///-----Revenue Reports-------//////
export const REVENUE_REPORTS = '/revenue/revenue_reports'
export const REVENUE = '/revenue'
export const REVENUE_FAILED = '/revenue/revenue_failed'
export const REVENUE_REFUND = '/revenue/revenue_refund'
///-----Customers------/////
export const CUSTOMERSIGNUP = '/customersignup'
export const DEMOSIGNUP = '/demosignup'
export const CUSTOMERS = '/customer'
export const CUSTOMERS_I_E = '/customer/edit-info'
export const CUSTOMERS_I_D = '/customer/details'
export const CUSTOMERS_INFO_EDIT = '/customer/edit-info'
export const CUSTOMERS_ADD_NEW = '/customer/add-new'
export const CUSTOMERS_INFO_DETAILS = '/customer/details'
export const CUSTOMERS_MEASUREMENT_EDIT = '/customer/edit-measurement'
export const CUSTOMERS_JUMPING_EDIT = '/customer/edit-jumping-result'
export const CUSTOMERS_PASS = '/customer/change-password'
export const CUSTOMERS_SUBSCRIPTION = '/customer/edit-subscription'
export const CUSTOMER_LISTING = '/customer_listing'
export const CUSTOMER_PROFILE_DETAIL = '/customer/profile/timline'
export const CUSTOMER_PROFILE = '/customer/profile'
export const CUSTOMER_EDIT = '/customer/profile/edit'
export const CUSTOMER_MEASUREMENT_EDIT = '/customer/profile/edit/measurement'
export const CUSTOMER_JUMPING_EDIT = '/customer/profile/edit/jumpingresult'
export const CUSTOMER_PASS = '/customer/profile/change/password'
export const PROGRAM = '/program'
export const PROGRAM_ALL = '/program/week'
export const PROGRAM_VIEW = '/program/view'
export const ACCOUNT = '/account'
export const CUSTOMER_ACCOUNT = '/account/subscription'
export const CUSTOMER_REFER_FRIEND = '/account/refer-a-friend'
export const FEED = '/feeds'

///---------Club---------///
export const CLUB_PROFILE = '/club/profile'
export const CLUB_PROFILE_UPDATE = '/club/profile/update'
export const CLUB_PASS_UPDATE = '/club/profile/change-password'

export const CLUBDASHBOARD = '/clubdashboard'
export const CLUBSIGNUP = '/clubsignup'

////-----LOG EVENTS --------///
export const LOG_EVENTS = '/log_event'

///------------Player-------//////
export const PLAYER = '/player'
export const PLAYER_LISTING = '/player/listing'
export const PLAYER_INFO_DETAILS = '/player/details'
export const PLAYER_EDIT = '/player/edit'
export const PLAYER_MEASUREMENT = '/player/measurement'
export const PLAYER_JUMPING = '/player/jumping'

////----Standard Admin------///
export const ADMIN = '/admin'
export const ADMIN_ADD = '/admin_add'
export const ADMIN_EDIT = '/admin/edit'
export const ADMIN_CHANGE_PASS = '/admin/change_pass'
