import { put, takeEvery } from 'redux-saga/effects'
import {
  GET_ALL_LIST,
  GET_CUSTOMER_LIST,
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  GET_REFUND,
  GET_REVENUE_ADD,
  GET_REVENUE_DETAILS,
  GET_REVENUE_LIST,
  GET_SUBSCRIPTION_LIST,
  SET_ALL_FAIL_LIST,
  SET_ALL_LIST,
  SET_ALL_REFUND_LIST,
  SET_CUSTOMER_LIST,
  SET_FAIL_REVENUE_LIST,
  SET_REFUND,
  SET_REFUND_REVENUE_LIST,
  SET_REVENUE_ADD,
  SET_REVENUE_DETAILS,
  SET_REVENUE_LIST,
} from '../ConstAction'
import { toast } from 'react-toastify'
import RevenueApiRequests from '../../services/Revenue'
import { CUSTOMERS_SUBSCRIPTION, REVENUE_REPORTS } from '../../routes/constURL'
import CustomersApiRequests from '../../services/customer'
let listing_payload = {
  date: '',
  start: 0,
  length: 5,
  sort_column: '',
  sort_by: '',
  search_val: '',
  is_export: false,
}
///-----*Functions*-----///
function* HandleApiResponse(
  response,
  successAction,
  errorMessage,
  navigate,
  nav,
  message,
  refresh,
) {
  if (nav?.page) {
    listing_payload['start'] = (nav?.page - 1) * listing_payload['length']
  }
  if (nav?.payment_mode) {
    listing_payload['payment_mode'] = nav?.payment_mode
  }
  if (nav?.date) {
    listing_payload['date'] = nav?.date
  }
  if (nav?.sport) {
    listing_payload['sport'] = nav?.sport
  }
  if (nav?.search_val) {
    listing_payload['search_val'] = nav?.search_val
  }
  if (nav?.type) {
    listing_payload['type'] = nav?.type
  }
  //console.log("Listing Payload", listing_payload);
  if (successAction === SET_REVENUE_ADD) nav.handleClose()
  if (response.data.status) {
    if (navigate) {
      if (successAction !== SET_REFUND && successAction !== SET_REVENUE_ADD) {
        setTimeout(() => {
          nav.navigate(navigate)
        }, 2000)
      }
    }
    if (navigate) {
      //console.log('nnn1', navigate)
      yield put({
        type: navigate,
        data: listing_payload,
      })
    }
    if (refresh?.length > 0) {
      yield put({
        type: refresh,
        data: listing_payload,
      })
    }
    //console.log('nnn', navigate)

    if (navigate === GET_SUBSCRIPTION_LIST) {
      Object.assign(listing_payload, { users_id: nav.users_id })
    }
    // let a = (response.data.data.jumpresult_list.page - 1) * 5

    if (message) {
      setTimeout(() => {
        toast.success(message)
      }, 1000)
    }
    yield put({
      type: successAction,
      response,
    })
  } else if (response.data.code === 303) {
    yield put({
      type: GET_EMPTY_STATUS,
      data: true,
    })
    console.log(response.data.message)
    if (nav?.page) {
      listing_payload['start'] = (nav?.page - 1) * listing_payload['length']
    }
    setTimeout(() => {
      toast.error(response.data.message || errorMessage)
    }, 1000)
    if (navigate) {
      //console.log('nnn1', navigate)
      yield put({
        type: navigate,
        data: listing_payload,
      })
    }
  } else {
    setTimeout(() => {
      toast.error(response?.data?.errors[0] || errorMessage)
    })
  }

  yield put({
    type: GET_LOADING_STATUS,
    data: false,
  })
}

function* getRevenueList(data) {
  try {
    let response = yield RevenueApiRequests.requestRevenueList(data.data)
    if (data.data.payment_status === 'failed') {
      yield HandleApiResponse(
        response,
        SET_FAIL_REVENUE_LIST,
        'Failed to fetch revenue list',
      )
    } else if (data.data.payment_status === 'refunded') {
      yield HandleApiResponse(
        response,
        SET_REFUND_REVENUE_LIST,
        'Failed to fetch revenue list',
      )
    } else {
      yield HandleApiResponse(
        response,
        SET_REVENUE_LIST,
        'Failed to fetch revenue list',
      )
    }
  } catch (err) { }
}
function* getCustomerList(data) {
  try {
    let response = yield CustomersApiRequests.requestCustomerList(data.data)
    yield HandleApiResponse(
      response,
      SET_CUSTOMER_LIST,
      'Failed to fetch customer list',
    )
  } catch (err) { }
}
function* getRevenueDetails(data) {
  try {
    let response = yield RevenueApiRequests.requestRevenueDetails(data.data)
    yield HandleApiResponse(
      response,
      SET_REVENUE_DETAILS,
      'Failed to fetch revenue',
    )
  } catch (err) { }
}
function* getRevenueAdd(data) {
  try {
    let response = yield RevenueApiRequests.requestRevenueAdd(data.data)
    yield HandleApiResponse(
      response,
      SET_REVENUE_ADD,
      'Failed to add revenue',
      GET_REVENUE_LIST,
      data,
      response?.data?.message,
    )
  } catch (err) { }
}
function* getReund(data) {
  //console.log(window.location.pathname)
  const url = window.location.pathname.slice(0, 27)
  try {
    let response = yield RevenueApiRequests.requestReund(data.data)
    if (url === CUSTOMERS_SUBSCRIPTION) {
      yield HandleApiResponse(
        response,
        SET_REFUND,
        'Failed to refund payment',
        GET_SUBSCRIPTION_LIST,
        data.data,
        response?.data?.message,
      )
    } else {
      yield HandleApiResponse(
        response,
        SET_REFUND,
        'Failed to refund payment',
        GET_REVENUE_LIST,
        data.data,
        response?.data?.message,
        GET_REVENUE_DETAILS,
      )
    }
  } catch (err) { }
}
function* getall(data) {
  try {
    let response = yield RevenueApiRequests.requestRevenueallList(data.data)

    if (data.data.payment_status === 'failed') {
      yield HandleApiResponse(
        response,
        SET_ALL_FAIL_LIST,
        'Failed to get list',
        // REVENUE_REPORTS,
        // data,
        // response?.data?.message,
      )
    } else if (data.data.payment_status === 'refunded') {
      yield HandleApiResponse(
        response,
        SET_ALL_REFUND_LIST,
        'Failed to get list',
        // REVENUE_REPORTS,
        // data,
        // response?.data?.message,
      )
    } else {
      yield HandleApiResponse(
        response,
        SET_ALL_LIST,
        'Failed to get list',
        // REVENUE_REPORTS,
        // data,
        // response?.data?.message,
      )
    }
  } catch (err) { }
}
function* RevenueSaga() {
  yield takeEvery(GET_REVENUE_LIST, getRevenueList)
  yield takeEvery(GET_REVENUE_DETAILS, getRevenueDetails)
  yield takeEvery(GET_REVENUE_ADD, getRevenueAdd)
  yield takeEvery(GET_CUSTOMER_LIST, getCustomerList)
  yield takeEvery(GET_REFUND, getReund)
  yield takeEvery(GET_ALL_LIST, getall)
}

export default RevenueSaga
