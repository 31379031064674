import styled from '@emotion/styled'
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useReducer, useState } from 'react'
import { Schema } from '../../Components/FormSchema/Schema'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import InputField from '../../Components/InputField'
import TransformButton from '../../Components/CustomButton/TransformButton'
import JumpingForm from '../../Components/Forms/JumpingForm'
import {
  getCustomerAddUpdateRequest,
  getCustomerDetailsRequest,
} from '../../redux/customer/customerAction'
import { useDispatch } from 'react-redux'
import {
  getAdminProfileUpdate,
  getLoadingStatus,
} from '../../redux/login/action'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Toastify from '../../Components/SnackBar/Toastify'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import { storage } from '../../config/storage'
import { EnhancedTableHead } from '../../Components/TableHeader/TableHeader'
import {
  getAdminProfileRequest,
  getJumpDatabyid,
  getJumpingData,
  getJumpingDelete,
} from '../../redux/customer_panel/actionCustomer'
import { initialState, reducerFunction } from '../../config/mockData'
import CustomPagination from '../../Components/Pagination'
import CustomTableBody from '../../Components/TableBox/CustomTableBody'
import AlertDialog from '../../Components/Dialog/Dialog'
import MeasureandJumpingModal from '../../Components/Modal/MeasureandJumpingModal'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const EditJumping = () => {
  const data = useSelector((state) => state)
  const navigate = useNavigate()
  const { Jumpinglist, empty, JumpData, customerData, loadingStatus } =
    useSelector((state) => state?.CustomerPanelData) || {}
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)

  const newState = storage.fetch.user_details()
  const myDispatch = useDispatch()
  const [columns, setColumns] = React.useState([])

  const [initialJumpingData, setInitialJumpingData] = useState({
    init_jump_height: '',
  })
  let data1 = {
    date: '',
    start: 0,
    length: 5,
    sort_column: '',
    sort_by: '',
    search_val: '',
    page: state.page,
  }
  useEffect(() => {
    window.scroll(0, 0)
    myDispatch(getAdminProfileRequest())
    myDispatch(getJumpingData(data1))
    myDispatch(getLoadingStatus(true))
    setColumns([
      { label: 'Date', name: 'date' },
      { label: 'Jump Height', name: 'height' },
    ])
  }, [])
  useEffect(() => {
    if (customerData) {
      setInitialJumpingData({
        init_jump_height:
          customerData?.init_jump_height == 0
            ? ''
            : customerData?.init_jump_height,
      })
    }
  }, [customerData])
  useEffect(() => {
    setStateReducer({
      pages: Jumpinglist?.pages,
      // page: Jumpinglist?.page,
      total: Jumpinglist?.total_records,
    })
  }, [Jumpinglist])
  const initialFormik = useFormik({
    initialValues: initialJumpingData,
    validationSchema: Schema.JUMPING,
    onSubmit: (value) => {
      setInitialJumpingData(value)
      onIntialSubmit(value)
    },
    enableReinitialize: true,
  })
  const onIntialSubmit = (value) => {
    const data = storage.fetch.user_details()
    // const email = JSON.parse(data)
    const formData = new FormData()
    formData.append('email', data.email)
    formData.append('init_jump_height', value.init_jump_height)
    myDispatch(getAdminProfileUpdate(formData))
    myDispatch(getLoadingStatus(true))
  }
  const handlePageChange = (event, value) => {
    setStateReducer({ page: value })
    let a = (value - 1) * state.length
    data1.start = a
    data1.length = state.length

    if (state.order && state.orderBy) {
      data1.sort_by = state.order === 'asc' ? 'desc' : 'asc'
      data1.sort_column = state.orderBy
    }
    myDispatch(getJumpingData(data1))
    myDispatch(getLoadingStatus(true))
  }
  const handleDelete = (id) => {
    setStateReducer({ id: id })
    setStateReducer({ alert: true })
  }
  const handleEdit = (id) => {
    myDispatch(getJumpDatabyid({ jump_result_id: id }))
    myDispatch(getLoadingStatus())
  }
  const confirmDelete = async (id) => {
    if (Jumpinglist?.filtered_records === 1) {
      Object.assign(Jumpinglist, { page: state.page - 1 })
      setStateReducer({ page: state.page - 1 })
      myDispatch(
        getJumpingDelete(
          { jump_result_id: id, page: state.page - 1 },
          navigate,
        ),
      )
    } else {
      ////console.log('page', state.page)
      myDispatch(
        getJumpingDelete({ jump_result_id: id, page: state.page }, navigate),
      )
    }

    myDispatch(getLoadingStatus(true))
    setStateReducer({ alert: false })
  }
  ////console.log(state.id)
  // const onSubmit = () => {}
  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )
  return (
    <>
      <form name="initialStandingJumping" onSubmit={initialFormik.handleSubmit}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <AlertDialog
            title={'Are you sure you want to delete this Jumping Result?'}
            open={state.alert}
            onClickButton={() => confirmDelete(state.id)}
            onClickButtonCancel={() => setStateReducer({ alert: false })}
          />
          {/* {//console.log('loadingStatussss', loadingStatus)} */}
          {/* <MeasureandJumpingModal jump={true} open={state.open} id={state.id} /> */}
          <LoaderComponent open={loadingStatus} />
          <Toastify />
          <Grid container sx={{ mb: '20px' }} columnSpacing={2}>
            <Grid sx={Style.mainGrid} item xs={12} sm={6} md={4}>
              <Item>
                <InputField
                  value={initialFormik.values.init_jump_height}
                  onChange={initialFormik.handleChange}
                  type={'number'}
                  placeholder={'Enter your initial jumping results'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'init_jump_height'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Initial Standing Jumping Result (cm)'}
                />
                {initialFormik.errors.init_jump_height &&
                initialFormik.touched.init_jump_height ? (
                  <p style={Style.validationStyle}>
                    {initialFormik.errors.init_jump_height}
                  </p>
                ) : null}
              </Item>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Item>
                <Button
                  className="btn-rt-skw"
                  variant="contained"
                  type="submit"
                  // onClick={onSubmit}
                  sx={Style.button_one}
                >
                  <Typography
                    sx={{ transform: 'skew(22deg) ', fontWeight: 500 }}
                  >
                    Update
                  </Typography>
                </Button>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </form>
      <JumpingForm
        Style={Style}
        data={data}
        customer={true}
        val={JumpData && JumpData}
        loading={loadingStatus}
        page={state.page}
      />
      <Box sx={Style.table.tableWrapBox}>
        <Table sx={Style.table.tableBox} aria-labelledby="tableTitle">
          <EnhancedTableHead
            // numSelected={selected.length}
            display={'none'}
            columns={columns}
            setColumns={setColumns}
            // action={true}
            // order={order}
            // orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            // onRequestSort={handleRequestSort}
            // rowCount={rows.length}
          />
          {/* //API_DATA// */}
          <TableBody>
            {Jumpinglist?.jumpresult_list &&
              Jumpinglist?.jumpresult_list?.map((row, index) => (
                <TableRow
                  key={row.users_id}
                  sx={{
                    bgcolor: (index + 1) % 2 === 0 ? '#EEF2FC' : 'white',
                    border: 0,
                  }}
                >
                  <TableCell align="left" sx={Style.table.tableCell}>
                    {/* {loading && <Skeleton sx={{ width: '100px' }} />} */}
                    {row?.formate_jump_date}
                  </TableCell>
                  <TableCell align="left" sx={Style.table.tableCell}>
                    {/* {loading && <Skeleton sx={{ width: '100px' }} />} */}
                    {row?.jump_height}cm
                  </TableCell>
                  <TableCell align="left" sx={Style.table.tableCell}>
                    <EditIcon
                      style={Style.editButton}
                      onClick={() => handleEdit(row?.id)}
                    />
                    <DeleteIcon
                      style={{
                        width: '19px',
                        height: '19px',
                        cursor: 'pointer',
                        marginBottom: '3px',
                        color: '#CB2128',
                        marginLeft: '3%',
                      }}
                      onClick={() => handleDelete(row?.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          {Jumpinglist?.jumpresult_list === undefined ||
            (Jumpinglist?.jumpresult_list.length == 0 && (
              <TableRow>
                <TableCell
                  colSpan={4}
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  No Data Found
                </TableCell>
              </TableRow>
            ))}
          <Toastify />
        </Table>

        {/* <CustomPagination
          state={Jumpinglist}
          empty={empty}
          page={state.page}
          handlePageChange={handlePageChange}
        /> */}
      </Box>
      {state.pages > 1 && (
        <Box
          sx={[
            Style.paginationBox,
            {
              display: empty ? 'none' : 'flex',
              justifyContent: 'flex-end',
              mt: 2,
              mb: 2,
            },
          ]}
        >
          <Pagination
            count={state.pages}
            page={state?.page}
            boundaryCount={1}
            onChange={handlePageChange}
            siblingCount={0}
            variant="outlined"
            shape="rounded"
            sx={{
              '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                  background: '#27245E',
                  color: 'white',
                },
              },
            }}
          />
        </Box>
      )}
    </>
  )
}

export default EditJumping
const Style = {
  table: {
    tableWrapBox: {
      overflow: 'auto',
      pt: 2,
      // p: { xs: 1, sm: 3 },
    },
    tableBox: {
      minWidth: { xs: '775px', md: '100%' },
      // border: '1px solid #dddddd',
      borderRadius: '25px',
    },
    SubscriptiontableBox: {
      width: { xs: '775px', md: '800px', lg: '900px' },
      // border: '1px solid #dddddd',
      borderRadius: '25px',
    },
    tableCell: {
      width: '50px',
      maxWidth: '80px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: '13px',
      color: '#707070',
      fontWeight: '600',
      padding: '20px',
      border: '0px',
      textTransform: 'capitalize',
    },

    tableCell1: {
      minWidth: '131px',
      fontSize: '13px',
      color: '#707070',
      fontWeight: '600',
      justifyContent: 'center',
      border: '0px',
      display: 'flex',
      textTransform: 'capitalize',
      marginRight: '50px',
    },
    tableCell2: {
      width: '50px',
      maxWidth: '100px',
      textOverflow: 'ellipsis',
      display: 'flex',
      whiteSpace: 'nowrap',
      // overflow: 'hidden',
      fontSize: '13px',
      color: '#707070',
      fontWeight: '600',
      padding: '20px',
      border: '0px',
      textTransform: 'capitalize',
    },
    button: {
      backgroundColor: '#EEF2FC',
      minWidth: '40px',
      borderRadius: '20px 0px 0px 20px',
      color: '#2D8E42',
      padding: '3px 10px 3px 10px',
      maxWidth: '100%',
      margin: '0px',
      fontSize: '16px',
      border: '1px solid #C3CDFF',
    },
    button2: {
      backgroundColor: '#2D8E42',
      minWidth: '40px',
      borderRadius: '0px 20px 20px 0px',
      color: '#fff',
      padding: '3px 10px 3px 10px',
      maxWidth: '100%',
      margin: '0px',
      fontSize: '16px',
      border: '1px solid #2D8E42',
    },
    headingText: {
      fontSize: { xs: '14px', sm: '31px' },
      fontWeight: '700',
      color: '#000000',
      // p: { xs: 1, sm: 3 },
      display: 'flex',
      alignItems: 'center',
    },
  },
  editButton: {
    width: '19px',
    height: '19px',
    cursor: 'pointer',
    marginBottom: '3px',
    color: '#CB2128',
  },
  button_one: {
    bgcolor: '#CB2128',
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      boxShadow: 'none',
    },

    // m: { xs: 1, sm: 2 },
    mt: '50px',
    borderRadius: '0px',
    boxShadow: 'none',
    p: '0px 30px 0px 30px',

    color: 'white',
    // width: "120px",
    textTransform: 'none',
    transform: {
      xs: 'skew(-22deg) translate(0%, -48%)',
      sm: 'skew(-22deg) translate(10%, 0%)',
    },
    height: '40px',
    fontSize: { xs: '12px', sm: '18px' },
  },
  star: {
    color: 'red',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    color: '#333333',
  },

  validationStyle: {
    color: 'red',
    fontWeight: 400,
  },
  mainGrid: {
    mt: '20px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: { md: 'flex-start' },
    mt: 2,
    mb: 2,
    pr: { xs: 1, sm: 10 },
    pl: { xs: 1, sm: 10 },
  },
}
