import Login from '../Login/Login'
import Dashboard from '../Admin/Pages/Dashboard'
import BulkDashboard from '../ClubAdmin/BulkDashboard'
import {
  ADD_BULK_ORDERS,
  BULK_ORDERS,
  BULK_ORDERS_INFO_EDIT,
  BULK_ORDERS_LISTING,
  BULK_ORDER_PASS,
  CLUBDASHBOARD,
  CLUBSIGNUP,
  CLUB_PASS_UPDATE,
  CLUB_PROFILE,
  CLUB_PROFILE_UPDATE,
  CUSTOMERS,
  CUSTOMERSIGNUP,
  DEMOSIGNUP,
  CUSTOMERS_ADD_NEW,
  CUSTOMERS_INFO_DETAILS,
  CUSTOMERS_INFO_EDIT,
  CUSTOMERS_JUMPING_EDIT,
  CUSTOMERS_MEASUREMENT_EDIT,
  CUSTOMERS_PASS,
  CUSTOMERS_SUBSCRIPTION,
  CUSTOMER_LISTING,
  DASHBOARD,
  LOGIN,
  NODATAFOUND,
  PASS_UPDATE,
  PLAYER,
  PLAYER_EDIT,
  PLAYER_INFO_DETAILS,
  PLAYER_JUMPING,
  PLAYER_LISTING,
  PLAYER_MEASUREMENT,
  PROFILE,
  PROFILE_UPDATE,
  REVENUE,
  REVENUE_REFUND,
  REVENUE_REPORTS,
  REVENUE_FAILED,
  WORKINPROGRESS,
  RESET_PASSWORD,
  SOMETHING_WENT_WRONG,
  FORGOT_PASSWORD,
  THANKYOU,
  LOG_EVENTS,
  CLUB_LOGIN,
  CUSTOMER_LOGIN,
  VERIFICATION,
  CUSTOMER_PROFILE,
  PROGRAM,
  CUSTOMER_ACCOUNT,
  ACCOUNT,
  PROGRAM_VIEW,
  PROGRAM_ALL,
  CUSTOMER_REFER_FRIEND,
  CUSTOMER_MEASUREMENT_EDIT,
  CUSTOMER_JUMPING_EDIT,
  CUSTOMER_PASS,
  CUSTOMER_EDIT,
  CUSTOMER_PROFILE_DETAIL,
  FEED,
  ADMIN,
  ADMIN_ADD,
  ADMIN_EDIT,
  ADMIN_CHANGE_PASS,
} from './constURL'
import OpenRoutes from './openRoutes'
import ProtectedRoute from './protectedRoutes'
import AdminProfile from '../Admin/Pages/AdminProfile'
import Profile from '../Admin/Pages/Profile'
import AdminPassword from '../Admin/Pages/AdminPassword'
import WorkInProgress from '../Admin/Pages/WorkInProgress'
import NoDataFound from '../Admin/Pages/NoDataFound'
import CustomerInfo from '../Admin/Pages/CustomerInfo'
import CustomersList from '../Admin/Pages/CustomerList'
import CustomerDetails from '../Admin/Pages/CustomerDetails'
import MeasurementEdit from '../Admin/Pages/MeasurementEdit'
import JumpingResults from '../Admin/Pages/JumpingResults'
import ChangePassCustomer from '../Admin/Pages/ChangePassCustomer'
import SubscriptionCustomer from '../Admin/Pages/SubscriptionCustomer'
import BulkOrders from '../Admin/Pages/BulkOrders'
import AddCustomer from '../Admin/Pages/AddCustomer'
import AddBulkOrder from '../Admin/Pages/AddBulkOrder'
import EditViewBulkOrder from '../Admin/Pages/EditViewBulkOrder'
import PlayerList from '../ClubAdmin/PlayerList'
import PlayerDetails from '../ClubAdmin/PlayerDetails'
import { storage } from '../config/storage'
import ClubAdmin from '../ClubAdmin/ClubAdmin'
import ClubPassword from '../ClubAdmin/ClubPassword'
import Signup from '../ClubAdmin/Signup'
import CustomerSignup from '../Customer/Pages/Signup'
import DemoSignup from '../Customer/Pages/DemoSignup'
import Error404 from '../Admin/Pages/ErrorPageNotFound'
import PlayerEdit from '../ClubAdmin/PlayerEdit'
import PlayerMeasurement from '../ClubAdmin/PlayerMeasurement'
import PlayerJumping from '../ClubAdmin/PlayerJumping'
import Revenue from '../Admin/Pages/Revenue'
import RevenueFailed from '../Admin/Pages/RevenueFailed.js'
import RevenueRefund from '../Admin/Pages/RevenueRefund'
import ResetPassword from '../Login/ResetPassword'
import SomethingWrong from '../Admin/Pages/SomethingWrong'
import ForgotPassword from '../Login/ForgotPassword'
import Thankyou from '../Customer/Pages/Thankyou'
import AdminProtected from './AdminProtected'
import ClubProtected from './ClubProtected'
import Verification from '../Login/Verification'
import CustomerProfile from '../Customer/Pages/CustomerProfile'
import LogEvents from '../Admin/Pages/LogEvents'
import Programs from '../Customer/Pages/Programs'
import MyAccount from '../Customer/Pages/MyAccount'
import ViewProgram from '../Customer/Pages/ViewProgram'
import Refer from '../Customer/Pages/Refer'
import EditProfile from '../Customer/Pages/EditProfile'
import EditMeasurement from '../Customer/Pages/EditMeasurement'
import EditJumping from '../Customer/Pages/EditJumping'
import ChangePass from '../Customer/Pages/ChangePass'
import Admins from '../Admin/Pages/Admins'
import AddAdmin from '../Admin/Pages/AddAdmin'
import ChangePassAdmin from '../Admin/Pages/ChangePassAdmin'
import EditAdmin from '../Admin/Pages/EditAdmin'
import Payment from '../Components/Payment'
import CustomerProtected from './CustomerProtected'
// import  from "../"
const login_user = storage.fetch.role()
const data = storage?.fetch?.user_details()
const reSub = storage.fetch.program_weeks_list()

export const globalRoutes = [
  {
    path: LOGIN,
    element: Login,
    routeType: OpenRoutes,
  },
  {
    path: CLUB_LOGIN,
    element: Login,
    routeType: OpenRoutes,
  },
  {
    path: CUSTOMER_LOGIN,
    element: Login,
    routeType: OpenRoutes,
  },
  {
    path: FORGOT_PASSWORD,
    element: ForgotPassword,
    routeType: OpenRoutes,
  },
  {
    path: THANKYOU,
    element: Thankyou,
    routeType: OpenRoutes,
  },
  {
    path: RESET_PASSWORD + '/:id',
    element: ResetPassword,
    routeType: OpenRoutes,
  },
  {
    path: VERIFICATION,
    element: Verification,
    routeType: OpenRoutes,
  },
  {
    path: '*',
    element: Error404,
    routeType: ProtectedRoute,
  },
  {
    path: PROFILE,
    element: Profile,
    routeType: AdminProtected,
    child: [
      {
        path: PROFILE_UPDATE,
        element: AdminProfile,
      },
      {
        path: PASS_UPDATE,
        element: AdminPassword,
      },
    ],
  },

  {
    path: CUSTOMERS,
    element: Profile,
    routeType: AdminProtected,
    child: [
      {
        path: CUSTOMERS_INFO_EDIT + '/:id',
        element: CustomerInfo,
      },
      {
        path: CUSTOMERS_MEASUREMENT_EDIT + '/:id',
        element: MeasurementEdit,
      },
      {
        path: CUSTOMERS_JUMPING_EDIT + '/:id',
        element: JumpingResults,
      },
      {
        path: CUSTOMERS_PASS + '/:id',
        element: ChangePassCustomer,
      },
      {
        path: CUSTOMERS_SUBSCRIPTION + '/:id',
        element: SubscriptionCustomer,
      },
    ],
  },

  {
    path: CUSTOMER_PROFILE,
    element: Profile,
    routeType: ProtectedRoute,
    child: [
      {
        path: CUSTOMER_EDIT,
        element: EditProfile,
      },
      {
        path: CUSTOMER_MEASUREMENT_EDIT,
        element: EditMeasurement,
      },
      {
        path: CUSTOMER_JUMPING_EDIT,
        element: EditJumping,
      },
      {
        path: CUSTOMER_PASS,
        element: ChangePass,
      },
    ],
  },
  {
    path: CUSTOMERS_ADD_NEW,
    element: AddCustomer,
    routeType: AdminProtected,
  },
  {
    path: CUSTOMER_LISTING,
    element: CustomersList,
    routeType: AdminProtected,
  },

  {
    path: CUSTOMERS_INFO_DETAILS + '/:id',
    element: CustomerDetails,
    routeType: AdminProtected,
  },
  {
    path: CUSTOMER_PROFILE_DETAIL,
    element: CustomerProfile,
    routeType: CustomerProtected,
  },
  {
    path: PROGRAM,
    // element: data?.active === 'active' ? Profile : Payment,
    element: Profile,
    routeType: CustomerProtected,
    child: [
      {
        path: PROGRAM_ALL + '/:week',
        element: Programs,
      },
      {
        path: PROGRAM_VIEW + '/:id',
        element: ViewProgram,
      },
    ],
  },

  {
    path: FEED,
    element: Profile,
    routeType: CustomerProtected,
  },
  {
    path: ACCOUNT,
    element: Profile,
    routeType: CustomerProtected,
    child: [
      {
        path: CUSTOMER_ACCOUNT,
        element: MyAccount,
        routeType: ProtectedRoute,
      },
      {
        path: CUSTOMER_REFER_FRIEND,
        element: Refer,
        routeType: ProtectedRoute,
      },
    ],
  },
  {
    path: DASHBOARD,
    element: Dashboard,
    routeType: AdminProtected,
  },

  {
    path: CLUBDASHBOARD,
    element: BulkDashboard,
    routeType: ClubProtected,
  },
  {
    path: CLUB_PROFILE,
    element: Profile,
    routeType: ClubProtected,
    child: [
      {
        path: CLUB_PROFILE_UPDATE,
        element: ClubAdmin,
      },
      {
        path: CLUB_PASS_UPDATE,
        element: ClubPassword,
      },
    ],
  },
  {
    path: BULK_ORDERS_LISTING,
    element: BulkOrders,
    routeType: AdminProtected,
  },
  {
    path: ADD_BULK_ORDERS,
    element: AddBulkOrder,
    routeType: AdminProtected,
  },
  {
    path: BULK_ORDERS,
    element: Profile,
    routeType: AdminProtected,
    child: [
      {
        path: BULK_ORDERS_INFO_EDIT + '/:id',
        element: EditViewBulkOrder,
      },
      {
        path: BULK_ORDER_PASS + '/:id',
        element: AdminPassword,
      },
    ],
  },
  {
    path: REVENUE,
    element: Revenue,
    routeType: AdminProtected,
    child: [
      {
        path: REVENUE_REPORTS,
        element: Revenue,
        routeType: AdminProtected,
      },
      {
        path: REVENUE_FAILED,
        element: WorkInProgress,
        routeType: AdminProtected,
      },
      {
        path: REVENUE_REFUND,
        element: WorkInProgress,
        routeType: AdminProtected,
      },
    ],
  },
  {
    path: PLAYER_LISTING,
    element: PlayerList,
    routeType: ClubProtected,
  },
  {
    path: PLAYER_INFO_DETAILS + '/:id',
    element: PlayerDetails,
    routeType: ClubProtected,
  },
  {
    path: PLAYER,
    element: Profile,
    routeType: ClubProtected,
    child: [
      {
        path: PLAYER_EDIT + '/:id',
        element: PlayerEdit,
        routeType: ProtectedRoute,
      },
      {
        path: PLAYER_MEASUREMENT + '/:id',
        element: PlayerMeasurement,
        routeType: ProtectedRoute,
      },
      {
        path: PLAYER_JUMPING + '/:id',
        element: PlayerJumping,
        routeType: ProtectedRoute,
      },
    ],
  },
  {
    path: CLUBSIGNUP,
    element: Signup,
    routeType: OpenRoutes,
  },
  {
    path: CUSTOMERSIGNUP,
    element: CustomerSignup,
    // routeType: OpenRoutes,
  },
  {
    path: DEMOSIGNUP,
    element: DemoSignup,
  },
  {
    path: LOG_EVENTS,
    element: LogEvents,
    routeType: AdminProtected,
  },
  {
    path: ADMIN,
    element: Admins,
    routeType: AdminProtected,
  },
  {
    path: ADMIN,
    element: Profile,
    routeType: AdminProtected,
    child: [
      {
        path: ADMIN_EDIT + '/:id',
        element: EditAdmin,
        routeType: ProtectedRoute,
      },
      {
        path: ADMIN_CHANGE_PASS + '/:id',
        element: ChangePassAdmin,
        routeType: ProtectedRoute,
      },
    ],
  },
  {
    path: ADMIN_ADD,
    element: AddAdmin,
    routeType: AdminProtected,
  },
  {
    path: WORKINPROGRESS,
    element: WorkInProgress,
    routeType: ProtectedRoute,
  },
  {
    path: NODATAFOUND,
    element: NoDataFound,
    routeType: ProtectedRoute,
  },
  {
    path: SOMETHING_WENT_WRONG,
    element: SomethingWrong,
    routeType: ProtectedRoute,
  },
]
