import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import TopBox from '../../Components/TableHeader/TopBox'
import { tabsProgramData } from '../../config/mockData'
import RevenueTab from '../../Components/Tabs/RevenueTab'
import VideoPlayer from '../../Components/Media/VideoPlayer'
import { getProgramListRequest } from '../../redux/program/programAction'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getLoadingStatus } from '../../redux/login/action'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import Toastify from '../../Components/SnackBar/Toastify'
import { storage } from '../../config/storage'

const Programs = () => {
  const { list, empty, loadingStatus } = useSelector(
    (state) => state?.ProgramData || {},
  )
  const myDispatch = useDispatch()
  const params = useParams()
  // const list = useSelector((state) => state?.ProgramData)
  //console.warn('list', params.week)
  useEffect(() => {
    document.title = 'OG Sports - Program'
    myDispatch(getProgramListRequest())
    myDispatch(getLoadingStatus(true))
  }, [])

  return (
    <Grid>
      <Grid container>
        <LoaderComponent open={loadingStatus} />
        <Grid>
          <VideoPlayer
            loading={loadingStatus}
            count={list?.[`week_${params.week}`]}
            // height={200}
            width={370}
            week={params.week}
          />
          <Toastify />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Programs
const Style = {
  box: {
    display: 'flex',
    width: '100%',
    bgcolor: '#F5F4FF',
    border: '1px solid #CDCAFC',
    mt: 5,
    flexWrap: { xs: 'wrap', sm: 'unset' },
  },
  itembox: {
    fontSize: '12px',
    fontWeight: '600',
    boxShadow: 'none',
    p: '10px 25px 10px 25px',
    textAlign: { xs: 'center', sm: 'unset' },
  },
}
