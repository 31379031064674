import { call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_EMPTY_STATUS,
  GET_ERROR_STATUS,
  GET_LOADING_STATUS,
  GET_MARK_COMPLETE,
  GET_PROGRAM_LIST,
  GET_PROGRAM_VIEW,
  GET_VIEW_UPDATE,
  SET_MARK_COMPLETE,
  SET_PROGRAM_LIST,
  SET_PROGRAM_VIEW,
  SET_VIEW_UPDATE
} from '../ConstAction'
import { toast } from 'react-toastify'
import { storage } from '../../config/storage'
import ProgramApiRequests from '../../services/programs'
import { PROGRAM_ALL } from '../../routes/constURL'
///-----*Functions*-----///
function* HandleApiResponse(
  response,
  successAction,
  errorMessage,
  toaster,
  navigate,
  nav,
) {
  // const navigate=useNavigate()
  // //console.log(navigate, nav)
  //console.log('response', response?.code)
  if (response.status) {
    if (navigate) {
      setTimeout(() => {
        nav.navigate(navigate)
      }, 2000)
    }
    const { data } = response?.data || {}
    if (data?.token) {
      storage.set.authToken(data.token)
    }
    if (data?.role) {
      storage.set.role(data.role)
    }
    if (toaster) {
      toast.success(response?.data?.message)
    }
    yield put({
      type: successAction,
      response,
    })
  } else if (response.code === 303) {
    //console.log('response', response?.data)
    toast.error(response?.data?.error[0])
    yield put({
      type: GET_EMPTY_STATUS,
      data: false,
    })
  } else if (response == 500) {
    yield put({
      type: GET_ERROR_STATUS,
      data: false,
    })
  } else {
    toast.error(
      response?.data?.errors[0] || errorMessage || response?.data?.error,
    )
  }
  yield put({
    type: GET_LOADING_STATUS,
    data: false,
  })
}

function* getProgramList(data) {
  try {
    let response = yield ProgramApiRequests.requestProgramList(data.data)
    yield HandleApiResponse(
      response,
      SET_PROGRAM_LIST,
      'Failed to fetch player list',
      true,
    )
    storage.set.program_weeks_list(Object.keys(response?.data?.data))
  } catch (err) {
    HandleApiResponse(err)
  }
}
function* getProgramView(data) {
  try {
    let response = yield ProgramApiRequests.requestProgramView(data.data)
    yield HandleApiResponse(
      response,
      SET_PROGRAM_VIEW,
      'Failed to fetch player',
    )
  } catch (err) {}
}
function* getMarkAsComplete(data) {
  try {
    const week = storage.fetch.week_number()
    let response = yield ProgramApiRequests.requestMarkComplete(data.data)
    yield HandleApiResponse(
      response,
      SET_MARK_COMPLETE,
      'Failed to mark complete',
      true,
      PROGRAM_ALL + `/${week}`,
      data,
    )
  } catch (err) {}
}
function* getViewUpdate(data) {
  try{
    let response = yield ProgramApiRequests.requestViewUpdate(data.data)
    yield HandleApiResponse(
      response,
      SET_VIEW_UPDATE,
      'Failed to update the view date'
    )
  } catch (err) {}
}
function* customNavigation(action) {
  if (action.URL) {
    action.navigate(action.URL)
  }
}

function* ProgramSaga() {
  yield takeEvery(GET_PROGRAM_LIST, getProgramList)
  yield takeEvery(GET_PROGRAM_VIEW, getProgramView)
  yield takeEvery(GET_MARK_COMPLETE, getMarkAsComplete)
  yield takeEvery(GET_VIEW_UPDATE, getViewUpdate)

  yield takeEvery('NAVIGATE', customNavigation)
}

export default ProgramSaga
