import styled from '@emotion/styled'
import { Box, Button, Grid, Paper, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useReducer } from 'react'
import RevenueTab from '../../Components/Tabs/RevenueTab'
import {
  initialState,
  reducerFunction,
  tabsRevenueData,
} from '../../config/mockData'
import { useLocation } from 'react-router-dom'
import {
  REVENUE_FAILED,
  REVENUE_REFUND,
  REVENUE_REPORTS,
} from '../../routes/constURL'
import {
  getRevenueDetailsRequest,
  getRevenueListRequest,
  getRevenueallListRequest,
} from '../../redux/revenue/revenueAction'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import Toastify from '../../Components/SnackBar/Toastify'
import { getLoadingStatus } from '../../redux/login/action'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import { CSVLink } from 'react-csv'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
  background: '#EEF2FC',
  borderRadius: '10px',
  textAlign: 'center',
  padding: '20px',
}))

const Revenue = () => {
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const { details, alllist, allrefundlist, allfaillist, loadingStatus } =
    useSelector((state) => state?.RevenueData) || {}
  const location = useLocation()
  const myDispatch = useDispatch()
  let URL = location.pathname
  let data = {
    payment_status:
      URL === REVENUE_REPORTS
        ? ''
        : URL === REVENUE_REFUND
          ? 'refunded'
          : 'failed',
  }
  const call = () => {
    setStateReducer({ list: [] })
    Object.assign(data, { is_export: true })
    myDispatch(getRevenueallListRequest(data))
  }
  useEffect(() => {
    document.title = 'OG Sports - Revenue Reports'
    window.scrollTo(0, 0)
    myDispatch(getRevenueDetailsRequest())
    // myDispatch(getRevenueallListRequest(data))
    myDispatch(getLoadingStatus(true))
  }, [])

  useEffect(() => {
    if (URL === REVENUE_REPORTS) {
      setStateReducer({ list: alllist?.subscriptions })
    } else if (URL === REVENUE_REFUND)
      setStateReducer({ list: allrefundlist?.subscriptions })
    else {
      setStateReducer({ list: allfaillist?.subscriptions })
    }
  }, [alllist, allfaillist, allrefundlist])
  console.log(state.list)
  const headers = [
    { label: 'Username', key: 'user_name' },
    { label: 'Role', key: 'user_role' },
    { label: 'Membership', key: 'user_membership' },
    { label: 'Sport', key: 'user_sports' },
    { label: 'Payment date', key: 'user_subscribe_at' },
    { label: 'Payment Type', key: 'payment_type' },
    { label: 'Status', key: 'user_payment_status' },
    { label: 'Tenure (Days)', key: 'user_tenure_days' },
    { label: 'Revenue (ex GST)', key: 'user_revenue_without_tax' },
    { label: 'Payment Mode', key: 'user_mode_of_payment' },
    // Add more headers as needed
  ]

  // useEffect(() => {
  //   setStateReducer({ details: data })
  // }, [details])
  return (
    <Grid>
      {/* <Toastify /> */}
      <Grid>
        <Typography sx={Style.headingText}>Revenue Reports</Typography>
        <LoaderComponent />
      </Grid>
      <Grid container columnGap={3} rowGap={3} sx={{ pt: 3 }}>
        <Item sx={Style.item}>
          {!details && <Skeleton sx={Style.skelton} />}
          <Typography style={Style.text}>
            ${details && details?.total_revenue}
          </Typography>
          <Typography style={Style.text1}>Total Revenue</Typography>
        </Item>
        <Item sx={Style.item}>
          {!details && <Skeleton sx={Style.skelton} />}
          <Typography style={Style.text}>
            ${details && details?.annual_revenue}
          </Typography>
          <Typography style={Style.text1}>Yearly Revenue</Typography>
        </Item>
        <Item sx={Style.item}>
          {!details && <Skeleton sx={Style.skelton} />}
          <Typography style={Style.text}>
            ${details && details?.bulk_order_revenue}
          </Typography>
          <Typography style={Style.text1}>Bulk order Revenue</Typography>
        </Item>
        <Item sx={Style.item}>
          {!details && <Skeleton sx={Style.skelton} />}
          <Typography style={Style.text}>
            {details && details?.total_subscription_count}
          </Typography>
          <Typography style={Style.text1}>Active Subscriptions</Typography>
        </Item>
      </Grid>
      <Box sx={Style.box}>
        {tabsRevenueData.map((item, index) => (
          <RevenueTab
            key={index}
            item_route={item.route}
            URL={URL}
            style={Style.itembox}
            item_text={item.text}
          />
        ))}
        <LoaderComponent open={loadingStatus} />
        {/* {console.log(alllist?.subscriptions)} */}
        <Box sx={Style.buttonBox}>
          {/* <Button sx={Style.button} onClick={() => printDiv()}>
            Export
          </Button> */}
          {state.list && (
            <CSVLink
              style={Style.button}
              data={state.list}
              headers={headers}
              filename={'Revenue.csv'}
              className="btn btn-primary"
              target="_blank"
            >
              Export
            </CSVLink>
          )}
        </Box>
      </Box>
      {URL === REVENUE_REPORTS
        ? tabsRevenueData.map((Item, index) => {
            return URL === Item.route ? (
              <Item.page key={index} call={call} />
            ) : null
          })
        : URL === REVENUE_FAILED
          ? tabsRevenueData.map((Item, index) => {
              return URL === Item.route ? (
                <Item.page key={index} call={call} />
              ) : null
            })
          : tabsRevenueData.map((Item, index) => {
              return URL === Item.route ? (
                <Item.page key={index} call={call} />
              ) : null
            })}
    </Grid>
  )
}

export default Revenue
const Style = {
  skelton: {
    height: '35px',
    ml: '35px',
    mr: '35px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #CDCAFC',
    marginBottom: '5px',
    width: { xs: '100%' },
    borderRadius: '0px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: { xs: '45%', md: '63%' },
    paddingRight: { xs: 0, md: '10px' },
  },
  button: {
    backgroundColor: '#2D8E42',
    color: 'white',
    fontWeight: 600,
    borderRadius: '5px',
    textTransform: 'none',
    maxHeight: '30px',
    marginTop: '5px',
    padding: '5px',
    paddingTop: '2px',
    textDecoration: 'none',
  },
  text: {
    color: 'red',
    fontSize: '20px',
    fontWeight: '700',
  },
  text1: {
    fontWeight: 700,
    color: '#27245E',
  },
  item: {
    width: { xs: '100%', md: '23.2%', lg: '22%', xl: '17.2vw' },
  },
  item1: {
    width: { xs: '100%' },
    mt: 2,
  },
  link: {
    textDecoration: 'none',
    border: 'none',
    color: '#27245E',
    textAlign: 'center',
    padding: '10px',
  },
  headingText: {
    fontSize: { xs: '16px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },
  box: {
    display: 'flex',
    width: '100%',
    bgcolor: '#F5F4FF',
    border: '1px solid #CDCAFC',
    mt: 5,
    flexWrap: { xs: 'wrap', sm: 'unset' },
  },

  itembox: {
    fontSize: '12px',
    fontWeight: '600',
    boxShadow: 'none',
    p: '10px 25px 10px 25px',
    textAlign: { xs: 'center', sm: 'unset' },
  },
}
