import styled from '@emotion/styled'
import { Box, Button, FormLabel, Grid, Paper, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Schema } from '../../Components/FormSchema/Schema'
import InputField from '../../Components/InputField'
import TransformButton from '../../Components/CustomButton/TransformButton'
import JumpingForm from '../../Components/Forms/JumpingForm'
import {
  getCustomerAddUpdateRequest,
  getCustomerDetailsRequest,
} from '../../redux/customer/customerAction'
import { useDispatch } from 'react-redux'
import { getLoadingStatus } from '../../redux/login/action'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Toastify from '../../Components/SnackBar/Toastify'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const JumpingResults = () => {
  const newState = useSelector((state) => state?.CustomerData)
  const myDispatch = useDispatch()

  const Params = useParams()
  let data = {
    users_id: Params.id,
  }
  // //console.log(newState)
  const [initialJumpingData, setInitialJumpingData] = useState({
    init_jump_height: '',
  })
  useEffect(() => {
    callApi()
  }, [])
  useEffect(() => {
    if (newState) {
      setInitialJumpingData({
        init_jump_height: newState?.customer_details?.init_jump_height,
      })
    }
  }, [newState])

  const initialFormik = useFormik({
    initialValues: initialJumpingData,
    validationSchema: Schema.JUMPING,
    onSubmit: (value) => {
      setInitialJumpingData(value)
      onIntialSubmit(value)
    },
    enableReinitialize: true,
  })

  const callApi = () => {
    myDispatch(getCustomerDetailsRequest({ users_id: data.users_id }))
    myDispatch(getLoadingStatus(true))
  }
  const onIntialSubmit = (value) => {
    // console.log(value)
    const formData = new FormData()
    formData.append('users_id', data.users_id)
    formData.append('init_jump_height', value.init_jump_height)
    myDispatch(getCustomerAddUpdateRequest(formData))
    myDispatch(getLoadingStatus(true))
    setTimeout(() => {
      callApi()
    }, 2000)
  }
  // const onSubmit = () => {}
  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )
  return (
    <>
      <form name="initialStandingJumping" onSubmit={initialFormik.handleSubmit}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <LoaderComponent open={newState?.loadingStatus} />
          <Toastify />
          <Grid container sx={{ mb: '20px' }} columnSpacing={2}>
            <Grid sx={Style.mainGrid} item xs={12} sm={6} md={4}>
              <Item>
                <InputField
                  value={initialFormik.values.init_jump_height}
                  onChange={initialFormik.handleChange}
                  type={'number'}
                  placeholder={'Enter your initial results'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'init_jump_height'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Initial Standing Jumping Results(cm)'}
                />
                {initialFormik.errors.init_jump_height &&
                initialFormik.touched.init_jump_height ? (
                  <p style={Style.validationStyle}>
                    {initialFormik.errors.init_jump_height}
                  </p>
                ) : null}
              </Item>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Item>
                <Button
                  className="btn-rt-skw"
                  variant="contained"
                  type="submit"
                  // onClick={onSubmit}
                  sx={Style.button_one}
                >
                  <Typography
                    sx={{ transform: 'skew(22deg) ', fontWeight: 500 }}
                  >
                    Update
                  </Typography>
                </Button>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </form>
      <JumpingForm Style={Style} data={data} />
    </>
  )
}

export default JumpingResults
const Style = {
  button_one: {
    bgcolor: '#CB2128',
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      boxShadow: 'none',
    },

    // m: { xs: 1, sm: 2 },
    mt: '50px',
    borderRadius: '0px',
    boxShadow: 'none',
    p: '0px 30px 0px 30px',

    color: 'white',
    // width: "120px",
    textTransform: 'none',
    transform: {
      xs: 'skew(-22deg) translate(0%, -48%)',
      sm: 'skew(-22deg) translate(10%, 0%)',
    },
    height: '40px',
    fontSize: { xs: '12px', sm: '18px' },
  },
  star: {
    color: 'red',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    color: '#333333',
  },

  validationStyle: {
    color: '#CB2128',
    fontWeight: 400,
  },
  mainGrid: {
    mt: '20px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: { md: 'flex-start' },
    mt: 2,
    mb: 2,
    pr: { xs: 1, sm: 10 },
    pl: { xs: 1, sm: 10 },
  },
}
