import { GET_CLUB_SIGNUP, GET_NOTIFICATION_NUMBER } from '../ConstAction'

export const getClubSignUp = (data, navigate) => {
  // console.log(data)
  return {
    type: GET_CLUB_SIGNUP,
    data: data,
    navigate: navigate,
  }
}
