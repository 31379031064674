import React from 'react'
import '../../Admin/CSS/CustomerDetail.css'
import { Button, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { CUSTOMERSIGNUP } from '../../routes/constURL'
const TransformButton = ({
  iconName,
  handleClick,
  button_name,
  typeSubmit,
  textT,
  color,
  transform,
}) => {
  const style = {
    button_one: {
      bgcolor: color === 'rgb(45, 142, 66)' ? 'rgb(45, 142, 66)' : '#CB2128',
      '&.MuiButtonBase-root:hover': {
        // bgcolor: '#CB2128',
        bgcolor: color === 'rgb(45, 142, 66)' ? 'rgb(45, 142, 66)' : '#CB2128',
        boxShadow: 'none',
      },
      // '&.MuiButtonLabel': {
      //   '@media (max-width: 600px)': { // xs breakpoint
      //     transform: 'skew(-22deg) translate(0%, -50%)',
      //   },
      //   '@media (min-width: 601px)': { // sm breakpoint
      //     transform: 'skew(-22deg) translate(-54%, -50%)',
      //   },
      // },
      // m: { xs: 1, sm: 2 },
      mt: '30px',
      boxShadow: 'none',
      p: { xs: '0px 46px 0px 50px', md: '0px 50px 0px 50px' },
      borderRadius: '0px',
      color: 'white',
      // width: "120px",
      textTransform: 'none',
      transform: {
        xs: transform ? transform : 'skew(-22deg) translate(0%, -50%)',
        sm: transform ? transform : 'skew(-22deg) translate(-54%, -50%)',
      },
      height: '40px',
      fontSize: { xs: '12px', sm: '18px' },
    },
  }
  const location = useLocation()
  const isCustomerSignup = location.pathname === CUSTOMERSIGNUP // Check if the current path is CUSTOMERSIGNUP
  const IconComponent = iconName
  return (
    <div>
      <Button
        className="btn-rt-skw"
        variant="contained"
        onClick={handleClick}
        type={typeSubmit}
        sx={[
          style.button_one,
          { textTransform: textT },
          isCustomerSignup && {
            // ml: { md: '-25%' },
            width: { lg: '82%', md: '75%', xs: '100%'},
          },
        ]}
      >
        <Typography
          sx={{
            transform: 'skew(22deg)',
            fontWeight: 500,
            fontSize: { xs: '10px', md: '16px' },
          }}
        >
          {button_name}
        </Typography>{' '}
        {IconComponent && (
          <IconComponent style={{ marginLeft: '15px', fontSize: '18px' }} />
        )}
        {/* {iconName} */}
      </Button>
    </div>
  )
}

export default TransformButton
