import { put, takeEvery } from 'redux-saga/effects'
import {
  GET_BULK_PAYMENT,
  GET_CLUB_LIST,
  GET_COUNTRIES,
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  GET_SIGNUP_PAYMENT,
  GET_STATES,
  SET_BULK_PAYMENT,
  SET_COUNTRIES,
  SET_SIGNUP_PAYMENT,
  SET_STATES,
} from '../ConstAction'
import { toast } from 'react-toastify'
import { storage } from '../../config/storage'
import PaymentApiRequests from '../../services/payments'
import { THANKYOU } from '../../routes/constURL'
///-----*Functions*-----///
function* HandleApiResponse(
  response,
  successAction,
  errorMessage,
  navigate,
  nav,
) {
  if (response.data.status) {
    const { data } = response?.data || {}
    if (data?.subscription_id) {
      storage.set.subs_id(data.subscription_id)
      storage.set.oneTime_id(data.oneTimePaymentIntentId)
      if (navigate) {
        setTimeout(() => {
          ////console.log(navigate + `?type=${nav.data.type}`)
          nav.navigate(
            navigate + `?type=${nav.data.type}&email=${nav.data.email}`,
          )
        }, 2000)
      }
    }
    if (data?.role) {
      storage.set.role(data.role)
    }
    toast.success(response?.data?.message)
    yield put({
      type: successAction,
      response,
    })
    if (successAction === SET_BULK_PAYMENT) {
      yield put({
        type: GET_CLUB_LIST,
      })
    }
  } else {
    toast.error(response?.data?.message || errorMessage)
  }
  yield put({
    type: GET_LOADING_STATUS,
    data: false,
  })
}
function* getSignupPayment(data) {
  // data.navigate("/")
  //console.log(data.data.type)
  try {
    let response = yield PaymentApiRequests.requestSignupStripePayment(
      data.data,
    )
    yield HandleApiResponse(
      response,
      SET_SIGNUP_PAYMENT,
      'Failed to delete player',
      THANKYOU,
      data,
    )
  } catch (err) {}
}
function* getCountries(data) {
  // data.navigate("/")
  try {
    let response = yield PaymentApiRequests.requestCountries(data.data)
    yield HandleApiResponse(response, SET_COUNTRIES, 'Failed to get countries')
  } catch (err) {}
}
function* getStates(data) {
  // data.navigate("/")
  try {
    let response = yield PaymentApiRequests.requestStates(data.data)
    yield HandleApiResponse(response, SET_STATES, 'Failed to get states')
  } catch (err) {}
}

function* PaymentSaga() {
  yield takeEvery(GET_SIGNUP_PAYMENT, getSignupPayment)
  yield takeEvery(GET_COUNTRIES, getCountries)
  yield takeEvery(GET_STATES, getStates)
}

export default PaymentSaga
