import { Box, Grid, Paper } from '@mui/material'
import React from 'react'
import InputField from '../InputField'
import styled from '@emotion/styled'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const InputGrids = ({xsOrder,xs,sm,control,helperText,errors,type,placeholder,name,formlabel}) => {
  return (
    <Grid item order={{ xs: xsOrder, sm: 0 }} xs={xs} sm={sm}>
      <Item>
        <Box sx={{ mb: '0px' }}>
          <InputField
            control={control}
            helperText={helperText}
            errors={errors}
            type={type}
            placeholder={placeholder}
            size={{ xs: '18px', md: '18px', lg: '18px' }}
            color={'#333333'}
            name={name}
            required={'*'}
            formlabel={formlabel}
          />
        </Box>
      </Item>
    </Grid>
  )
}

export default InputGrids
