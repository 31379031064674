import {
  GET_MARK_COMPLETE,
  GET_PROGRAM_LIST,
  GET_PROGRAM_VIEW,
  GET_VIEW_UPDATE
} from '../ConstAction'
export const getProgramListRequest = (data) => {
  return {
    type: GET_PROGRAM_LIST,
    data: data,
  }
}
export const getProgramViewRequest = (data) => {
  return {
    type: GET_PROGRAM_VIEW,
    data: data,
  }
}
export const getMarkAsComplete = (data, navigate) => {
  return {
    type: GET_MARK_COMPLETE,
    data: data,
    navigate: navigate,
  }
}

export const getUpdateRequest = (data)=> {
  return {
    type: GET_VIEW_UPDATE,
    data: data
  }
}
