import {
  GET_CUSTOMER_DELETE,
  GET_CUSTOMER_DETAILS,
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_PROFILE_UPDATE,
} from '../ConstAction'

export const getCustomerListRequest = (data) => {
  return {
    type: GET_CUSTOMER_LIST,
    data: data,
  }
}
export const getCustomerDetailsRequest = (data) => {
  return {
    type: GET_CUSTOMER_DETAILS,
    data: data,
  }
}
export const getCustomerAddUpdateRequest = (data, navigate) => {
  return {
    type: GET_CUSTOMER_PROFILE_UPDATE,
    data: data,
    navigate: navigate,
  }
}
export const getCustomerDelete = (data) => {
  return {
    type: GET_CUSTOMER_DELETE,
    data: data,
  }
}
