import styled from '@emotion/styled'
import { Box, CircularProgress, Grid, Paper } from '@mui/material'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import FeedBox from '../../Components/Timeline/FeedBox'
import { getTimeline } from '../../redux/customer_panel/actionCustomer'
import { getLoadingStatus } from '../../redux/login/action'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'

import { initialState, reducerFunction } from '../../config/mockData'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import { Button } from 'react-bootstrap'
import { ArrowUpward } from '@mui/icons-material'
import { storage } from '../../config/storage'
const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  alignItems: 'center',
}))
const Feed = () => {
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const { list, loadingStatus } = useSelector(
    (state) => state?.CustomerPanelData,
  )
  const [visible, setVisible] = useState(false)
  const [fetchingData, setFetchingData] = useState(false)

  const timelineRef = useRef(null)
  const myDispatch = useDispatch()
  let data = {
    user_filter: false,
    date: '',
    start: 0,
    length: 10,
    sort_column: '',
    sort_by: '',
    search_val: '',
  }
  useEffect(() => {
    document.title = 'OG Sports - Feed'
    window.scroll(0, 0)
    myDispatch(getTimeline(data))
    myDispatch(getLoadingStatus(true))
  }, [])
  useEffect(() => {
    storage.set.posts(list)
  }, [list])
  const handleScroll = () => {
    const container = timelineRef.current
    // console.log(container)

    // Calculate the distance scrolled
    const scrolled = container.scrollTop

    // Calculate the total height of the content
    const totalHeight = container.scrollHeight

    // Calculate the height of the visible portion
    const visibleHeight = container.clientHeight

    // Calculate the remaining space
    const remainingSpace = totalHeight - (scrolled + visibleHeight)

    // console.log(remainingSpace)
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
    if (remainingSpace < 10 && !fetchingData) {
      // If the remaining space is less than 10 pixels, fetch more data
      fetchMoreData()
    }
  }

  const fetchMoreData = async () => {
    setFetchingData(true) // Set fetchingData to true to indicate that fetching is in progress

    const all = await storage.fetch.posts()
    console.log(all.total_records, data['length'])
    if (all?.filtered_records < all?.total_records) {
      data['length'] = data['length'] + 10
      myDispatch(getTimeline(data))
    }

    setFetchingData(false)
  }
  const scrollToTop = () => {
    timelineRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    })
  }
  useEffect(() => {
    setStateReducer({ total: list?.total_records })
  }, [loadingStatus, list])
  useEffect(() => {
    const timelineElement = timelineRef.current
    if (timelineElement) {
      timelineElement.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (timelineElement) {
        timelineElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])
  return (
    <Grid container>
      {' '}
      <Grid xs={12} md={8}>
        {/* <InfiniteScroll
          dataLength={list && list?.pages * 10}
          pullDownToRefreshThreshold={50}
          next={fetchMoreData}
          style={{ overflowX: 'hidden', paddingBottom: '50px' }}
          hasMore={true}
          loader={
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress
                sx={{
                  display: 'flex',
                  overflowX: 'hidden',
                  height: '60px',
                  width: '60px',
                }}
              />
            </Box>
          }
          scrollThreshold={10}
          // endMessage={
          //   <p style={{ textAlign: 'center' }}>
          //     <b>You've seen it all!</b>
          //   </p>
          // }
          scrollableTarget="scrollableDiv"
        > */}
        <LoaderComponent open={loadingStatus} />
        <Box
          className="timeline"
          ref={timelineRef}
          sx={{
            maxHeight: '450px',
            overflow: 'scroll',
            width: '100%',
          }}
        >
          <FeedBox
            count={list?.logs_list}
            loading={data.start === 0 ? loadingStatus : false}
            total={state.total}
            user_filter={false}
          />
          <Box>
            <ArrowUpward
              onClick={scrollToTop}
              style={{
                display: visible ? 'inline' : 'none',
                background: '#4cd964',
                borderRadius: '50%',
                color: 'white',
                cursor: 'pointer',
                // top: '-50px',
                right: '400px',
                bottom: '50px',
                // marginBottom: '150px',
                position: 'absolute',
              }}
            />
          </Box>
          {!loadingStatus && list?.filtered_records !== list?.total_records && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress
                sx={{
                  display: 'flex',
                  overflowX: 'hidden',
                  height: '60px',
                  width: '60px',
                }}
              />
            </Box>
          )}
        </Box>

        {/* </InfiniteScroll> */}
      </Grid>
      <Grid></Grid>
    </Grid>
  )
}

export default Feed
