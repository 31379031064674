import { put, takeEvery } from 'redux-saga/effects'
import { storage } from '../../config/storage'
import {
  GET_CUSTOMER_DELETE,
  GET_CUSTOMER_DETAILS,
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_PROFILE_UPDATE,
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  SET_CUSTOMER_DELETE,
  SET_CUSTOMER_DETAILS,
  SET_CUSTOMER_LIST,
  SET_CUSTOMER_PROFILE_UPDATE,
} from '../ConstAction'
import CustomersApiRequests from '../../services/customer'
import { toast } from 'react-toastify'
import { navigateTo } from '../navigationAction/action'
import {
  BULK_ORDERS,
  CUSTOMERS_INFO_DETAILS,
  CUSTOMER_LISTING,
} from '../../routes/constURL'

function* getCustomerList(data) {
  try {
    let response = yield CustomersApiRequests.requestCustomerList(data.data)
    if (response.data.status) {
      yield put({
        type: SET_CUSTOMER_LIST,
        response,
      })
      yield put({
        type: GET_LOADING_STATUS,
        data: false,
      })
      // yield put(navigateTo('BULK_ORDERS', { response }))
    } else if (response.data.status === 303) {
      yield put({
        type: GET_EMPTY_STATUS,
        data: true,
      })
    } else {
      yield put({
        type: GET_LOADING_STATUS,
        data: false,
      })
      yield put({
        type: GET_EMPTY_STATUS,
        data: true,
      })
    }
  } catch (err) {}
}

function* getCustomerDetails(data) {
  try {
    let response = yield CustomersApiRequests.requestCustomerDetails(data.data)
    ////console.warn('response', response.status)
    if (response.status) {
      ////console.warn(response?.data)
      yield put({
        type: SET_CUSTOMER_DETAILS,
        response,
      })
      yield put({
        type: GET_LOADING_STATUS,
        data: false,
      })
    } else {
      yield put({
        type: GET_LOADING_STATUS,
        data: false,
      })
    }
  } catch (err) {}
}
function* getCustomerAddUpdate(data) {
  try {
    let response = yield CustomersApiRequests.requestCustomerAddUpdate(
      data.data,
    )
    if (response.data.status) {
      ////console.log(data.data)
      yield toast.success(response?.data?.message)
      yield put({
        type: SET_CUSTOMER_PROFILE_UPDATE,
        response,
      })
      if (
        data.data.users_id &&
        (data.data.measure_date || data.data.jump_date)
      ) {
        yield put({
          type: GET_CUSTOMER_DETAILS,
          data: { users_id: data.data.users_id },
        })
        setTimeout(() => {
          data?.navigate(`${CUSTOMERS_INFO_DETAILS}/${data.data.users_id}`)
        }, 2000)
      }
      const formdata = Object.fromEntries(data.data).users_id
      if (formdata) {
        yield put({
          type: GET_CUSTOMER_DETAILS,
          data: { users_id: formdata },
        })
      }
    } else {
      toast.error(response?.data?.errors[0])
    }
    yield put({
      type: GET_LOADING_STATUS,
      data: false,
    })
  } catch (err) {}
}
function* getCustomerDelete(data) {
  try {
    let response = yield CustomersApiRequests.requestCustomerDelete(data.data)
    if (response.status) {
      toast.success(response?.data?.message)

      yield put({
        type: SET_CUSTOMER_DELETE,
        response,
      })
      yield put({
        type: GET_LOADING_STATUS,
        data: true,
      })
      yield put({
        type: GET_CUSTOMER_LIST,
      })
    } else if (response.code == 303) {
      toast.error(response?.errors[0])
      yield put({
        type: GET_EMPTY_STATUS,
        data: true,
      })
      yield put({
        type: GET_CUSTOMER_LIST,
      })
      yield put({
        type: GET_LOADING_STATUS,
        data: true,
      })
    } else {
      toast.error(response?.errors[0])
      yield put({
        type: GET_LOADING_STATUS,
        data: true,
      })
      yield put({
        type: GET_CUSTOMER_LIST,
      })
    }
  } catch (err) {}
}
function* CustomerSaga() {
  yield takeEvery(GET_CUSTOMER_LIST, getCustomerList)
  yield takeEvery(GET_CUSTOMER_DETAILS, getCustomerDetails)
  yield takeEvery(GET_CUSTOMER_PROFILE_UPDATE, getCustomerAddUpdate)
  yield takeEvery(GET_CUSTOMER_DELETE, getCustomerDelete)
}

export default CustomerSaga
