import {
  GET_ADMIN_PROFILE,
  GET_CUSTOMER_DETAILS,
  GET_JUMPING_DATA,
  GET_MEASUREMENT_DATA,
  GET_MEASUREMENT_MODIFY_DATA,
  GET_JUMPING_DELETE,
  GET_JUMPING_UPDATE,
  GET_TIMELINE,
  GET_TIMELINE_POST,
  GET_JUMPING_EDIT,
  GET_MEASUREMENT_EDIT,
  GET_MEASUREMENT_DELETE,
  GET_PROFILE_DATA,
} from '../ConstAction'
export const getTimeline = (data) => {
  return {
    type: GET_TIMELINE,
    data: data,
  }
}
export const getTimelinePosted = (data, callApi) => {
  return {
    type: GET_TIMELINE_POST,
    data: data,
    callApi: callApi,
  }
}
export const getAdminProfileRequest = (data) => {
  return {
    type: GET_PROFILE_DATA,
    data: data,
  }
}
export const getJumpingData = (data) => {
  return {
    type: GET_JUMPING_DATA,
    data: data,
  }
}
export const getMeasurementData = (data) => {
  return {
    type: GET_MEASUREMENT_DATA,
    data: data,
  }
}
export const getMeasurementModify = (data) => {
  return {
    type: GET_MEASUREMENT_MODIFY_DATA,
    data: data,
  }
}

export const getCustomerJumpingUpdate = (data, navigate) => {
  return {
    type: GET_JUMPING_UPDATE,
    data: data,
    navigate: navigate,
  }
}
export const getJumpingDelete = (data, navigate) => {
  return {
    type: GET_JUMPING_DELETE,
    data: data,
    navigate: navigate,
  }
}
export const getJumpDatabyid = (data, navigate) => {
  return {
    type: GET_JUMPING_EDIT,
    data: data,
    navigate: navigate,
  }
}
export const getMeasurementEdit = (data, navigate) => {
  return {
    type: GET_MEASUREMENT_EDIT,
    data: data,
    navigate: navigate,
  }
}
export const getMeasurementDelete = (data, navigate) => {
  return {
    type: GET_MEASUREMENT_DELETE,
    data: data,
    navigate: navigate,
  }
}
