import {
  Button,
  Grid,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import styled from '@emotion/styled'
import { EnhancedTableHead } from '../../Components/TableHeader/TableHeader'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getDashboardData } from '../../redux/dashboard/dashboardAction'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  BULK_ORDERS_LISTING,
  CUSTOMER_LISTING,
  REVENUE_REPORTS,
} from '../../routes/constURL'
import { getLoadingStatus } from '../../redux/login/action'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import GridBox from '../../Components/Box/GridBox'
import CustomTableBody from '../../Components/TableBox/CustomTableBody'
import { storage } from '../../config/storage'
import NoDataFound from './NoDataFound'
import Toastify from '../../Components/SnackBar/Toastify'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
  background: '#EEF2FC',
  borderRadius: '10px',
  textAlign: 'center',
  padding: '20px',
}))
const Item1 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
  background: 'none',
}))

const Dashboard = () => {
  const newState = useSelector(
    (state) => state?.DashboardReducer?.dashboardData?.clubData,
  )
  const customerData = useSelector(
    (state) => state?.DashboardReducer?.dashboardData?.customerData,
  )
  const userData = useSelector(
    (state) => state?.DashboardReducer?.dashboardData?.userData,
  )
  const name = storage.fetch.AdminName()
  const loading = useSelector((state) => state?.DashboardReducer?.loadingStatus)
  const myDispatch = useDispatch()
  const [columns, setColumns] = React.useState([])
  const [columns1, setColumns1] = React.useState([])
  const [list1, setList1] = React.useState([])
  const [img, setImg] = React.useState()
  useEffect(() => {
    window.scrollTo(0, 0)
    myDispatch(getDashboardData())
    myDispatch(getLoadingStatus(true))
    const headCells = [
      { label: 'Customer Name', name: 'customerName' },
      { label: 'Created On', name: 'created_on' },
      { label: 'Membership', name: 'Membership' },
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    setColumns(headCells)
    const headCells1 = [
      { label: 'Club Name', name: 'clubName' },
      { label: 'Licences', name: 'Licences' },
      { label: 'Created On', name: 'created_on' },
      { label: 'Amount', name: 'Amount' },
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    setColumns1(headCells1)
  }, [])
  useEffect(() => {
    document.title = 'OG Sports - Dashboard'
    setList1([
      {
        value: customerData?.total_customers
          ? customerData?.total_customers
          : '0',
        name: 'Total Customers',
        click: CUSTOMER_LISTING,
      },
      {
        value: newState?.total_bulk_orders ? newState?.total_bulk_orders : '0',
        name: 'Total Bulk Orders',
        click: BULK_ORDERS_LISTING,
      },
      {
        value: newState?.club_member_licenses
          ? newState?.club_member_licenses
          : '0',
        name: 'CLUB MEMBER LICENCES',
      },
      {
        value: newState?.total_revenue ? `$${newState?.total_revenue}` : '$0',
        name: 'Total Revenue',
        click: REVENUE_REPORTS,
      },
    ])
  }, [newState])

  useEffect(() => {
    console.log(storage.fetch.fetchImage())
    setImg(userData?.image)
  }, [userData?.image])
  useEffect(() => {
    console.log(img)
    if (
      !storage.fetch.fetchImage() ||
      storage.fetch.fetchImage() === 'undefined'
    ) {
      storage.set.profileImage(
        img !== null ? img : require('../../assets/profileRect.png'),
      )
    }
  }, [img])
  return (
    <Grid container rowGap={3}>
      <LoaderComponent open={loading} />
      <Grid>
        <Typography sx={Style.headingText}>
          Welcome, {userData?.name} !
        </Typography>
      </Grid>
      <Grid container columnGap={3} rowGap={3}>
        <GridBox
          list={list1}
          width={{ xs: '100%', md: '22%', lg: '22.64%', xl: '23.4%' }}
        />
      </Grid>
      <Grid container columnGap={3} rowGap={4}>
        <Grid xs={12} md={5.6}>
          <Item1 sx={Style.row}>
            <Button disableRipple disableFocusRipple sx={Style.button}>
              Last 5 Individual Orders
            </Button>
            <Link to={CUSTOMER_LISTING} style={Style.link}>
              View all
            </Link>
          </Item1>
          <Item1 sx={{ ...Style.item1 }}>
            <Table>
              <EnhancedTableHead
                display={'none'}
                columns={columns}
                setColumns={setColumns}
                action={true}
              />
              {/* //API_DATA// */}
              <TableBody>
                {customerData &&
                  customerData?.customer_list.map((row, index) => (
                    <CustomTableBody
                      key={row?.users_id}
                      row={row}
                      loadingStatus={loading}
                      ind={index}
                      columns={[
                        row?.user_name,
                        row?.user_created_at,
                        row?.user_membership.length != 0
                          ? row?.user_membership
                          : 'No membership',
                      ]}
                    />
                  ))}
              </TableBody>
              {customerData?.total_records && <NoDataFound />}
            </Table>
          </Item1>
        </Grid>
        <Grid xs={12} md={6}>
          <Item1 sx={Style.row}>
            <Button disableRipple disableFocusRipple sx={Style.button}>
              Last 5 Bulk Orders
            </Button>
            <Link to={BULK_ORDERS_LISTING} style={Style.link}>
              View all
            </Link>
          </Item1>
          <Item1 sx={Style.item1}>
            <Table>
              <EnhancedTableHead
                display={'none'}
                columns={columns1}
                setColumns={setColumns1}
                action={true}
              />
              {/* //API_DATA// */}
              <TableBody sx={{ border: 0 }}>
                {newState &&
                  newState?.club_list.map((item, index) => (
                    <CustomTableBody
                      key={item?.users_id}
                      row={item}
                      loadingStatus={loading}
                      ind={index}
                      columns={[
                        item?.club_name,
                        item?.num_of_licenses,
                        item?.created_at,
                        `$${item?.amount}`,
                      ]}
                    />
                  ))}
              </TableBody>
              {newState?.total_records && <NoDataFound />}
            </Table>
          </Item1>
        </Grid>
      </Grid>
      <Toastify />
    </Grid>
  )
}

export default Dashboard

const Style = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #CDCAFC',
    marginBottom: '5px',
    width: { xs: '100%' },
    borderRadius: '0px',
  },
  button: {
    bgcolor: '#CB2128',
    color: 'white',
    fontWeight: 600,
    borderRadius: '0px',
    textTransform: 'none',
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      boxShadow: 'none',
    },
  },
  text: {
    color: 'red',
    fontSize: '20px',
    fontWeight: '700',
  },
  text1: {
    color: '#27245E',
  },
  item: {
    width: { xs: '100%', md: '23%' },
  },
  item1: {
    width: { xs: '100%', md: '100%' },
    mt: 2,
    overflow: { xs: 'scroll', md: 'hidden' },
    mb: 2,
  },
  link: {
    textDecoration: 'none',
    border: 'none',
    color: '#27245E',
    textAlign: 'center',
    padding: '10px',
  },
  headingText: {
    fontSize: { xs: '16px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },
}
