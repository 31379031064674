import React from 'react'
import '../../Admin/CSS/CustomerDetail.css'
import { Button, Typography } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useNavigate } from 'react-router-dom'
const BackButton = () => {
  const navigate = useNavigate()
  return (
    <div>
      <Button
        className="btn-rt-skw"
        variant="contained"
        onClick={() => navigate(-1)}
        sx={[style.button_one, { textTransform: 'none' }]}
      >
        <ArrowBackIosIcon sx={{ transform: 'skew(22deg) ' }} fontSize="16px" />
        <Typography sx={{ transform: 'skew(22deg) ', fontWeight: 500 }}>
          {'Back'}
        </Typography>
      </Button>
    </div>
  )
}

export default BackButton
const style = {
  button_one: {
    bgcolor: '#CB2128',
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      boxShadow: 'none',
    },
    mt: '30px',
    boxShadow: 'none',
    p: '0px 30px 0px 30px',
    borderRadius: '0px',
    color: 'white',
    textTransform: 'none',
    transform: {
      xs: 'skew(-22deg) translate(0%, -50%)',
      sm: 'skew(-22deg) translate(-12%, -50%)',
    },
    height: '40px',
    fontSize: { xs: '12px', sm: '18px' },
  },
}
