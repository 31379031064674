import React, { useEffect, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'
import { Style } from '../CSS/Table'
import { EnhancedTableHead } from '../../Components/TableHeader/TableHeader'
import {
  Box,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Toastify from '../../Components/SnackBar/Toastify'
import { useDispatch } from 'react-redux'
import { getSubscriptionList } from '../../redux/myaccount/myaccountActions'
import { storage } from '../../config/storage'
import { NODATAFOUND } from '../../routes/constURL'
import NoDataFound from './NoDataFound'
import CustomPagination from '../../Components/Pagination'
import { initialState, reducerFunction } from '../../config/mockData'
import { getLoadingStatus } from '../../redux/login/action'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import CustomTableBody from '../../Components/TableBox/CustomTableBody'
import ListSkelton from '../../Components/Loader/ListSkelton'
import { useParams } from 'react-router-dom'
import Payment from '../../Components/Payment'

const SubscriptionCustomer = () => {
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const { list, empty, loadingStatus } =
    useSelector((state) => state?.AccountData) || {}
  const [columns, setColumns] = useState([])
  const [rows, setRows] = React.useState([])
  const myDispatch = useDispatch()
  let user_id = useParams()
  // //console.log(list)
  let data = {
    users_id: user_id.id,
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    myDispatch(getSubscriptionList(data))
    myDispatch(getLoadingStatus(true))

    const headCells = [
      { label: 'Membership', name: 'Membership' },
      { label: 'Sport', name: 'sport' },
      // {label:"Email",name:"Email"},
      // {label:"Phone",name:"Phone"},
      { label: 'Payment date', name: 'subscribed_on' },
      { label: 'Payment type', name: 'payment_type' },
      { label: 'Tenure(Days)', name: 'tenure' },
      { label: 'Revenue(ex GST)', name: 'revenue' },
      { label: 'Mode', name: 'mode' },
      { label: 'Status', name: 'Status' },
      // { label: 'Action', name: 'Action' },
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    setColumns(headCells)
  }, [])
  useEffect(() => {
    setStateReducer({ pages: list?.pages, total: list?.total_records })
  }, [list])
  // console.log(loadingStatus)
  const handlePageChange = (event, value) => {
    setStateReducer({ page: value })
    let a = (value - 1) * state.length
    data = {
      start: a,
      length: state.length,
    }
    if (state.order && state.orderBy) {
      data.sort_by = state.order === 'asc' ? 'desc' : 'asc'
      data.sort_column = state.orderBy
    }
    myDispatch(getSubscriptionList(data))
    myDispatch(getLoadingStatus(true))
  }
  const handleRequestSort = (event, property) => {}
  return (
    <Box sx={{ overflow: 'auto', whiteSpace: 'nowrap', mt: 2 }}>
      {/* {!newState && ( */}
      <LoaderComponent open={loadingStatus} />
      {state.total !== 0 && (
        <Table
          sx={Style.table.SubscriptiontableBox}
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            // numSelected={selected.length}
            columns={columns}
            setColumns={setColumns}
            order={state.order}
            orderBy={state.orderBy}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
          />
          <TableBody sx={{ border: 0 }}>
            {list &&
              list?.list?.map((row, ind) => (
                <CustomTableBody
                  key={row?.users_id}
                  row={row}
                  loadingStatus={loadingStatus}
                  ind={ind}
                  columns={[
                    row?.user_membership.length != 0
                      ? row?.user_membership
                      : 'none',
                    row?.user_sports,
                    row?.user_subscribe_at,
                    row?.payment_type,
                    row?.user_tenure_days,
                    row?.user_revenue_without_tax,
                    row?.user_mode_of_payment,
                    row?.membership_status,
                  ]}
                  // membership_status={true}
                />
              ))}
            {loadingStatus && (
              <ListSkelton
                loadingStatus={loadingStatus}
                rows={5}
                columns={8}
                width={'100px'}
              />
            )}
          </TableBody>
          <Toastify />
        </Table>
      )}
      {state.total === 0 && (
        <Grid sx={Style.box}>
          <Typography sx={Style.text}>
            This customer is related to club admin and we have not recieved any
            payment from this customer.
          </Typography>
          {/* <TransformButton button_name={'Pay'} /> */}
        </Grid>
      )}

      <CustomPagination
        state={state}
        empty={empty}
        handlePageChange={handlePageChange}
      />
      {/* )} */}
    </Box>
  )
}

export default SubscriptionCustomer
