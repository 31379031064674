import createSagaMiddleware from 'redux-saga'
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'
import rootSaga from './rootSaga'
import saga from './login/saga'
const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,

  middleware: () => [sagaMiddleware],
})
sagaMiddleware.run(rootSaga)
export default store
