import {
  Box,
  Button,
  FormLabel,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import InputField from '../../Components/InputField'
import { Schema } from '../../Components/FormSchema/Schema'
import RowRadioButtonsGroup from '../../Components/RadioButtons/RadioButton'
import { useDispatch } from 'react-redux'

import { useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import Toastify from '../../Components/SnackBar/Toastify'
import TransformButton from '../../Components/CustomButton/TransformButton'
import EditIcon from '@mui/icons-material/Edit'
import { getLoadingStatus } from '../../redux/login/action'
import {
  getClubAddUpdateRequest,
  getClubDetailsRequest,
} from '../../redux/clubs/clubAction'
import SelectComponent from '../../Components/SelectComponent'
import {
  initialState,
  programs,
  reducerFunction,
  sportsData,
} from '../../config/mockData'
import { toast } from 'react-toastify'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import Select2 from '../../Components/Select2'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const EditViewBulkOrder = ({ getEmail }) => {
  const location = useLocation()
  const myDispatch = useDispatch()
  const newState = useSelector((state) => state?.ClubData)
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const [sportserror, setsportserror] = useState()
  const [club, setClub] = useState('')
  const [image_uploaded, setImage_uploaded] = useState(
    '../../assets/profilepic.png',
  )
  const [image_binary, setImage_binary] = useState()
  // '../../assets/profileRect.png',

  let user_id = useParams()
  let data = {
    users_id: user_id.id,
  }
  const [clubData, setClubData] = useState({
    club_name: '',
    name: '',
    email: '',
    mobile: '',
    sports: '',
    num_of_licenses: '',
    membership: 'Select Program',
    amount: '',
    subscription_link: '',
  })
  useEffect(() => {
    callApi()
  }, [])

  const callApi = () => {
    myDispatch(getClubDetailsRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  getEmail(newState?.details?.clubData?.email)

  useEffect(() => {
    let data = newState?.details?.clubData
    if (newState?.details) {
      setClubData({
        club_name: data?.club_name,
        name: data?.f_name,
        email: data?.email,
        mobile: data?.mobile,
        sports: data?.sports,
        num_of_licenses: data?.num_of_licenses,
        membership: data?.membership,
        amount: data?.amount,
        subscription_link: data?.subscription_link,
      })

      setClub(data?.club_type)
    }
    setImage_uploaded(
      data?.image ? data?.image : require('../../assets/profileRect.png'),
    )
  }, [newState?.details])
  const formik = useFormik({
    initialValues: clubData,
    validationSchema: Schema.BULK_ORDER,
    onSubmit: (value) => {
      setClubData(value)
      setClub(value?.club_type)
      onSubmit(value)
    },
    enableReinitialize: true,
  })
  console.log(
    formik.errors,
    formik.values.membership,
    clubData,
    formik.errors.membership,
    formik.touched.membership,
  )

  const onSubmit = (value) => {
    console.log('value', value)
    Object.assign(value, data)
    const formData = new FormData()
    formData.append('club_name', value.club_name)
    formData.append('f_name', value.name)
    formData.append('email', value.email)
    formData.append('mobile', value.mobile)
    formData.append('club_type', club)
    formData.append('num_of_licenses', value.num_of_licenses)
    formData.append('membership', value.membership)
    formData.append('sports', value.sports)
    formData.append('users_id', data.users_id)
    formData.append('amount', value.amount)
    // formData.append('subscription_link', value.subscription_link)
    formData.append('role', 'club_admin')

    if (image_binary) {
      formData.append('image', image_binary)
    }
    // else{
    //   formData.delete('image')
    // }
    myDispatch(getLoadingStatus(true))
    myDispatch(getClubAddUpdateRequest(formData))
    // setTimeout(() => {
    //   callApi()
    // }, 2000)
  }
  // //////console.warn("1",newState?.loadingStatus)

  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )
  const handleChangeType = (e) => {
    // setClubData((prevUserData) => ({
    //   ...prevUserData, // Shallow copy of the old object
    //   club_type: e.target.value, // Update the specific key with the new value
    // }))
    setClub(e.target.value)
  }
  const handleSelect = (e) => {
    setStateReducer({ membership: e.target.value })
  }
  const handleFileChange = (e) => {
    // //////console.log('hii', e.target.files[0])
    setImage_binary(e.target.files[0])
    const binary = URL.createObjectURL(e.target.files[0])
    // //////console.log(binary)
    setImage_uploaded(binary)
    // //////console.log(image_uploaded)
  }
  const copy = () => {
    try {
      // //////console.log(formik.values.subscription_link)
      navigator.clipboard.writeText(formik.values.subscription_link)
      toast.success('URL copied Successfully.')
    } catch (err) {
      // //////console.error('Unable to copy to clipboard', err)
      toast.error('Error copying URL to clipboard.')
    }
  }

  return (
    <form name="bulkOrder" onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <LoaderComponent open={newState?.loadingStatus} />

        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid
            sx={Style.mainGrid1}
            item
            order={{ xs: 2, sm: 0 }}
            xs={12}
            sm={6}
          >
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.club_name}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter club name'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'club_name'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Club Name'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.club_name && formik.touched.club_name ? (
                  <p style={Style.validationStyle}>{formik.errors.club_name}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
              <Box sx={{}}>
                <InputField
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter last name'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'name'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Club Owner Name'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.name && formik.touched.name ? (
                  <p style={Style.validationStyle}>{formik.errors.name}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid
            order={{ xs: 1, sm: 0 }}
            sx={Style.mainGrid}
            item
            xs={12}
            sm={6}
          >
            <Item
              sx={{
                boxShadow: 'none',
                cursor: 'pointer',
                borderRadius: '100%',
                maxWidth: { xs: '145px', sm: '170px' },
                p: 1,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  width: '30px',
                  height: '30px',
                  bgcolor: '#d80027',
                  color: 'white',
                  borderRadius: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  right: '14px',
                }}
              >
                <EditIcon
                  fontSize="30px"
                  sx={{ position: 'absolute', width: '1.5em', height: '1.5em' }}

                  // style={style.iconEye}
                />
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleFileChange}
                  style={{
                    position: 'absolute',
                    opacity: 0,
                    height: '30px',
                    width: '30px',
                  }}
                  // Ref for triggering file input click
                />
              </Box>
              <img
                alt="profileimage"
                className="profileImage"
                // src={vehicData?.photo_links[selected]?.image_url}
                src={image_uploaded}

                // onClick={() => setOpen(true)}
              />
            </Item>
          </Grid>
          <Grid order={{ xs: 3, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter age'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'email'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Email'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.email && formik.touched.email ? (
                  <p style={Style.validationStyle}>{formik.errors.email}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 3, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  type={'number'}
                  placeholder={'Please enter phone'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'mobile'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Phone Number'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.mobile && formik.touched.mobile ? (
                  <p style={Style.validationStyle}>{formik.errors.mobile}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 4, sm: 0 }} item xs={12} sm={6}>
            <Label name={'Club type'} />
            <div>
              <RowRadioButtonsGroup
                labelItems={[
                  { label: 'Club', value: 'club' },
                  { label: 'School', value: 'school' },
                  { label: 'Others', value: 'others' },
                ]}
                defaultValue={club}
                handleChange={handleChangeType}
              />
            </div>
          </Grid>
          <Grid order={{ xs: 5, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Label name={'Club Sports'} />
              <Box sx={{ mb: '20px' }}>
                <Select2
                  name="sports"
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      'sports',
                      selectedOption ? selectedOption.value : null,
                    )
                  }
                  placeholder={'Select Sport'}
                  options={sportsData}
                  mt={'7px'}
                  edit={true}
                  value={formik.values.sports}
                  error={formik.errors.sports}
                  errorMessage={formik?.sports?.message}
                />
                {formik.errors.sports && formik.touched.sports ? (
                  <p style={Style.validationStyle}>{formik.errors.sports}</p>
                ) : null}
                {/* <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                /> */}
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 6, sm: 0 }} item xs={12} sm={3}>
            <Item>
              <Box sx={{}}>
                <InputField
                  value={formik.values.num_of_licenses}
                  onChange={formik.handleChange}
                  type={'number'}
                  placeholder={'Please enter licences'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'num_of_licenses'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Number of licences'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.num_of_licenses &&
                formik.touched.num_of_licenses ? (
                  <p style={Style.validationStyle}>
                    {formik.errors.num_of_licenses}
                  </p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 7, sm: 0 }} item xs={12} sm={9}>
            <Item sx={{mt:'0%'}}>
              <Label name={'Program'} />
              {/* <div>
                <SelectComponent
                  menuData={programs}
                  value={formik.values.membership}
                  name={'membership'}
                  edit={true}
                  mt={'5px'}
                  handleChangeForSelect={(selectedOption) => {
                    console.log('selectedOption', selectedOption)
                    formik.setFieldValue(
                      'membership',
                      selectedOption ? selectedOption.target.value : null,
                    )
                  }}
                />
                {formik.errors.membership ? (
                  <p style={Style.validationStyle}>
                    {formik.errors.membership}
                  </p>
                ) : null}
              </div> */}

              <Box sx={{ mb: '20px' }}>
                <Select2
                  name="membership"
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      'membership',
                      selectedOption ? selectedOption.value : null,
                    )
                  }
                  options={programs}
                  mt={'0px'}
                  edit={true}
                  value={formik.values.membership}
                  error={formik.errors.membership}
                  errorMessage={formik?.membership?.message}
                />
                {formik.errors.membership && formik.touched.membership ? (
                  <p style={Style.validationStyle}>{formik.errors.membership}</p>
                ) : null}
                {/* <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                /> */}
              </Box>


            </Item>
          </Grid>
          <Grid order={{ xs: 8, sm: 0 }} item xs={12} sm={3}>
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  type={'number'}
                  placeholder={'Please enter Amount'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'amount'}
                  required={'*'}
                  amount={true}
                  edit_update={true}
                  formlabel={'Amount'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.amount && formik.touched.amount ? (
                  <p style={Style.validationStyle}>{formik.errors.amount}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid
            order={{ xs: 8, sm: 0 }}
            item
            xs={12}
            sm={9}
            sx={{ display: 'flex' }}
          >
            <Grid xs={12}>
              <Box sx={{ mb: '20px', width: '100%' }}>
                <InputField
                  value={formik.values.subscription_link}
                  disabled={true}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter sport'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'subscription_link'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Club Signup Link'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {/* {formik.errors.subscription_link &&
                formik.touched.subscription_link ? (
                  <p style={Style.validationStyle}>
                    {formik.errors.subscription_link}
                  </p>
                ) : null} */}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Grid>
            <Grid xs={2}>
              <Button
                className="btn-rt-skw"
                variant="contained"
                sx={{
                  ...Style.button_one,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'rgb(45, 142, 66)',
                    // bgcolor: color==='rgb(45, 142, 66)' ? 'rgb(45, 142, 66)' : '#CB2128',
                    // boxShadow: 'none',
                  },
                }}
                onClick={() => copy()}
              >
                <Typography>COPY</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={Style.buttonBox}>
          <TransformButton typeSubmit={'submit'} button_name={'Update'} />
        </Box>
        <Toastify />
      </Box>

      <Toastify />
    </form>
  )
}

export default EditViewBulkOrder
const Style = {
  Skeleton: {
    width: '100%',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  star: {
    color: '#d32f2f',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#333333',
  },

  button_one: {
    bgcolor: '#2D8E42',
    color: 'white',
    height: '47%',
    p: 1,
    mt: 3.7,
    transform: 'none',
    width: '100%',
  },
  headingText: {
    fontSize: { xs: '14px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },

  validationStyle: {
    color: '#d32f2f',
    // margin: '10px',
    fontWeight: '500',
  },
  mainGrid: {
    mt: '20px',
  },
  mainGrid1: {
    mt: { xs: 0, sm: '20px' },
  },
  buttonBox: {
    display: 'flex',
    justifyContent: { md: 'flex-start' },
    mt: 2,
    mb: 2,
    pr: { xs: 1, sm: 10 },
    pl: { xs: 1, sm: 10 },
  },
}
