import styled from '@emotion/styled'
import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'

const CustomFilledGrid = ({ list, radius, width, md }) => {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    // border: '1px solid grey',
    boxShadow: 'none',
    fontSize: '12px',
    fontWeight: '600',
    background: '#27245E',
    borderRadius: radius,
    textAlign: 'center',
    padding: '20px',
    // paddingTop: '4%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // maxWidth: '500px',
    height: '150px',
    width: { xs: '100%', md: width },
  }))
  return (
    <>
      {list.map((item, key) => (
        <Grid item xs={12} md={md}>
          <Item>
            {/* <div className="result-box"> */}
            <Typography sx={Style.text}>{item.title}</Typography>
            <Grid sx={Style.innerGrid}>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                {' '}
                <span style={{ color: '#27245E' }}>{item.name} </span>
                <span style={{ marginRight: '2px', marginLeft: '2px' }}>
                  {item.data}{' '}
                </span>
                {item.title !== 'Subscription' && <span>{item.unit}</span>}
              </div>
            </Grid>
            {/* </div> */}
          </Item>
        </Grid>
      ))}
    </>
  )
}

export default CustomFilledGrid
const Style = {
  innerGrid: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    bgcolor: '#F5F4FF',
    borderRadius: '10px',
    color: 'rgb(216, 0, 39)',
    border: '1px solid #CDCAFC',
    mt: 1,
    pt: 1,
    pb: 1,
    textAlign: 'center',
  },
  text: {
    color: 'white',
  },
}
