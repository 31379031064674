import styled from '@emotion/styled'
import { Grid, Paper, useMediaQuery } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  borderRadius: 'unset',
}))
const BulkOrderTab = ({ item_route, item_text, URL, style,id }) => {
  return (
    <Grid>
      <Link style={{ textDecoration: 'none'}} to={item_route + '/' + id}>
        <Item
          sx={style}
          style={{
            color: item_route + '/' + id === URL ? 'white' : 'black',
            backgroundColor:
              item_route + '/' + id === URL ? '#D80027' : '#F5F4FF',
          }}
        >
          {item_text}
        </Item>
      </Link>
    </Grid>
  )
}

export default BulkOrderTab
