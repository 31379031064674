import { GET_CLUB_ADMIN_PAYMENT, GET_CLUB_DASHBOARD_DATA, GET_DASHBOARD_DATA, GET_EXPIRY_LINK } from '../ConstAction'
  
  export const getDashboardData = (data) => {
    return {
      type: GET_DASHBOARD_DATA,
      data: data,
    }
  }
  export const getClubDashboardData = (data) => {
    return {
      type: GET_CLUB_DASHBOARD_DATA,
      data: data,
    }
  }
  export const clubAdminpayment = (data, handleClose) => {
    return {
      type: GET_CLUB_ADMIN_PAYMENT,
      data: data,
      handleClose: handleClose,
    }
  }

  export const getExpiredLink = (data) =>{
    return {
      type: GET_EXPIRY_LINK,
      data: data,
    }
  }
  