import {
  Box,
  Button,
  Grid,
  Tabs,
  Tab,
  Paper,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate, useParams } from 'react-router-dom'
import { CUSTOMERS_INFO_EDIT } from '../../routes/constURL'
import '../CSS/CustomerDetail.css'
import styled from '@emotion/styled'
import SearchIcon from '@mui/icons-material/Search'
import { Style } from '../CSS/Table'

import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import {
  getCustomerDelete,
  getCustomerDetailsRequest,
} from '../../redux/customer/customerAction'
import { useDispatch } from 'react-redux'
import { EnhancedTableHead } from '../../Components/TableHeader/TableHeader'
import Toastify from '../../Components/SnackBar/Toastify'
import { storage } from '../../config/storage'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import { getLoadingStatus } from '../../redux/login/action'
import TransformButton from '../../Components/CustomButton/TransformButton'
import AlertDialog from '../../Components/Dialog/Dialog'
import CustomFilledGrid from '../../Components/CustomFilledGrid/CustomFilledGrid'
import AboutGrid from '../../Components/CustomFilledGrid/AboutGrid'
const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  alignItems: 'center',
}))
const useStyles = makeStyles((theme) => ({
  tab: {
    backgroundColor: 'transparent',
    fontSize: { xs: '14px', sm: '16px' },
    fontWeight: '400',
    width: "50%",
    '&.MuiTab-root.Mui-selected': {
      backgroundColor: '#D80027',
      color: 'white',
      textTransform: 'none',
    },
    '&.MuiButtonBase-root': {
      backgroundColor: 'transparent',
      color: '#3D2E57',
      textTransform: 'none',
      //marginRight: "7%",
    },
  },
}))
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}
const CustomerDetails = () => {
  const classes = useStyles()
  const loading = useSelector((state) => state?.CustomerData?.loadingStatus)
  const newState = useSelector((state) => state?.CustomerData?.customer_details)
  const jumping = useSelector((state) => state?.CustomerData?.resultJumping)
  const measurementData = useSelector(
    (state) => state?.CustomerData?.resultHeightWeight,
  )

  const tabClasses = { root: classes.tab }
  const tabPanelClasses = { root: classes.TabPanel }
  const [value, setValue] = React.useState(0)
  const [columns, setColumns] = React.useState([])
  const [columns1, setColumns1] = React.useState([])
  // const [pages, setPages] = useState(newState && newState?.pages)
  const [measpages, setmeasPages] = React.useState(measurementData?.pages)
  const [meastotal, setmeasTotal] = useState(measurementData?.total_records)
  const [measpage, setmeasPage] = React.useState(1)
  const [measlength, setmeasLength] = useState(5)
  const [measstart, setmeasStart] = React.useState(0)
  const [measSearch, setmeasSearch] = React.useState('')

  const [jumppages, setjumpPages] = React.useState(jumping?.pages)
  const [jumptotal, setjumpTotal] = useState(jumping?.total_records)
  const [jumppage, setjumpPage] = React.useState(1)
  const [jumplength, setjumpLength] = useState(5)
  const [jumpstart, setjumpStart] = React.useState(0)
  const [jumpSearch, setjumpSearch] = React.useState('')
  const [searchValue, setSearchValue] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)

  const AboutList = [
    {
      title: 'Initial Jumping Result',
      name: '',
      data: newState?.init_jump_height==null || newState?.init_jump_height==0 ?  'NA' : newState.init_jump_height,
      unit: newState?.init_jump_height==null || newState?.init_jump_height==0 ? '' :' cm',
    },
    {
      title: 'Initial Height Result',
      name: '',
      data: newState?.init_height==0 || newState?.init_height==null ? 'NA' : newState.init_height,
      unit: newState?.init_height==0 || newState?.init_height==null ? '' : ' cm',
    },
    {
      title: 'Initial Weight Result',
      name: '',
      data: newState?.int_weight==0 || newState?.int_weight==null ? 'NA' : newState.int_weight,
      unit: newState?.int_weight==0 || newState?.int_weight==null ? '' : ' kg',
    },
  ]
  const AboutUpper = [
    { value: newState?.age==0 || newState?.age==null ? 'NA' : newState.age, name: 'Age', unit: '', md: 1.7 },
    { value: newState?.init_height==0 || newState?.init_height==null ? 'NA' : newState.init_height, name: 'Height', unit:newState?.init_height==0 || newState?.init_height==null ? '' : ' cm', md: 1.7 },
    { value: newState?.int_weight==0 || newState?.int_weight==null ? 'NA' : newState.int_weight, name: 'Weight', unit:newState?.int_weight==0 || newState?.int_weight==null ? '' : ' kg', md: 1.7 },
    { value: newState?.sports=='Select Sport' || newState?.sports==null ? 'NA' : newState.sports , name: 'Sport', unit: '', md: 2 },
    {
      value: newState?.init_jump_height==null || newState?.init_jump_height==0 ?  'NA' : newState.init_jump_height,
      name: 'Standing Jump Height',
      unit: newState?.init_jump_height==null || newState?.init_jump_height==0 ? '' :' cm',
      md: 2,
    },
    { value: newState?.armspan==null || newState?.armspan==0 ? 'NA' : newState.armspan, name: 'Arm Span', unit:newState?.armspan==null || newState?.armspan==0 ? '' : ' cm', md: 2 },
    {
      value:
        newState?.handed != null
          ? newState?.handed == 'right'
            ? 'Right'
            : 'Left'
          : 'NA',
      name: 'Handed',
      unit: '',
      md: 1.7,
    },
    { value: newState?.team=="" || newState?.team==null ? 'NA' : newState?.team , name: 'Current Team', unit: '', md: 3 },
  ]
  const navigate = useNavigate()

  // //console.log(measurementData)
  const myDispatch = useDispatch()
  let user_id = useParams()
  let data = {
    users_id: user_id.id,
    search_val_measurements: measSearch,
    start_measurements: measstart,
    length_measurements: measlength,
    search_val_jump_results: jumpSearch,
    start_jump_results: jumpstart,
    length_jump_results: jumplength,
  }
  useEffect(() => {
    document.title = 'OG Sports - Customer View'
    // setmeasPage()
    myDispatch(getCustomerDetailsRequest(data))
    myDispatch(getLoadingStatus(true))
    const headCells = [
      { label: 'Date', name: 'date' },
      { label: 'Height', name: 'height' },
      // {label:"Email",name:"Email"},
      // {label:"Phone",name:"Phone"},
      { label: 'Weight', name: 'weight' },
      // {label:"Action",name:"Action"},
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    const headCell1 = [
      { label: 'Date', name: 'date' },
      { label: 'Jump Height', name: 'height' },

      // {label:"Action",name:"Action"},
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    setColumns1(headCell1)
    setColumns(headCells)
  }, [])
  useEffect(() => {
    setjumpPages(jumping?.pages)
    setjumpTotal(jumping?.total_records)
    setmeasTotal(measurementData?.total_records)
    setmeasPages(measurementData?.pages)
  }, [measurementData, jumping])
  // //console.log(measpages)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const navigateTo = () => {
    storage.set.customerId(user_id.id)
    navigate(CUSTOMERS_INFO_EDIT + '/' + user_id.id, {
      state: user_id.id,
    })
  }
  const handleMeasPageChange = (event, value) => {
    setmeasPage(value)
    let a = (value - 1) * measlength
    setmeasStart(a)
    Object.assign(data, {
      start_measurements: a,
      length_measurements: measlength,
    })

    if (searchValue) {
      let searchVal = { search_val_measurements: searchValue }
      Object.assign(data, searchVal)
    }
    // setSelected([]);
    // getuserData();
    // data = value

    myDispatch(getCustomerDetailsRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  const handleJumpPageChange = (event, value) => {
    setjumpPage(value)
    let a = (value - 1) * jumplength
    setmeasStart(a)
    Object.assign(data, {
      start_jump_results: a,
      length_jump_results: jumplength,
    })

    if (searchValue) {
      let searchVal = { search_val_jump_results: searchValue }
      Object.assign(data, searchVal)
    }
    // setSelected([]);
    // getuserData();
    // data = value

    myDispatch(getCustomerDetailsRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  const handleSearch = () => {
    // //console.log(e.target)
    // //console.log(value)
    Object.assign(
      data,
      value
        ? { search_val_jump_results: searchValue }
        : { search_val_measurements: searchValue },
    )
    myDispatch(getCustomerDetailsRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  const deleteuser = () => {
    setOpenAlert(true)
  }
  const confirmDelete = (id) => {
    myDispatch(getCustomerDelete({ users_id: user_id.id }))
    setTimeout(() => {
      navigate('/customer_listing')
    }, 2000)
    setOpenAlert(false)
    myDispatch(getLoadingStatus(true))
  }
  return (
    <>
      <Box
        className="head-main"
        sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h2" sx={style.headingText}>
          {'Customer View'}
        </Typography>
        <AlertDialog
          title={'Are you sure you want to delete this Customer?'}
          open={openAlert}
          onClickButton={() => confirmDelete()}
          onClickButtonCancel={() => setOpenAlert(false)}
        />
        <Button
          className="btn-rt-skw"
          variant="contained"
          onClick={navigateTo}
          // onClick={button_one_onClick}
          sx={style.button_one}
        >
          <Typography sx={{ transform: 'skew(22deg) ' }}>Edit </Typography>
          <EditIcon style={style.iconEye} />
        </Button>
      </Box>
      
      <Box sx={{ display: 'flex' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <Item className="about-info">
              {/* <div className="image-div"> */}
              <img
                className="profileRecImage"
                // src={vehicData?.photo_links[selected]?.image_url}
                src={
                  newState && newState?.image
                    ? newState?.image
                    : require('./../../assets/profileRect.png')
                }

                // onClick={() => setOpen(true)}
              />
              {/* </div> */}
              <div className="info-cust">
                <h3>{newState && newState?.f_name + ' ' + newState?.l_name}</h3>
                <p>
                  {/* {newState?.team} - {newState?.sports}{' '} */}
                  {newState?.team ? newState?.team : 'NA'} - {' '}
                  {newState?.sports ? newState?.sports : 'NA'}
                </p>
                {/* <p className="text-light">Montgomery, AL</p> */}
              </div>
            </Item>
          </Grid>
          <CustomFilledGrid
            list={[
              {
                title: 'Subscription',
                name: '',
                data:
                  newState && newState.membership != null
                    ? newState.membership
                    : 'No membership',
              },
            ]}
            radius={'10px'}
            width={'260px'}
            md={3}
          />
        </Grid>
      </Box>
      <Box className="about-sec">
        <div className="heading-bg">
          <h3>About</h3>
        </div>
        <Grid container columnGap={2} rowGap={2}>
          <AboutGrid list={AboutUpper} md={1.85} p={20} />
        </Grid>
      </Box>
      <Box className="about-result">
        <Grid container spacing={3}>
          <CustomFilledGrid list={AboutList} width={'362px'} md={4} />
        </Grid>
      </Box>
      <LoaderComponent open={loading} />
      <Box sx={{ width: '100%', mt: 4 }}>
        <Box sx={{ '&.MuiBox-root': { padding: 0 } }}>
          <Tabs
            indicatorColor="none"
            value={value}
            onChange={handleChange}
            sx={{ bgcolor: '#F5F4FF', border: '1px solid #CDCAFC', mb: 2 }}
          >
            <Tab classes={tabClasses} label="Result (Height/Weight)" />
            <Tab classes={tabClasses} label="Result (Jumping)" />
          </Tabs>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width:{xs:"100%",md:"100%"}
            }}
          >
            {/* <Typography sx={{ mr: '20px' }}>Filter By</Typography> */}
            <TextField
              variant="filled"
              inputProps={{
                style: {
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  color: '#000',
                },
              }}
              sx={{
                width: "100%",
                display: "contents"
              }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                disableUnderline: true,
                sx: {
                  fontSize: '16px',
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '300px',
                  },
                  border: '1px solid #C8C8C8',
                  color: '#000',
                  height: '40px',
                  borderRadius: '0px',
                  '& .MuiInputBase-root-MuiFilledInput-root ': {
                    // Style the root element of MuiInputBase
                    //  pl:0
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                  },
                  '& .MuiInputBase-root-': {
                    // Style the root element of MuiFilledInput
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                  },
                  pr: '0px',
                },
              
            endAdornment:(
            <Box
              sx={{
                width: '60px',
                bgcolor: '#CB2128',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '0px',
              }}
              className="btn-rt-skw"
              onClick={handleSearch}
            >
              <SearchIcon
                sx={{
                  fontSize: '30px',
                  color: 'white',
                  cursor: 'pointer',
                }}
              />
            </Box>
            ),
              }}
              placeholder="Search"
            />
            {/* <Box
              sx={{
                display: { xs: 'none', md: 'flex', color: '#DDDDDD' },
                bgcolor:,
                height: '100%',
              }}
            >
              
            </Box> */}
          </Box>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box sx={Style.table.tableWrapBox}>
            <Table sx={Style.table.tableBox} aria-labelledby="tableTitle">
              <EnhancedTableHead
                // numSelected={selected.length}
                display={'none'}
                columns={columns}
                setColumns={setColumns}
                action={true}
                // order={order}
                // orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                // onRequestSort={handleRequestSort}
                // rowCount={rows.length}
              />
              {/* //API_DATA// */}
              <TableBody>
                {measurementData?.measurement_list &&
                  measurementData?.measurement_list?.map((row, index) => (
                    <TableRow
                      key={row.users_id}
                      sx={{
                        bgcolor: (index + 1) % 2 === 0 ? '#EEF2FC' : 'white',
                        border: 0,
                      }}
                    >
                      <TableCell align="left" sx={Style.table.tableCell}>
                        {/* {loading && <Skeleton sx={{ width: '100px' }} />} */}
                        {row?.measure_date}
                      </TableCell>
                      <TableCell align="left" sx={{...Style.table.tableCell,textTransform:'lowercase'}}>
                        {/* {loading && <Skeleton sx={{ width: '100px' }} />} */}
                        {row?.height+' '}cm
                      </TableCell>
                      <TableCell align="left" sx={{...Style.table.tableCell,textTransform:'lowercase'}}>
                        {/* {loading && <Skeleton sx={{ width: '100px' }} />} */}
                        {row?.weight+' '}kg
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              {measurementData === undefined && (
                <TableRow>
                  <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                    No Data Found
                  </TableCell>
                </TableRow>
              )}

              <Toastify />
            </Table>
            {measpages > 1 && (
              <Pagination
                count={measpages}
                page={measpage}
                boundaryCount={1}
                onChange={handleMeasPageChange}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                sx={{
                  '& .MuiPaginationItem-root': {
              '&.Mui-selected': {
                background: '#27245E',
                color: 'white',
              },
                  },
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
  }}
              />
            )}
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Table sx={Style.table.tableBox} aria-labelledby="tableTitle">
            <EnhancedTableHead
              // numSelected={selected.length}
              display={'none'}
              columns={columns1}
              setColumns={setColumns1}
              action={true}
              // order={order}
              // orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              // onRequestSort={handleRequestSort}
              // rowCount={rows.length}
            />
            {/* //API_DATA// */}
            <TableBody>
              {jumping?.jump_results_list &&
                jumping?.jump_results_list?.map((row, index) => (
                  <TableRow
                    key={row.users_id}
                    sx={{
                      bgcolor: (index + 1) % 2 === 0 ? '#EEF2FC' : 'white',
                      border: 0,
                    }}
                  >
                    <TableCell align="left" sx={Style.table.tableCell}>
                      {/* {loading && <Skeleton sx={{ width: '100px' }} />} */}
                      {row?.jump_date}
                    </TableCell>
                    <TableCell align="left" sx={{...Style.table.tableCell,textTransform:'lowercase'}}>
                      {/* {loading && <Skeleton sx={{ width: '100px' }} />} */}
                      {row?.jump_height+' '}cm
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            {jumping === undefined && (
              <TableRow>
                <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                  No Data Found
                </TableCell>
              </TableRow>
            )}

            <Toastify />
          </Table>
          {jumppages > 1 && (
            <Pagination
              count={jumppages}
              page={jumppage}
              boundaryCount={1}
              onChange={handleJumpPageChange}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
              sx={{'& .MuiPaginationItem-root': {
              '&.Mui-selected': {
                background: '#27245E',
                color: 'white',
              },
              },
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
  }}
            />
          )}
        </CustomTabPanel>
        {/* {total == 0 && ( */}
        <Box
          sx={{
            display:
              measurementData?.measurement_list?.length > 0 &&
              jumping?.jump_results_list?.length > 0
                ? 'flex'
                : 'none',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            p: 1,
          }}
        >
          {/* <Pagination
              count={pages}
              page={page}
              boundaryCount={1}
              sx={{
                button: { fontSize: '16px', mr: 1 },
                width: '100%',
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-end' },
              }}
              onChange={handlePageChange}
              siblingCount={0}
            /> */}
          {/* <Pagination
              count={pages}
              page={page}
              boundaryCount={1}
              onChange={handleMeasPageChange}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
            /> */}
          {/* )} */}
        </Box>
        {/* )} */}
        <Box sx={{ mb: '50px', ml: 13, mt: 4 }}>
          <Button
            className="btn-rt-skw"
            variant="contained"
            onClick={() => {
              deleteuser()
            }}
            sx={style.button_one}
          >
            <Typography sx={{ transform: 'skew(22deg) ', fontWeight: 500 }}>
              Delete User
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default CustomerDetails
const style = {
  button_one: {
    bgcolor: '#CB2128',
    fontWeight: 400,
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      boxShadow: 'none',
    },
    '&.MuiButtonLabel': {
      transform: 'skew(-22deg) translate(-54%, -50%)',
    },
    // m: { xs: 1, sm: 2 },
    mt: '30px',
    boxShadow: 'none',
    p: '0px 50px 0px 50px',

    color: 'white',
    // width: "120px",
    textTransform: 'none',
    transform: 'skew(-22deg) translate(-54%, -50%)',
    height: '40px',
    fontSize: { xs: '12px', sm: '18px' },
  },
  headingText: {
    fontSize: { xs: '14px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },
  iconEye: {
    width: '19px',
    height: '19px',
    cursor: 'pointer',
    marginLeft: '10px',
    color: '#fff',
  },
}
