import {
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import EditIcon from '@mui/icons-material/Edit'
import { useSelector } from 'react-redux'
import { initialState, reducerFunction, tabsFeeds } from '../../config/mockData'
import InputField from '../../Components/InputField'
import { EnhancedTableHead } from '../../Components/TableHeader/TableHeader'
import TransformButton from '../../Components/CustomButton/TransformButton'
import {
  CUSTOMER_EDIT,
  CUSTOMER_PROFILE,
  CUSTOMER_PROFILE_DETAIL,
} from '../../routes/constURL'
import { useLocation, useNavigate } from 'react-router-dom'
import FeedBox from '../../Components/Timeline/FeedBox'
import {
  getTimeline,
  getTimelinePosted,
  getAdminProfileRequest,
  getJumpingData,
} from '../../redux/customer_panel/actionCustomer'
import { getLoadingStatus } from '../../redux/login/action'
import { storage } from '../../config/storage'
import ProfileTab from '../../Components/Tabs/ProfileTab'
import Preview from '../../Components/Modal/Preview'
import { toast } from 'react-toastify'
import Toastify from '../../Components/SnackBar/Toastify'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import { ArrowUpward } from '@mui/icons-material'
const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  alignItems: 'center',
}))
const CustomerProfile = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const fileImgRef = useRef(null)
  const fileVideoRef = useRef(null)
  const timelineRef = useRef(null)

  const { list, loadingStatus, customerData, posted, Jumpinglist, total } =
    useSelector((state) => state?.CustomerPanelData) || {}

  // const newstate = useSelector((state) => state?.CustomerPanelData) || {}
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const [visible, setVisible] = useState(false)
  const [loadmore, setLoadmore] = useState(false)
  const [details, setDetails] = useState(list)

  const myDispatch = useDispatch()

  let URL = location.pathname
  let id
  let data = {
    user_filter: true,
    date: '',
    start: 0,
    length: 10,
    sort_column: '',
    sort_by: '',
    search_val: '',
  }
  let data1 = {
    date: '',
    start: 0,
    length: 3,
    sort_column: '',
    sort_by: '',
    search_val: '',
  }
  useEffect(() => {
    document.title = 'OG Sports - Profile'
    window.scroll(0, 0)
    callApi()
  }, [posted])
  //console.log('posted', posted)
  // console.log(list)
  const h = list?.total_records >= list?.filtered_records
  const callApi = () => {
    myDispatch(getLoadingStatus(true))
    myDispatch(getAdminProfileRequest())
    setTimeout(() => {
      myDispatch(getTimeline(data))
    }, 500)
    myDispatch(getJumpingData(data1))
  }

  useEffect(() => {
    if (customerData != null) {
      setLoadmore(true)
      if (list) {
        console.log(
          list.total_records,
          list.filtered_records,
          list.total_records <= list.filtered_records,
        )
        if (list.total_records <= list.filtered_records) {
          setLoadmore(false)
          console.log(loadmore)
        }
        console.log(details)
      }
      setStateReducer({
        details: list,
        tableData: [
          { name: 'Age:', value: customerData?.age==0 || customerData?.age==null ? 'NA' : customerData?.age },
          { name: 'Height:', value: customerData?.init_height==0 || customerData?.init_height==null ? 'NA'  : customerData?.init_height + ' cm' },
          { name: 'Weight:', value: customerData?.int_weight==0 || customerData?.int_weight==null ? 'NA' : customerData.int_weight + ' kg' },
          { name: 'Sport:', value: customerData?.sports==null || customerData?.sports=="" ? 'NA' : customerData?.sports },
          {
            name: 'Standing Jump Height:',
            value: customerData?.init_jump_height==0 || customerData?.init_jump_height==null ? 'NA' : customerData?.init_jump_height + ' cm',
          },
          { name: 'Arm Span:', value: customerData?.armspan==0 || customerData?.armspan==null ? 'NA' : customerData?.armspan + ' cm' },
          {
            name: 'Handed:',
            value: customerData?.handed != null ? customerData?.handed == 'right' ? 'Right' : 'Left' : 'NA',
          },
        ],
        profileImage: customerData?.image,
      })
    }
    // storage.set.profileImage(
    //   customerData?.image == null ? null : customerData?.image,
    // )
    storage.set.profileImage(
      customerData?.image === null
        ? require('../../assets/profileRect.png')
        : `${customerData?.image}`,
    )
    storage.set.posts(details)
    storage.set.user_details(customerData)
    if (list) {
      //console.log(list);
      //console.log(
      //   list.total_records,
      //   list.filtered_records,
      //   list.total_records <= list.filtered_records,
      // )
      // if (list.total_records <= list.filtered_records) {
      // console.log(';;;;;')
      // setLoadmore(list.total_records)
      // }
    }
  }, [customerData, state.img, state.video, state.text, list, details])
  useEffect(() => {
    // Ensure that list exists before accessing its properties

    if (list) {
      setStateReducer({
        total: list?.total_records,
        filtered: list?.filtered_records,
      })
    }
    setDetails(list)
  }, [
    list?.total_records,
    list?.filtered_records,
    timelineRef.current,
    details,
    list,
  ])
  console.log(details)
  const handleScroll = () => {
    const container = timelineRef.current
    // console.log(container)

    // Calculate the distance scrolled
    const scrolled = container.scrollTop

    // Calculate the total height of the content
    const totalHeight = container.scrollHeight

    // Calculate the height of the visible portion
    const visibleHeight = container.clientHeight

    // Calculate the remaining space
    const remainingSpace = totalHeight - (scrolled + visibleHeight)

    // console.log(remainingSpace)
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
    if (remainingSpace < 10) {
      // If the remaining space is less than 10 pixels, fetch more data
      console.log(details)
      fetchMoreData()
    }
  }

  const fetchMoreData = async () => {
    const all = storage.fetch.posts()
    console.log(all)
    if (all.filtered_records < all.total_records) {
      data['length'] = data['length'] + 10
      //console.log('Welcome....', data)
      myDispatch(getTimeline(data))

      // myD100)
    }
  }
  const scrollToTop = () => {
    timelineRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    })
  }

  // window.addEventListener('scroll', toggleVisible)

  useEffect(() => {
    const timelineElement = timelineRef.current
    if (timelineElement) {
      timelineElement.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (timelineElement) {
        timelineElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])
  const navigateTo = () => {
    navigate(CUSTOMER_EDIT)
  }
  const handleText = (e) => {
    setStateReducer({ text: e.target.value })
  }
  const handleVideo = (e) => {
    //console.log('Video')
    myDispatch(getLoadingStatus(true))
    //console.log(loadingStatus)
    setStateReducer({ video: e?.target?.files[0] })
    myDispatch(getLoadingStatus(false))
    //console.log(loadingStatus)
  }
  const handleImage = (e) => {
    setStateReducer({ img: e?.target?.files[0] })
  }
  const handlePreview = (e) => {
    setStateReducer({ open: true })
  }
  const handleClose = (e) => {
    setStateReducer({ open: false })
  }
  const handleRemoveVideo = () => {
    setStateReducer({ video: '' })
    if (fileVideoRef.current) {
      fileVideoRef.current.value = ''
    }
  }
  const handleRemoveImg = () => {
    setStateReducer({ img: null })
    if (fileImgRef.current) {
      fileImgRef.current.value = ''
    }
  }
  //console.log('video', state.video)
  const post = () => {
    if (state.text == '' && state.img == '' && state.video == '') {
      toast.error('Please fill form to Post')
    } else {
      const formData = new FormData()
      if (state.text) formData.append('text', state.text)
      if (state.img != '') formData.append('image', state.img)
      if (state.video != '') formData.append('video', state.video)
      //console.log(formData)
      myDispatch(getTimelinePosted(formData, callApi))
      myDispatch(getLoadingStatus(true))
      // setTimeout(() => {
      //   callApi()
      // }, 2000)
      if (fileVideoRef.current) {
        fileVideoRef.current.value = ''
      }
      if (fileImgRef.current) {
        fileImgRef.current.value = ''
      }
      setStateReducer({ text: '', img: '', video: '' })
    }
  }
  return (
    <Box>
      <Grid container>
        <Item sx={{ width: '45%' }} xs={6}>
          <Typography variant="h2" sx={style.headingText}>
            {'Profile'}
          </Typography>
        </Item>
        <LoaderComponent open={loadingStatus} />
        <Item xs={6} sx={style.edit}>
          <TransformButton
            button_name={'Edit'}
            handleClick={navigateTo}
            iconName={EditIcon}
            transform={'skew(-22deg) translate(0%, -50%)'}
          />
        </Item>
      </Grid>
      <Toastify />
      <Preview
        open={state?.open}
        img={state?.img}
        video={state?.video}
        text={state?.text}
        handleClose={handleClose}
        handleRemoveImg={handleRemoveImg}
        handleRemoveVideo={handleRemoveVideo}
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
        <Item className="about-info">
          <img
            className="profileRecImage"
            src={
              state?.profileImage
                ? state?.profileImage
                : require('./../../assets/profileRect.png')
            }
          />
          <div className="info-cust">
            <h3>
              {customerData &&
                customerData?.f_name + ' ' + customerData?.l_name}
            </h3>
            <p>
              {customerData?.team} - {customerData?.sports}{' '}
            </p>
          </div>
        </Item>
      </Grid>
      <Grid container columnGap={4} sx={style.content}>
        <Grid md={3} xs={12}>
          <Item>
            <Typography sx={style.about}>ABOUT</Typography>
            <List sx={style.aboutData}>
              {/* {//console.warn('state', state)} */}
              {state.tableData?.map((data) => (
                <ListItem sx={style.row2}>
                  <ListItemText sx={style.row}>
                    <span style={{ minWidth: '55px', display: 'inline-flex' }}>
                      {data?.name}{' '}
                    </span>{' '}
                    <span style={style.row1}> {data?.value}</span>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            <Typography sx={style.about}>CURRENT TEAM</Typography>
            <Table sx={style.aboutData}>
              <Typography sx={{ m: 2, color: '#27245f', fontWeight: '600' }}>
                {customerData?.team ? customerData?.team : ''}
              </Typography>
            </Table>
            <Table sx={style.aboutData}>
              <Typography sx={style.about}>JUMPING RESULTS</Typography>
              <Box sx={{...style.jump,}}>
                Initial Jumping Result{' '}
                <span style={{ color: '#CB2128', fontSize: '14px' }}>
                  {customerData?.init_jump_height
                    ? customerData?.init_jump_height + ' cm'
                    : ''}
                </span>
              </Box>
              <Table sx={{ m: 2, width: '80%' }}>
                <TableHead>
                  <TableCell sx={style.tableCell}>Date</TableCell>
                  <TableCell sx={style.tableCell}>Result</TableCell>
                </TableHead>
                <TableBody>
                  {Jumpinglist?.jumpresult_list?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item?.formate_jump_date}</TableCell>
                      <TableCell>{item?.jump_height + ' cm'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Table>
          </Item>
        </Grid>
        <Grid xs={12} lg={8}>
          <Grid>
            <TextareaAutosize
              placeholder="Add text for feed"
              minRows={7}
              style={{
                width: '100%',
                backgroundColor: '#F2F2F2',
                resize: 'none',
                border: '1px solid #C8C8C8',
                padding: '10px',
                marginTop: '2px',
              }}
              value={state.text}
              variant="filled"
              onChange={handleText}
            />
          </Grid>
          <Grid sx={style.buttons}>
            <Box>
              <Tooltip title={' Only .mp4, .mov and .webm files are supported'}>
                <Button sx={style.button1}>
                  <input
                    style={style.input}
                    type="file"
                    accept=".mp4,.mov,.webm "
                    onChange={handleVideo}
                    ref={fileVideoRef}
                  />
                  <img
                    src={require('../../assets/uploadvideo.png')}
                    style={{ margin: '5px', marginLeft: '0px' }}
                  />
                  upload video
                </Button>
              </Tooltip>
              <Button sx={style.button}>
                <input
                  style={style.input}
                  type="file"
                  accept=".png , .jpeg, .jpg"
                  onChange={handleImage}
                  ref={fileImgRef}
                />
                <img
                  src={require('../../assets/uploadimage.png')}
                  style={{ margin: '5px' }}
                />
                upload images
              </Button>
              <Button
                sx={style.button1}
                style={{ display: state.img || state.video ? '' : 'none' }}
                onClick={handlePreview}
              >
                Preview Post
              </Button>
            </Box>
            <TransformButton
              button_name={'POST'}
              transform={'skew(-22deg) translate(-14%, -50%)'}
              handleClick={post}
            />
          </Grid>
          <Typography sx={{ color: '#CB2128', fontSize: '14px' }}></Typography>
          <Grid
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
              alignContent: 'end',
            }}
          >
            <Box sx={style.box}>
              {' '}
              {[{ text: 'TIMELINE', route: CUSTOMER_PROFILE_DETAIL }].map(
                (item, key) => {
                  return (
                    <ProfileTab
                      item_route={item.route}
                      URL={URL}
                      style={style.itembox}
                      item_text={item.text}
                    />
                  )
                },
              )}
            </Box>
            {console.log(
              list?.filtered_records,
              list?.total_records,
              list?.filtered_records == list?.total_records,
            )}
            <Box
              className="timeline"
              ref={timelineRef}
              sx={{
                maxHeight: '500px',

                overflow: 'scroll',
              }}
            >
              <FeedBox
                count={list && list?.logs_list}
                loading={loadingStatus}
                total={list?.total_records}
              />

              {!loadingStatus &&
                list?.total_records != 0 &&
                list?.filtered_records !== list?.total_records && (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress
                      sx={{
                        display: 'flex',
                        overflowX: 'hidden',
                        height: '60px',
                        width: '60px',
                      }}
                    />
                  </Box>
                )}
            </Box>
            <Box sx={{ mb: '46px', cursor: 'pointer' }}>
              <ArrowUpward
                onClick={scrollToTop}
                style={{
                  display: visible ? 'inline' : 'none',
                  background: '#4cd964',
                  borderRadius: '50%',
                  color: 'white',
                  right: '30px',
                  // bottom: '1px',
                  // top: '-150px',
                  marginBottom: '10px',
                  position: 'absolute',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CustomerProfile
const style = {
  itembox: {
    fontSize: '12px',
    fontWeight: '600',
    boxShadow: 'none',
    p: '10px 25px 10px 25px',
    textAlign: { xs: 'center', sm: 'unset' },
  },

  box: {
    display: 'flex',
    width: '100%',
    bgcolor: '#F5F4FF',
    border: '1px solid #CDCAFC',
    flexWrap: { xs: 'wrap', sm: 'unset' },
  },
  headingText: {
    fontSize: { xs: '30px', sm: '30px' },
    fontWeight: '500',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    textTransform: 'none',
    color: '#27245E',
    pl: 0,
  },
  button1: {
    textTransform: 'none',
    color: '#27245E',
    pl: 1,
  },
  input: {
    position: 'absolute',
    opacity: 0,
    left: 0,
    right: 0,
  },
  row: {
    borderBottom: 'none',
    p: 0,
    color: '#333333',
    '&.css-1p823my-MuiListItem-root ': {
      pl: 0,
    },
  },
  row2: {
    p: 0,
  },
  jump: {
    border: '1px solid #CDCAFC',
    bgcolor: '#F5F4FF',
    borderRadius: '20px',
    width: '75%',
    p: 1,
    fontSize: '14px',
    ml: 2,
    mt: 2,
    color: '#27245f',
  },
  tableCell: {
    color: '#27245E',
    fontWeight: 700,
  },
  row1: {
    borderBottom: 'none',
    color: '#CB2128',
    fontWeight: 600,
    marginLeft: '15px',
  },
  about: {
    bgcolor: '#27245E',
    color: 'white',
    p: 1,
    pl: 2,
  },
  aboutData: {
    bgcolor: '#F5F4FF',
    p: 2,
  },
  button_one: {
    bgcolor: '#CB2128',
    fontWeight: 400,
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      boxShadow: 'none',
    },
    '&.MuiButtonLabel': {
      transform: 'skew(-22deg) translate(-54%, -50%)',
    },
    // m: { xs: 1, sm: 2 },
    mt: '30px',
    boxShadow: 'none',
    p: '0px 50px 0px 50px',

    color: 'white',
    // width: "120px",
    textTransform: 'none',
    transform: 'skew(-22deg) translate(-54%, -50%)',
    height: '40px',
    fontSize: { xs: '12px', sm: '18px' },
  },
  iconEye: {
    width: '19px',
    height: '19px',
    cursor: 'pointer',
    marginLeft: '10px',
    color: '#fff',
  },
  edit: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '50%',
  },
  content: {
    mt: 2,
  },
}
