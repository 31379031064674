import {
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  SET_COUNTRIES,
  SET_SIGNUP_PAYMENT,
  SET_STATES,
} from '../ConstAction'
const initialState = {}
export const PaymentData = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIGNUP_PAYMENT:
      return { payment: action.response.data.data, empty: false }
    case SET_COUNTRIES:
      //console.log('hello')
      return { ...state, countries: action.response.data.data, empty: false }
    case SET_STATES:
      //console.log('hii')
      return { ...state, states: action.response.data.data, empty: false }
    case GET_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: action.data,
      }
    case GET_EMPTY_STATUS:
      return {
        ...state,
        empty: action.data,
      }

    default:
      return { ...state }
  }
}
