import { Api } from '../../config/request'

export const requestLogin = async (data) => {
  return Api.PostRequest('/login', data)
}
export const requestLogout = async (data) => {
  return Api.PostRequest('/logout', data)
}
export const requestAdminProfile = async (data) => {
  return Api.PostRequest('/user', data)
}
export const requestAdminProfileUpdate = async (data) => {
  return Api.PostRequest('/edit/profile', data)
}
export const requestAdminUpdatePass = async (data) => {
  return Api.PostRequest('/reset-password', data)
}
export const requestForgotPassword = async (data) => {
  //console.log(data)
  return Api.PostRequest('/forgot-password', data)
}
export const requestnotifications = async (data) => {
  //console.log(data)
  return Api.PostRequest('/notification/list', data)
}
export const requestnotificationnumber = async (data) => {
  //console.log(data)
  return Api.PostRequest('/notification/getCount', data)
}

export const requestEmailVerify = async (data) => {
  return Api.PostRequest('/verify-email',data)
}
const ApiRequests = {
  requestLogin,
  requestLogout,
  requestAdminProfile,
  requestAdminProfileUpdate,
  requestAdminUpdatePass,
  requestForgotPassword,
  requestnotifications,
  requestnotificationnumber,
  requestEmailVerify
}
export default ApiRequests
