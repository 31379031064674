import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { storage as LocalStorage } from '../config/storage'
import 'react-toastify/dist/ReactToastify.css'
import Toastify from '../Components/SnackBar/Toastify'
import { toast } from 'react-toastify'
import {
  CLUB_PROFILE_UPDATE,
  CUSTOMER_EDIT,
  PLAYER_EDIT,
  PROFILE_UPDATE,
} from './constURL'
const OpenRoutes = ({ children }) => {
  //console.log('heyyyy')
  // const [state, setstate] = useState(false);
  const acc_Token = LocalStorage.fetch.authToken()
  const role = LocalStorage.fetch.role()
  if (acc_Token) {
    setTimeout(() => {
      toast.error('You are already logged in')
    }, 500)
    return (
      <Navigate
        to={
          role === 'club_admin'
            ? CLUB_PROFILE_UPDATE
            : role === 'player'
              ? CUSTOMER_EDIT
              : PROFILE_UPDATE
        }
        replace
      />
    )
  } else {
  }
  return children
}

export default OpenRoutes
