import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Grid, Paper, useMediaQuery } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  borderRadius: 'unset',
}))
const RevenueTab = ({ item_route, item_text, URL, style, id }) => {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.up('xs'))
  const isdes = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Grid
      // sx={{
      //   width: { xs: '50%', sm: '20%' },
      //   textAlign: { xs: 'center', sm: 'none' },
      // }}
      sx={{
        width: isdes ? '25%' : '50%',
        textAlign: { xs: 'center', sm: 'center' },
      }}
    >
      <Link style={{ textDecoration: 'none' }} to={item_route}>
        <Item
          sx={style}
          style={{
            color: item_route === URL ? 'white' : 'black',
            backgroundColor: item_route === URL ? '#D80027' : '#F5F4FF',
          }}
        >
          {item_text}
        </Item>
      </Link>
    </Grid>
  )
}

export default RevenueTab
