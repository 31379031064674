import { Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputField from '../../Components/InputField'
import { toast } from 'react-toastify'
import { storage } from '../../config/storage'
import TransformButton from '../../Components/CustomButton/TransformButton'
import { useDispatch } from 'react-redux'
import {
  getReferLink,
  sendReferLink,
} from '../../redux/myaccount/myaccountActions'
import { useSelector } from 'react-redux'
import { Schema } from '../../Components/FormSchema/Schema'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getLoadingStatus } from '../../redux/login/action'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import Toastify from '../../Components/SnackBar/Toastify'
import Medal from '../../assets/medal.png'

const defaultValues = {
  email: '',
}
const Refer = () => {
  const [url, setUrl] = useState('')
  const { link, loadingStatus } = useSelector(
    (state) => state?.AccountData || {},
  )
  const myDispatch = useDispatch()
  useEffect(() => {
    myDispatch(getReferLink())
  }, [])
  useEffect(() => {
    setUrl(link?.link)
  }, [link])
  //console.log(link)
  const copy = () => {
    try {
      navigator.clipboard.writeText(link?.link)
      toast.success('URL copied Successfully.')
    } catch (err) {
      //console.error('Unable to copy to clipboard', err)
      toast.error('Error copying URL to clipboard.')
    }
  }
  const { control, formState, handleSubmit, setError, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(Schema.FORGOT_PASSWORD),
  })
  const { isValid, dirtyFields, errors } = formState
  const onSubmit = (value) => {
    // let id = storage.fetch.customerId()
    let send = {
      email: value.email,
      link: url,
    }
    myDispatch(sendReferLink(send))
    myDispatch(getLoadingStatus(true))
    reset(defaultValues)
  }

  var count = link?.referral_count
  const renderTextAndLogo = count > 0 && (
    <Grid container columnGap={2} alignItems="center" sx={{ mt: '2%' }}>
      <Grid item>
        <img style={{ width: '35px', height: '35px' }} src={Medal} alt="Logo" />
      </Grid>
      <Grid item>
        <Typography variant="body1" sx={{ color: '#27245E', mt: '-2%' }}>
          Hey! Your 50% discount has been applied.
        </Typography>
      </Grid>
    </Grid>
  )

  return (
    <Grid>
      <LoaderComponent open={loadingStatus} />
      <Toastify />
      <Grid container sx={Style.grid}>
        <Grid xs={5.5} sx={{}}>
          <Typography sx={Style.text}>
            If your friend creates an account using this link you'll receive{' '}
            <span style={Style.innertext}>50% off</span> your monthly
            subscription. This discount will only be applied once per account.
          </Typography>
        </Grid>
        <Grid xs={6} sx={Style.img}>
          <img src={require('../../assets/refer_img1.png')} />
          <img src={require('../../assets/refer_img.png')} />
        </Grid>
      </Grid>
      <Grid>
        <Grid container md={12} sx={{ pt: 4 }}>
          <Typography sx={{ color: '#CB2128', pb: 2 }}>
            {!link?.is_subscribed
              ? 'NOTE: This discount will not be applied on club admin players.'
              : ''}
          </Typography>
          <Grid
            xs={9}
            md={10.5}
            sx={{
              userSelect: 'none',
            }}
          >
            <InputField
              value={link?.link}
              // onChange={(e) => {
              //   setUrl(e.target.value)
              // }}
              disabled={true}
              type={'text'}
              placeholder={'Club Login/Signup Link'}
              size={{ xs: '18px', md: '18px', lg: '18px' }}
              color={'#333333'}
              name1={'Club Login/Signup Link'}
              required={'*'}
              edit_update={true}
              formlabel={'Referral Link'}
              mt={0}
            />
          </Grid>

          <Grid md={1.5} sx={Style.copybutton}>
            <Button
              className="btn-rt-skw"
              variant="contained"
              // disabled={
              //   data?.membership_status === 'non-active' ? true : false
              // }
              sx={{
                ...Style.button_one,
                '&.MuiButton-root:hover': {
                  bgcolor: '#2D8E42',
                },
              }}
              onClick={() => copy()}
            >
              <Typography>COPY</Typography>
            </Button>
          </Grid>
        </Grid>
        {renderTextAndLogo}
        <form name="sendLink" onSubmit={handleSubmit(onSubmit)}>
          <Grid container columnGap={12} sx={{ mt: 3 }}>
            <Grid xs={12} md={4}>
              <InputField
                control={control}
                helperText={errors?.email?.message}
                errors={!!errors.email}
                type={'text'}
                placeholder={'Enter Your Email Address'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
                name="email"
                required={'*'}
                formlabel={'Enter Email Address'}
              />
            </Grid>
          </Grid>
          <Grid sx={Style.button1}>
            <TransformButton
              transform={'skew(-22deg) translate(0%, -50%)'}
              typeSubmit={'submit'}
              button_name={'SEND'}
            />
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default Refer
const Style = {
  grid: {
    mt: 2,
    p: 2,
    bgcolor: '#F5F4FF',
    borderRadius: '10px',
    display: { xs: 'none', md: 'flex' },
  },
  img: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  text: {
    p: 3,
    color: '#27245E',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  innertext: {
    color: '#CB2128',
  },
  button: {
    background: 'red',
    color: 'white',
    textTransform: 'none',
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      boxShadow: 'none',
    },
  },
  button1: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    pt: { xs: 0, md: 3.5 },
  },

  button_one: {
    bgcolor: '#2D8E42',
    color: 'white',
    height: '60%',
    p: 0,
    transform: 'none',
  },
  copybutton: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'end',
    justifyContent: 'flex-end',
  },
}
