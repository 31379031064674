import { Box, Table, TableBody } from '@mui/material'
import React, { useEffect, useLayoutEffect, useReducer, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { EnhancedTableHead } from '../Components/TableHeader/TableHeader'
import { Style } from './../Admin/CSS/Table'
import Toastify from '../Components/SnackBar/Toastify'
import { storage } from '../config/storage'
import {
  getPlayerDelete,
  getPlayerListRequest,
} from '../redux/players/playerAction'
import TopBox from '../Components/TableHeader/TopBox'
import { PLAYER_EDIT, PLAYER_INFO_DETAILS } from '../routes/constURL'
import { getLoadingStatus } from '../redux/login/action'
import NoDataFound from '../Admin/Pages/NoDataFound'
import ListSkelton from '../Components/Loader/ListSkelton'
import CustomTableBody from '../Components/TableBox/CustomTableBody'
import CustomPagination from '../Components/Pagination'
import AlertDialog from '../Components/Dialog/Dialog'
import { status_arr } from '../config/mockData'
import SomethingWrong from '../Admin/Pages/SomethingWrong'
import LoaderComponent from '../Components/Loader/LoaderComponent'
import { useState } from 'react'

//---State for handling data & payloads---//
const initialState = {
  order: 'asc',
  orderBy: '',
  page: 1,
  pages: '',
  total: '',
  rows: [],
  status: 'Select Status',
  membership: 'Select Program',
  columns: [],
  startFrom: 0,
  length: 5,
  statusNew:'Select Status',
  searched:false
}

//---Setter function---//
function reducerFunction(prevState, nextState) {
  return { ...prevState, ...nextState }
}
const PlayerList = () => {
  const { list, empty, loadingStatus, error } = useSelector(
    (state) => state.PlayerData || {},
  )
  const newstate = useSelector((state) => state)
  console.log(list)
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  // const [isResetDisabled, setResetDisabled] = useState(true);
  const navigate = useNavigate()
  const myDispatch = useDispatch()
  let data = {
    start: 0,
    length: state.length,
    sort_column: '',
    sort_by: '',
    search_val: '',
    listType: 'club_palyers',
  }
  ///---Side effect function when change in list length(Pagination)---///
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    myDispatch(getPlayerListRequest(data))
    myDispatch(getLoadingStatus(true))
    const headCells = [
      { label: 'Player Name', name: 'customerName' },
      { label: 'Age', name: 'age' },
      { label: 'Membership', name: 'Membership' },
      { label: 'Created On', name: 'created_on' },
      { label: 'Status', name: 'Status' },
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    setStateReducer({ columns: headCells })
  }, [state.length])
  ///---Side effect function when change in list---///
  useLayoutEffect(() => {
    setStateReducer({
      rows: list,
      pages: list?.pages,
      total: list?.total_records,
    })
  }, [list])
  useEffect(() => {
    document.title = 'OG Sports - Player'
    setStateReducer({statusNew:state.status})
  }, [state.searched])

  ///---Sorting function---///
  const handleRequestSort = (event, property) => {
    setStateReducer({ page: state.page })
    let a = (state.page - 1) * state.length
    data.start = a
    data.length = state.length
    
    let isAsc = state.orderBy === property && state.order === 'asc'
    if (state.orderBy !== property) {
      setStateReducer({ order: 'desc', orderBy: property })
      data.sort_by = 'asc'
      data.sort_column = property
    } else {
      setStateReducer({ order: isAsc ? 'desc' : 'asc', orderBy: property })
      data.sort_by = state.order
      data.sort_column = property
    }
    Object.assign(data,{membership_status:state.statusNew=='Select Status' ? '' : state?.status});
    myDispatch(getPlayerListRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  ///---Pagination Function---///
  const handlePageChange = (event, value) => {
    setStateReducer({ page: value })
    let a = (value - 1) * state.length
    data.start = a
    data.length = state.length
    if (state.order && state.orderBy) {
      data.sort_by = state.order === 'asc' ? 'desc' : 'asc'
      data.sort_column = state.orderBy
    }
    Object.assign(data, {
      membership_status: state.statusNew == 'Select Status' ? '' : state?.statusNew,
    })
    myDispatch(getPlayerListRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  ///---Seach & Filter Function---///
  const handleChangeSelect = (e, type) => {
    switch (type) {
      case 'status':
        //////////console.log(e.target.value)
        return setStateReducer({ status: e.target.value })
      case 'member':
        return setStateReducer({ membership: e.target.value })
      case 'search':
        return setStateReducer({ search: e.target.value })
      case 'search_click':
        setStateReducer({statusNew: state.status})
        Object.assign(data, {
          search_val: state.search,
          membership_status:
            state.status === 'Select Status' ? '' : state.status,
        })
        myDispatch(getPlayerListRequest(data))
        myDispatch(getLoadingStatus(true))
        return setStateReducer({ page: 1 })
      default:
        return null
    }
  }
  ///---Navigation to edit page---///
  const navigateTo = (id) => {
    storage.set.customerId(id)
    navigate(PLAYER_EDIT + '/' + id, {
      state: id,
    })
  }
  const handleDelete = (id) => {
    setStateReducer({ id: id })
    setStateReducer({ alert: true })
  }
  const confirmDelete = async (id) => {
    myDispatch(getPlayerDelete({ users_id: id }))
    myDispatch(getLoadingStatus(true))
    myDispatch(getPlayerListRequest(data))
    setStateReducer({ alert: false })
    // myDispatch({type: "NAVIGATE", navigate,URL:CUSTOMER_LISTING})
  }

  useEffect(() => {
    // Check if any two drop-downs have default values
    // const isDefaultStatus = state.status === 'Select Status';
    // const isDefaultMembership = state.membership === 'Select Program';
   
    const isDefaultStatus = state.status === 'Select Status';
    const isDefaultSearch = state.search === '' || state.search === undefined;
    // If any two drop-downs have default values, disable the button
    setButtonDisabled( isDefaultSearch && isDefaultStatus );
  }, [state.status,state.search]);

  // useEffect(() => {
  //   // Check if both drop-downs have default values
  //   // const isDefaultStatus = state.status === 'Select Status';
  //   // const isDefaultMembership = state.membership === 'Select Program';
  //   // If both drop-downs have default values, disable the reset button
    
  //   const isDefaultStatusR = state.status === 'Select Status' || state.status === '';
  //   const isDefaultSearchR = state.search === undefined || state.search === '';

  //   setResetDisabled( isDefaultStatusR && isDefaultSearchR );
  // }, [state.status,state.search]);

  const handleReset = () => {
    window.scrollTo(0, 0)
    myDispatch(getPlayerListRequest(data))
    myDispatch(getLoadingStatus(true))

    setStateReducer({
      // status: 'Select Status',
      // membership: 'Select Program', order: 'asc',
      orderBy: '',
      page: 1,
      pages: 0,
      status:'Select Status',
      search:'',
      statusNew:'Select Status'
    })
  }
  return (
    <>
      <TopBox
        headerText={'Players'}
        search_According_To_routes1={true}
        searchText={'Search player'}
        search_val={state.search}
        handleChangeFirst={(e) => handleChangeSelect(e, 'status')}
        setSearch_val={(e) => handleChangeSelect(e, 'search')}
        handleChangeSecond={(e) => handleChangeSelect(e, 'member')}
        sortingText={'Filter by'}
        initialValue={'Select Program'}
        menuData={['1 month', '3 month']}
        value1={state.status}
        select_arr1={status_arr}
        width={'20%'}
        button_two={() => handleChangeSelect('', 'search_click')}
        // searchValue={state.search_val}
        onReset={handleReset}
        disabledVal={isButtonDisabled}
        // disabledReset={isResetDisabled}
      />
      <AlertDialog
        title={'Are you sure you want to delete this Player?'}
        open={state.alert}
        onClickButton={() => confirmDelete(state.id)}
        onClickButtonCancel={() => setStateReducer({ alert: false })}
      />
      {empty && <NoDataFound />}
      {error && <SomethingWrong />}
      {!empty && (
        <Box
          sx={[
            Style.table.tableWrapBox,
            { display: empty || error ? 'none' : 'flex' },
          ]}
        >
          <LoaderComponent open={loadingStatus} />
          <Table sx={Style.table.tableBox} aria-labelledby="tableTitle">
            <EnhancedTableHead
              columns={state.columns}
              setColumns={setStateReducer}
              order={state.order}
              orderBy={state.orderBy}
              onRequestSort={handleRequestSort}
              // rowCount={state.rows.user_list.length}
            />
            <TableBody sx={{ border: 0 }}>
              {!loadingStatus &&
                state.rows &&
                state.rows.user_list?.map((row, ind) => (
                  <CustomTableBody
                    key={row?.users_id}
                    URL={PLAYER_INFO_DETAILS}
                    navigateTo={navigateTo}
                    row={row}
                    loadingStatus={loadingStatus}
                    ind={ind}
                    columns={[
                      row?.user_name,
                      row?.user_age,
                      row?.user_membership.length != 0
                        ? row?.user_membership
                        : 'none',
                      row?.user_created_at,
                    ]}
                    handleDelete={handleDelete}
                    membership_status={true}
                  />
                ))}
              {loadingStatus && (
                <ListSkelton
                  loadingStatus={loadingStatus}
                  rows={5}
                  columns={6}
                  width={'100px'}
                />
              )}
            </TableBody>
            <Toastify />
          </Table>
        </Box>
      )}
      <CustomPagination
        state={state}
        empty={empty}
        error={error}
        handlePageChange={handlePageChange}
      />
    </>
  )
}

export default PlayerList
