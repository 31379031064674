import React, { useEffect, useReducer, useRef, useState } from 'react'
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableRow,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import InputField from '../InputField'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import TransformButton from '../CustomButton/TransformButton'
import Toastify from '../SnackBar/Toastify'
import styled from '@emotion/styled'
import SelectComponent from '../SelectComponent'
import {
  initialState,
  membership_arr,
  programs,
  reducerFunction,
  sportsData,
} from '../../config/mockData'
import { CloseOutlined } from '@mui/icons-material'
import { Schema } from '../FormSchema/Schema'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  addrevenueoffline,
  getRevenueDetailsRequest,
  getRevenueListRequest,
} from '../../redux/revenue/revenueAction'
import Select from 'react-select'
import { getcountries } from '../../redux/payments/paymentAction'
import { useSelector } from 'react-redux'
import { getCustomerListRequest } from '../../redux/customer/customerAction'
import { getLoadingStatus } from '../../redux/login/action'
import LoaderComponent from '../Loader/LoaderComponent'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const defaultValue = {
  amount: '',
  date: '',
  email: '',
}

const ModalBox = ({ open, handleClose, id }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const select = useRef(null)
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const { customer_list, loadingStatus } =
    useSelector((state) => state?.RevenueData) || {}
  const [length, setLength] = useState(10)
  const [search_value, setSearch_Value] = useState('')

  //console.warn(customer_list)
  let data = {
    start: 0,
    length: length,
    sort_column: '',
    sort_by: '',
    search_val: search_value,
    role: 'all',
  }
  let data1 = {
    start: 0,
    length: length,
    sort_column: '',
    sort_by: '',
    search_val: search_value,
  }
  const { control, formState, handleSubmit, reset, setError } = useForm({
    mode: 'onChange',
    defaultValue,
    resolver: yupResolver(Schema.ADDOFFLINEPAYMENTS),
  })
  const { isValid, dirtyFields, errors } = formState
  let options =
    customer_list &&
    customer_list?.user_list?.map((item, index) => {
      item = { value: item, label: item.user_name_selected }
      return item
    })
  if (customer_list?.total_records > length) {
    options?.push({ label: 'Loading...' })
  }
  if (state.customer_id === undefined && state.customer_select_err == '') {
    setStateReducer({ customer_select_err: 'Please Select a Customer' })
  }
  useEffect(() => {
    dispatch(getCustomerListRequest(data))
  }, [])

  // useEffect(() => {
  //   const SelectElement = select.current
  //   if (SelectElement) {
  //     SelectElement.addEventListener('scroll', handleScroll)
  //   }

  //   return () => {
  //     if (SelectElement) {
  //       SelectElement.removeEventListener('scroll', handleScroll)
  //     }
  //   }
  // }, [select])
  const onSubmit = (value) => {
    Object.assign(value, {
      id: state.customer_id,
      membership: state.membership,
      sport: state.sport,
    })
    if (state.customer_select_err == '') {
      dispatch(addrevenueoffline(value, navigate, handleClose))
      dispatch(getLoadingStatus(true))

      setTimeout(() => {
        dispatch(getRevenueDetailsRequest())
      }, 1000)

      reset()
    }
    setStateReducer({
      type: 'Select Type',
      membership: 'Select Program',
      sport: 'Select Sport',
    })
  }
  const handleChangeSelect = (e, type) => {
    //console.log(type, e)
    switch (type) {
      case 'type':
        return setStateReducer({ type: e?.target?.value, typeerr: '' })
      case 'member':
        return setStateReducer({
          membership: e?.target?.value,
          membershiperr: '',
        })
      case 'sport':
        return setStateReducer({ sport: e?.target?.value, sporterr: '' })
      default:
        return null
    }
  }
  const handleScroll = (e) => {
    setTimeout(() => {
      // setLength(length + 10)
      data.length = data.length + 10
      dispatch(getCustomerListRequest(data))
    }, 100)
  }
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
      borderRadius: '0px',
      padding: '3px',
      marginTop: '15px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor:
          isFocused || isSelected ? '#C7C8CC' : 'rgba(0, 0, 0, 0.06)',
        color: '#000',
        border: '0px',
      }
    },
    placeholder: (styles) => ({
      ...styles,
      fontSize: '16px',
      color: '#000',
      fontWeight: '500',
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: 'rgba(0, 0, 0, 0.54)', // Custom colour
    }),
  }
  const handleSelectCustomer = (event) => {
    console.log(event?.value?.user_membership)
    sportsData.map((i) => {
      if (i.value === event?.value?.sports) event.value.sports = i.label
    })
    setStateReducer({
      customer_id: event?.value?.users_id,
      customer_select_err: '',
      membership: event?.value?.user_membership,
      sport: event?.value?.sports === undefined ? '' : event?.value?.sports,
    })
  }
  const handleSearch = (event) => {
    setSearch_Value(event)
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Style.modal}>
          <Toastify />
          <LoaderComponent open={loadingStatus} />
          <Box sx={Style.header}>
            <Box sx={Style.header1}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ color: 'black', fontWeight: '700' }}
              >
                Add New Offline Payment
              </Typography>

              <Button onClick={handleClose}>
                <CloseOutlined sx={{ color: 'grey' }} />
              </Button>
            </Box>
          </Box>

          <form
            name="OfflineRevenue"
            style={{ minWidth: '360px' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              sx={{
                width: '100%',
                p: 4,
                pt: 2,
                mb: 2,
                maxHeight: '550px',
                overflow: 'scroll',
              }}
              className="timeline"
            >
              {/* <LoaderComponent open={newState?.loadingStatus} /> */}

              <Grid container rowSpacing={2} columnSpacing={3}>
                <Grid
                  sx={Style.mainGrid}
                  item
                  order={{ xs: 0, sm: 0 }}
                  xs={12}
                  md={6}
                >
                  <Item>
                    <Box sx={{ mb: '0px' }}>
                      <FormLabel sx={[Style.label, { mb: '20px' }]}>
                        Select User/Club
                        <span style={{ color: 'red' }}>*</span>
                      </FormLabel>
                      <Select
                        ref={select}
                        options={options}
                        isClearable
                        onMenuScrollToBottom={handleScroll}
                        styles={colourStyles}
                        onChange={handleSelectCustomer}
                        onInputChange={handleSearch}
                      />
                      {state.customer_select_err !== '' ? (
                        <p style={Style.validationStyle}>
                          {state.customer_select_err}
                        </p>
                      ) : null}
                    </Box>
                  </Item>
                  <Box sx={{ width: '100%', color: '#CB2128' }}>
                    <Typography sx={{ fontSize: '14px' }}>
                      The subscription of selected User/Club will get
                      'Activated' when you add offline revenue.
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid
                sx={Style.mainGrid}
                item
                order={{ xs: 2, sm: 0 }}
                xs={12}
                md={6}
              >
                <Item>
                  <Box>
                    <FormLabel sx={Style.label}>
                      Select Type(Individual/Bulk)
                      <span style={{ color: 'red' }}>*</span>
                    </FormLabel>
                    <SelectComponent
                      value={state.type}
                      handleChangeForSelect={(e) => {
                        handleChangeSelect(e, 'type')
                      }}
                      menuData={['Select', 'Individual', 'Bulk Order']}
                      mt={2}
                    />
                    {state.typeerr !== '' ? (
                      <p style={Style.validationStyle}>{state.typeerr}</p>
                    ) : null}
                  </Box>
                </Item>
              </Grid> */}
                <Grid
                  sx={Style.row2}
                  item
                  // order={{ xs: 3, sm: 0 }}
                  xs={12}
                  columnGap={2}
                  columnSpacing={3}
                >
                  <Grid item sm={6} xs={12}>
                    <Item>
                      <Box>
                        <InputField
                          value={state.sport}
                          disabled={true}
                          type={'text'}
                          placeholder={'Please enter sport'}
                          size={{ xs: '18px', md: '18px', lg: '18px' }}
                          color={'#333333'}
                          name1={'team'}
                          required={'*'}
                          edit_update={true}
                          formlabel={'Sport'}
                        />
                        {/* {state.sporterr !== '' ? (
                          <p style={Style.validationStyle}>{state.sporterr}</p>
                        ) : null} */}
                      </Box>
                    </Item>
                  </Grid>
                  <Grid sm={6} xs={12}>
                    <Item>
                      <Box sx={{ mt: { xs: 2, sm: 0 } }}>
                        {/* <FormLabel sx={Style.label}>
                          Select Program
                          <span style={{ color: 'red' }}>*</span>
                        </FormLabel> */}
                        {/* <SelectComponent
                          disabled={true}
                          mt={0.6}
                          value={state.membership}
                          handleChangeForSelect={(e) => {
                            handleChangeSelect(e, 'member')
                          }}
                          menuData={programs}
                        /> */}
                        <InputField
                          value={state.membership}
                          disabled={true}
                          type={'text'}
                          placeholder={'Please enter sport'}
                          size={{ xs: '18px', md: '18px', lg: '18px' }}
                          color={'#333333'}
                          name1={'team'}
                          required={'*'}
                          edit_update={true}
                          formlabel={'Select Program'}
                        />
                        {/* {state.membershiperr !== '' ? (
                          <p style={Style.validationStyle}>
                            {state.membershiperr}
                          </p>
                        ) : null} */}
                      </Box>
                    </Item>
                  </Grid>
                </Grid>
                <Grid item order={{ xs: 5, sm: 0 }} xs={12} sm={6}>
                  <Item>
                    <Box sx={{ mb: '0px' }}>
                      <InputField
                        control={control}
                        helperText={errors?.amount?.message}
                        value={state.price}
                        errors={!!errors.amount}
                        type={'text'}
                        amount={true}
                        placeholder={'Please enter payment amount'}
                        size={{ xs: '18px', md: '18px', lg: '18px' }}
                        color={'#333333'}
                        name={'amount'}
                        required={'*'}
                        formlabel={'Payment Amount'}
                      />
                    </Box>
                  </Item>
                </Grid>
                <Grid item order={{ xs: 6, sm: 0 }} xs={12} sm={6}>
                  <Item>
                    <Box sx={{ mb: '0px' }}>
                      <InputField
                        control={control}
                        helperText={
                          errors?.date?.message
                            ? 'Please select Payment Date'
                            : errors?.date?.message
                        }
                        errors={!!errors.date}
                        type={'date'}
                        placeholder={'Please enter date'}
                        size={{ xs: '18px', md: '18px', lg: '18px' }}
                        color={'#333333'}
                        name={'date'}
                        required={'*'}
                        formlabel={'Payment Date'}
                        max={new Date().toISOString().split('T')[0]}
                      />
                    </Box>
                  </Item>
                </Grid>
                <Grid item order={{ xs: 7, sm: 0 }} xs={12} sm={12}>
                  <Item>
                    <FormLabel sx={Style.label}>Remarks</FormLabel>
                    <Box sx={{ mb: '0px' }}>
                      <TextareaAutosize
                        style={{
                          width: '100%',
                          backgroundColor: 'rgba(0, 0, 0, 0.06)',
                        }}
                        variant="filled"
                        minRows={5}
                      />
                    </Box>
                  </Item>
                </Grid>
              </Grid>

              <Box sx={Style.buttonBox}>
                <TransformButton
                  typeSubmit={'submit'}
                  button_name={'Save'}
                  transform={'skew(-22deg) translate(-10%, -50%)'}
                />
              </Box>
            </Box>

            {/* <Toastify /> */}
          </form>
        </Box>
      </Modal>
    </div>
  )
}

export default ModalBox
const Style = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 2,
    borderRadius: 2,
  },
  header: {
    borderBottom: '1px solid gray',
    p: 2,
    pl: 4,
    width: '100%',
  },
  header1: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: 'red',
  },
  Skeleton: {
    width: '100%',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  star: {
    color: '#d32f2f',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    // mt: 2,
    color: '#333333',
  },
  headingText: {
    fontSize: { xs: '14px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },

  validationStyle: {
    color: '#d32f2f',
    margin: '10px',
    fontWeight: '500',
    fontSize: '12px',
  },
  mainGrid: {
    mt: '20px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: { md: 'flex-end' },
    width: '100%',
    // mt: 2,
    // pr: { xs: 1, sm: 10 },
    // pl: { xs: 1, sm: 10 },
  },
  row2: {
    display: { xs: 'block', sm: 'flex' },
  },
}
