import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TransformButton from '../../Components/CustomButton/TransformButton'
import { useNavigate, useParams } from 'react-router-dom'
import Video from '../../Components/Media/Video'
import {
  getMarkAsComplete,
  getProgramListRequest,
  getProgramViewRequest,
  getUpdateRequest,
} from '../../redux/program/programAction'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { storage } from '../../config/storage'
import { getLoadingStatus } from '../../redux/login/action'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import Toastify from '../../Components/SnackBar/Toastify'

const ViewProgram = () => {
  const { details, empty, loadingStatus } = useSelector(
    (state) => state?.ProgramData || {},
  )
  const navigate = useNavigate()
  const week = storage.fetch.week_number()
  let params = useParams()
  let data = {
    week_name: `week_${week}`,
    video_id: params.id,
  }
  const myDispatch = useDispatch()
  // const data = details?.Object.values(week_name).join('')

  useEffect(() => {
    myDispatch(getUpdateRequest(data))
    myDispatch(getLoadingStatus(true))

    myDispatch(getProgramViewRequest(data))
    myDispatch(getLoadingStatus(true))
  }, [])
  //console.log(details)

  const handleMarkComplete = () => {
    myDispatch(getMarkAsComplete({ video_id: params.id }, navigate))
  }
  return (
    <>
      {details?.map((item) => (
        <Grid sx={Style.outer}>
          <LoaderComponent open={loadingStatus} />
          <Typography sx={Style.heading}>{item?.name}</Typography>
          <Typography sx={Style.date}>{item?.active_date}</Typography>
          {details && <Video src={item.link} />}

          <Grid sx={Style.text}>
            <Typography sx={Style.heading1}>{item.details.title}</Typography>
            <Typography>{item.details.description}</Typography>
            <Grid sx={Style.button}>
              {!item?.is_watched && (
                <TransformButton
                  button_name={'Mark as complete'}
                  color={'#37BD54'}
                  handleClick={handleMarkComplete}
                />
              )}
            </Grid>
            <Toastify />
          </Grid>
        </Grid>
      ))}
    </>
  )
}
export default ViewProgram
const Style = {
  outer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    color: '#27245E',
    fontSize: '20px',
    fontWeight: 700,
    m: 3,
  },
  date: {
    border: '1px solid #CDCAFC',
    bgcolor: '#F5F4FF',
    borderRadius: '20px',
    p: '5px 15px',
    mb: 5,
  },
  heading1: {
    fontSize: '20px',
    fontWeight: 700,
  },
  text: {
    width: '80%',
    m: 2,
  },

  button: {
    ml: { xs: 2, md: 15 },
    mt: 2,
  },
}
