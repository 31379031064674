/* eslint-disable jsx-a11y/alt-text */
import { Checkbox, Grid, Paper, Typography } from '@mui/material'
import React, { useReducer, useState } from 'react'
import TransformButton from '../Components/CustomButton/TransformButton'
import InputField from '../Components/InputField'
import styled from '@emotion/styled'
import { Box } from '@mui/system'
import { yupResolver } from '@hookform/resolvers/yup'
import { Schema } from '../Components/FormSchema/Schema'

import { useForm } from 'react-hook-form'
import Toastify from '../Components/SnackBar/Toastify'
import { useDispatch } from 'react-redux'
import { getClubSignUp } from '../redux/signup/action'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getLoadingStatus } from '../redux/login/action'
import LoaderComponent from '../Components/Loader/LoaderComponent'
import { useSelector } from 'react-redux'
import SelectComponent from '../Components/SelectComponent'
import { initialState, reducerFunction, sportsData } from '../config/mockData'
import { FormLabel } from 'react-bootstrap'
import Select2 from './../Components/Select2'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))

const Signup = () => {
  const { loadingStatus } = useSelector((state) => state?.SignupData) || {}
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const myDispatch = useDispatch()
  const navigate = useNavigate()
  const URL = useLocation()
  const defaultValue = {
    f_name: '',
    l_name: '',
    email: '',
    sports: '',
    password: '',
    password_confirmation: '',
  }
  const [check, setCheck] = useState(false)
  const [checkError, setCheckError] = useState(null)
  const { control, formState, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValue,
    resolver: yupResolver(Schema.CLUB_SIGNUP),
  })

  const id = URL.search.substring(1)
  //console.log(id)

  const { errors } = formState
  const onSubmit = (value) => {
    //console.log(check)
    if (check == false) {
      setCheckError('Please accept the terms and conditions first.')
    } else {
      setCheckError(null)
      // console.log()]
      Object.assign(value, {
        club_type: 'club',
        role: 'player',
        parents_id: id,
        sports: value.sports,
      })
      myDispatch(getClubSignUp(value, navigate))
      myDispatch(getLoadingStatus(true))
      //   setTimeout(() => {
      //     navigate(BULK_ORDERS_LISTING)
      //   }, 2000)
    }
  }
  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )

  return (
    <Grid container>
      <Grid xs={12} sm={6}>
        <Toastify />
        <form name="signup" onSubmit={handleSubmit(onSubmit)}>
          <LoaderComponent open={loadingStatus} />
          <Box sx={{ mt: { xs: '40px', sm: '0px' }, p: 5, pt: 2, pb: 2 }}>
            <img
              alt="logo"
              // className="logoSize"
              style={{ width: '200px' }}
              src={require('../assets/logo-11.png')}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              p: 5,
              pt: 2,
              pb: 0,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '16px', sm: '18px' },
                // mt: { xs: 4, sm: 10 },
                mb: { xs: 0, sm: 2 },

                fontStyle: 'semiBold',
                fontWeight: '600',
                color: '#27245E',
              }}
            >
              Register your account here
            </Typography>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid
                sx={Style.mainGrid}
                item
                order={{ xs: 2, sm: 0 }}
                xs={12}
                sm={6}
              >
                <Item>
                  <Box>
                    <InputField
                      control={control}
                      helperText={errors?.f_name?.message}
                      errors={!!errors.f_name}
                      type={'text'}
                      placeholder={'Please enter first name'}
                      size={{ xs: '18px', md: '18px', lg: '18px' }}
                      color={'#333333'}
                      name={'f_name'}
                      required={'*'}
                      formlabel={'First Name'}
                    />
                  </Box>
                </Item>
              </Grid>
              <Grid
                sx={Style.mainGrid}
                item
                order={{ xs: 2, sm: 0 }}
                xs={12}
                sm={6}
              >
                <Item>
                  <Box sx={{}}>
                    <InputField
                      control={control}
                      helperText={errors?.l_name?.message}
                      errors={!!errors.l_name}
                      type={'text'}
                      placeholder={'Please enter last name'}
                      size={{ xs: '18px', md: '18px', lg: '18px' }}
                      color={'#333333'}
                      name={'l_name'}
                      required={'*'}
                      formlabel={'Last Name'}
                    />
                  </Box>
                </Item>
              </Grid>

              <Grid order={{ xs: 3, sm: 0 }} item xs={12} sm={12}>
                <Item>
                  <Box sx={{}}>
                    <InputField
                      control={control}
                      helperText={errors?.email?.message}
                      errors={!!errors.email}
                      type={'text'}
                      placeholder={'Please enter email'}
                      size={{ xs: '18px', md: '18px', lg: '18px' }}
                      color={'#333333'}
                      name={'email'}
                      required={'*'}
                      formlabel={'Email'}
                    />
                  </Box>
                </Item>
              </Grid>
              <Grid order={{ xs: 3, sm: 0 }} item xs={12} sm={12}>
                <Item>
                  <Box sx={{}}>
                    <Label name={'Sport'} />
                    <Select2
                      name="sports"
                      control={control}
                      options={sportsData}
                      mt={'10px'}
                      // defaultValue={Allcountries[0]}
                      placeholder={'Select Sport'}
                      error={!!errors.sports}
                      errorMessage={errors?.sports?.message}
                    />
                  </Box>
                </Item>
              </Grid>
              <Grid order={{ xs: 8, sm: 0 }} item xs={12} sm={12}>
                <Item
                  sx={{
                    '& input[type="password"]::-ms-reveal': { display: 'none' },
                  }}
                >
                  <InputField
                    control={control}
                    helperText={errors?.password?.message}
                    errors={!!errors.password}
                    type={'password'}
                    placeholder={'Please enter password'}
                    size={{ xs: '18px', md: '18px', lg: '18px' }}
                    color={'#333333'}
                    name={'password'}
                    required={'*'}
                    formlabel={'Password'}
                  />
                </Item>
              </Grid>
              <Grid order={{ xs: 8, sm: 0 }} item xs={12} sm={12}>
                <Item
                  sx={{
                    '& input[type="password"]::-ms-reveal': { display: 'none' },
                  }}
                >
                  <InputField
                    control={control}
                    helperText={errors?.password_confirmation?.message}
                    errors={!!errors.password_confirmation}
                    type={'password'}
                    placeholder={'Please confirm your password'}
                    size={{ xs: '18px', md: '18px', lg: '18px' }}
                    color={'#333333'}
                    name={'password_confirmation'}
                    required={'*'}
                    formlabel={'Confirm Password'}
                  />
                </Item>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', pt: 1 }}>
              <label id="checkbox1">
                <Checkbox
                  sx={{ pl: 0, float: 'left' }}
                  value={check}
                  onChange={(e) => setCheck(!check)}
                >
                  {' '}
                </Checkbox>
                <Typography
                  htmlfor="checkbox1"
                  sx={{
                    mt: '10px',
                    fontSize: '14px',
                    cursor: 'pointer',
                    float: 'left',
                  }}
                >
                  By signing up I agree with
                  <span
                    style={{
                      color: '#CB2128',
                      fontSize: '14px',
                      float: 'right',
                      marginLeft: '5px',
                    }}
                  >
                    {'  '}
                    Terms & Conditions
                  </span>
                </Typography>
              </label>
            </Box>
            {checkError !== null ? (
              <p style={Style.validationStyle}>{checkError}</p>
            ) : null}
            <Box sx={Style.buttonBox}>
              <TransformButton typeSubmit={'submit'} button_name={'Submit'} />
            </Box>
          </Box>
        </form>
      </Grid>
      <Grid
        xs={6}
        sx={{
          display: { xs: 'none', sm: 'flex' },
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <img
          width={'60%'}
          height={'100%'}
          style={{
            marginLeft: '50%',
            marginTop: '9%',
          }}
          src={require('../assets/bulk-signup-background.png')}
        />
        <img
          width={'50%'}
          height={'60%'}
          style={{ position: 'relative', right: '57%', marginTop: '13%' }}
          src={require('../assets/bulk-signup.png')}
        />
      </Grid>
    </Grid>
  )
}

export default Signup
const Style = {
  Skeleton: {
    width: '100%',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  star: {
    color: 'red',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#333333',
  },
  headingText: {
    fontSize: { xs: '14px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },

  validationStyle: {
    color: '#d32f2f',
    margin: '10px',
    fontWeight: '500',
  },
  mainGrid: {
    mt: '20px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: { md: 'flex-start' },
    mt: 1,
    // mb: 2,
    pr: { xs: 1, sm: 10 },
    pl: { xs: 1, sm: 10 },
  },
}
