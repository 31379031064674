import {
  Box,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Skeleton,
  Switch,
  Typography,
} from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import InputField from '../../Components/InputField'
import { Schema } from '../../Components/FormSchema/Schema'
import RowRadioButtonsGroup from '../../Components/RadioButtons/RadioButton'
import { useDispatch } from 'react-redux'
import {
  getCustomerAddUpdateRequest,
  getCustomerDetailsRequest,
} from '../../redux/customer/customerAction'
import { useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import Toastify from '../../Components/SnackBar/Toastify'
import TransformButton from '../../Components/CustomButton/TransformButton'
import EditIcon from '@mui/icons-material/Edit'
import { getLoadingStatus } from '../../redux/login/action'
import { CUSTOMERS_ADD_NEW } from '../../routes/constURL'
import SelectComponent from '../../Components/SelectComponent'
import Select2 from '../../Components/Select2'
import {
  initialState,
  programs,
  reducerFunction,
  sportsData,
} from '../../config/mockData'
import { setLocale } from 'yup'
import LoaderComponent from '../../Components/Loader/LoaderComponent'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))
const CustomerInfo = () => {
  const location = useLocation()
  const myDispatch = useDispatch()
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const newState = useSelector((state) => state?.CustomerData)
  const [image_uploaded, setImage_uploaded] = useState(
    '../../assets/profilepic.png',
  )
  const [gender, setGender] = useState('')
  const [image_binary, setImage_binary] = useState()
  const [sportserror, setsportserror] = useState(null)
  const [membershiperror, setmembershiperror] = useState(null)
  let user_id = useParams()
  let data = {
    users_id: user_id.id,
  }
  const [customerData, setCustomerData] = useState({
    f_name: '',
    l_name: '',
    age: '',
    membership: '',
    email: '',
    mobile: '',
    team: '',
    sports: '',
  })
  useEffect(() => {
    callApi()
  }, [])
  const callApi = () => {
    myDispatch(getCustomerDetailsRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  useEffect(() => {
    if (newState) {
      setCustomerData({
        f_name: newState?.customer_details?.f_name,
        l_name: newState?.customer_details?.l_name,
        email: newState?.customer_details?.email,
        mobile: newState?.customer_details?.mobile,
        age: newState?.customer_details?.age,
        team: newState?.customer_details?.team,
        sports: newState?.customer_details?.sports,
        membership: newState?.customer_details?.membership,
      })
      // console.log(newState?.customer_details?.active)
      setStateReducer({
        checked:
          newState?.customer_details?.membership_status === 'active'
            ? true
            : false,
      })
      setStateReducer({ sport: newState?.customer_details?.sports })
      setGender(newState?.customer_details?.gender)
    }
    setImage_uploaded(
      newState?.customer_details?.image
        ? newState?.customer_details?.image
        : require('../../assets/profileRect.png'),
    )
  }, [newState])

  const formik = useFormik({
    initialValues: customerData,
    validationSchema: Schema.CUSTOMER_PROFILE,
    onSubmit: (value) => {
      setCustomerData(value)
      setGender(value?.gender)
      onSubmit(value)
    },
    enableReinitialize: true,
  })
  const onSubmit = (value) => {
    Object.assign(value, data)
    const formData = new FormData()
    formData.append('f_name', value.f_name)
    formData.append('l_name', value.l_name)
    formData.append('email', value.email)
    formData.append('mobile', value.mobile)
    formData.append('gender', gender)
    formData.append('age', value.age)
    formData.append('team', value.team)
    formData.append('sports', value.sports)
    formData.append('membership', value.membership)
    formData.append('users_id', data.users_id)
    formData.append('active', state.checked)

    if (image_binary) formData.append('image', image_binary)
    myDispatch(getCustomerAddUpdateRequest(formData))
    myDispatch(getLoadingStatus(true))
    // setTimeout(() => {
    //   callApi()
    // }, 2000)
  }
  // ////console.log('customerData>>>>', customerData)
  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )
  const handleSelect1 = (e) => {
    if (e.target.value === 'Select Program') {
      setmembershiperror('Please select a program')
    } else {
      setmembershiperror(null)
    }
    setCustomerData((prevUserData) => ({
      ...prevUserData, // Shallow copy of the old object
      membership: e.target.value, // Update the specific key with the new value
    }))
  }
  const handleChangeGender = (e) => {
    // setCustomerData((prevUserData) => ({
    //   ...prevUserData, // Shallow copy of the old object
    //   gender: e.target.value, // Update the specific key with the new value
    // }))
    setGender(e.target.value)
  }
  const handleFileChange = (e) => {
    // ////console.log('hii', e.target.files[0])
    setImage_binary(e.target.files[0])
    const binary = URL.createObjectURL(e.target.files[0])
    // ////console.log(binary)
    setImage_uploaded(binary)
    // ////console.log(image_uploaded)
  }
  return (
    <form name="CustomerProfile" onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid
            sx={Style.mainGrid1}
            item
            order={{ xs: 2, sm: 0 }}
            xs={12}
            sm={6}
          >
            {/* {//console.warn(newState?.loadingStatus)} */}
            <LoaderComponent open={newState?.loadingStatus} />
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.f_name}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter first name'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'f_name'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'First Name'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.f_name && formik.touched.f_name ? (
                  <p style={Style.validationStyle}>{formik.errors.f_name}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
              <Box sx={{}}>
                <InputField
                  value={formik.values.l_name}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter last name'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'l_name'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Last Name'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.l_name && formik.touched.l_name ? (
                  <p style={Style.validationStyle}>{formik.errors.l_name}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid
            order={{ xs: 1, sm: 0 }}
            sx={Style.mainGrid}
            item
            xs={12}
            sm={6}
          >
            <Item
              sx={{
                boxShadow: 'none',
                cursor: 'pointer',
                borderRadius: '100%',
                maxWidth: { xs: '145px', sm: '170px' },
                p: 1,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  width: '30px',
                  height: '30px',
                  bgcolor: '#d80027',
                  color: 'white',
                  borderRadius: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  right: '14px',
                }}
              >
                <EditIcon
                  fontSize="30px"
                  sx={{ position: 'absolute', width: '1.5em', height: '1.5em' }}

                  // style={style.iconEye}
                />
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleFileChange}
                  style={{
                    position: 'absolute',
                    opacity: 0,
                    height: '30px',
                    width: '30px',
                  }}
                  // Ref for triggering file input click
                />
              </Box>
              <img
                alt="profileimage"
                className="profileImage"
                // src={vehicData?.photo_links[selected]?.image_url}
                src={image_uploaded}

                // onClick={() => setOpen(true)}
              />
            </Item>
          </Grid>
          <Grid order={{ xs: 3, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.age}
                  onChange={formik.handleChange}
                  type={'number'}
                  placeholder={'Please enter age'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'age'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Age'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.age && formik.touched.age ? (
                  <p style={Style.validationStyle}>{formik.errors.age}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 4, sm: 0 }} item xs={12} sm={6}>
            <Label name={'Gender'} />
            <div>
              <RowRadioButtonsGroup
                labelItems={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                  { label: 'Others', value: 'others' },
                ]}
                defaultValue={gender}
                handleChange={handleChangeGender}
              />
            </div>
          </Grid>
          <Grid order={{ xs: 5, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Box sx={{}}>
                <InputField
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter email'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'email'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Email Address'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.email && formik.touched.email ? (
                  <p style={Style.validationStyle}>{formik.errors.email}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 6, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Box sx={{}}>
                <InputField
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  type={'number'}
                  placeholder={'Please enter phone number'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'mobile'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Phone Number'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.mobile && formik.touched.mobile ? (
                  <p style={Style.validationStyle}>{formik.errors.mobile}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 7, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.team}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter current team'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'team'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Current Team'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.team && formik.touched.team ? (
                  <p style={Style.validationStyle}>{formik.errors.team}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 7, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Label name={'Sport'} />
              <Box sx={{ mb: '20px' }}>
                <Select2
                  name="sports"
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      'sports',
                      selectedOption ? selectedOption.value : null,
                    )
                  }
                  options={sportsData}
                  placeholder={'Select Sport'}
                  // mt={'2px'}
                  edit={true}
                  value={formik.values.sports}
                  error={formik.errors.sports}
                  errorMessage={formik?.sports?.message}
                />
                {formik.errors.sports && formik.touched.sports ? (
                  <p style={Style.validationStyle}>{formik.errors.sports}</p>
                ) : null}
                {/* <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                /> */}
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 7, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Label name={'Program'} />
              <div>
                {/* <SelectComponent
                  menuData={programs}
                  value={formik.values.membership}
                  name={'membership'}
                  edit={true}
                  mt={'5px'}
                  handleChangeForSelect={(selectedOption) => {
                    console.log('selectedOption', selectedOption)
                    formik.setFieldValue(
                      'membership',
                      selectedOption ? selectedOption.target.value : null,
                    )
                  }}
                />
                {formik.errors.membership ? (
                  <p style={Style.validationStyle}>
                    {formik.errors.membership}
                  </p>
                ) : null} */}
                <Select2
                  name="membership"
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      'membership',
                      selectedOption ? selectedOption.value : null,
                    )
                  }
                  options={programs}
                  placeholder={'Select Program'}
                  // mt={'2px'}
                  edit={true}
                  value={formik.values.membership}
                  error={formik.errors.membership}
                  errorMessage={formik?.membership?.message}
                />
                {formik.errors.membership && formik.touched.membership ? (
                  <p style={Style.validationStyle}>
                    {formik.errors.membership}
                  </p>
                ) : null}
              </div>
            </Item>
          </Grid>
          <Grid order={{ xs: 7, sm: 0 }} item xs={12} sm={6}>
            <Grid item order={{ xs: 9, sm: 0 }} xs={12} sm={6}>
              <Item>
                <FormLabel
                  sx={{
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    color: '#333333',
                    mb: '5px',
                  }}
                >
                  Membership Status
                  <span
                    style={{
                      color: 'red',
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <Box sx={{}}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1, height: '100px' }}
                        checked={state.checked}
                        onChange={() =>
                          setStateReducer({ checked: !state.checked })
                        }
                      />
                    }
                  />
                </Box>
              </Item>
            </Grid>
          </Grid>{' '}
        </Grid>

        <Box sx={Style.buttonBox}>
          <TransformButton typeSubmit={'submit'} button_name={'Update'} />
        </Box>
      </Box>

      <Toastify />
    </form>
  )
}

export default CustomerInfo
const Style = {
  Skeleton: {
    width: '100%',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  star: {
    color: 'red',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#333333',
  },
  headingText: {
    fontSize: { xs: '14px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },

  validationStyle: {
    color: '#CB2128',
    fontWeight: 400,
    // margin: '10px',
  },
  mainGrid: {
    mt: '20px',
  },
  mainGrid1: {
    mt: { xs: 0, sm: '20px' },
  },
  buttonBox: {
    display: 'flex',
    justifyContent: { md: 'flex-start' },
    mt: 2,
    mb: 2,
    pr: { xs: 1, sm: 10 },
    pl: { xs: 1, sm: 10 },
  },
}
