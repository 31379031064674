import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Grid, Paper, useMediaQuery } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  borderRadius: 'unset',
}))
const CustomerTab = ({ item_route, item_text, URL, style, id }) => {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.up('xs'))
  const isdes = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Grid
      sx={{
        width: isMobile && '50%',
        textAlign: { xs: 'center', sm: '' },
      }}
      style={{textWrap:'nowrap'}}
    >
      <Link style={{ textDecoration: 'none' }} to={item_route + '/' + id}>
        <Item
          sx={style}
          style={{
            color: item_route + '/' + id === URL ? 'white' : 'black',
            backgroundColor:
              item_route + '/' + id === URL ? '#D80027' : '#F5F4FF',
          }}
        >
          {item_text}
        </Item>
      </Link>
    </Grid>
  )
}

export default CustomerTab
