import { put, takeEvery } from 'redux-saga/effects'
import {
  GET_ADMIN_ADD_UPDATE,
  GET_ADMIN_DELETE,
  GET_ADMIN_DETAILS,
  GET_ADMIN_LIST,
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  SET_ADMIN_ADD_UPDATE,
  SET_ADMIN_DELETE,
  SET_ADMIN_DETAILS,
  SET_ADMIN_LIST,
} from '../ConstAction'
import { toast } from 'react-toastify'
import AdminApiRequests from '../../services/admins'
import { ADMIN } from '../../routes/constURL'

///-----*Functions*-----///
let listing_payload = {
  date: '',
  start: 0,
  length: 5,
  sort_column: '',
  sort_by: '',
  search_val: '',
  user_filter: true,
}
function* HandleApiResponse(
  response,
  successAction,
  errorMessage,
  refresh,
  toaster,
  data,
  navigate,
) {
  // console.log('data', response, navigate, data)
  if (response.data.status) {
    if (data?.page) {
      listing_payload['start'] = (data?.page - 1) * listing_payload['length']
    }

    //console.log('data', navigate, listing_payload, data)
    // let a = (response.data.data.jumpresult_list.page - 1) * 5
    if (refresh?.length > 0) {
      yield put({
        type: refresh,
        data: listing_payload,
      })
    }
    if (toaster) {
      toast.success(response?.data?.message)
    }
    setTimeout(() => {
      if (navigate) {
        navigate.navigate(data)
      }
    }, 1000)
    yield put({
      type: successAction,
      response,
    })
  } else if (response.data.code == 303) {
    yield put({
      type: GET_EMPTY_STATUS,
      data: true,
    })
    toast.error(response?.data?.errors[0] || errorMessage)
  } else if (response.data.code == 412) {
    //console.log('data', response?.data)
    toast.error(response?.data?.errors[0] || errorMessage)
  } else {
    toast.error(response?.data?.message || errorMessage)
  }
  yield put({
    type: GET_LOADING_STATUS,
    data: false,
  })
}

function* getAdminList(data) {
  try {
    let response = yield AdminApiRequests.requestAdminList(data.data)
    yield HandleApiResponse(response, SET_ADMIN_LIST, 'Failed to fetch list')
  } catch (err) {}
}

function* getAdminDetails(data) {
  try {
    let response = yield AdminApiRequests.requestAdminDetails(data.data)
    yield HandleApiResponse(
      response,
      SET_ADMIN_DETAILS,
      'Failed to fetch  profile',
    )
  } catch (err) {}
}
function* getAdminUpdate(data) {
  try {
    let response = yield AdminApiRequests.requestAdminUpdate(data.data)
    yield HandleApiResponse(
      response,
      SET_ADMIN_ADD_UPDATE,
      'Failed to add/update profile',
      false,
      true,
      ADMIN,
      data,
    )
  } catch (err) {}
}
function* getAdminDelete(data) {
  try {
    let response = yield AdminApiRequests.requestAdminDelete(data.data)
    yield HandleApiResponse(
      response,
      SET_ADMIN_DELETE,
      'Failed to delete  profile',
    )
  } catch (err) {}
}
function* adminSaga() {
  yield takeEvery(GET_ADMIN_LIST, getAdminList)
  yield takeEvery(GET_ADMIN_DETAILS, getAdminDetails)
  yield takeEvery(GET_ADMIN_ADD_UPDATE, getAdminUpdate)
  yield takeEvery(GET_ADMIN_DELETE, getAdminDelete)
}
export default adminSaga
