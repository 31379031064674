import {
  Box,
  Button,
  FormLabel,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputField from '../../Components/InputField'
import { Schema } from '../../Components/FormSchema/Schema'
import RowRadioButtonsGroup from '../../Components/RadioButtons/RadioButton'
import { useDispatch } from 'react-redux'
import {
  getCustomerAddUpdateRequest,
  getCustomerDetailsRequest,
} from '../../redux/customer/customerAction'
import { getLoadingStatus } from '../../redux/login/action'
import Toastify from '../../Components/SnackBar/Toastify'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import MeasurementForm from '../../Components/Forms/MeasurementForm'
import TransformButton from '../../Components/CustomButton/TransformButton'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import { playerData } from '../../config/mockData'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const MeasurementEdit = () => {
  let user_id = useParams()
  let navigate = useNavigate()
  let data = {
    users_id: user_id.id,
  }
  const newState = useSelector((state) => state?.CustomerData)
  const [handed, setHanded] = useState('')
  // console.log(newState)
  const [customerData, setCustomerData] = useState({
    armspan: '',
    init_height: '',
    int_weight: '',
    handed: 'left',
  })
  useEffect(() => {
    callAPi()
  }, [])
  const callAPi = () => {
    myDispatch(getCustomerDetailsRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  useEffect(() => {
    let data = newState?.customer_details
    if (newState) {
      setCustomerData({
        armspan: data?.armspan == 0 ? '' : data?.armspan,
        init_height: data?.init_height == 0 ? '' : data?.init_height,
        int_weight: data?.int_weight == 0 ? '' : data?.int_weight,
        handed: data?.handed === null ? 'left' : data?.handed,
      })
      setHanded(data?.handed === null ? 'left' : data?.handed)
    }
  }, [newState])
  const onSubmit = (value) => {
    //  value.handed= value.handed === null ? value.handed = 'left' : value.handed;
    Object.assign(value, data)
    myDispatch(getLoadingStatus(true))
    myDispatch(getCustomerAddUpdateRequest(value, navigate))
    setTimeout(() => {
      callAPi()
    }, 2000)
  }
  const formik = useFormik({
    initialValues: customerData,
    validationSchema: Schema.MEASUREMENTUPDATE,
    onSubmit: (value) => {
      setCustomerData(value)
      onSubmit(value)
      setHanded(value?.handed)
    },
    enableReinitialize: true,
  })
  let id = useLocation()

  // const { isValid, dirtyFields, errors } = formState
  const myDispatch = useDispatch()
  const handleHanded = (e) => {
    // setCustomerData((prevUserData) => ({
    //   ...prevUserData, // Shallow copy of the old object
    //   handed: e.target.value, // Update the specific key with the new value
    // }))
    setHanded(e.target.value)
    formik.setFieldValue('handed', e.target.value)
  }
  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )
  return (
    <>
      <form name="MeasurementEditForm" onSubmit={formik.handleSubmit}>
        <Grid
          sx={{ mt: 3 }}
          rowSpacing={2}
          columnSpacing={3}
          container
          spacing={2}
        >
          <LoaderComponent open={newState?.loadingStatus} />
          <Grid item xs={12} sm={3}>
            <Item>
              <InputField
                value={formik.values.armspan}
                onChange={formik.handleChange}
                type={'number'}
                placeholder={'Please enter Arm Span'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
                name1={'armspan'}
                required={'*'}
                formlabel="Arm Span"
                edit_update={true}
              />
              {formik.errors.armspan && formik.touched.armspan ? (
                <p style={Style.validationStyle}>{formik.errors.armspan}</p>
              ) : null}
            </Item>
            <Toastify />
          </Grid>
          <Grid xs={12} item sm={4}>
            <Item>
              <Label name={'Handed'} />
              <div>
                <RowRadioButtonsGroup
                  labelItems={[
                    { label: 'Left', value: 'left' },
                    { label: 'Right', value: 'right' },
                  ]}
                  defaultValue={handed}
                  handleChange={handleHanded}
                />
              </div>
            </Item>
          </Grid>
        </Grid>
        <Grid sx={{ mt: 3 }} container spacing={2}>
          <Grid xs={12} item sm={3}>
            <Item>
              <InputField
                value={formik.values.init_height}
                onChange={formik.handleChange}
                type={'number'}
                placeholder={'Please enter initial height'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
                name1={'init_height'}
                required={'*'}
                formlabel="Initial Height"
                edit_update={true}
              />
              {formik.errors.init_height && formik.touched.init_height ? (
                <p style={Style.validationStyle}>{formik.errors.init_height}</p>
              ) : null}
            </Item>
          </Grid>
          <Grid xs={12} item sm={3}>
            <Item>
              <InputField
                value={formik.values.int_weight}
                onChange={formik.handleChange}
                type={'number'}
                placeholder={'Please enter Initial Weight'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
                name1="int_weight"
                required={'*'}
                edit_update={true}
                formlabel="Initial Weight"
              />
              {formik.errors.int_weight && formik.touched.int_weight ? (
                <p style={Style.validationStyle}>{formik.errors.int_weight}</p>
              ) : null}
            </Item>
          </Grid>

          <Grid xs={12} item sm={3}>
            <Item>
              <Button
                className="btn-rt-skw"
                variant="contained"
                type={'submit'}
                sx={Style.button_one}
              >
                <Typography sx={{ transform: 'skew(22deg) ', fontWeight: 500 }}>
                  Update
                </Typography>
              </Button>
            </Item>
          </Grid>
        </Grid>
      </form>
      <MeasurementForm type_case={'customer_edit'} Style={Style} />
    </>
  )
}

export default MeasurementEdit
const Style = {
  star: {
    color: 'red',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#333333',
  },
  rowBoxStyle: {
    width: '100%',
    display: 'flex',
    fontSize: '18px',
    fontWeight: '500',
    flexDirection: { xs: 'column', md: 'row' },
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  validationStyle: {
    color: '#CB2128',
    fontWeight: 400,
    // margin: '10px',
  },
  button_one: {
    bgcolor: '#CB2128',
    fontWeight: 400,
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      boxShadow: 'none',
    },

    // m: { xs: 1, sm: 2 },
    mt: '50px',
    borderRadius: '0px',

    boxShadow: 'none',
    p: '0px 50px 0px 50px',

    color: 'white',
    // width: "120px",
    textTransform: 'none',
    transform: {
      xs: 'skew(-22deg) translate(0%, -48%)',
      sm: 'skew(-22deg) translate(10%, -48%)',
    },
    height: '40px',
    fontSize: { xs: '12px', sm: '18px' },
  },
}
