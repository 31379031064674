import { call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_BULK_PAYMENT,
  GET_CLUB_ADD_UPDATE,
  GET_CLUB_DASHBOARD_DATA,
  GET_CLUB_DELETE,
  GET_CLUB_DETAILS,
  GET_CLUB_LIST,
  GET_CLUB_USER_DETAILS,
  GET_EMAIL_SENT,
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_NUMBER,
  GET_PLAYER_DETAILS,
  GET_PLAYER_LIST,
  SET_BULK_PAYMENT,
  SET_CLUB_ADD_UPDATE,
  SET_CLUB_DELETE,
  SET_CLUB_DETAILS,
  SET_CLUB_LIST,
  SET_EMAIL_SENT,
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_NUMBER,
  SET_PLAYER_DETAILS,
  SET_PLAYER_LIST,
} from '../ConstAction'
import { toast } from 'react-toastify'
import ClubApiRequests from '../../services/clubs'
import {
  BULK_ORDERS,
  BULK_ORDERS_LISTING,
  CLUBDASHBOARD,
  CUSTOMER_LISTING,
  PLAYER_LISTING,
} from '../../routes/constURL'
import { storage } from '../../config/storage'
import PaymentApiRequests from '../../services/payments'
import ApiRequests from '../../services/login'
///-----*Functions*-----///
const location = window.location.pathname
function* HandleApiResponse(
  response,
  successAction,
  errorMessage,
  toaster,
  load,
) {
  console.log('res', location)
  // const navigate=useNavigate()
  if (response.data.status) {
    // navigate(CUSTOMER_LISTING)
    const { data } = response?.data || {}
    if (data?.token) {
      storage.set.authToken(data.token)
    }
    if (data?.role) {
      storage.set.role(data.role)
    }

    if (
      toaster &&
      successAction !== SET_NOTIFICATIONS &&
      successAction !== SET_NOTIFICATION_NUMBER
    ) {
      toast.success(response?.data?.message)
    }
    console.log('res', response)
    yield put({
      type: successAction,
      response,
    })
    // if (successAction == SET_NOTIFICATION_NUMBER) {
    //   if (location === PLAYER_LISTING) {
    //     yield put({
    //       type: GET_PLAYER_LIST,
    //       // response,
    //     })
    //   }
    // }
  } else if (response.code == 303) {
    yield put({
      type: GET_EMPTY_STATUS,
      data: true,
    })
  } else {
    toast.error(response?.data?.errors[0] || errorMessage)
  }
  yield put({
    type: GET_LOADING_STATUS,
    data: false,
  })
  // console.log('res', response)
}
function* getClubList(data) {
  // console.log('wrong')
  try {
    let response = yield ClubApiRequests.requestClubList(data.data)
    let toaster = false
    yield HandleApiResponse(
      response,
      SET_CLUB_LIST,
      'Failed to fetch club admin list',
      toaster,
    )
  } catch (err) {}
}
function* getClubDetails(data) {
  try {
    let response = yield ClubApiRequests.requestClubDetails(data.data)
    yield HandleApiResponse(
      response,
      SET_CLUB_DETAILS,
      'Failed to fetch club admin list',
    )
  } catch (err) {}
}
function* getClubUpdate(data) {
  try {
    let response = yield ClubApiRequests.requestClubAddUpdate(data.data)
    // console.log('response', response)
    if (response.data.status) {
      toast.success(response?.data?.message)

      const formdata = Object.fromEntries(data.data)?.users_id
      yield put({
        type: SET_CLUB_ADD_UPDATE,
        response,
      })
      yield put({
        type: GET_LOADING_STATUS,
        data: false,
      })
      if (formdata) {
        yield put({
          type: GET_CLUB_DETAILS,
          data: { users_id: formdata },
        })
      }
    } else if (response.status === 303) {
      yield put({
        type: GET_LOADING_STATUS,
        data: true,
      })
    } else {
      toast.error(response?.data?.errors[0])
      yield put({
        type: GET_LOADING_STATUS,
        data: true,
      })
    }
  } catch (err) {}
}
function* getClubUserDetails(data) {
  try {
    let response = yield ClubApiRequests.requestClubUserDetails(data.data)
    if (response.status) {
      yield put({
        type: SET_CLUB_ADD_UPDATE,
        response,
      })
      yield put({
        type: GET_LOADING_STATUS,
        data: false,
      })
    } else if (response.status === 303) {
    } else {
      toast.error(response?.errors[0])
      yield put({
        type: GET_LOADING_STATUS,
        data: true,
      })
    }
  } catch (err) {}
}
function* getClubDelete(data) {
  try {
    let list = {
      start: 0,
      length: 5,
      sort_column: '',
      sort_by: '',
      search_val: '',
      role: 'club_admin',
    }
    let response = yield ClubApiRequests.requestClubDelete(data.data)
    if (response.status) {
      toast.success(response?.data?.message)
      yield put({
        type: SET_CLUB_DELETE,
        response,
      })
      yield put({
        type: GET_CLUB_LIST,
        data: list,
      })
      yield put({
        type: GET_LOADING_STATUS,
        data: true,
      })
    } else if (response.code == 303) {
      yield put({
        type: GET_EMPTY_STATUS,
        data: true,
      })
      yield put({
        type: GET_CLUB_LIST,
      })
      yield put({
        type: GET_LOADING_STATUS,
        data: true,
      })
    } else {
      toast.error(response?.errors[0])
      yield put({
        type: GET_LOADING_STATUS,
        data: true,
      })
      yield put({
        type: GET_CLUB_LIST,
      })
    }
  } catch (err) {
    //console.log('wrong')
  }
}
function* getbulkpayments(data) {
  //console.log(data)
  // data.navigate("/")
  Object.assign(data?.payloadForPagination, {
    sort_column: '',
    sort_by: '',
    search_val: '',
    role: 'club_admin',
  })
  // let payload = {
  //   start: 0,
  //   length: 5,
  //   sort_column: '',
  //   sort_by: '',
  //   search_val: '',
  //   role: 'club_admin',
  // }
  try {
    let response = yield PaymentApiRequests.requestbulkpayments(data.data)
    yield put({
      type: GET_LOADING_STATUS,
      data: false,
    })

    if (response.data.status) {
      toast.success(response?.data?.message)

      yield put({
        type: SET_BULK_PAYMENT,
        response,
      })
      setTimeout(() => {
        // data.navigate(BULK_ORDERS_LISTING)
        data.handleClose()
      }, 1000)
      yield put({
        type: GET_CLUB_LIST,
        data: data.payloadForPagination,
      })
    } else if (response.code == 303) {
      //console.log('response', response.message)
      toast.error(response?.data?.errors[0])
      yield put({
        type: GET_EMPTY_STATUS,
        data: true,
      })
      yield put({
        type: GET_CLUB_LIST,
        data: data.payloadForPagination,
      })
      yield put({
        type: GET_LOADING_STATUS,
        data: false,
      })
    } else {
      toast.error(response?.data?.errors[0])
      yield put({
        type: GET_LOADING_STATUS,
        data: false,
      })
      yield put({
        type: GET_CLUB_LIST,
        data: data.payloadForPagination,
      })
    }
  } catch (err) {
    //console.log('wrong')
  }
}

function* getnotifications(data) {
  //console.log('testtest')
  try {
    let response = yield ApiRequests.requestnotifications(data.data)

    yield HandleApiResponse(response, SET_NOTIFICATIONS, 'Failed to fetch list')
  } catch (err) {}
}
function* getnotify(data) {
  try {
    let response = yield ApiRequests.requestnotificationnumber(data.data)

    // yield HandleApiResponse(
    //   response,
    //   SET_NOTIFICATION_NUMBER,
    //   'Failed to Sign up',
    // )
    if (response.status) {
      yield put({
        type: SET_NOTIFICATION_NUMBER,
        response,
      })
    } else if (response.status === 303) {
    } else {
      toast.error(response?.errors[0])
    }
  } catch (err) {}
}
function* ClubSaga() {
  yield takeEvery(GET_CLUB_LIST, getClubList)
  yield takeEvery(GET_CLUB_DETAILS, getClubDetails)
  yield takeEvery(GET_CLUB_ADD_UPDATE, getClubUpdate)
  yield takeEvery(GET_CLUB_USER_DETAILS, getClubUserDetails)
  yield takeEvery(GET_CLUB_DELETE, getClubDelete)
  yield takeEvery(GET_BULK_PAYMENT, getbulkpayments)
  yield takeEvery(GET_NOTIFICATIONS, getnotifications)
  yield takeEvery(GET_NOTIFICATION_NUMBER, getnotify)
}

export default ClubSaga
