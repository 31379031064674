import {
  Box,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Skeleton,
  Switch,
  ToggleButton,
  Typography,
  SwitchProps,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import InputField from '../../Components/InputField'
import { Schema } from '../../Components/FormSchema/Schema'
import RowRadioButtonsGroup from '../../Components/RadioButtons/RadioButton'
import { useDispatch } from 'react-redux'
import { getCustomerAddUpdateRequest } from '../../redux/customer/customerAction'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import Toastify from '../../Components/SnackBar/Toastify'
import TransformButton from '../../Components/CustomButton/TransformButton'
import EditIcon from '@mui/icons-material/Edit'
import { getLoadingStatus } from '../../redux/login/action'
import {
  ADMIN,
  CUSTOMERS_ADD_NEW,
  CUSTOMERS_INFO_EDIT,
  CUSTOMER_LISTING,
} from '../../routes/constURL'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import SelectComponent from '../../Components/SelectComponent'
import {
  initialState,
  reducerFunction,
  sportsData,
} from '../../config/mockData'
import { toast } from 'react-toastify'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import InputGrids from '../../Components/Grids/InputGrids'
import { getAdminAddUpdateRequest } from '../../redux/admins/adminAction'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const defaultValue = {
  f_name: '',
  l_name: '',
  age: '',
  email: '',
  mobile: '',
  role: 'Select',
  sports: '',
  password: '',
  password_confirmation: '',
}
const AddAdmin = () => {
  const [gender, setGender] = useState('male')
  const newState = useSelector((state) => state?.CustomerData)
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const [genderError, setGenderError] = useState(null)
  const [membershiperror, setmembershiperror] = useState(
    'Please Select Program',
  )
  const navigate = useNavigate()
  const [image_uploaded, setImage_uploaded] = useState(
    require('../../assets/profilepic.png'),
  )
  const [customerData, setCustomerData] = useState({
    f_name: '',
    l_name: '',
    age: '',
    email: '',
    mobile: '',
    password: '',
    password_confirmation: '',
  })
  const formFields = {
    email: '',
    mobile: '',
    password: '',
    password_confirmation: '',
  }
  const inputFields = []
  for (let i = 0; i < formFields.length; i++) {
    const fieldName = formFields[i]
    const fieldProps = {
      name: fieldName,
      xsOrder: 5 + i,
      xs: 12,
      sm: 6,
      control: control,
      helperText: errors?.[fieldName]?.message,
      errors: !!errors[fieldName],
      placeholder: fieldName.charAt(0).toUpperCase() + fieldName.slice(1),
      formlabel: fieldName.charAt(0).toUpperCase() + fieldName.slice(1),
    }

    inputFields.push(fieldProps)
  }

  const [image_binary, setImage_binary] = useState()
  useEffect(() => {
    if (newState?.URL) {
      // toast.success('Customer Added Successfully.')
      setTimeout(() => {
        navigate(CUSTOMER_LISTING)
      }, 2000)
    }
  }, [newState?.URL])
  ////console.log(newState)

  const { control, formState, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValue,
    resolver: yupResolver(Schema.ADMIN_ADD_PROFILE),
  })
  const { isValid, dirtyFields, errors } = formState
  console.log(errors)
  const onSubmit = (value) => {
    const formData = new FormData()
    formData.append('f_name', value.f_name)
    formData.append('l_name', value.l_name)
    formData.append('email', value.email)
    formData.append('phone', value.mobile)
    // formData.append('gender', value.gender)
    formData.append('age', value.age)
    formData.append('active', state.checked)

    formData.append('password', value.password)
    formData.append('password_confirmation', value.password_confirmation)
    formData.append('role', value.role)
    formData.append('gender', gender)

    if (image_binary) formData.append('image', image_binary)
    // Object.assign(formData)
    // ////console.log(value, state.checked)
    if (genderError === null) {
      myDispatch(getAdminAddUpdateRequest(formData, navigate))
      myDispatch(getLoadingStatus(true))
      // if (newState?.URL) navigate(ADMIN)
      // setTimeout(() => {
      //   navigate(ADMIN)
      // }, 2000)
    } else {
      if (genderError !== null) setGenderError('Please select a gender')
    }
  }

  const CustomDropdownIcon = (props) => {
    return <ExpandMoreIcon color="#757575" {...props} />
  }
  const myDispatch = useDispatch()

  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )
  const handleChangeGender = (e) => {
    if (e.target.value) {
      setGenderError(null)
    }
    setGender(e.target.value)
  }
  const handleFileChange = (e) => {
    // //console.log('hii', e.target.files[0])
    setImage_binary(e.target.files[0])
    const binary = URL.createObjectURL(e.target.files[0])
    // //console.log(binary)
    setImage_uploaded(binary)
    // //console.log(image_uploaded)
  }

  return (
    <form name="AdminProfile" onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <LoaderComponent open={newState?.loadingStatus} />
        <Typography variant="h2" sx={Style.headingText}>
          {'Add New Admin'}
        </Typography>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid
            sx={Style.mainGrid}
            item
            order={{ xs: 2, sm: 0 }}
            xs={12}
            sm={6}
          >
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  control={control}
                  helperText={errors?.f_name?.message}
                  errors={!!errors.f_name}
                  type={'text'}
                  placeholder={'Please enter first name'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'f_name'}
                  required={'*'}
                  formlabel={'First Name'}
                />
              </Box>
            </Item>

            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  control={control}
                  helperText={errors?.l_name?.message}
                  errors={!!errors.l_name}
                  type={'text'}
                  placeholder={'Please enter last name'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'l_name'}
                  required={'*'}
                  formlabel={'Last Name'}
                />
              </Box>
            </Item>
          </Grid>
          <Grid
            sx={Style.mainGrid}
            item
            order={{ xs: 1, sm: 0 }}
            xs={12}
            sm={6}
          >
            <Item
              sx={{
                boxShadow: 'none',
                cursor: 'pointer',
                borderRadius: '100%',
                maxWidth: { xs: '145px', sm: '170px' },
                p: 1,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  width: '30px',
                  height: '30px',
                  bgcolor: '#d80027',
                  color: 'white',
                  borderRadius: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  right: '14px',
                }}
              >
                <EditIcon
                  fontSize="30px"
                  sx={{ position: 'absolute', width: '1.5em', height: '1.5em' }}
                  // style={style.iconEye}
                />
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleFileChange}
                  style={{
                    position: 'absolute',
                    opacity: 0,
                    height: '30px',
                    width: '30px',
                  }}
                  // Ref for triggering file input click
                />
              </Box>
              <img
                alt="profileimage"
                className="profileImage"
                // src={vehicData?.photo_links[selected]?.image_url}
                src={image_uploaded}

                // onClick={() => setOpen(true)}
              />
            </Item>
          </Grid>
          <Grid
            style={{ paddingTop: 0 }}
            item
            order={{ xs: 3, sm: 0 }}
            xs={12}
            sm={6}
          >
            <Item>
              <Box sx={{ mb: '0px' }}>
                <InputField
                  control={control}
                  helperText={errors?.age?.message}
                  errors={!!errors.age}
                  type={'number'}
                  placeholder={'Please enter age'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'age'}
                  required={'*'}
                  formlabel={'Age'}
                />
              </Box>
            </Item>
          </Grid>
          <Grid item order={{ xs: 4, sm: 0 }} xs={12} sm={6}>
            <Label name={'Gender'} />
            <div>
              <RowRadioButtonsGroup
                labelItems={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                  { label: 'Others', value: 'others' },
                ]}
                defaultValue={gender}
                handleChange={handleChangeGender}
              />
            </div>
            {genderError !== null ? (
              <p style={Style.validationStyle}>{genderError}</p>
            ) : null}
          </Grid>
          <Grid item order={{ xs: 5, sm: 0 }} xs={12} sm={6}>
            <Item>
              <Box sx={{ mb: '0px' }}>
                <InputField
                  control={control}
                  helperText={errors?.email?.message}
                  errors={!!errors.email}
                  type={'text'}
                  placeholder={'Please enter email'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'email'}
                  required={'*'}
                  formlabel={'Email Address'}
                />
              </Box>
            </Item>
          </Grid>
          <Grid item order={{ xs: 6, sm: 0 }} xs={12} sm={6}>
            <Item>
              <Box sx={{ mb: '0px' }}>
                <InputField
                  control={control}
                  helperText={errors?.mobile?.message}
                  errors={!!errors.mobile}
                  type={'number'}
                  placeholder={'Please enter phone number'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'mobile'}
                  required={'*'}
                  formlabel={'Phone Number'}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 7, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Label name={'Role'} />
              <div>
                {/* <Controller
                  name="role"
                  control={control}
                  defaultValue={defaultValue.role}
                  render={({ field }) => (
                    <SelectComponent
                      mt={'10px'}
                      error={!!errors.role}
                      errorMessage={errors?.role?.message}
                      value={field.value}
                      handleChangeForSelect={field.onChange}
                      menuData={['Select Role', 'Admin', 'Standa']}
                    />
                  )}
                /> */}
                <Controller
                  name="role"
                  control={control}
                  defaultValue={defaultValue.role}
                  render={({ field }) => (
                    <FormControl error={!!errors.role} sx={{ width: '100%' }}>
                      <Select
                        variant="filled"
                        value={field.value}
                        onChange={field.onChange}
                        displayEmpty
                        disableUnderline
                        SelectDisplayProps={{
                          style: {
                            padding: 3,
                            marginLeft: '10px',
                          },
                        }}
                        MenuProps={{
                          style: {
                            maxHeight: '300px',
                            zIndex: 99999,
                          },
                          disableScrollLock: true,
                        }}
                        IconComponent={CustomDropdownIcon}
                        inputProps={{
                          style: {
                            paddingTop: '8px',
                            paddingBottom: '8px',
                            height: '300px',
                          },
                        }}
                        color="primary"
                        sx={{
                          height: '43px',
                          width: '100%',
                          fontSize: '16px',
                          fontWeight: '400',
                          border: '1px solid #C8C8C8',
                          borderRadius: '0px',
                          '& .MuiSelect-select:focus': {
                            backgroundColor: 'transparent',
                          },
                          '&.MuiList-root': {
                            height: '300px',
                          },
                          minWidth: '100px',
                          mt: 2,
                        }}
                      >
                        {[
                          { name: 'Select', value: 'Select' },
                          { name: 'Admin', value: 'admin' },
                          { name: 'Standard Admin', value: 'standard_admin' },
                        ]?.map((item, index) => (
                          <MenuItem
                            sx={{
                              textTransform: 'capitalize',
                              '&.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root':
                                {
                                  zIndex: 99999,
                                },
                            }}
                            value={item.value}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!errors.role && (
                        <FormHelperText
                          sx={{ '&.MuiFormHelperText-root': { ml: 0 } }}
                        >
                          {errors?.role?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </div>
            </Item>
          </Grid>
          <Grid order={{ xs: 7, sm: 0 }} item xs={12} sm={6}>
            <Grid item order={{ xs: 9, sm: 0 }} xs={12} sm={6}>
              <Item>
                <FormLabel
                  sx={{
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    color: '#333333',
                    mb: '5px',
                  }}
                >
                  Status
                  <span
                    style={{
                      color: 'red',
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <Box sx={{}}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1, height: '100px' }}
                        onChange={() =>
                          setStateReducer({ checked: !state.checked })
                        }
                      />
                    }
                  />
                </Box>
              </Item>
            </Grid>
          </Grid>
          <Grid item order={{ xs: 9, sm: 0 }} xs={12} sm={6}>
            <Item
              sx={{
                '& input[type="password"]::-ms-reveal': { display: 'none' },
              }}
            >
              <Box sx={{ mb: '0px' }}>
                <InputField
                  control={control}
                  helperText={errors?.password?.message}
                  errors={!!errors.password}
                  type={'password'}
                  placeholder={'Please enter password'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'password'}
                  required={'*'}
                  formlabel={'Password'}
                />
              </Box>
            </Item>
          </Grid>
          {/* <Grid item order={{ xs: 10, sm: 0 }} xs={12} sm={6}>
              <Item>
                <Box sx={{ mb: '0px' }}>
                  <InputField
                    control={control}
                    helperText={errors?.password_confirmation?.message}
                    errors={!!errors.password_confirmation}
                    type={'password'}
                    placeholder={'Please confirm your password'}
                    size={{ xs: '18px', md: '18px', lg: '18px' }}
                    color={'#333333'}
                    name={'password_confirmation'}
                    required={'*'}
                    formlabel={'Confirm Password'}
                  />
                </Box>
              </Item>
            </Grid> */}
          <InputGrids
            control={control}
            xsOrder={10}
            xs={12}
            sm={6}
            type={'password'}
            name={'password_confirmation'}
            helperText={errors?.password_confirmation?.message}
            errors={!!errors.password_confirmation}
            placeholder={'Please re-enter password'}
            formlabel={'Confirm Password'}
            sx={{
              '& input[type="password"]::-ms-reveal': { display: 'none' },
            }}
          />
        </Grid>

        <Box sx={Style.buttonBox}>
          <TransformButton typeSubmit={'submit'} button_name={'Submit'} />
        </Box>
      </Box>

      <Toastify />
    </form>
  )
}

export default AddAdmin
const Style = {
  Skeleton: {
    width: '100%',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  star: {
    color: '#d32f2f',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#333333',
  },
  headingText: {
    fontSize: { xs: '14px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },

  validationStyle: {
    color: '#d32f2f',
    margin: '10px',
    fontWeight: '500',
    fontSize: '12px',
  },
  mainGrid: {
    mt: '20px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: { md: 'flex-start' },
    mt: 2,
    mb: 2,
    pr: { xs: 1, sm: 10 },
    pl: { xs: 1, sm: 10 },
  },
}
