export const GET_ADMIN_LOGIN = 'GET_ADMIN_LOGIN'
export const GET_ADMIN_LOGOUT = 'GET_ADMIN_LOGOUT'
export const GET_LOADING_STATUS = 'GET_LOADING_STATUS'
export const GET_EMPTY_STATUS = 'GET_EMPTY_STATUS'
export const GET_ERROR_STATUS = 'GET_ERROR_STATUS'
export const GET_SAGA_CHECK = 'GET_SAGA_CHECK'
export const GET_CLUB_SIGNUP = 'GET_CLUB_SIGNUP'
export const SET_CLUB_SIGNUP = 'GET_CLUB_SIGNUP'
export const GET_ADMIN_PROFILE = 'GET_ADMIN_PROFILE'
export const GET_SET_ADMINLOGIN = 'GET_SET_ADMINLOGIN'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'
export const SET_ADMIN_PROFILE = 'SET_ADMIN_PROFILE'
///////----------FORGOT PASSWORD---------////////
export const GET_FORGOT_PASSWORD = 'GET_FORGOT_PASSWORD'
export const SET_FORGOT_PASSWORD = 'SET_FORGOT_PASSWORD'
export const GET_RESET_PASSWORD = 'GET_RESET_PASSWORD'
export const SET_RESET_PASSWORD = 'SET_RESET_PASSWORD'
///////
export const GET_ADMIN_PROFILE_UPDATE = 'GET_ADMIN_PROFILE_UPDATE'
export const SET_ADMIN_PROFILE_UPDATE = 'SET_ADMIN_PROFILE_UPDATE'
export const GET_ADMIN_PASS_UPDATE = 'GET_ADMIN_PASS_UPDATE'
export const SET_ADMIN_PASS_UPDATE = 'SET_ADMIN_PASS_UPDATE'

///////
export const GET_CUSTOMER_LIST = 'GET_CUSTOMER_LIST'
export const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST'
export const GET_CUSTOMER_DELETE = 'GET_CUSTOMER_DELETE'
export const SET_CUSTOMER_DELETE = 'SET_CUSTOMER_DELETE'

///////
export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS'
export const SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS'
//////
export const GET_CUSTOMER_PROFILE_UPDATE = 'GET_CUSTOMER_PROFILE_UPDATE'
export const SET_CUSTOMER_PROFILE_UPDATE = 'SET_CUSTOMER_PROFILE_UPDATE'
export const GET_CUSTOMER_PASS_UPDATE = 'GET_CUSTOMER_PASS_UPDATE'
export const SET_CUSTOMER_PASS_UPDATE = 'SET_CUSTOMER_PASS_UPDATE'
//////
export const GET_CLUB_LIST = 'GET_CLUB_LIST'
export const SET_CLUB_LIST = 'SET_CLUB_LIST'
export const GET_CLUB_DETAILS = 'GET_CLUB_DETAILS'
export const SET_CLUB_DETAILS = 'SET_CLUB_DETAILS'
export const GET_CLUB_ADD_UPDATE = 'GET_CLUB_ADD_UPDATE'
export const SET_CLUB_ADD_UPDATE = 'SET_CLUB_ADD_UPDATE'
export const GET_CLUB_USER_DETAILS = 'GET_CLUB_USER_DETAILS'
export const SET_CLUB_USER_DETAILS = 'SET_CLUB_USER_DETAILS'
export const GET_CLUB_DELETE = 'GET_CLUB_DELETE'
export const SET_CLUB_DELETE = 'SET_CLUB_DELETE'
export const GET_BULK_PAYMENT = 'GET_BULK_PAYMENT'
export const SET_BULK_PAYMENT = 'SET_BULK_PAYMENT'
//////
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA'
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA'
export const GET_CLUB_DASHBOARD_DATA = 'GET_CLUB_DASHBOARD_DATA'
export const SET_CLUB_DASHBOARD_DATA = 'SET_CLUB_DASHBOARD_DATA'
export const GET_EMAIL_SENT = 'GET_EMAIL_SENT'
export const SET_EMAIL_SENT = 'SET_EMAIL_SENT'
export const GET_EXPIRY_LINK = 'GET_EXPIRY_LINK'
export const SET_EXPIRY_LINK = 'SET_EXPIRY_LINK'
/////
export const GET_PLAYER_LIST = 'GET_PLAYER_LIST'
export const SET_PLAYER_LIST = 'SET_PLAYER_LIST'
export const GET_PLAYER_DETAILS = 'GET_PLAYER_DETAILS'
export const SET_PLAYER_DETAILS = 'SET_PLAYER_DETAILS'
export const GET_PLAYER_UPDATE = 'GET_PLAYER_UPDATE'
export const SET_PLAYER_UPDATE = 'SET_PLAYER_UPDATE'
export const GET_PLAYER_DELETE = 'GET_PLAYER_DELETE'
export const SET_PLAYER_DELETE = 'SET_PLAYER_DELETE'
export const GET_CLUB_ADMIN_PAYMENT = 'GET_CLUB_ADMIN_PAYMENT'
export const SET_CLUB_ADMIN_PAYMENT = 'SET_CLUB_ADMIN_PAYMENT'
/////
export const GET_SIGNUP_PAYMENT = 'GET_SIGNUP_PAYMENT'
export const SET_SIGNUP_PAYMENT = 'SET_SIGNUP_PAYMENT'
export const GET_STATES = 'GET_STATES'
export const SET_STATES = 'SET_STATES'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const SET_COUNTRIES = 'SET_COUNTRIES'
/////
export const GET_REVENUE_LIST = 'GET_REVENUE_LIST'
export const SET_REVENUE_LIST = 'SET_REVENUE_LIST'
export const GET_FAIL_REVENUE_LIST = 'GET_FAIL_REVENUE_LIST'
export const SET_FAIL_REVENUE_LIST = 'SET_FAIL_REVENUE_LIST'
export const GET_REFUND_REVENUE_LIST = 'GET_REFUND_REVENUE_LIST'
export const SET_REFUND_REVENUE_LIST = 'SET_REFUND_REVENUE_LIST'
export const GET_REVENUE_DETAILS = 'GET_REVENUE_DETAILS'
export const SET_REVENUE_DETAILS = 'SET_REVENUE_DETAILS'
export const GET_REVENUE_ADD = 'GET_REVENUE_ADD'
export const SET_REVENUE_ADD = 'SET_REVENUE_ADD'
/////
export const GET_EVENT_LOG_LIST = 'GET_EVENT_LOG_LIST'
export const SET_EVENT_LOG_LIST = 'SET_EVENT_LOG_LIST'
/////
export const GET_PROGRAM_LIST = 'GET_PROGRAM_LIST'
export const SET_PROGRAM_LIST = 'SET_PROGRAM_LIST'
export const GET_PROGRAM_VIEW = 'GET_PROGRAM_VIEW'
export const SET_PROGRAM_VIEW = 'SET_PROGRAM_VIEW'
export const GET_MARK_COMPLETE = 'GET_MARK_COMPLETE'
export const SET_MARK_COMPLETE = 'SET_MARK_COMPLETE'
export const GET_VIEW_UPDATE = 'GET_VIEW_UPDATE'
export const SET_VIEW_UPDATE = 'SET_VIEW_UPDATE'
////////
export const GET_REFUND = 'GET_REFUND'
export const SET_REFUND = 'SET_REFUND'
export const GET_ALL_LIST = 'GET_ALL_LIST'
export const SET_ALL_LIST = 'SET_ALL_LIST'
export const GET_ALL_FAIL_LIST = 'GET_ALL_FAIL_LIST'
export const SET_ALL_FAIL_LIST = 'SET_ALL_FAIL_LIST'
export const GET_ALL_REFUND_LIST = 'GET_ALL_REFUND_LIST'
export const SET_ALL_REFUND_LIST = 'SET_ALL_REFUND_LIST'
//////
export const GET_TIMELINE = 'GET_TIMELINE'
export const SET_TIMELINE = 'SET_TIMELINE'
export const GET_TIMELINE_POST = 'GET_TIMELINE_POST'
export const SET_TIMELINE_POST = 'SET_TIMELINE_POST'
////////////
export const GET_PROFILE_DATA = 'GET_PROFILE_DATA'
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA'
export const GET_JUMPING_DATA = 'GET_JUMPING_DATA'
export const SET_JUMPING_DATA = 'SET_JUMPING_DATA'
export const GET_MEASUREMENT_DATA = 'GET_MEASUREMENT_DATA'
export const SET_MEASUREMENT_DATA = 'SET_MEASUREMENT_DATA'
export const GET_MEASUREMENT_MODIFY_DATA = 'GET_MEASUREMENT_MODIFY_DATA'
export const SET_MEASUREMENT_MODIFY_DATA = 'SET_MEASUREMENT_MODIFY_DATA'
export const GET_MEASUREMENT_EDIT = 'GET_MEASUREMENT_EDIT'
export const SET_MEASUREMENT_EDIT = 'SET_MEASUREMENT_EDIT'
export const GET_MEASUREMENT_DELETE = 'GET_MEASUREMENT_DELETE'
export const SET_MEASUREMENT_DELETE = 'SET_MEASUREMENT_DELETE'
export const GET_JUMPING_UPDATE = 'GET_JUMPING_UPDATE'
export const SET_JUMPING_UPDATE = 'SET_JUMPING_UPDATE'
export const GET_JUMPING_DELETE = 'GET_JUMPING_DELETE'
export const SET_JUMPING_DELETE = 'SET_JUMPING_DELETE'
export const GET_JUMPING_EDIT = 'GET_JUMPING_EDIT'
export const SET_JUMPING_EDIT = 'SET_JUMPING_EDIT'
///////////
export const GET_CANCEL_SUBSCRIPTION = 'GET_CANCEL_SUBSCRIPTION'
export const SET_CANCEL_SUBSCRIPTION = 'SET_CANCEL_SUBSCRIPTION'
export const GET_UPGRADE_SUBSCRIPTION = 'GET_UPGRADE_SUBSCRIPTION'
export const SET_UPGRADE_SUBSCRIPTION = 'SET_UPGRADE_SUBSCRIPTION'
export const GET_SUBSCRIPTION_LIST = 'GET_SUBSCRIPTION_LIST'
export const SET_SUBSCRIPTION_LIST = 'SET_SUBSCRIPTION_LIST'
export const GET_REFER_LINK = 'GET_REFER_LINK'
export const SET_REFER_LINK = 'SET_REFER_LINK'
export const GET_SEND_REFER_LINK = 'GET_SEND_REFER_LINK'
export const SET_SEND_REFER_LINK = 'SET_SEND_REFER_LINK'
//////////
export const GET_ADMIN_LIST = 'GET_ADMIN_LIST'
export const SET_ADMIN_LIST = 'SET_ADMIN_LIST'
export const GET_ADMIN_DETAILS = 'GET_ADMIN_DETAILS'
export const SET_ADMIN_DETAILS = 'SET_ADMIN_DETAILS'
export const GET_ADMIN_ADD_UPDATE = 'GET_ADMIN_ADD_UPDATE'
export const SET_ADMIN_ADD_UPDATE = 'SET_ADMIN_ADD_UPDATE'
export const GET_ADMIN_DELETE = 'GET_ADMIN_DELETE'
export const SET_ADMIN_DELETE = 'SET_ADMIN_DELETE'
/////////////
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const GET_NOTIFICATION_NUMBER = 'GET_NOTIFICATION_NUMBER'
export const SET_NOTIFICATION_NUMBER = 'SET_NOTIFICATION_NUMBER'
export const GET_VERIFY_REQUEST = 'GET_VERIFY_REQUEST'
export const SET_VERIFY_REQUEST = 'SET_VERIFY_REQUEST'