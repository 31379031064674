import { put, takeEvery } from 'redux-saga/effects'
import {
  GET_ADMIN_PROFILE,
  GET_CUSTOMER_LIST,
  GET_EMPTY_STATUS,
  GET_EVENT_LOG_LIST,
  GET_JUMPING_DATA,
  GET_JUMPING_DELETE,
  GET_JUMPING_UPDATE,
  GET_LOADING_STATUS,
  GET_MEASUREMENT_DATA,
  GET_MEASUREMENT_MODIFY_DATA,
  GET_TIMELINE,
  GET_TIMELINE_POST,
  SET_ADMIN_PROFILE,
  SET_CUSTOMER_LIST,
  SET_EVENT_LOG_LIST,
  SET_JUMPING_DATA,
  SET_MEASUREMENT_DATA,
  SET_MEASUREMENT_MODIFY_DATA,
  SET_JUMPING_DELETE,
  SET_JUMPING_UPDATE,
  SET_TIMELINE,
  SET_TIMELINE_POST,
  GET_JUMPING_EDIT,
  SET_JUMPING_EDIT,
  SET_MEASUREMENT_EDIT,
  GET_MEASUREMENT_EDIT,
  SET_MEASUREMENT_DELETE,
  GET_MEASUREMENT_DELETE,
  GET_PROFILE_DATA,
  SET_PROFILE_DATA,
} from '../ConstAction'
import { toast } from 'react-toastify'
import EventLogsApiRequests from '../../services/event_logs'
import TimelineApiRequests from '../../services/customer_panel'
import CustomersApiRequests from '../../services/customer'
import ApiRequests from '../../services/login'
import CustomerPanelApiRequests from '../../services/customer_panel'
import {
  CUSTOMERS_JUMPING_EDIT,
  CUSTOMER_JUMPING_EDIT,
  CUSTOMER_PROFILE_DETAIL,
} from '../../routes/constURL'
///-----*Functions*-----///
let listing_payload = {
  date: '',
  start: 0,
  length: 5,
  sort_column: '',
  sort_by: '',
  search_val: '',
  user_filter: true,
}
function* HandleApiResponse(
  response,
  successAction,
  errorMessage,
  refresh,
  toaster,
  data,
  nav,
  path,
) {
  // console.log('data', response)
  if (response === 500) {
    // console.log('500')
    yield put({
      type: GET_LOADING_STATUS,
      data: false,
    })
  }
  if (response.data.status) {
    if (data?.page) {
      listing_payload['start'] = (data?.page - 1) * listing_payload['length']
    }

    // let a = (response.data.data.jumpresult_list.page - 1) * 5

    yield put({
      type: successAction,
      response,
    })
    // if (successAction === SET_MEASUREMENT_MODIFY_DATA) {
    //   yield put({
    //     type: SET_MEASUREMENT_DATA,
    //     listing_payload,
    //   })
    // }
    if (refresh?.length > 0) {
      yield put({
        type: refresh,
        data: listing_payload,
      })
      // window.location.reload()
    }
    if (successAction === SET_TIMELINE_POST) {
      //console.log(nav)
      nav.callApi()
    }

    if (toaster) {
      toast.success(response?.data?.message)
    }
  } else if (response.code == 413) {
    toast.error('Please upload file of less than 90 mb')
  } else if (response.code == 303) {
    yield put({
      type: GET_EMPTY_STATUS,
      data: true,
    })
    toast.error(response?.data?.message || errorMessage)
  } else {
    toast.error(response?.data?.message || errorMessage)
  }
  yield put({
    type: GET_LOADING_STATUS,
    data: false,
  })
}

function* getAdminProfile(data) {
  try {
    let response = yield ApiRequests.requestAdminProfile(data.data)
    yield HandleApiResponse(
      response,
      SET_PROFILE_DATA,
      'Failed to fetch  profile',
    )
  } catch (err) {}
}

function* getTimeline(data) {
  try {
    let response = yield CustomerPanelApiRequests.requestTimeline(data.data)
    yield HandleApiResponse(
      response,
      SET_TIMELINE,
      'Failed to fetch event log list',
    )
  } catch (err) {}
}
function* getTimelinePosted(data) {
  try {
    let response = yield CustomerPanelApiRequests.requestTimelinePosted(
      data.data,
    )
    yield HandleApiResponse(
      response,
      SET_TIMELINE_POST,
      'Failed to Post in Timeline',
      GET_TIMELINE,
      true,
      data,
      data,
      CUSTOMER_PROFILE_DETAIL,
    )
  } catch (err) {}
}
function* getJumpingResult(data) {
  try {
    let response = yield CustomerPanelApiRequests.requestJumping(data.data)
    yield HandleApiResponse(
      response,
      SET_JUMPING_DATA,
      'Failed to get jump result list',
    )
  } catch (err) {}
}
function* getMeasurementResult(data) {
  try {
    let response = yield CustomerPanelApiRequests.requestMeasurement(data.data)
    yield HandleApiResponse(
      response,
      SET_MEASUREMENT_DATA,
      'Failed to fetch list',
    )
  } catch (err) {}
}
function* getMeasurementModify(data) {
  try {
    let response = yield CustomerPanelApiRequests.requestMeasurementModify(
      data.data,
    )
    yield HandleApiResponse(
      response,
      SET_MEASUREMENT_MODIFY_DATA,
      'Failed to add/update measurement data',
      GET_MEASUREMENT_DATA,
      true,
      data.data,
    )
  } catch (err) {}
}
function* getJumpingResultUpdate(data) {
  try {
    let response = yield CustomerPanelApiRequests.requestJumpingResultUpdate(
      data.data,
    )
    yield HandleApiResponse(
      response,
      SET_JUMPING_UPDATE,
      'Failed to Update',
      GET_JUMPING_DATA,
      true,
      data.data,
    )
  } catch (err) {}
}
function* getJumpingResultDelete(data) {
  try {
    let response = yield CustomerPanelApiRequests.requestJumpingResultDelete(
      data.data,
    )
    yield HandleApiResponse(
      response,
      SET_JUMPING_DELETE,
      'Failed to Delete',
      GET_JUMPING_DATA,
      true,
      data.data,
    )
  } catch (err) {}
}
function* getJumpDatabyid(data) {
  try {
    let response = yield CustomerPanelApiRequests.requestJumpingResultEdit(
      data.data,
    )
    yield HandleApiResponse(
      response,
      SET_JUMPING_EDIT,
      'Failed to fetch data',
      // GET_JUMPING_DATA,
    )
  } catch (err) {}
}
function* getMeasurementEdit(data) {
  try {
    let response = yield CustomerPanelApiRequests.requestMeasurementEdit(
      data.data,
    )
    yield HandleApiResponse(
      response,
      SET_MEASUREMENT_EDIT,
      'Failed to fetch data',
    )
  } catch (err) {}
}
function* getMeasurementDelete(data) {
  //console.log(data)

  try {
    let response = yield CustomerPanelApiRequests.requestMeasurementDelete(
      data.data,
    )
    yield HandleApiResponse(
      response,
      SET_MEASUREMENT_DELETE,
      'Failed to fetch data',
      GET_MEASUREMENT_DATA,
      true,
      data.data,
    )
  } catch (err) {}
}
function* SagaCustomer() {
  yield takeEvery(GET_PROFILE_DATA, getAdminProfile)
  yield takeEvery(GET_TIMELINE, getTimeline)
  yield takeEvery(GET_TIMELINE_POST, getTimelinePosted)
  yield takeEvery(GET_JUMPING_DATA, getJumpingResult)
  yield takeEvery(GET_MEASUREMENT_DATA, getMeasurementResult)
  yield takeEvery(GET_MEASUREMENT_MODIFY_DATA, getMeasurementModify)
  yield takeEvery(GET_JUMPING_UPDATE, getJumpingResultUpdate)
  yield takeEvery(GET_JUMPING_DELETE, getJumpingResultDelete)
  yield takeEvery(GET_JUMPING_EDIT, getJumpDatabyid)
  yield takeEvery(GET_MEASUREMENT_EDIT, getMeasurementEdit)
  yield takeEvery(GET_MEASUREMENT_DELETE, getMeasurementDelete)
}

export default SagaCustomer
