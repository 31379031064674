import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'

const PostedVideo = ({ src, index }) => {
  // console.log('src,', src)
  // useEffect(() => {
  //   document.getElementById('video-' + index)?.load()
  //   document.getElementById('video-' + index).play()
  // }, [src])

  return (
    <Grid xs={12} sx={Style.grid}>
      <video
        id={`video-${index}`}
        controls
        controlsList="nodownload"
        style={Style.video}
        muted
      >
        <source src={src} />
      </video>

      {/* <ReactPlayer
        url={src}
        // playing={false}
        style={Style.video}
        // height="250px"
        // width="400px"
      /> */}
    </Grid>
  )
}

export default PostedVideo
const Style = {
  grid: {
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
  },
  video: {
    width: '100%',
    borderRadius: '20px',
  },
}
