/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import '../Admin/CSS/AdminProfile.css'
import { toast } from 'react-toastify'
import Toastify from '../Components/SnackBar/Toastify'
// import LoaderComponent from "../Loader/LoaderComponent";
import EditIcon from '@mui/icons-material/Edit'

import {
  Button,
  Skeleton,
  Typography,
  TextField,
  FormLabel,
  Box,
  Paper,
  Grid,
} from '@mui/material'
import { storage } from '../config/storage'
import {
  getAdminProfileRequest,
  getAdminProfileUpdate,
  getLoadingStatus,
} from '../redux/login/action'
import { Schema } from '../Components/FormSchema/Schema'
import styled from '@emotion/styled'
import RowRadioButtonsGroup from '../Components/RadioButtons/RadioButton'
import InputField from '../Components/InputField'
import TransformButton from '../Components/CustomButton/TransformButton'
import { requestClubAddUpdate } from '../services/clubs'
import LoaderComponent from '../Components/Loader/LoaderComponent'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const ClubAdmin = ({ getEmail }) => {
  const [userData, setUserData] = useState({
    club_name: '',
    name: '',
    email: '',
    mobile: '',
  })
  const newState = useSelector((state) => state)
  const [loading, setLoading] = useState(false)
  const [image_uploaded, setImage_uploaded] = useState(
    require('../assets/profileRect.png'),
  )
  // //////console.log('HH', newState)
  const [image_binary, setImage_binary] = useState('')
  const myDispatch = useDispatch()

  useEffect(() => {
    document.title = 'OG Sports - Profile'
    callApi()
  }, [])
  const callApi = () => {
    myDispatch(getAdminProfileRequest())
    myDispatch(getLoadingStatus(true))
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    // ////console.log('newState', newState)
    if (newState != null) {
      setLoading(false)
      let data = newState?.LoginData?.adminData
      getEmail(newState?.LoginData?.adminData?.email)
      storage.set.customerId(data?.id)

      storage.set.notification(data?.unread_notification_count)
      setImage_uploaded(
        data?.image ? data?.image : require('../assets/profileRect.png'),
      )

      setUserData({
        club_name: data?.club_name,
        name: data?.name,
        email: data?.email,
        mobile: data?.mobile,
        // image:data?.image
      })
      // storage.set.profileImage(data?.image)
      storage.set.AdminName(data?.club_name)
    } else {
    }
  }, [newState])

  const formik = useFormik({
    initialValues: userData,
    validationSchema: Schema.EDIT_BULK_ORDER,
    onSubmit: (value) => {
      setUserData(value)
      onSubmit(value)
    },
    enableReinitialize: true,
  })
  const onSubmit = (value) => {
    const formData = new FormData()
    // ////console.log(value)
    formData.append('club_name', value.club_name)
    formData.append('f_name', value.name)
    formData.append('email', value.email)
    formData.append('mobile', value.mobile)
    // formData.append('club_type', 'club')
    // formData.append('role', 'club_admin')
    // formData.append('users_id', storage.fetch.customerId())
    if (image_binary) formData.append('image', image_binary)
    // myDispatch(requestClubAddUpdate(formData))
    myDispatch(getAdminProfileUpdate(formData))
    myDispatch(getLoadingStatus(true))
  }
  const handleChangeGender = (e) => {
    setUserData((prevUserData) => ({
      ...prevUserData, // Shallow copy of the old object
      gender: e.target.value, // Update the specific key with the new value
    }))
  }
  const handleFileChange = (e) => {
    // ////console.log('hii', e.target.files[0])
    setImage_binary(e.target.files[0])
    setImage_uploaded(URL.createObjectURL(e.target.files[0]))
    // ////console.log(image_uploaded)
  }
  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )
  return (
    <form name="ClubProfile" onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <LoaderComponent open={newState?.LoginData?.loadingStatus} />
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid
            order={{ xs: 2, sm: 0 }}
            sx={Style.mainGrid1}
            item
            xs={12}
            sm={6}
          >
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.club_name}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter club name'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'club_name'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Club Name'}
                  mt={0}
                  displayForSkelton={
                    newState?.LoginData?.loadingStatus ? 'none' : 'flex'
                  }
                />
                {formik.errors.club_name && formik.touched.club_name ? (
                  <p style={Style.validationStyle}>{formik.errors.club_name}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.LoginData?.loadingStatus
                      ? 'flex'
                      : 'none',
                  }}
                />
              </Box>
              <Box sx={{ mb: '' }}>
                <InputField
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter name'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'name'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Club Owner Name'}
                  displayForSkelton={
                    newState?.LoginData?.loadingStatus ? 'none' : 'flex'
                  }
                />
                {formik.errors.name && formik.touched.name ? (
                  <p style={Style.validationStyle}>{formik.errors.name}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.LoginData?.loadingStatus
                      ? 'flex'
                      : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid
            order={{ xs: 1, sm: 0 }}
            sx={Style.mainGrid}
            item
            xs={12}
            sm={6}
          >
            <Item
              sx={{
                boxShadow: 'none',
                cursor: 'pointer',
                borderRadius: '100%',
                maxWidth: { xs: '145px', sm: '170px' },
                p: 1,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  width: '30px',
                  height: '30px',
                  bgcolor: '#d80027',
                  color: 'white',
                  borderRadius: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  right: '14px',
                }}
              >
                <EditIcon
                  fontSize="30px"
                  sx={{ position: 'absolute', width: '1.5em', height: '1.5em' }}

                  // style={style.iconEye}
                />
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleFileChange}
                  style={{
                    position: 'absolute',
                    opacity: 0,
                    height: '30px',
                    width: '30px',
                  }}
                  // Ref for triggering file input click
                />
              </Box>
              <img
                alt="profileimage"
                className="profileImage"
                // name="image"
                // src={vehicData?.photo_links[selected]?.image_url}
                src={image_uploaded}

                // onClick={() => setOpen(true)}
              />
            </Item>
          </Grid>
          <Grid order={{ xs: 5, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter email'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'email'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Email Address'}
                  displayForSkelton={
                    newState?.LoginData?.loadingStatus ? 'none' : 'flex'
                  }
                />
                {formik.errors.email && formik.touched.email ? (
                  <p style={Style.validationStyle}>{formik.errors.email}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.LoginData?.loadingStatus
                      ? 'flex'
                      : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 6, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  type={'number'}
                  placeholder={'Please enter phone number'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'mobile'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Phone Number'}
                  displayForSkelton={
                    newState?.LoginData?.loadingStatus ? 'none' : 'flex'
                  }
                />
                {formik.errors.mobile && formik.touched.mobile ? (
                  <p style={Style.validationStyle}>{formik.errors.mobile}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.LoginData?.loadingStatus
                      ? 'flex'
                      : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
        </Grid>

        <Box sx={Style.buttonBox}>
          <TransformButton typeSubmit={'submit'} button_name={'Update'} />
        </Box>
      </Box>

      <Toastify />
    </form>
  )
}

export default ClubAdmin

const Style = {
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#333333',
  },
  mainGrid: {
    mt: '20px',
  },
  mainGrid1: {
    mt: { xs: 0, sm: '20px' },
  },
  typographyStyle: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: { xs: '30px', md: '60px' },
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#000',
    display: 'flex',
    // pb: 2,
    pl: { xs: 1, sm: 3 },
    pt: { xs: 2, sm: 0 },
    pb: { xs: 2, sm: 0 },
  },
  inputStyle: {
    width: {
      xs: '100%',
      sm: '100%',
      md: '48%',
      lg: '49%',
      xl: '49%',
    },
    mb: 2,
  },
  inputStyle2: {
    width: '100%',
    mb: 2,
  },
  star: {
    color: 'red',
  },

  validationStyle: {
    color: 'red',
    margin: '10px',
  },

  buttonBox: {
    display: 'flex',
    justifyContent: { md: 'flex-start' },
    mt: 2,
    mb: 2,
    pr: { xs: 1, sm: 10 },
    pl: { xs: 1, sm: 10 },
  },
  Skeleton: {
    width: '100%',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
}
