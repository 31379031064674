import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { useState, useEffect } from 'react'
export default function RowRadioButtonsGroup({
  labelItems,
  defaultValue,
  handleChange,
  label,
}) {
  // const [state, setState] = useState(defaultValue)
  // useEffect(() => {
  //   //console.log(state)
  // }, [state])
  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={handleChange}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {labelItems.map((item, index) => (
          <FormControlLabel
            sx={{
              pt: '5px',
            }}
            control={
              <Radio
                sx={{
                  '&.MuiRadio-root.Mui-checked': {
                    color: '#CB2128',
                  },
                }}
                value={item.value}
                defaultValue={defaultValue ? defaultValue : 'left'}
                checked={item.value === defaultValue}
                // onChange={() => {
                //   setState(item.value)
                // }}
              />
            }
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
