import { Api } from '../../config/request'

export const requestClubList = async (data) => {
  //console.warn(data)
  return Api.PostRequest('/club/list', data)
}
export const requestClubDetails = async (data) => {
  return Api.PostRequest('/user/details', data)
}
export const requestClubAddUpdate = async (data) => {
  return Api.PostRequest('/user/edit', data)
}
export const requestClubUserDetails = async (data) => {
  return Api.PostRequest('/user/details/token', data)
}

export const requestClubDelete = async (data) => {
  return Api.PostRequest('/user/delete', data)
}
export const requestPlayerList = async (data) => {
  //console.log('hi')
  // return Api.PostRequest('/user/delete', data)
}
export const requestPlayerDetails = async (data) => {
  ////console.log('hiii')
  // return Api.PostRequest('/user/delete', data)
}
const ClubApiRequests = {
  requestClubList,
  requestClubDetails,
  requestClubAddUpdate,
  requestClubUserDetails,
  // requestClubEmailSent,
  requestClubDelete,
  requestPlayerList,
  requestPlayerDetails,
}
export default ClubApiRequests
