import styled from '@emotion/styled'
import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
  background: '#F5F4FF',
  border: '1px solid #CDCAFC',
}))
const AboutGrid = ({ list, p, md }) => {
  return (
    <>
      {list.map((item, key) => (
        <Grid sx={Style.innerGrid} xs={12} md={md}>
          <Item sx={Style.innerItem} style={{ padding: p }}>
            <Typography style={Style.text}>
              {item.value}
              <span>{item?.unit}</span>
            </Typography>
            <Typography style={Style.text1}>{item.name}</Typography>
          </Item>
        </Grid>
      ))}
    </>
  )
}

export default AboutGrid
const Style = {
  outerbox: {
    display: 'flex',
    justifyContent: 'flex-end',
    mt: { xs: 5, md: 0 },
    p: 3,
  },
  text: {
    color: 'red',
    fontSize: '16px',
    fontWeight: '700',
  },
  text1: {
    color: '#27245E',
  },
  innerItem: {
    // padding: '2%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  innerGrid: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    bgcolor: 'white',
    borderRadius: '10px',
    mt: 1,
    textAlign: 'center',
    minWidth: '170px',
    maxWidth: '500px',
  },
}
