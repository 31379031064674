import {
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  SET_ADMIN_PROFILE,
  SET_CUSTOMER_LIST,
  SET_JUMPING_DATA,
  SET_JUMPING_EDIT,
  SET_MEASUREMENT_DATA,
  SET_MEASUREMENT_EDIT,
  SET_PROFILE_DATA,
  SET_TIMELINE,
  SET_TIMELINE_POST,
} from '../ConstAction'
const initialState = {}
export const CustomerPanelData = (state = initialState, action) => {
  // console.log(
  //   action.type,
  //   action?.response?.data?.data?.total_records,
  //   state.list?.total_records,
  // )
  switch (action.type) {
    case SET_TIMELINE:
      return {
        ...state,
        list: action.response.data.data,
        // total: { total: state?.list?.total_records },
        empty: false,
        posted: false,
      }
    case SET_JUMPING_DATA:
      return {
        ...state,
        Jumpinglist: action.response.data.data,
        empty: false,
        posted: false,
      }

    case SET_MEASUREMENT_DATA:
      return {
        // ...state,
        Measurement_list: action.response.data.data,
        empty: false,
        posted: false,
      }
    case SET_JUMPING_EDIT:
      return {
        ...state,
        JumpData: action.response.data.data,
        empty: false,
        posted: false,
      }
    case SET_MEASUREMENT_EDIT:
      return {
        ...state,
        MeasureData: action.response.data.data,
        empty: false,
        posted: false,
      }
    case SET_PROFILE_DATA:
      return {
        ...state,
        customerData: action.response.data.data,
        empty: false,
        posted: false,
      }
    case SET_TIMELINE_POST:
      return {
        ...state,
        posted: true,
      }
    case GET_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: action.data,
        posted: false,
      }
    case GET_EMPTY_STATUS:
      return {
        ...state,
        empty: action.data,
        posted: false,
      }

    default:
      return { ...state }
  }
}
