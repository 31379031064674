import {
  Box,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Skeleton,
  Switch,
  ToggleButton,
  Typography,
  SwitchProps,
  MenuItem,
  Select,
} from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import InputField from '../../Components/InputField'
import { Schema } from '../../Components/FormSchema/Schema'
import RowRadioButtonsGroup from '../../Components/RadioButtons/RadioButton'
import { useDispatch } from 'react-redux'
import { getCustomerAddUpdateRequest } from '../../redux/customer/customerAction'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import Toastify from '../../Components/SnackBar/Toastify'
import TransformButton from '../../Components/CustomButton/TransformButton'
import EditIcon from '@mui/icons-material/Edit'
import { getLoadingStatus } from '../../redux/login/action'
import {
  ADMIN,
  CUSTOMERS_ADD_NEW,
  CUSTOMERS_INFO_EDIT,
  CUSTOMER_LISTING,
} from '../../routes/constURL'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import SelectComponent from '../../Components/SelectComponent'
import {
  initialState,
  reducerFunction,
  sportsData,
} from '../../config/mockData'
import { toast } from 'react-toastify'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import InputGrids from '../../Components/Grids/InputGrids'
import {
  getAdminAddUpdateRequest,
  getAdminDetailsRequest,
} from '../../redux/admins/adminAction'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const defaultValue = {
  f_name: '',
  l_name: '',
  age: '',
  email: '',
  mobile: '',
  role: '',
}
const EditAdmin = () => {
  const id = useParams()
  const [gender, setGender] = useState('')
  const { details, loadingStatus } = useSelector((state) => state?.AdminData)
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const [sportserror, setsportserror] = useState('Please select a sport')
  const [genderError, setGenderError] = useState('Please select gender')
  const [membershiperror, setmembershiperror] = useState(
    'Please Select Program',
  )
  const navigate = useNavigate()
  const [image_uploaded, setImage_uploaded] = useState(
    require('../../assets/profilepic.png'),
  )
  const [AdminData, setAdminData] = useState({
    f_name: '',
    l_name: '',
    age: '',
    email: '',
    mobile: '',
    role: 'Select',
  })

  const [image_binary, setImage_binary] = useState()
  useEffect(() => {
    window.scroll(0, 0)
    callApi()
  }, [])

  const callApi = () => {
    myDispatch(getAdminDetailsRequest({ id: id.id }))
    myDispatch(getLoadingStatus(true))
  }
  useEffect(() => {
    console.log("working")
    console.log('details', details)
    setAdminData({
      f_name: details?.first_name,
      l_name: details?.last_name,
      email: details?.email,
      age: details?.age,
      mobile: details?.phone,
      role: details?.role,
    })
    setImage_uploaded(
      details?.image
        ? details?.image
        : require('../../assets/profileRect.png'),
    )
    setStateReducer({
      role: details?.role,
      checked: details?.status === 'active' ? true : false,
    })
    setGender(details?.gender)
    setGenderError(null)
  }, [details])
  // //////console.log(state.checked)
  const formik = useFormik({
    initialValues: AdminData,
    validationSchema: Schema.ADMIN_EDIT_PROFILE,
    onSubmit: (value) => {
      setAdminData(value)
      onSubmit(value)
    },
    enableReinitialize: true,
  })

  const onSubmit = (value) => {
    const formData = new FormData()
    formData.append('id', id.id)
    formData.append('f_name', value.f_name)
    formData.append('l_name', value.l_name)
    formData.append('email', value.email)
    formData.append('phone', value.mobile)
    // formData.append('gender', value.gender)
    formData.append('age', value.age)
    formData.append('active', state.checked)

    formData.append('role', value.role)
    formData.append('gender', gender)

    if (image_binary) formData.append('image', image_binary)
    // Object.assign(formData)
    // //////console.log('value', state.checked)
    if (genderError === null) {
      myDispatch(getAdminAddUpdateRequest(formData, navigate))
      myDispatch(getLoadingStatus(true))
      // setTimeout(() => {
      //   navigate(ADMIN)
      // }, 2000)
    }
  }
  const CustomDropdownIcon = (props) => {
    return <ExpandMoreIcon {...props} />
  }
  const handleChangeForSelect = (e) => {
    // //////console.log(e.target.value)
    setStateReducer({ role: e.target.value })
  }

  const myDispatch = useDispatch()

  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )
  const handleChangeGender = (e) => {
    if (e.target.value) {
      setGenderError(null)
    }
    setGender(e.target.value)
  }
  const handleFileChange = (e) => {
    // //////console.log('hii', e.target.files[0])
    setImage_binary(e.target.files[0])
    const binary = URL.createObjectURL(e.target.files[0])
    // //////console.log(binary)
    setImage_uploaded(binary)
    // //////console.log(image_uploaded)
  }

  return (
    <form name="AdminProfile" onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <LoaderComponent open={loadingStatus} />

        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid
            sx={Style.mainGrid}
            item
            order={{ xs: 2, sm: 0 }}
            xs={12}
            sm={6}
          >
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.f_name}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter first name'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'f_name'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'First Name'}
                  displayForSkelton={loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.f_name && formik.touched.f_name ? (
                  <p style={Style.validationStyle}>{formik.errors.f_name}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>

            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.l_name}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter last name'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'l_name'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Last Name'}
                  displayForSkelton={loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.l_name && formik.touched.l_name ? (
                  <p style={Style.validationStyle}>{formik.errors.l_name}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid
            sx={Style.mainGrid}
            item
            order={{ xs: 1, sm: 0 }}
            xs={12}
            sm={6}
          >
            <Item
              sx={{
                boxShadow: 'none',
                cursor: 'pointer',
                borderRadius: '100%',
                maxWidth: { xs: '145px', sm: '170px' },
                p: 1,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  width: '30px',
                  height: '30px',
                  bgcolor: '#d80027',
                  color: 'white',
                  borderRadius: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  right: '14px',
                }}
              >
                <EditIcon
                  fontSize="30px"
                  sx={{ position: 'absolute', width: '1.5em', height: '1.5em' }}
                  // style={style.iconEye}
                />
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleFileChange}
                  style={{
                    position: 'absolute',
                    opacity: 0,
                    height: '30px',
                    width: '30px',
                  }}
                  // Ref for triggering file input click
                />
              </Box>
              <img
                alt="profileimage"
                className="profileImage"
                // src={vehicData?.photo_links[selected]?.image_url}
                src={image_uploaded}

                // onClick={() => setOpen(true)}
              />
            </Item>
          </Grid>
          <Grid
            style={{ paddingTop: 0 }}
            item
            order={{ xs: 3, sm: 0 }}
            xs={12}
            sm={6}
          >
            <Item>
              <Box sx={{ mb: '0px' }}>
                <InputField
                  value={formik.values.age}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter age'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'age'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Age'}
                  displayForSkelton={loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.age && formik.touched.age ? (
                  <p style={Style.validationStyle}>{formik.errors.age}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid item order={{ xs: 4, sm: 0 }} xs={12} sm={6}>
            <Label name={'Gender'} />
            <div>
              <RowRadioButtonsGroup
                labelItems={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                  { label: 'Others', value: 'others' },
                ]}
                defaultValue={gender}
                handleChange={handleChangeGender}
              />
            </div>
            {genderError !== null ? (
              <p style={Style.validationStyle}>{genderError}</p>
            ) : null}
          </Grid>
          <Grid item order={{ xs: 5, sm: 0 }} xs={12} sm={6}>
            <Item>
              <Box sx={{ mb: '0px' }}>
                <InputField
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter email'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'email'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Email'}
                  displayForSkelton={loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.email && formik.touched.email ? (
                  <p style={Style.validationStyle}>{formik.errors.email}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid item order={{ xs: 6, sm: 0 }} xs={12} sm={6}>
            <Item>
              <Box sx={{ mb: '0px' }}>
                <InputField
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter mobile'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'mobile'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Phone'}
                  displayForSkelton={loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.mobile && formik.touched.mobile ? (
                  <p style={Style.validationStyle}>{formik.errors.mobile}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 7, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Label name={'Role'} />
              <div>
                <Select
                  variant="filled"
                  name="role"
                  value={formik.values.role}
                  onChange={(selectedOption) => {
                    formik.setFieldValue(
                      'role',
                      selectedOption ? selectedOption.target.value : null,
                    )
                  }}
                  displayEmpty
                  disableUnderline
                  SelectDisplayProps={{
                    style: {
                      padding: 3,
                      marginLeft: '10px',
                    },
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: '300px',
                      zIndex: 99999,
                    },
                    disableScrollLock: true,
                  }}
                  IconComponent={CustomDropdownIcon}
                  inputProps={{
                    style: {
                      paddingTop: '8px',
                      paddingBottom: '8px',
                      height: '300px',
                    },
                  }}
                  color="primary"
                  sx={{
                    height: '43px',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    border: '1px solid #C8C8C8',
                    borderRadius: '0px',
                    '& .MuiSelect-select:focus': {
                      backgroundColor: 'transparent',
                    },
                    '&.MuiList-root': {
                      height: '300px',
                    },
                    minWidth: '100px',
                    mt: 2,
                  }}
                >
                  {[
                    { name: 'Select', value: 'Select' },
                    { name: 'Admin', value: 'admin' },
                    { name: 'Standard Admin', value: 'standard_admin' },
                  ]?.map((item, index) => (
                    <MenuItem
                      sx={{
                        textTransform: 'capitalize',
                        '&.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root':
                          {
                            zIndex: 99999,
                          },
                      }}
                      value={item.value}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors.role ? (
                  <p style={Style.validationStyle}>{formik.errors.role}</p>
                ) : null}
                <div></div>
                {/* <SelectComponent
                  menuData={[
                    { name: 'Select', value: 'Select' },
                    { name: 'Admin', value: 'admin' },
                    { name: 'Standard Admin', value: 'standard_admin' },
                  ]}
                  value={state.role}
                  mt={'12px'}
                  handleChangeForSelect={handleSelect}
                /> */}
                {/* {membershiperror !== null ? (
                  <p style={Style.validationStyle}>{membershiperror}</p>
                ) : null} */}
              </div>
            </Item>
          </Grid>
          <Grid order={{ xs: 7, sm: 0 }} item xs={12} sm={6}>
            <Grid item order={{ xs: 9, sm: 0 }} xs={12} sm={6}>
              <Item>
                <FormLabel
                  sx={{
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    color: '#333333',
                    mb: '5px',
                  }}
                >
                  Status
                  <span
                    style={{
                      color: 'red',
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <Box sx={{}}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1, height: '100px' }}
                        checked={state.checked}
                        onChange={() =>
                          setStateReducer({ checked: !state.checked })
                        }
                      />
                    }
                  />
                </Box>
              </Item>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={Style.buttonBox}>
          <TransformButton typeSubmit={'submit'} button_name={'Submit'} />
        </Box>
      </Box>

      <Toastify />
    </form>
  )
}

export default EditAdmin
const Style = {
  Skeleton: {
    width: '100%',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  star: {
    color: '#d32f2f',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#333333',
  },
  headingText: {
    fontSize: { xs: '14px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },

  validationStyle: {
    color: '#d32f2f',
    margin: '10px',
    fontWeight: '500',
    fontSize: '12px',
  },
  mainGrid: {
    mt: '20px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: { md: 'flex-start' },
    mt: 2,
    mb: 2,
    pr: { xs: 1, sm: 10 },
    pl: { xs: 1, sm: 10 },
  },
}
