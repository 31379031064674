import React from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useLocation } from 'react-router-dom'
import { CLUBDASHBOARD, REVENUE_REPORTS } from '../../routes/constURL'

export const EnhancedTableHead = ({
  display,
  columns,
  setColumns,
  order,
  orderBy,
  onRequestSort,
  action = false,
  revenue,
}) => {
  const [indexOfArrow, setIndexOfArrow] = React.useState()
  const location = useLocation()
  // const [columns, setColumns] = React.useState(totalColumn);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }
  ////console.log(action)
  // const headCells = columns.map((item, index) => ({
  //   id: item === "" ? "none" : item,
  //   numeric: false,
  //   disablePadding: true,
  //   label: item,
  // }));
  const createSortHandler = (property, index) => (event) => {
    onRequestSort(event, property)
    setIndexOfArrow(index)
  }
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const items = reorder(
      columns,
      result.source.index,
      result.destination.index,
    )

    setColumns([...items])
  }
  const getItemStyle = (draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // padding: grid * 2,
    // margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const getListStyle = () => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
    // display: 'flex',
    // padding: grid,
    overflow: 'auto',
  })
  const Style = {
    tableHeader: {
      color: '#fff',
      letterSpacing: '0px',
      paddingLeft: revenue ? '8px' : '16px',
      fontSize: '14px',
      fontWeight: '800',
      minWidth: revenue ? '100px' : '131px',
      p: revenue ? '' : { xs: '9px', sm: '12px', md: '16px' },
      whiteSpace: 'nowrap',
      pr: revenue ? '0px' : '',
      textAlign: 'left',
      // pl: revenue ? '10px' : '',
    },

    tableHeaderCheckBox: {
      lineHeight: '22px',
      color: '#333333',
      width: '40px',
      borderBottom: '2px solid #FF8D2A',
      borderRadius: '2px',
      paddingTop: 0,
      paddingLeft: '16px',
    },
  }
  const Icon = ({ fontUp, fontDown, marginDown, marginUp }) => {
    return (
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'left',
          alignItems: 'left',
          marginLeft: '5px',
        }}
      >
        {console.log(order !== 'asc', order !== 'desc', fontDown, indexOfArrow)}
        <ArrowDropUpIcon
          sx={{
            fontSize: order !== 'asc' ? fontUp : '',
            marginTop: '-2px',
            marginBottom: '-6px',
            ml: order !== 'desc' ? (fontUp !== '16px' ? '5px' : '0px') : '0px',
          }}
        />
        <ArrowDropDownIcon
          sx={{
            fontSize: order !== 'desc' ? fontDown : '',
            marginTop: '-3px',
            marginBottom: '-6px',
            ml: order !== 'asc' ? (fontDown !== '16px' ? '5px' : '0px') : '0px',
          }}
        />
      </span>
    )
    // fontSize:order==="asc"?"20px":"16px",
  }
  return (
    <TableHead>
      {location.pathname === '/vehicles/negotiating' ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <TableRow
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
                sx={{
                  bgcolor: '#F6FAFD',
                  border: '1px solid #ECECEC',
                  borderRadius: '10px',
                }}
              >
                {columns.map((headCell, index) => (
                  <Draggable
                    key={index}
                    draggableId={headCell.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <TableCell
                        key={headCell.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                        // align={headCell.numeric ? 'right' : 'left'}
                        sx={Style.tableHeader}
                        // padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        {headCell.label}
                        {/* {//console.log('location', location.pathname)} */}
                        {console.log('TableHeader--', headCell.id)}
                        {location.pathname !== REVENUE_REPORTS &&
                          headCell.id !== 'Type' &&
                          headCell.id !== 'Action' &&
                          headCell.id !== 'Contact No' &&
                          headCell.id !== 'Template format name' &&
                          headCell.id !== 'Make' && (
                            <TableSortLabel
                              active={orderBy === headCell.id}
                              sx={{
                                '& .MuiTableSortLabel-icon': {
                                  color: 'green !important',
                                },
                              }}
                              IconComponent={() => (
                                <Icon
                                  fontDown={
                                    index === indexOfArrow ? '24px' : '16px'
                                  }
                                  fontUp={
                                    index === indexOfArrow ? '24px' : '16px'
                                  }
                                />
                              )}
                              direction={
                                orderBy === headCell.id ? order : 'asc'
                              }
                              onClick={createSortHandler(
                                headCell.id === 'Item' ? 'none' : headCell.id,
                                index,
                              )}
                            >
                              {/* //   {orderBy === headCell.id ? (
              //     <Box component="span" sx={visuallyHidden}>
              //       {order === "desc" ? "sorted descending" : "sorted ascending"}
              //     </Box>
              //   ) : null} */}
                            </TableSortLabel>
                          )}
                      </TableCell>
                    )}
                  </Draggable>
                ))}
                {action === false && (
                  <TableCell sx={Style.tableHeader}>Action</TableCell>
                )}
              </TableRow>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <TableRow
          sx={{
            bgcolor: '#27245E',
            color: 'white',
            border: '1px solid #ECECEC',
            '.MuiTableRow-root': {
              // borderRadius: "20px",
              // border-bottom-left-radius: 10px;
            },
            borderRadius: '10px',
          }}
        >
          {/* {!checkbox && (
        <TableCell style={Style.tableHeaderCheckBox}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
            sx={{ mt: "10px" }}
          />
        </TableCell>
      )} */}
          {columns.map((headCell, index) => (
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              // sx={Style.tableHeader}
              sx={{
                ...Style.tableHeader,
                textAlign:
                  headCell.label === 'Used/Remaining Licences'
                    ? 'center'
                    : 'left',
              }}
              // padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
              {headCell.id !== 'Type' &&
                headCell.id !== 'Action' &&
                headCell.id !== 'Contact No' &&
                headCell.id !== 'Template format name' &&
                headCell.id !== 'Make' &&
                headCell.id !== 'Membership' &&
                headCell.id !== 'payment_status' &&
                headCell.id !== 'subscription_link' &&
                headCell.id !== 'expiry_date' && (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    sx={{
                      display: display,
                      '&:hover': {
                        color: 'white', // Set the color you want on hover
                      },
                      '&.css-bgt102-MuiSvgIcon-root': {
                        fill: 'white',
                      },
                      ...(orderBy === headCell.id && {
                        // Apply styles only if active
                        '&.MuiTableSortLabel-root.Mui-active ': {
                          color: 'white', // Set the colors based on the sorting order
                        },
                      }),
                    }}
                    IconComponent={() => (
                      <Icon
                        fontDown={index === indexOfArrow ? '24px' : '16px'}
                        fontUp={index === indexOfArrow ? '24px' : '16px'}
                      />
                    )}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(
                      headCell.id === 'Item' ? 'none' : headCell.id,
                      index,
                    )}
                  >
                    {/* //   {orderBy === headCell.id ? (
            //     <Box component="span" sx={visuallyHidden}>
            //       {order === "desc" ? "sorted descending" : "sorted ascending"}
            //     </Box>
            //   ) : null} */}
                  </TableSortLabel>
                )}
            </TableCell>
          ))}
          {!action && <TableCell sx={Style.tableHeader}>Action</TableCell>}
        </TableRow>
      )}
    </TableHead>
  )
}
