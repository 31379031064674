import { Api } from '../../config/request'

export const requestRevenueList = async (data) => {
  return Api.PostRequest('/revenue/revenueList', data)
}
export const requestRevenueallList = async (data) => {
  return Api.PostRequest('/revenue/revenueList', data)
}
export const requestRevenueDetails = async (data) => {
  return Api.PostRequest('/revenue/revenueDetails', data)
}
export const requestRevenueAdd = async (data) => {
  return Api.PostRequest('/revenue/createRevenue', data)
}
export const requestReund = async (data) => {
  return Api.PostRequest('/stripe/refund', data)
}
const RevenueApiRequests = {
  requestRevenueList,
  requestRevenueDetails,
  requestRevenueAdd,
  requestReund,
  requestRevenueallList,
}
export default RevenueApiRequests
