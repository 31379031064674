import { Box, Typography } from '@mui/material'
import React from 'react'

const HeaderText = ({ headerText }) => {
  return (
    <Box
      // className="head-main"
      sx={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography sx={style.typographyStyle}>{headerText}</Typography>
    </Box>
  )
}

export default HeaderText
const style = {
  typographyStyle: {
    fontSize: { xs: '16px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    mb: 2,
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },
}
