import {
  GET_BULK_PAYMENT,
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  SET_BULK_PAYMENT,
  SET_CLUB_ADD_UPDATE,
  SET_CLUB_DELETE,
  SET_CLUB_DETAILS,
  SET_CLUB_LIST,
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_NUMBER,
} from '../ConstAction'
const initialState = {}
export const ClubData = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLUB_LIST:
      return {
        list: action.response.data.data,
        empty: false,
      }
    case SET_CLUB_DETAILS:
      return {
        ...state,
        details: action.response.data.data,
      }
    case SET_NOTIFICATIONS:
      return { ...state, notilist: action.response.data }
    case SET_NOTIFICATION_NUMBER:
      // console.log('res', action.response)
      return { ...state, notify_number: action.response.data }
    case SET_BULK_PAYMENT:
      return {
        ...state,
      }
    case GET_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: action.data,
      }
    case GET_EMPTY_STATUS:
      return {
        ...state,
        empty: action.data,
      }
    // case SET_BULK_PAYMENT:
    //   return {
    //     ...state,
    //   }
    case SET_NOTIFICATION_NUMBER:
      return {
        ...state,
      }
    case SET_NOTIFICATIONS:
      return {
        ...state,
      }
    case SET_CLUB_ADD_UPDATE:
      return {
        ...state,
        URL: true,
      }
    default:
      return { ...state }
  }
}
