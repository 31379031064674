import {
  GET_CANCEL_SUBSCRIPTION,
  GET_EMAIL_SENT,
  GET_REFER_LINK,
  GET_SEND_REFER_LINK,
  GET_SUBSCRIPTION_LIST,
  GET_UPGRADE_SUBSCRIPTION,
} from '../ConstAction'

export const getEmailSent = (data) => {
  return {
    type: GET_EMAIL_SENT,
    data: data,
  }
}
export const getCancelSubscription = (data) => {
  return {
    type: GET_CANCEL_SUBSCRIPTION,
    data: data,
  }
}
export const getUpgradeMembership = (data) => {
  return {
    type: GET_UPGRADE_SUBSCRIPTION,
    data: data,
  }
}
export const getSubscriptionList = (data) => {
  return {
    type: GET_SUBSCRIPTION_LIST,
    data: data,
  }
}
export const getReferLink = (data) => {
  return {
    type: GET_REFER_LINK,
    data: data,
  }
}
export const sendReferLink = (data) => {
  return {
    type: GET_SEND_REFER_LINK,
    data: data,
  }
}
