import {
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  SET_CUSTOMER_DELETE,
  SET_CUSTOMER_DETAILS,
  SET_CUSTOMER_LIST,
  SET_CUSTOMER_PROFILE_UPDATE,
} from '../ConstAction'
const initialState = {}

export const CustomerData = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_LIST:
      //console.warn(action)
      return { list: action.response.data.data, empty: false }
    case SET_CUSTOMER_DETAILS:
      //console.warn(action.response.data.data)
      return {
        ...state,
        customer_details: action.response.data.data.userData,
        resultHeightWeight: action.response.data.data.measurementData,
        resultJumping: action.response.data.data.jumpResultData,
      }
    case GET_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: action.data,
      }
    case GET_EMPTY_STATUS:
      return {
        ...state,
        empty: action.data,
      }
    case SET_CUSTOMER_DELETE:
      return {
        ...state,
        deleteStatus: true,
      }
    case SET_CUSTOMER_PROFILE_UPDATE:
      return {
        ...state,
        URL: true,
      }
    default:
      return { ...state }
  }
}
