import {
  GET_ADMIN_LOGIN,
  GET_ADMIN_LOGOUT,
  GET_ADMIN_PASS_UPDATE,
  GET_ADMIN_PROFILE,
  GET_ADMIN_PROFILE_UPDATE,
  GET_FORGOT_PASSWORD,
  GET_LOADING_STATUS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_NUMBER,
  GET_RESET_PASSWORD,
  GET_VERIFY_REQUEST
} from '../ConstAction'

//will be called when we finish the API call unsuccessfully
export const getRequest = (data, navigate) => {
  return {
    type: GET_ADMIN_LOGIN,
    data: data,
    navigate: navigate,
  }
}
export const getAdminProfileRequest = (data) => {
  return {
    type: GET_ADMIN_PROFILE,
    data: data,
  }
}
export const getAdminProfileUpdate = (data) => {
  return {
    type: GET_ADMIN_PROFILE_UPDATE,
    data: data,
  }
}
export const getAdminPassUpdate = (data) => {
  return {
    type: GET_ADMIN_PASS_UPDATE,
    data: data,
  }
}
export const getLoadingStatus = (data) => {
  return {
    type: GET_LOADING_STATUS,
    data: data,
  }
}
export const getAdminLogout = (data) => {
  return {
    type: GET_ADMIN_LOGOUT,
    data: data,
  }
}
export const getForgotPassword = (data) => {
  return {
    type: GET_FORGOT_PASSWORD,
    data: data,
  }
}
export const getResetPassword = (data, navigate) => {
  return {
    type: GET_RESET_PASSWORD,
    data: data,
    navigate: navigate,
  }
}


export const getVerificationRequest = (data) => {
  return {
    type: GET_VERIFY_REQUEST,
    data: data,
  }
}
