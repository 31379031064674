import { Grid, Typography } from '@mui/material'
import React from 'react'
import TransformButton from './CustomButton/TransformButton'

const Payment = ({ data }) => {
  return (
    <Grid sx={Style.box}>
      <Typography sx={Style.text}>
        You are unable to view the videos since your account is non-active.
      </Typography>
      <Typography sx={Style.text}>Please contact admin.</Typography>
      {/* <TransformButton button_name={'Pay'} /> */}
    </Grid>
  )
}

export default Payment
const Style = {
  box: {
    textAlign: 'center',
    mt: '15%',
  },
  text: {
    fontSize: '24px',
    fontWeight: '700',
    color: 'rgb(39, 36, 94)',
  },
}
