import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, Button, Checkbox, Typography } from '@mui/material'
import InputField from '../InputField'
import { useFormik } from 'formik'
import { Schema } from '../FormSchema/Schema'
import { getForgotPassword } from '../../redux/login/action'
import { useDispatch } from 'react-redux'
import { REVENUE_REPORTS } from '../../routes/constURL'
import { CheckBox, Label } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import { initialState, reducerFunction } from '../../config/mockData'

const AlertDialog = ({
  title,
  image,
  text,
  open,
  onClickButton,
  onClickButtonCancel,
  forgot,
  fullWidth,
  minHeight,
  handlecheck,
}) => {
  const myDispatch = useDispatch()
  const location = useLocation()
  const [state, setStateReducer] = React.useReducer(
    reducerFunction,
    initialState,
  )
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: Schema.FORGOT_PASSWORD,
    onSubmit: (value) => {
      onSubmit(value)
    },
    enableReinitialize: true,
  })
  const onSubmit = (value) => {
    //console.log(value)
    myDispatch(getForgotPassword(value))
  }
  // console.log(state.checked)
  return (
    <div>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            minHeight: minHeight,
          },
        }}
        // onClose={onClose}
        fullWidth={fullWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form name="ForgotPassord" onSubmit={formik.handleSubmit}>
          <DialogTitle
            sx={{ fontSize: '18px', color: '#3D2E57' }}
            id="alert-dialog-title"
          >
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontSize: '16px', color: '#A8A8A8', minWidth: '260px' }}
              id="alert-dialog-description"
            >
              {image ? (
                <img src={image} alt="img" />
              ) : forgot ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    minHeight: 200,
                  }}
                >
                  <InputField
                    edit_update={true}
                    name1={'email'}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    placeholder={'Please enter email'}
                    type={'text'}
                    formlabel={'Email'}
                    required={'*'}
                  />

                  {formik.errors.email && formik.touched.email ? (
                    <p style={Style.validationStyle}>{formik.errors.email}</p>
                  ) : null}
                </Box>
              ) : (
                text
              )}
              {location.pathname === REVENUE_REPORTS &&
              title !== 'Are you sure you want to logout?' ? (
                <Box sx={{ display: 'flex' }}>
                  <Checkbox
                    sx={{
                      pl: 0,
                      color: '#D9D9D9',
                      '&.Mui-checked': {
                        color: '#27245E',
                      },
                    }}
                    // value={state.checked}
                    id='inactive-checkbox'
                    onChange={handlecheck}
                  >
                    {' '}
                  </Checkbox>
                  <label htmlFor='inactive-checkbox' style={{cursor:'pointer'}}>
                  <Typography sx={{ mt: '8px' }}>
                    Do you want to make user inactive?
                  </Typography>
                  </label>
                </Box>
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                bgcolor: '#D80027',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#D80027',
                },
              }}
              onClick={onClickButtonCancel}
            >
              Cancel
            </Button>
            {forgot ? (
              <Button
                variant="contained"
                sx={{
                  bgcolor: '#27245E',
                  textTransform: 'none',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#27245E',
                  },
                }}
                type="submit"
              >
                Submit
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{
                  bgcolor: '#27245E',
                  textTransform: 'none',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#27245E',
                  },
                }}
                onClick={onClickButton}
              >
                Confirm
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
export default AlertDialog
const Style = {
  validationStyle: {
    color: 'red',
    margin: '10px',
  },
}
