import styled from '@emotion/styled'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
  background: '#EEF2FC',
  borderRadius: '10px',
  textAlign: 'center',
  padding: '20px',
  // paddingTop: '4%',
  height: '150px',
}))
const GridBox = ({ list, width }) => {
  const navigate = useNavigate()
  const Style = {
    text: {
      color: 'red',
      fontSize: '20px',
      fontWeight: '700',
    },
    text1: {
      color: '#27245E',
      fontWeight: '600',
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: { xs: '100%', md: '100%' },
    },
    item1: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: width,
    },
  }
  return (
    <>
      {/* {console.log(list)} */}
      {list.map((item, key) => (
        <>
          {item.click ? (
            <Button sx={Style.item1} onClick={() => navigate(item.click)}>
              <Item sx={Style.item}>
                <Typography style={Style.text}>{item.value}</Typography>
                <Typography style={Style.text1}>{item.name}</Typography>
              </Item>
            </Button>
          ) : (
            <Box sx={Style.item1}>
              <Item sx={Style.item}>
                <Typography style={Style.text}>{item.value}</Typography>
                <Typography style={Style.text1}>{item.name}</Typography>
              </Item>
            </Box>
          )}
        </>
      ))}
    </>
    // </Grid>
  )
}

export default GridBox
