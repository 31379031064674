import {
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  SET_ALL_FAIL_LIST,
  SET_ALL_LIST,
  SET_ALL_REFUND_LIST,
  SET_CUSTOMER_LIST,
  SET_FAIL_REVENUE_LIST,
  SET_REFUND_REVENUE_LIST,
  SET_REVENUE_DETAILS,
  SET_REVENUE_LIST,
} from '../ConstAction'
const initialState = {}
export const RevenueData = (state = initialState, action) => {
  //console.log('state', state)
  switch (action.type) {
    case SET_REVENUE_LIST:
      return {
        ...state,
        list: action.response.data.data,
        empty: false,
        // loadingStatus: action.data,
      }
    case SET_FAIL_REVENUE_LIST:
      return {
        ...state,
        faillist: action.response.data.data,
        empty: false,
        // loadingStatus: action.data,
      }
    case SET_REFUND_REVENUE_LIST:
      return {
        ...state,
        refundlist: action.response.data.data,
        empty: false,
        // loadingStatus: action.data,
      }
    case SET_ALL_LIST:
      return {
        ...state,
        alllist: action.response.data.data,
        empty: false,
        // loadingStatus: action.data,
      }
    case SET_ALL_FAIL_LIST:
      return {
        ...state,
        allfaillist: action.response.data.data,
        empty: false,
        // loadingStatus: action.data,
      }
    case SET_ALL_REFUND_LIST:
      return {
        ...state,
        allrefundlist: action.response.data.data,
        empty: false,
        // loadingStatus: action.data,
      }
    case SET_REVENUE_DETAILS:
      return {
        ...state,
        details: action.response.data.data,
        // loadingStatus: action.data,
      }
    case SET_CUSTOMER_LIST:
      return {
        ...state,
        customer_list: action.response.data.data,
        // loadingStatus: action.data,
      }
    case GET_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: action.data,
      }
    case GET_EMPTY_STATUS:
      return {
        ...state,
        empty: action.data,
      }

    default:
      return { ...state }
  }
}
