import { CloseOutlined } from '@mui/icons-material'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import React from 'react'
import TransformButton from '../CustomButton/TransformButton'

const Preview = ({
  open = false,
  img,
  video,
  text,
  handleClose,
  handleRemoveImg,
  handleRemoveVideo,
}) => {
  //console.log(open)
  let image, vid
  if (img) {
    image = URL.createObjectURL(img)
  }
  if (video) {
    vid = URL.createObjectURL(video)
  }
  //console.log(video)
  let content = null,
    content1 = null,
    content2 = null

  if (img) {
    content = (
      <Grid xs={12} sx={Style.box}>
        <img src={image} alt="Preview" style={Style.video1} />
      </Grid>
    )
  }
  if (video) {
    // Assuming 'video' is a URL pointing to a video file
    content1 = (
      <Grid xs={12} sx={Style.box}>
        <video controls style={Style.video}>
          <source src={vid} />
        </video>
      </Grid>
    )
  }
  if (text) {
    // Assuming 'video' is a URL pointing to a video file
    content2 = (
      <Grid xs={12} sx={Style.box}>
        <Typography>{text}</Typography>
      </Grid>
    )
  }
  const handleRemove = (type) => {
    if (type === 'image') {
      handleRemoveImg()
    } else {
      handleRemoveVideo()
    }
    handleClose()
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={Style.modal} className="timeline">
        <Box sx={Style.header}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: 'black', fontWeight: '700' }}
          >
            Preview
          </Typography>
          <Button onClick={handleClose}>
            <CloseOutlined sx={{ color: 'grey' }} />
          </Button>
        </Box>
        <div>{content}</div>
        <div>{content1}</div>
        <div>{content2}</div>
        <Box sx={Style.buttons}>
          {img && (
            <TransformButton
              button_name={'Remove image'}
              transform={'skew(-22deg) translate(-5%, -50%)'}
              handleClick={() => handleRemove('image')}
            />
          )}
          {video && (
            <TransformButton
              button_name={'Remove video'}
              transform={'skew(-22deg) translate(0%, -50%)'}
              handleClick={() => handleRemove('video')}
            />
          )}
        </Box>
      </Box>
    </Modal>
  )
}

export default Preview
const Style = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 2,
    overflow: 'scroll',
    maxHeight: '500px',
  },
  header: {
    borderBottom: '1px solid gray',
    p: 2,
    pl: 4,
    display: 'flex',
    justifyContent: 'space-between',
  },
  box: {
    mt: 2,
    display: 'flex',
    justifyContent: 'center',
  },
  video: {
    width: '400px',
    height: '200px',
  },
  video1: {
    width: '400px',
    height: '200px',
    // objectFit: 'none',
  },
  img: {
    width: '400px',
    height: '200px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
}
