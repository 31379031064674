import { Api } from '../../config/request'

export const requestPlayerList = async (data) => {
  return Api.PostRequest('/user/list', data)
}
export const requestPlayerDetails = async (data) => {
  return Api.PostRequest('/player/playerView', data)
}
export const requestPlayerAddUpdate = async (data) => {
  return Api.PostRequest('/player/playerEdit', data)
}
// export const requestClubUserDetails = async (data) => {
//   return Api.PostRequest('/user/details/token', data)
// }
// export const requestClubEmailSent = async (data) => {
//   return Api.PostRequest('/user/sendSignupUrl', data)
// }
export const requestPlayerDelete = async (data) => {
  //console.warn('hiiii')
  return Api.PostRequest('/player/playerDelete', data)
}

const PlayerApiRequests = {
  requestPlayerList,
  requestPlayerDetails,
  requestPlayerAddUpdate,
  //   requestClubUserDetails,
  //   requestClubEmailSent,
  requestPlayerDelete,
  //   requestPlayerList,
  //   requestPlayerDetails,
}
export default PlayerApiRequests
