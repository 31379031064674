import {
  BULK_ORDERS,
  BULK_ORDERS_INFO_EDIT,
  BULK_ORDERS_LISTING,
  BULK_ORDER_PASS,
  CUSTOMERS,
  CUSTOMERS_ADD_NEW,
  CUSTOMERS_INFO_EDIT,
  CUSTOMERS_JUMPING_EDIT,
  CUSTOMERS_MEASUREMENT_EDIT,
  CUSTOMERS_PASS,
  CUSTOMERS_SUBSCRIPTION,
  CUSTOMER_LISTING,
  PLAYER_LISTING,
  DASHBOARD,
  PASS_UPDATE,
  PROFILE,
  PROFILE_UPDATE,
  REVENUE_REPORTS,
  CLUBDASHBOARD,
  WORKINPROGRESS,
  CLUB_PROFILE_UPDATE,
  CLUB_PASS_UPDATE,
  PLAYER_EDIT,
  PLAYER_MEASUREMENT,
  PLAYER_JUMPING,
  REVENUE_FAILED,
  REVENUE_REFUND,
  LOG_EVENTS,
  PROGRAM,
  CUSTOMER_PROFILE,
  CUSTOMER_ACCOUNT,
  PROGRAM_VIEW,
  CUSTOMER_REFER_FRIEND,
  CUSTOMER_MEASUREMENT_EDIT,
  CUSTOMER_JUMPING_EDIT,
  CUSTOMER_PROFILE_DETAIL,
  CUSTOMER_PASS,
  CUSTOMER_EDIT,
  FEED,
  PROGRAM_ALL,
  ADMIN,
  ADMIN_EDIT,
  ADMIN_CHANGE_PASS,
} from '../routes/constURL'
import logoutIcon from '../../src/assets/logout.svg'
import dashboardIcon from '../../src/assets/dashbardIcon.svg'
import bulkIcon from '../../src/assets/bulkOrderIcon.svg'
import revenueIcon from '../../src/assets/revenueReportIcon.svg'
import customersIcon from '../../src/assets/customersIcon.svg'
import profileIcon from '../../src/assets/profileIcon.svg'
import calender11 from '../../src/assets/calendar1.svg'
import users from '../../src/assets/users.svg'
import CustomerInfo from '../Admin/Pages/CustomerInfo'
import MeasurementEdit from '../Admin/Pages/MeasurementEdit'
import JumpingResults from '../Admin/Pages/JumpingResults'
import ChangePassCustomer from '../Admin/Pages/ChangePassCustomer'
import SubscriptionCustomer from '../Admin/Pages/SubscriptionCustomer'
import AdminProfile from '../Admin/Pages/AdminProfile'
import AdminPassword from '../Admin/Pages/AdminPassword'
import { storage } from './storage'
import WorkInProgress from '../Admin/Pages/WorkInProgress'
import EditViewBulkOrder from '../Admin/Pages/EditViewBulkOrder'
import ClubAdmin from '../ClubAdmin/ClubAdmin'
import ClubPassword from '../ClubAdmin/ClubPassword'
import PlayerEdit from '../ClubAdmin/PlayerEdit'
import PlayerMeasurement from '../ClubAdmin/PlayerMeasurement'
import PlayerJumping from '../ClubAdmin/PlayerJumping'
import Revenue_all from '../Admin/Pages/Revenue_all'
import RevenueFailed from '../Admin/Pages/RevenueFailed'
import RevenueRefund from '../Admin/Pages/RevenueRefund'
import Program from '../../src/assets/program.svg'
import FeedIcon from '../../src/assets/feed.svg'
import CustomerAccount from '../../src/assets/customerAccount.svg'
import MyAccount from '../Customer/Pages/MyAccount'
import Programs from '../Customer/Pages/Programs'
import ViewProgram from '../Customer/Pages/ViewProgram'
import Refer from '../Customer/Pages/Refer'
import EditProfile from '../Customer/Pages/EditProfile'
import EditMeasurement from '../Customer/Pages/EditMeasurement'
import EditJumping from '../Customer/Pages/EditJumping'
import ChangePass from '../Customer/Pages/ChangePass'
import Feed from '../Customer/Pages/Feed'
import EditAdmin from '../Admin/Pages/EditAdmin'
import ChangePassAdmin from '../Admin/Pages/ChangePassAdmin'
///////// -------------  /////////////

const login_user = storage.fetch.role()
export const AccountData = [
  {
    val: 'Dashboard',
    routes: DASHBOARD,
    status: false,
    src: dashboardIcon,
  },
  {
    val: 'Bulk Orders',
    routes: BULK_ORDERS_LISTING,
    status: false,
    src: bulkIcon,
  },
  {
    val: 'Customers',
    routes: CUSTOMER_LISTING,
    status: false,
    src: customersIcon,
  },
  {
    val: 'Revenue Reports',
    routes: REVENUE_REPORTS,
    status: false,
    src: revenueIcon,
  },
  {
    val: 'Admins',
    routes: ADMIN,
    status: false,
    src: users,
  },
  {
    val: 'Admin Profile',
    routes: PROFILE_UPDATE,
    status: false,
    src: profileIcon,
  },
  {
    val: 'Event log',
    routes: LOG_EVENTS,
    status: false,
    src: calender11,
  },
  {
    val: 'Sign Out',
    status: false,
    src: logoutIcon,
  },
]
export const StandardAdminData = [
  {
    val: 'Dashboard',
    routes: DASHBOARD,
    status: false,
    src: dashboardIcon,
  },
  {
    val: 'Bulk Orders',
    routes: BULK_ORDERS_LISTING,
    status: false,
    src: bulkIcon,
  },
  {
    val: 'Customers',
    routes: CUSTOMER_LISTING,
    status: false,
    src: customersIcon,
  },
  {
    val: 'Revenue Reports',
    routes: REVENUE_REPORTS,
    status: false,
    src: revenueIcon,
  },
  {
    val: 'Admin Profile',
    routes: PROFILE_UPDATE,
    status: false,
    src: profileIcon,
  },
  {
    val: 'Event log',
    routes: LOG_EVENTS,
    status: false,
    src: calender11,
  },
  {
    val: 'Sign Out',
    status: false,
    src: logoutIcon,
  },
]
export const BulkData = [
  {
    val: 'Dashboard',
    routes: CLUBDASHBOARD,
    status: false,
    src: dashboardIcon,
  },
  {
    val: 'Players',
    routes: PLAYER_LISTING,
    status: false,
    src: customersIcon,
  },
  {
    val: 'Profile',
    routes: CLUB_PROFILE_UPDATE,
    status: false,
    src: profileIcon,
  },
  {
    val: 'Sign Out',
    status: false,
    src: logoutIcon,
  },
]
export const PlayerData = [
  {
    val: 'Program',
    routes: PROGRAM_ALL + '/1',
    status: false,
    src: Program,
  },

  {
    val: 'Profile',
    routes: CUSTOMER_EDIT,
    status: false,
    src: profileIcon,
  },
  // {
  //   val: 'Feed',
  //   routes: FEED,
  //   status: false,
  //   src: FeedIcon,
  // },
  {
    val: 'Account',
    routes: CUSTOMER_ACCOUNT,
    status: false,
    src: CustomerAccount,
  },
  {
    val: 'Sign Out',
    status: false,
    src: logoutIcon,
  },
]
export const tabsCustomerData = [
  {
    text: 'General Info',
    route: CUSTOMERS_INFO_EDIT,
    page: CustomerInfo,
  },
  {
    text: 'Measurement details',
    route: CUSTOMERS_MEASUREMENT_EDIT,
    page: MeasurementEdit,
  },
  {
    text: 'Jumping Results',
    route: CUSTOMERS_JUMPING_EDIT,
    page: JumpingResults,
  },
  {
    text: 'Change Password',
    route: CUSTOMERS_PASS,
    page: ChangePassCustomer,
  },
  {
    text: 'Subscription',
    route: CUSTOMERS_SUBSCRIPTION,
    page: SubscriptionCustomer,
  },
]
export const tabsCustomerProfile = [
  {
    text: 'General Info',
    route: CUSTOMER_EDIT,
    page: EditProfile,
  },
  {
    text: 'Measurement details',
    route: CUSTOMER_MEASUREMENT_EDIT,
    page: EditMeasurement,
  },
  {
    text: 'Jumping Results',
    route: CUSTOMER_JUMPING_EDIT,
    page: EditJumping,
  },
  {
    text: 'Change Password',
    route: CUSTOMER_PASS,
    page: ChangePass,
  },
]
export const tabsPlayerData = [
  {
    text: 'General Info',
    route: PLAYER_EDIT,
    page: PlayerEdit,
  },
  {
    text: 'Measurements details',
    route: PLAYER_MEASUREMENT,
    page: PlayerMeasurement,
  },
  {
    text: 'Jumping Results',
    route: PLAYER_JUMPING,
    page: PlayerJumping,
  },
]
export const tabsAdminProfileData = [
  {
    text: 'General Info',
    route: PROFILE_UPDATE,
    page: AdminProfile,
  },
  {
    text: 'Change Password',
    route: PASS_UPDATE,
    page: AdminPassword,
  },
]
export const tabsRevenueData = [
  {
    text: 'All Payments',
    route: REVENUE_REPORTS,
    page: Revenue_all,
  },
  {
    text: 'Failed payments',
    route: REVENUE_FAILED,
    page: RevenueFailed,
  },
  {
    text: 'Refunds',
    route: REVENUE_REFUND,
    page: RevenueRefund,
  },
]
export const tabsProgramData = [
  {
    text: 'Week 1',
    route: PROGRAM,
    page: Programs,
  },
]
let week = storage.fetch.week_number()
// console.log('week', week)
export const tabsProgramView = [
  {
    text: `Week ${week} - Program`,
    route: PROGRAM_VIEW,
    page: ViewProgram,
  },
]
export const tabsFeeds = [
  {
    text: 'Public Feed',
    route: FEED,
    page: Feed,
  },
]

export const tabsClubAdminProfileData = [
  {
    text: 'General Info',
    route: CLUB_PROFILE_UPDATE,
    page: ClubAdmin,
  },
  {
    text: 'Change Password',
    route: CLUB_PASS_UPDATE,
    page: ClubPassword,
  },
]
export const tabsAdminBulkData = [
  {
    text: 'General Info',
    route: BULK_ORDERS_INFO_EDIT,
    page: EditViewBulkOrder,
  },
  { text: 'Change Password', route: BULK_ORDER_PASS, page: ClubPassword },
]
export const tabsCustomerAccountData = [
  {
    text: 'Subscription',
    route: CUSTOMER_ACCOUNT,
    page: MyAccount,
  },
  { text: 'Refer a friend', route: CUSTOMER_REFER_FRIEND, page: Refer },
]
export const tabsAdmin = [
  {
    text: 'General Info',
    route: ADMIN_EDIT,
    page: EditAdmin,
  },
  { text: 'Change Password', route: ADMIN_CHANGE_PASS, page: ChangePassAdmin },
]
export const sportsData = [
  { value: 'aussie-rules', label: 'Aussie Rules' },
  { value: 'basketball', label: 'Basketball' },
  { value: 'football-soccer', label: 'Football (Soccer)' },
  { value: 'rugby-union', label: 'Rugby Union' },
  { value: 'rugby-league', label: 'Rugby League' },
  { value: 'netball', label: 'Netball' },
  { value: 'volleyball', label: 'Volleyball' },
  { value: 'tennis', label: 'Tennis' },
  { value: 'gridiron', label: 'Gridiron' },
  { value: 'baseball', label: 'Baseball' },
  { value: 'european-handball', label: 'European Handball' },
  { value: 'lacrosse', label: 'Lacrosse' },
  { value: 'field-hockey', label: 'Field Hockey' },
  { value: 'ice-hockey', label: 'Ice Hockey' },
  { value: 'cricket', label: 'Cricket' },
  { value: 'ultimate-frisbee', label: 'Ultimate Frisbee' },
  { value: 'dragonboat-racing', label: 'Dragonboat Racing' },
  { value: 'rowing', label: 'Rowing' },
  { value: 'swimming', label: 'Swimming' },
  { value: 'sprinting', label: 'Sprinting' },
  { value: 'long-distance-running', label: 'Long-Distance Running' },
  { value: 'other', label: 'Other' },
  { value: 'multi', label: 'Multi' },
]
export const playerData = [
  {
    name: 'Mock',
    age: '23',
    membership: '1 month membership',
    created_on: '2/1/2024',
    status: 'non-active',
  },
]
export const RevenueData = [
  {
    name: 'John William',
    membership: '1 month',
    sport: 'Football',
    subscribed_on: '2/1/2024',
    unsubscribed_on: '2/1/2024',
    tenure: '1',
    revenue: '$200',
    mode: 'online',
    status: 'non-active',
  },
]
export const initialState = {
  plan: '',
  show: false,
  order: 'asc',
  orderBy: '',
  tableData: [],
  alert1: false,
  filtered: '',
  page: 1,
  checked: false,
  pages: '',
  total: 0,
  rows: [],
  isCancelled: false,
  status: 'Select Status',
  sport: 'Select Sport',
  sporterr: 'Please Select a Sport',
  payment: 'Payment Mode',
  type: 'Select Type',
  typeerr: 'Please Select a Type',
  date: '',
  customer_id: '',
  customer_select_err: 'Please Select a User/Club',
  membership: 'Select Program',
  membershiperr: 'Please Select a membership',
  columns: [],
  startFrom: 0,
  length: 5,
  open: false,
  open1: false,
  state: 'Select State',
  stateerr: '',
  country: 'Select Country',
  countryerr: '',
  month: 'Select month',
  monthserr: '',
  check: false,
  checkerr: '',
  year: 'Select year',
  yearerr: '',
  details: {},
  search: '',
  role: 'Select',
  countries: 'Select Country',
  states: 'Select State',
  stripePromise: null,
  event_type: 'Event type',
  Created_by: 'Created By',
  text: '',
  video: '',
  img: '',
  profileImage: '',
  event_typeNew: 'Event type',
  searched:false,
  typeNew:'Select Type',
  paymentNew:'Payment Mode',
  sportNew:'Select Sport'
  
}
export function reducerFunction(prevState, nextState) {
  return { ...prevState, ...nextState }
}
export const status_arr = ['Select Status', 'Active', 'Non-active']
export const membership_arr = [
  'Select Program',
  '1 Month Program',
  '3 Month Program',
]
export const months = [
  { name: 'Select month', value: 'Select month' },
  { name: 'January', value: 1 },
  { name: 'February', value: 2 },
  { name: 'March', value: 3 },
  { name: 'April', value: 4 },
  { name: 'May', value: 5 },
  { name: 'June', value: 6 },
  { name: 'July', value: 7 },
  { name: 'August', value: 8 },
  { name: 'September', value: 9 },
  { name: 'October', value: 10 },
  { name: 'November', value: 11 },
  { name: 'December', value: 12 },
]
// export const years = ['Select year', '2024', '2025', '2026', '2027']

export const generateYears = () => {
  const currentYear = new Date().getFullYear()
  const years = ['Select year']

  for (let i = 0; i < 5; i++) {
    years.push((currentYear + i).toString())
  }

  return years
}
// export const programs = [
//   'Select Program',
//   '1 Month Program',
//   '3 Months Program',
// ]

export const programs = [
  { value: '1 Month Program', label: '1 Month Program' },
  { value: '3 Months Program', label: '3 Months Program' },
]

export const FILE_TYPE = {
  IMAGE: 1,
  GIF: 2,
  VIDEO: 3,
  AUDIO: 4,
}
