import {
  Box,
  Button,
  FormLabel,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import React, { useEffect, useState } from 'react'
import InputField from '../../Components/InputField'
import { Schema } from '../../Components/FormSchema/Schema'
import RowRadioButtonsGroup from '../../Components/RadioButtons/RadioButton'
import { useDispatch } from 'react-redux'
import {
  getCustomerAddUpdateRequest,
  getCustomerDetailsRequest,
} from '../../redux/customer/customerAction'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import {
  CUSTOMERS_INFO_DETAILS,
  CUSTOMERS_MEASUREMENT_EDIT,
  PLAYER_MEASUREMENT,
} from '../../routes/constURL'
import { getLoadingStatus } from '../../redux/login/action'
import LoaderComponent from '../Loader/LoaderComponent'
import { getPlayerAddUpdateRequest } from '../../redux/players/playerAction'
import {
  getMeasurementEdit,
  getMeasurementModify,
} from '../../redux/customer_panel/actionCustomer'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const MeasurementForm = ({ Style, type_case, loading, val, page }) => {
  const newState = useSelector((state) => state?.CustomerData)
  const myDispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  //   const trimmedURL = location.pathname.slice(0, 12)
  // //console.log(trimmedURL)
  let user_id = useParams()
  let data = {
    users_id: user_id.id,
    page: page,
  }
  //console.log(val)
  // const newState = useSelector((state) => state?.CustomerData)
  const [defaultValue, setdefaultValue] = useState({
    measure_date: '',
    height: '',
    weight: '',
  })
  useEffect(() => {
    const date = new Date(val?.measure_date)
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
    //console.log(formattedDate)
    setdefaultValue({
      measure_date: formattedDate,
      height: val?.height,
      weight: val?.weight,
    })
  }, [val])
  const onSubmit = (value) => {
    Object.assign(value, data)
    let dispatchResult = null
    switch (type_case) {
      case 'player_edit':
        dispatchResult = myDispatch(getPlayerAddUpdateRequest(value, navigate))
        break
      case 'customer_edit':
        dispatchResult = myDispatch(
          getCustomerAddUpdateRequest(value, navigate),
        )
        break
      case 'user_edit':
        if (val) Object.assign(value, { measurement_id: val.id, page: page })
        dispatchResult = myDispatch(getMeasurementModify(value))
        break
      default:
        break
    }
    myDispatch(getLoadingStatus(true))
    setdefaultValue({
      measure_date: '',
      height: '',
      weight: '',
    })
    val = {
      measure_date: '',
      height: '',
      weight: '',
      id: '',
    }
    return dispatchResult
  }
  const formik = useFormik({
    initialValues: defaultValue,
    validationSchema: Schema.MEASUREMENTADD,
    onSubmit: (value) => {
      onSubmit(value)
    },
    enableReinitialize: true,
  })
  // let id = useLocation()

  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )
  return (
    <form name="MeasurementForm" onSubmit={formik.handleSubmit}>
      <Grid
        sx={{ mt: '10px' }}
        rowSpacing={2}
        columnSpacing={3}
        container
        spacing={2}
      >
        <LoaderComponent open={newState?.loadingStatus || loading} />

        <Grid item xs={12} sm={3}>
          <Item>
            <InputField
              value={formik.values.measure_date}
              onChange={formik.handleChange}
              type={'date'}
              placeholder={'Please enter date'}
              size={{ xs: '18px', md: '18px', lg: '18px' }}
              color={'#333333'}
              name1="measure_date"
              required={'*'}
              edit_update={true}
              formlabel={'Select Date'}
              max={new Date().toISOString().split('T')[0]}
            />
            {formik.errors.measure_date && formik.touched.measure_date ? (
              <p style={Style.validationStyle}>Please select a date</p>
            ) : null}
          </Item>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Item>
            <InputField
              value={formik.values.height}
              onChange={formik.handleChange}
              edit_update={true}
              type={'number'}
              placeholder={'Please enter height'}
              size={{ xs: '18px', md: '18px', lg: '18px' }}
              color={'#333333'}
              name1="height"
              required={'*'}
              formlabel={'Height(cm)'}
            />
            {formik.errors.height && formik.touched.height ? (
              <p style={Style.validationStyle}>{formik.errors.height}</p>
            ) : null}
          </Item>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Item>
            <InputField
              value={formik.values.weight}
              onChange={formik.handleChange}
              edit_update={true}
              type={'number'}
              placeholder={'Please enter weight'}
              size={{ xs: '18px', md: '18px', lg: '18px' }}
              color={'#333333'}
              name1="weight"
              required={'*'}
              formlabel={'Weight(kg)'}
            />
            {formik.errors.weight && formik.touched.weight ? (
              <p style={Style.validationStyle}>{formik.errors.weight}</p>
            ) : null}
          </Item>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Item>
            <Button
              className="btn-rt-skw"
              variant="contained"
              type="submit"
              // onClick={onSubmit}
              sx={style.button_one}
              endIcon={<AddIcon sx={{ transform: 'skew(22deg) ' }} />}
            >
              <Typography sx={{ transform: 'skew(22deg) ', fontWeight: 500 }}>
                {defaultValue.height !== undefined &&
                defaultValue.measure_date !== ''
                  ? 'Update'
                  : 'Add new'}
              </Typography>
            </Button>
          </Item>
        </Grid>
      </Grid>
    </form>
  )
}

export default MeasurementForm
const style = {
  button_one: {
    bgcolor: '#CB2128',
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      boxShadow: 'none',
    },

    // m: { xs: 1, sm: 2 },
    mt: '50px',
    borderRadius: '0px',
    boxShadow: 'none',
    p: '0px 30px 0px 30px',

    color: 'white',
    // width: "120px",
    textTransform: 'none',
    transform: {
      xs: 'skew(-22deg) translate(0%, -48%)',
      sm: 'skew(-22deg) translate(10%, -48%)',
    },
    height: '40px',
    fontSize: { xs: '12px', sm: '18px' },
  },
}
