import Select from 'react-select'
import React from 'react'
import { FormControl, FormHelperText } from '@mui/material'
import { Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { getstates } from '../redux/payments/paymentAction'
import { reducerFunction, initialState } from '../config/mockData'
import { useReducer } from 'react'
import { storage } from '../config/storage'
import { CUSTOMERSIGNUP } from '../routes/constURL'
import { useLocation } from 'react-router-dom'

const Select2 = ({
  control,
  name,
  defaultValue,
  options,
  error,
  errorMessage,
  handleState,
  mt,
  handleCountryChange,
  edit,
  value,
  onChange,
  placeholder,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()

  // const [state, setStateReducer] = useReducer(reducerFunction, initialState)

  // function doCall(selectedOption)
  // {
  //   console.log("YOU1",selectedOption.value);
  //   localStorage.setItem("countryId",selectedOption.value);
  //   setStateReducer(prevState => ({
  //     ...prevState,
  //     countryId: selectedOption.value
  //   }));
  // }
  const getDefaultValue = () => {
    return options
      ? options.find((option) => option.value === defaultValue)
      : null
  }

  return (
    <>
      {edit ? (
        <>
          <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable
            isSearchable
            name={name}
            options={options}
            value={
              options.find((option) => option.value === value) ||
              getDefaultValue()
            }
            placeholder={placeholder}
            onChange={onChange}
            styles={{
              control: (provided) => ({
                ...provided,
                height: '43px',
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                border: '1px solid #C8C8C8',
                background: 'rgb(242, 242, 242);',
                borderRadius: '0px',
                marginTop: '0.5%',
              }),
              menu: (provided) => ({
                ...provided,
                maxHeight: '300px',
                zIndex: 99999,
                marginTop: mt,
              }),
              input: (provided) => ({
                ...provided,
                // paddingTop: '8px',
                paddingBottom: '8px',
              }),
              singleValue: (provided) => ({
                ...provided,
                marginTop: 0,
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
              placeholder: (provided) => ({
                ...provided,
                color: '#757575',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                color: '#757575',
              }),
            }}
          />
          {error && (
            <FormHelperText
              sx={{
                '&.MuiFormHelperText-root': { ml: 0 },
                color: '#d32f2f',
              }}
            >
              {errorMessage}
            </FormHelperText>
          )}
        </>
      ) : (
        <Controller
          name={name}
          control={control}
          defaultValue={control.defaultValue}
          render={({ field }) => (
            <FormControl error={!!error} sx={{ width: '100%' }}>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable
                isSearchable
                name={name}
                options={options}
                placeholder={placeholder}
                value={
                  options?.find((option) => option.value === field.value) ||
                  null
                }
                onChange={(selectedOption) => {
                  field.onChange(selectedOption ? selectedOption.value : null)
                  name == 'country' &&
                    console.log('postal_code_regex', selectedOption)
                  storage.set.PostalDetails(selectedOption)
                  // console.log(storage.fetch.PostalDetails())
                }}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: '42px',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    border: '1px solid #C8C8C8',
                    background: 'rgb(242, 242, 242);',
                    borderRadius: '0px',
                    marginTop:
                      location.pathname === CUSTOMERSIGNUP ? '2.5%' : '1.2%',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    zIndex: 99999,
                    marginTop: mt,
                    width: '100%',
                  }),
                  input: (provided) => ({
                    ...provided,
                    // paddingTop: '8px',
                    paddingBottom: '8px',
                    width: '100%',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    marginTop: mt,
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '#757575',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#757575',
                  }),
                }}
              />
              {!!error && (
                <FormHelperText
                  sx={{
                    '&.MuiFormHelperText-root': { ml: 0 },
                    color: '#d32f2f',
                  }}
                >
                  {errorMessage}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      )}
    </>
  )
}

export default Select2
