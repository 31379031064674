import React, { useLayoutEffect, useReducer } from 'react'
import { EnhancedTableHead } from '../../Components/TableHeader/TableHeader'
import { Box, Grid, Table, TableBody } from '@mui/material'
import CustomTableBody from '../../Components/TableBox/CustomTableBody'
import ListSkelton from '../../Components/Loader/ListSkelton'
import Toastify from '../../Components/SnackBar/Toastify'
import CustomPagination from '../../Components/Pagination'
import { useSelector } from 'react-redux'

import { initialState, reducerFunction } from '../../config/mockData'
import { Style } from '../../Admin/CSS/Table'
import '../../Admin/CSS/CustomerDetail.css'
import TransformButton from '../../Components/CustomButton/TransformButton'
import { useDispatch } from 'react-redux'
import {
  getCancelSubscription,
  getSubscriptionList,
  getUpgradeMembership,
} from '../../redux/myaccount/myaccountActions'
import { getLoadingStatus } from '../../redux/login/action'
import NoDataFound from '../../Admin/Pages/NoDataFound'
import AlertDialog from '../../Components/Dialog/Dialog'
import { useNavigate } from 'react-router-dom'
import LoaderComponent from '../../Components/Loader/LoaderComponent'

const MyAccount = () => {
  const { list, empty, loadingStatus } = useSelector(
    (state) => state?.AccountData || {},
  )
  const navigate = useNavigate()
  //console.warn(list)
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const myDispatch = useDispatch()
  let data = {
    start: 0,
    length: state.length,
    sort_column: '',
    sort_by: '',
    search_val: '',
    role: 'player',
  }
  ///---Side effect function when change in list length(Pagination)---///
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    document.title = 'OG Sports - My Account'
    myDispatch(getSubscriptionList(data))
    myDispatch(getLoadingStatus(true))
    const headCells = [
      { label: 'Membership', name: 'membership' },
      { label: 'Sport', name: 'sports' },
      { label: 'Payment date', name: 'subscribe_at' },
      { label: 'Payment type', name: 'payment_type' },
      { label: 'Tenure (Days)', name: 'tenure' },
      { label: 'Payment Amount', name: 'revenue_without_tax' },
      { label: 'Mode', name: 'payment_mode' },
      { label: 'Status', name: 'Status' },
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    setStateReducer({ columns: headCells })
  }, [state.length])
  ///---Side effect function when change in list---///
  useLayoutEffect(() => {
    setStateReducer({ pages: list?.pages, total: list?.total_records })
  }, [list])
  ///---Sorting function---///
  const handleRequestSort = (event, property) => {
    let isAsc = state.orderBy === property && state.order === 'asc'
    if (state.orderBy !== property) {
      setStateReducer({ order: 'desc', orderBy: property })
      data.sort_by = 'asc'
      data.sort_column = property
    } else {
      setStateReducer({ order: isAsc ? 'desc' : 'asc', orderBy: property })
      data.sort_by = state.order
      data.sort_column = property
    }
    myDispatch(getSubscriptionList(data))
    myDispatch(getLoadingStatus(true))
  }
  ///---Pagination Function---///
  const handlePageChange = (event, value) => {
    setStateReducer({ page: value })
    let a = (value - 1) * state.length
    data = {
      start: a,
      length: state.length,
    }
    if (state.order && state.orderBy) {
      data.sort_by = state.order === 'asc' ? 'desc' : 'asc'
      data.sort_column = state.orderBy
    }
    myDispatch(getSubscriptionList(data))
    myDispatch(getLoadingStatus(true))
  }
  const handleUpgrade = () => {
    myDispatch(
      getUpgradeMembership({ payment_id: list && list.list[0].payment_id }),
    )
    myDispatch(getLoadingStatus(true))
    setStateReducer({ alert: false })

    // setTimeout(() => {
    //   myDispatch(getSubscriptionList(data))
    // }, 1000)
  }
  const handleOpen = (value) => {
    if (value === 'upgrade') {
      setStateReducer({ alert: true, alert1: false })
    } else {
      setStateReducer({ alert: false, alert1: true })
    }
  }
  const handleCancel = () => {
    myDispatch(
      getCancelSubscription(
        { payment_id: list && list.list[0].payment_id },
        navigate,
      ),
    )
    myDispatch(getLoadingStatus(true))
    setStateReducer({ alert1: false })
    // setTimeout(() => {
    //   myDispatch(getSubscriptionList(data))
    // }, 1000)
  }

  return (
    <React.Fragment>
      <Box
        sx={[
          Style.table.tableWrapBox,
          {
            display: empty ? 'none' : 'flex',
            mt: 4,
          },
        ]}
      >
        <AlertDialog
          title={'Are you sure you want to Upgrade your subscription?'}
          open={state.alert}
          onClickButton={() => handleUpgrade()}
          onClickButtonCancel={() => setStateReducer({ alert: false })}
        />
        <AlertDialog
          title={'Are you sure you want to cancel your subscription?'}
          open={state.alert1}
          onClickButton={() => handleCancel()}
          onClickButtonCancel={() => setStateReducer({ alert1: false })}
        />
        <LoaderComponent open={loadingStatus} />
        {empty || state.total === 0 ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <NoDataFound />
          </Box>
        ) : (
          <>
            <Table sx={Style.table.tableBox} aria-labelledby="tableTitle">
              <EnhancedTableHead
                columns={state.columns}
                setColumns={setStateReducer}
                order={state.order}
                orderBy={state.orderBy}
                onRequestSort={handleRequestSort}
                rowCount={state.rows.length}
                action={true}
              />
              <TableBody sx={{ border: 0 }}>
                {!loadingStatus &&
                  list &&
                  list?.list?.map((row, ind) => (
                    <CustomTableBody
                      key={row?.users_id}
                      row={row}
                      loadingStatus={loadingStatus}
                      ind={ind}
                      columns={[
                        row?.user_membership.length != 0
                          ? row?.user_membership
                          : 'none',
                        row?.user_sports,
                        row?.user_subscribe_at,
                        row?.payment_type,
                        row?.user_tenure_days,
                        row?.user_revenue_with_tax,
                        row?.user_mode_of_payment,
                        row?.membership_status,
                      ]}
                      // membership_status={true}
                    />
                  ))}
                {loadingStatus && (
                  <ListSkelton
                    loadingStatus={loadingStatus}
                    rows={5}
                    columns={8}
                    width={'100px'}
                  />
                )}
              </TableBody>
            </Table>
          </>
        )}
      </Box>
      <Toastify />
      {list?.list[0]?.subscription_canceled_at && (
        <p style={{ color: '#CB2128', width: '60%' }}>
          Subscription cancelled on {list?.list[0]?.subscription_canceled_at}
        </p>
      )}
      <CustomPagination
        state={state}
        empty={empty}
        handlePageChange={handlePageChange}
      />

      <Grid sx={style.buttonBox} xs={12}>
        {list?.list[0]?.user_membership === '1 Month Program' &&
          list?.list[0]?.membership_status === 'active' && (
            <TransformButton
              handleClick={() => handleOpen('upgrade')}
              color={'#27245E'}
              button_name={'UPGRADE'}
              transform={'skew(-22deg) translate(-20%, -50%)'}
            />
          )}
        {list?.list[0]?.membership_status === 'active' && (
          <TransformButton
            handleClick={() => handleOpen('cancel')}
            color={'#CB2128'}
            button_name={'CANCEL SUBSCRIPTION'}
            transform={'skew(-22deg) translate(-6%, -50%)'}
          />
        )}
      </Grid>
    </React.Fragment>
  )
}

export default MyAccount
const style = {
  buttonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}
