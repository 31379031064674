import {
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  SET_REVENUE_DETAILS,
  SET_REVENUE_LIST,
} from '../ConstAction'
const initialState = {}
export const SignupData = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: action.data,
      }
    case GET_EMPTY_STATUS:
      return {
        ...state,
        empty: action.data,
      }

    default:
      return null
  }
}
