import {
  Box,
  Typography,
  Backdrop,
  Button,
  CircularProgress,
  Checkbox,
  Container,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputField from '../Components/InputField'
import Toastify from '../Components/SnackBar/Toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import './Login.css'
import { useNavigate } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/Lock'
import IconLinkButton from '../Components/CustomButton/IconLinkButton'
import {
  getAdminProfileRequest,
  getForgotPassword,
  getLoadingStatus,
  getRequest,
} from '../redux/login/action'
import { CLUB_PROFILE_UPDATE, PROFILE_UPDATE } from '../routes/constURL'
import { Schema } from '../Components/FormSchema/Schema'
import TransformButton from '../Components/CustomButton/TransformButton'

const defaultValues = {
  email: '',
}

const ForgotPassword = () => {
  const newState = useSelector((state) => state?.LoginData)
  const myDispatch = useDispatch()
  useEffect(() => {
    document.title = 'OG Sports - Forgot Passord'
  }, [newState])
  const { control, formState, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(Schema.FORGOT_PASSWORD),
  })
  const { isValid, dirtyFields, errors } = formState
  //console.log(errors)
  const onSubmit = (value) => {
    myDispatch(getForgotPassword(value))
    myDispatch(getLoadingStatus(true))
  }

  return (
    <>
      <Box sx={style.outBox} className="form-screens">
        <Container maxWidth="xl">
          <Box sx={style.mainBox}>
            <Box sx={style.leftBox}>
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={newState?.loadingStatus}
              >
                <CircularProgress size={80} color="inherit" />
              </Backdrop>
              <form
                name="ForgotPassord"
                className="loginForm"
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '80%',
                  // justifyContent: 'space-evenly',
                }}
              >
                <Box sx={{ mt: { xs: '40px', sm: '50px' } }}>
                  <img
                    alt="logo"
                    // className="logoSize"
                    style={{ width: '250px' }}
                    src={require('../assets/logo-11.png')}
                  />
                </Box>

                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: '16px', sm: '20px' },
                    mt: { xs: 4, sm: 10 },
                    mb: { xs: 0, sm: 3 },

                    fontStyle: 'semiBold',
                    fontWeight: '600',
                    color: '#27245E',
                  }}
                >
                  Forgot your Account Password?
                </Typography>

                <InputField
                  control={control}
                  helperText={errors?.email?.message}
                  errors={!!errors.email}
                  type={'text'}
                  placeholder={'Enter your email address'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name="email"
                  required={'*'}
                  IconName={PersonIcon}
                />

                <Box sx={{ height: '15px' }} />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: { md: 'flex-start' },
                    mt: 2,
                    mb: 2,
                    pr: { xs: 1, sm: 10 },
                    pl: { xs: 1, sm: 10 },
                  }}
                >
                  <TransformButton
                    textT={'uppercase'}
                    button_name={'Submit'}
                    typeSubmit={'submit'}
                  />
                </Box>
              </form>
              <Toastify />
            </Box>
            <Box
              sx={{
                width: '50%',
                height: 'calc(100vh - 80px)',
                p: '40px 40px 40px 0px',
                display: { xs: 'none', sm: 'flex' },
              }}
            >
              <img
                alt="logo"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '10px',
                }}
                src={require('../assets/admin-login.jpg')}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default ForgotPassword

const style = {
  outBox: {
    width: '100%',
    overFlow: 'hidden',
  },
  mainBox: {
    maxWidth: '1530px',
    width: '100%',
    minHeight: '500px',
    ml: 'auto',
    mr: 'auto',
    bgcolor: 'white',
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    justifyContent: { xs: 'center', sm: 'flex-end' },
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: { xs: '100%', sm: '50%' },
  },
  button: {
    width: '30%',
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      border: '1.5px solid #FF8D2A',
    },
    bgcolor: '#CB2128',
    color: '#FFFFFF',
    border: '1.5px solid #FF8D2A',
    mb: 2,
    mt: 4,
    borderRadius: '5px',
    fontSize: { xs: '16px', md: '16px', lg: '16px' },
    fontWeight: 400,
    textTransform: 'none',
    transform: 'skewX(-20deg)',
    '& .MuiButton-label': {
      transform: 'skewX(20deg)',
    },
  },
}
