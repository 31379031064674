import {
  GET_BULK_PAYMENT,
  GET_COUNTRIES,
  GET_SIGNUP_PAYMENT,
  GET_STATES,
  SET_BULK_PAYMENT,
} from '../ConstAction'
export const getSignupPayment = (data, navigate) => {
  return {
    type: GET_SIGNUP_PAYMENT,
    data: data,
    navigate: navigate,
  }
}
export const getstates = (data, navigate) => {
  return {
    type: GET_STATES,
    data: data,
    navigate: navigate,
  }
}
export const getcountries = (data, navigate) => {
  return {
    type: GET_COUNTRIES,
    data: data,
    navigate: navigate,
  }
}
