import { fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import ApiRequests from '../../services/login'
import { storage } from '../../config/storage'
import {
  GET_ADMIN_LOGIN,
  GET_ADMIN_LOGOUT,
  GET_ADMIN_PASS_UPDATE,
  GET_ADMIN_PROFILE,
  GET_ADMIN_PROFILE_DATA,
  GET_ADMIN_PROFILE_UPDATE,
  GET_FORGOT_PASSWORD,
  GET_LOADING_STATUS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_NUMBER,
  GET_RESET_PASSWORD,
  GET_SET_ADMINLOGIN,
  GET_STATUS_FALSE,
  GET_VERIFY_REQUEST,
  SET_ADMIN_PASS_UPDATE,
  SET_ADMIN_PROFILE,
  SET_ADMIN_PROFILE_UPDATE,
  SET_FORGOT_PASSWORD,
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_NUMBER,
  SET_PLAYER_LIST,
  SET_RESET_PASSWORD,
  SET_VERIFY_REQUEST
} from '../ConstAction'
import { toast } from 'react-toastify'
import {
  CLUBDASHBOARD,
  CLUB_PROFILE_UPDATE,
  CUSTOMERS,
  CUSTOMER_LISTING,
  CUSTOMER_PROFILE,
  CUSTOMER_PROFILE_DETAIL,
  DASHBOARD,
  PROGRAM_ALL,
  LOGIN,
  PLAYER_LISTING,
  PROFILE_UPDATE,
  PROGRAM,
  ADMIN,
  CUSTOMER_EDIT,
} from '../../routes/constURL'
const role = storage.fetch.role()
const location = window.location.pathname
console.log(location)
///-----*Functions*-----///
function* HandleApiResponse(
  response,
  successAction,
  errorMessage,
  navigate,
  nav,
) {
  if (response.data.status) {
    if (navigate) {
      setTimeout(() => {
        nav.navigate(navigate)
      }, 2000)
    }
    const { data } = response?.data || {}
    //console.log('loginnaan', data)
    if (data?.token) {
      storage.set.authToken(data.token)
    }
    if (data?.role) {
      storage.set.role(data.role)
    }
    console.log('window', window.location.pathname)
    if (
      window.location.pathname === PROFILE_UPDATE ||
      window.location.pathname === CUSTOMER_EDIT ||
      window.location.pathname === CLUB_PROFILE_UPDATE
    ) {
      console.log('window1', window.location.pathname)
      storage.set.profileImage(
        data?.image == null
          ? require('../../assets/profileRect.png')
          : `${data?.image}`,
      )
    }
    
    setTimeout(()=>{
      toast.success(response?.data?.message)
    },3000)
   

    yield put({
      type: successAction,
      response,
    })
  } else {
    //console.log('loginnn')
    toast.error(response?.error || errorMessage)
  }
  yield put({
    type: GET_LOADING_STATUS,
    data: false,
  })
}
function* getAdminProfile(data) {
  try {
    let response = yield ApiRequests.requestAdminProfile(data.data)
    //console.log('response', response?.data)
    storage.set.user_details(response?.data.data)
    yield HandleApiResponse(
      response,
      SET_ADMIN_PROFILE,
      'Failed to fetch admin profile',
    )
  } catch (err) {}
}
function* getCallApi(data) {
  try {
    // //console.log(data.navigate)
    let response = yield ApiRequests.requestLogin(data.data)
    // //console.log(response?.data?.data)
    if (response?.status && response?.data?.data?.role === 'club_admin') {
      yield HandleApiResponse(
        response,
        GET_SET_ADMINLOGIN,
        'Failed to log in',
        CLUBDASHBOARD,
        data,
      )
    } else if (response?.status && response?.data?.data?.role === 'admin') {
      yield HandleApiResponse(
        response,
        GET_SET_ADMINLOGIN,
        'Failed to log in',
        DASHBOARD,
        data,
      )
    } else if (response?.status && response?.data?.data?.role === 'player') {
      yield HandleApiResponse(
        response,
        GET_SET_ADMINLOGIN,
        'Failed to log in',
        PROGRAM_ALL + '/1',
        data,
      )
    } else if (
      response?.status &&
      response?.data?.data?.role === 'standard_admin'
    ) {
      yield HandleApiResponse(
        response,
        GET_SET_ADMINLOGIN,
        'Failed to log in',
        DASHBOARD,
        data,
      )
    } else {
      toast.error(response?.data?.errors[0])
      yield put({
        type: GET_LOADING_STATUS,
        data: false,
      })
    }
  } catch (err) {}
}
function* getAdminProfileUpdate(data) {
  try {
    let response = yield ApiRequests.requestAdminProfileUpdate(data.data)
    //console.log(response)
    if (response.status) {
      toast.success(response?.data?.message)
      yield put({
        type: SET_ADMIN_PROFILE_UPDATE,
        response,
      })
      yield put({
        type: GET_LOADING_STATUS,
        data: true,
      })

      storage.set.profileImage(
        response.data.image == null
          ? require('../../assets/profileRect.png')
          : `${response?.data?.image}`,
      )
    } else {
      toast.error(response?.data?.message)
      yield put({
        type: GET_LOADING_STATUS,
        data: true,
      })
    }
    yield put({
      type: GET_ADMIN_PROFILE,
    })
  } catch (err) {}
}
function* getAdminPassUpdate(data) {
  try {
    let response = yield ApiRequests.requestAdminProfileUpdate(data.data)
    if (response.data.status) {
      toast.success(response?.data?.message)
      yield put({
        type: SET_ADMIN_PASS_UPDATE,
        response,
      })
    } else {
    }
  } catch (err) {}
}
function* getAdminLogout(data) {
  try {
    let response = yield ApiRequests.requestLogout(data.data)
    if (response.status) {
      toast.success('Logged out successfully')
    } else {
    }
  } catch (err) {}
}
function* getForgotPassword(data) {
  try {
    let response = yield ApiRequests.requestForgotPassword(data.data)
    yield HandleApiResponse(
      response,
      SET_FORGOT_PASSWORD,
      'Failed to send email',
    )
  } catch (err) {}
}
function* getResetPassword(data) {
  try {
    let response = yield ApiRequests.requestAdminUpdatePass(data.data)

    yield HandleApiResponse(
      response,
      SET_RESET_PASSWORD,
      'Failed to send email',
      LOGIN,
      data,
    )
  } catch (err) {}
}

function* getVerifyRequest(data)
{
  try{
    let response = yield ApiRequests.requestEmailVerify(data.data)
    // yield HandleApiResponse(
    //   response,
    //   SET_VERIFY_REQUEST,
    //   'Failed to verify email'
    // )
    if(response.data.status)
      {
        toast.success(response?.data?.message)
        yield put({
          type: SET_VERIFY_REQUEST,
          response,
        })
      }else{
        toast.error(response?.data?.message)
        yield put({
          type: SET_VERIFY_REQUEST,
          response,
        })
      }
  }catch (err) {}
}

function* saga() {
  yield takeEvery(GET_ADMIN_LOGIN, getCallApi)
  yield takeEvery(GET_ADMIN_PROFILE, getAdminProfile)
  yield takeEvery(GET_ADMIN_PROFILE_UPDATE, getAdminProfileUpdate)
  yield takeEvery(GET_ADMIN_LOGOUT, getAdminLogout)
  yield takeEvery(GET_ADMIN_PASS_UPDATE, getAdminPassUpdate)
  yield takeEvery(GET_FORGOT_PASSWORD, getForgotPassword)
  yield takeEvery(GET_RESET_PASSWORD, getResetPassword)
  yield takeEvery(GET_VERIFY_REQUEST, getVerifyRequest)
}

export default saga
