import styled from '@emotion/styled'
import { Grid, IconButton, Paper, Skeleton, Typography } from '@mui/material'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PROGRAM_VIEW } from '../../routes/constURL'
import videoSnapshot from 'video-snapshot'
import ReactPlayer from 'react-player'
import { storage } from '../../config/storage'
const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  alignItems: 'center',
}))
const Thumbnail = ({ height, width, item, week, fade }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const navigate = useNavigate()
  const handleImageLoad = () => {
    setIsLoaded(true)
  }

  const Style = {
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      mt: 2,
      fontSize: '14px',
      color: '#C8C8C8',
    },
    loader: {
      height: '320px',
      width: '350px',
      position: 'relative',
      top: '-54px',
      marginTop: '-10px',
      '&.MuiGrid-root': {
        maxHeight: '203px',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      },
    },
    name: {
      color: '#27245E',
      fontSize: '18px',
      fontWeight: 700,
    },
    play: {
      color: 'white',
      // left: 160,
      // bottom: 210,
    },
    img: {
      // margin: 5,
      marginLeft: '5px',
      marginRight: '5px',
      // maxWidth: width - '100px',
      // marginBottom: '0px',
    },

    outer: {
      // border: '1px solid #A8A8A8',
      // borderRadius: '10px',
      // mb: 2,
      // maxHeight: '207px',
      // maxWidth: width,
      border: '1px solid #EEEEEE',
    borderRadius: '4px',
    padding: '6px 6px 0px',
    maxHeight: '100%',
    mb:2,
    maxWidth:width
    },
  }
  return (
    <Item
      xs={12}
      md={4}
      xl={3}
      sx={{
        opacity: fade ? 0.5 : 1,
      }}
    >
      <Grid sx={Style.outer}>
        {/* {!isLoaded && <Skeleton sx={Style.loader}></Skeleton>} */}
        <img
          class="player"
          src={item.thumbnail}
          style={{width: '100%',
    height: 'auto',
    maxWidth: '100%',
    minHeight: '200px'}}
          // url={item.link}
          // playing={false}
          // style={{
          //   ...Style.img,
          //   visibility: isLoaded ? 'visible' : 'hidden',
          //   display: isLoaded ? 'flex' : 'none',
          //   maxWidth: '350px',
          // }}
        />
      </Grid>

      <Typography sx={Style.name}>
        {item.name}: {item.details.title}
      </Typography>
      <Item sx={Style.item}>
        <Typography sx={Style.date}>Last Viewed: {item.last_view_date ? item.last_view_date : 'NA'}</Typography>
        {/* <Typography sx={Style.date}>{item.tenure_days} days ago</Typography> */}
      </Item>
      <IconButton
        color="white"
        className="button"
        disabled={fade ? true : false}
        sx={Style.play}
        onClick={() => {
          navigate(PROGRAM_VIEW + '/' + item.key, {
            state: item.key,
          })
          storage.set.week_number(week)
        }}
      >
        <PlayCircleIcon sx={Style.icon} fontSize="large" />
      </IconButton>
    </Item>
  )
}

export default Thumbnail
