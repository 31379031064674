import { Api } from '../../config/request'

export const requestProgramList = async (data) => {
  return Api.PostRequest('/program/list', data)
}
export const requestProgramView = async (data) => {
  return Api.PostRequest('/program/list', data)
}
export const requestMarkComplete = async (data) => {
  return Api.PostRequest('/program/markWatched', data)
}

export const requestViewUpdate = async (data) => {
  return Api.PostRequest('/program/view',data)
}

const ProgramApiRequests = {
  requestProgramList,
  requestProgramView,
  requestMarkComplete,
  requestViewUpdate
}
export default ProgramApiRequests
