import {
  Box,
  Button,
  Grid,
  Tabs,
  Tab,
  Paper,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate, useParams } from 'react-router-dom'
import { CUSTOMERS_INFO_EDIT, PLAYER_LISTING } from '../routes/constURL'
import '../Admin/CSS/CustomerDetail.css'
import styled from '@emotion/styled'
import SearchIcon from '@mui/icons-material/Search'

import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { getCustomerDetailsRequest } from '../redux/customer/customerAction'
import { useDispatch } from 'react-redux'
import { EnhancedTableHead } from '../Components/TableHeader/TableHeader'
import { Style } from '../Admin/CSS/Table'
import Toastify from '../Components/SnackBar/Toastify'
import { storage } from '../config/storage'
import LoaderComponent from '../Components/Loader/LoaderComponent'
import { getLoadingStatus } from '../redux/login/action'
import AlertDialog from '../Components/Dialog/Dialog'
import {
  getPlayerDetailsRequest,
  getPlayerDelete,
} from '../redux/players/playerAction'
import CustomFilledGrid from '../Components/CustomFilledGrid/CustomFilledGrid'
import AboutGrid from '../Components/CustomFilledGrid/AboutGrid'
const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  alignItems: 'center',
}))
const useStyles = makeStyles((theme) => ({
  tab: {
    backgroundColor: 'transparent',
    fontSize: { xs: '14px', sm: '16px' },
    fontWeight: '400',
    //width: "45%",
    '&.MuiTab-root.Mui-selected': {
      backgroundColor: '#D80027',
      color: 'white',
      textTransform: 'none',
    },
    '&.MuiButtonBase-root': {
      backgroundColor: 'transparent',
      color: '#3D2E57',
      textTransform: 'none',
      //marginRight: "7%",
    },
  },
}))
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}
const PlayerDetails = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const loading = useSelector((state) => state?.CustomerData?.loadingStatus)
  const newState = useSelector((state) => state?.PlayerData?.details)
  const { measurementData, jumpResultData, playerData } = useSelector(
    (state) => state?.PlayerData?.details || [],
  )
  const tabClasses = { root: classes.tab }
  const tabPanelClasses = { root: classes.TabPanel }
  const [value, setValue] = React.useState(0)
  const [columns, setColumns] = React.useState([])
  const [columns1, setColumns1] = React.useState([])
  // const [pages, setPages] = useState(playerData && newState?.pages)
  const [pages, setPages] = React.useState(5)
  const [searchValue, setSearchValue] = React.useState('')
  const [total, setTotal] = useState(5)
  const [openAlert, setOpenAlert] = React.useState(false)
  const [page, setPage] = React.useState(1)
  const [measpages, setmeasPages] = React.useState(measurementData?.pages)
  const [meastotal, setmeasTotal] = useState(measurementData?.total_records)
  const [measpage, setmeasPage] = React.useState(1)
  const [measlength, setmeasLength] = useState(5)
  const [measstart, setmeasStart] = React.useState(0)
  const [measSearch, setmeasSearch] = React.useState('')

  const [jumppages, setjumpPages] = React.useState(jumpResultData?.pages)
  const [jumptotal, setjumpTotal] = useState(jumpResultData?.total_records)
  const [jumppage, setjumpPage] = React.useState(1)
  const [jumplength, setjumpLength] = useState(5)
  const [jumpstart, setjumpStart] = React.useState(0)
  const [jumpSearch, setjumpSearch] = React.useState('')

  // const jumpResultData = useSelector((state) => state?.CustomerData?.resultjumpResultData)

  // const measurementData = useSelector(
  //   (state) => state?.CustomerData?.resultHeightWeight,
  // )
  const AboutList = [
    {
      title: 'Initial Jumping Result',
      name: '',
      data: playerData?.init_jump_height==0 || playerData?.init_jump_height==null ? 'NA' : playerData?.init_jump_height,
      unit: playerData?.init_jump_height==0 || playerData?.init_jump_height==null ? '' :' cm',
    },
    {
      title: 'Initial Height Result',
      name: '',
      data: playerData?.init_height==null || playerData?.init_height==0 ? 'NA' : playerData.init_height,
      unit: playerData?.init_height==0 || playerData?.init_height==null ?'' : ' cm',
    },
    {
      title: 'Initial Weight Result',
      name: '',
      data: playerData?.int_weight==0 || playerData?.int_weight==null ? 'NA' : playerData?.int_weight,
      unit: playerData?.int_weight==0 || playerData?.int_weight==null ? '' : ' kg',
    },
  ]
  const AboutUpper = [
    { value: playerData?.age==null || playerData?.age==0 ? 'NA' : playerData.age, name: 'Age', unit: '', md: 1.7 },
    { value: playerData?.init_height==null || playerData?.init_height==0 ? 'NA' : playerData.init_height, name: 'Height', unit:playerData?.init_height==0 || playerData?.init_height==null ?'' : ' cm', md: 1.7 },
    { value: playerData?.int_weight==0 || playerData?.int_weight==null ? 'NA' : playerData?.int_weight, name: 'Weight', unit: playerData?.int_weight==0 || playerData?.int_weight==null ? '' : ' kg', md: 1.7 },
    { value: playerData?.sports==null || playerData?.sports=='Select Sport' ? 'NA' : playerData.sports, name: 'Sport', unit: '', md: 2 },
    // {
    //   value: playerData?.init_jump_height == null ? playerData?.init_jump_height : 'NA',
    //   name: 'Standing Jump Height',
    //   unit: playerData?.init_jump_height == null ? 'cm' : '',
    //   md: 2,
    // },
    {
  value: playerData?.init_jump_height==0 || playerData?.init_jump_height==null ? 'NA' : playerData?.init_jump_height,
  name: 'Standing Jump Height',
  unit: playerData?.init_jump_height==0 || playerData?.init_jump_height==null ? '' :' cm',
  md: 2,
},
    { value: playerData?.armspan ==0 || playerData?.armspan==null ? 'NA' :playerData.armspan, name: 'Arm Span', unit: playerData?.armspan==0 || playerData?.armspan==null ? '' : ' cm', md: 2 },
    {
      value:
        playerData?.handed != null
          ? playerData?.handed == 'right'
            ? 'Right'
            : 'Left'
          : 'NA',
      name: 'Handed',
      unit: '',
      md: 1.7,
    },
    {
      value: playerData?.team == null || playerData?.team=="" ? 'NA' : playerData?.team,
      name: 'Current Team',
      unit: '',
      md: 3,
    },
  ]
  // //console.log(newState)
  const myDispatch = useDispatch()
  let user_id = useParams()
  let data = {
    users_id: user_id.id,
  }
  useEffect(() => {
    document.title = 'OG Sports - Player View'
    myDispatch(getPlayerDetailsRequest(data))
    myDispatch(getLoadingStatus(true))
    const headCells = [
      { label: 'Date', name: 'date' },
      { label: 'Height', name: 'height' },
      // {label:"Email",name:"Email"},
      // {label:"Phone",name:"Phone"},
      { label: 'Weight', name: 'weight' },
      // {label:"Action",name:"Action"},
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    const headCell1 = [
      { label: 'Date', name: 'date' },
      { label: 'Jump Height', name: 'height' },

      // {label:"Action",name:"Action"},
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    setColumns1(headCell1)
    setColumns(headCells)
  }, [])
  useEffect(() => {}, [measurementData, jumpResultData])
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleDelete = () => {
    setOpenAlert(true)
  }
  const confirmDelete = () => {
    myDispatch(getPlayerDelete({ users_id: user_id.id }))
    setTimeout(() => {
      navigate(PLAYER_LISTING)
    }, 2000)
    setOpenAlert(false)
    myDispatch(getLoadingStatus(true))
  }
  const handleMeasPageChange = (event, value) => {
    setmeasPage(value)
    let a = (value - 1) * measlength
    setmeasStart(a)
    Object.assign(data, {
      start_measurements: a,
      length_measurements: measlength,
    })

    if (searchValue) {
      let searchVal = { search_val_measurements: searchValue }
      Object.assign(data, searchVal)
    }
    // setSelected([]);
    // getuserData();
    // data = value

    myDispatch(getPlayerDetailsRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  const handleJumpPageChange = (event, value) => {
    setjumpPage(value)
    let a = (value - 1) * jumplength
    setmeasStart(a)
    Object.assign(data, {
      start_jump_results: a,
      length_jump_results: jumplength,
    })

    if (searchValue) {
      let searchVal = { search_val_jump_results: searchValue }
      Object.assign(data, searchVal)
    }
    // setSelected([]);
    // getuserData();
    // data = value

    myDispatch(getPlayerDetailsRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  const handleSearch = () => {
    // console.log(searchValue)
    Object.assign(
      data,
      value
        ? { search_val_jump_results: searchValue }
        : { search_val_measurements: searchValue },
    )
    myDispatch(getPlayerDetailsRequest(data))
    myDispatch(getLoadingStatus(true))
  }

  return (
    <>
      <Box
        className="head-main"
        sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="p" sx={style.headingText}>
          {'Player View'}
        </Typography>
        <Button
          className="btn-rt-skw"
          variant="contained"
          onClick={handleDelete}
          // onClick={button_one_onClick}
          sx={style.button_one}
        >
          <Typography sx={{ transform: 'skew(22deg) ' }}>
            DELETE PLAYER{' '}
          </Typography>
        </Button>
      </Box>
      <AlertDialog
        title={'Are you sure you want to delete this Player?'}
        open={openAlert}
        onClickButton={() => confirmDelete()}
        onClickButtonCancel={() => setOpenAlert(false)}
      />
      <Box sx={{ display: 'flex' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <Item className="about-info">
              {/* <div className="image-div"> */}
              <img
                className="profileRecImage"
                src={
                  playerData && playerData.image
                    ? playerData.image
                    : require('./../assets/profileRect.png')
                }
                // onClick={() => setOpen(true)}
              />
              {/* </div> */}
              <div className="info-cust">
                <h3>
                  {playerData && playerData.f_name + ' ' + playerData.l_name}
                </h3>
                <p>
                {playerData?.team ? playerData?.team : 'NA'} - {' '}
                {playerData?.sports ? playerData?.sports : 'NA'}
                </p>
                {/* <p>Carver High School - Montgomery - Carver Football </p> */}
                {/* <p className="text-light">Montgomery, AL</p> */}
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box className="about-sec">
        <div className="heading-bg">
          <h3>About</h3>
        </div>
        <Grid container columnGap={2} rowGap={2}>
          <AboutGrid list={AboutUpper} md={1.85} p={20} />
        </Grid>
      </Box>
      <Box className="about-result">
        <Grid container spacing={3}>
          <CustomFilledGrid list={AboutList} width={'362px'} md={4} />
        </Grid>
      </Box>
      <LoaderComponent open={loading} />
      <Box sx={{ width: '100%', mt: 4 }}>
        <Box sx={{ '&.MuiBox-root': { padding: 0 } }}>
          <Tabs indicatorColor="none" value={value} onChange={handleChange} sx={{backgroundColor:"#f5f4ff",border:"1px solid #CDCAFC",mb:"2%"}}>
            <Tab classes={tabClasses} label="Result (Height/Weight)" />
            <Tab classes={tabClasses} label="Result (Jumping)" />
          </Tabs>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ mr: '20px' }}>Filter By</Typography>
            <TextField
              variant="filled"
              inputProps={{
                style: {
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  color: '#000',
                },
              }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                disableUnderline: true,
                sx: {
                  fontSize: '16px',
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '300px',
                  },
                  border: '1px solid #C8C8C8',
                  color: '#000',
                  height: '40px',
                  '& .MuiInputBase-root-MuiFilledInput-root ': {
                    // Style the root element of MuiInputBase
                    //  pl:0
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                  },
                  '& .MuiInputBase-root-': {
                    // Style the root element of MuiFilledInput
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                  },
                  pr: '0px',
                },
                endAdornment: (
                  <Box
                    sx={{
                      width: '60px',
                      bgcolor: '#CB2128',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <SearchIcon
                      sx={{
                        fontSize: '30px',
                        color: 'white',
                        cursor: 'pointer',
                      }}
                      onClick={handleSearch}
                    />
                  </Box>
                ),
              }}
              // onKeyDown={handleEnter}
              placeholder="Search"
            />
            {/* <Box
                sx={{
                  display: { xs: 'none', md: 'flex', color: '#DDDDDD' },
                  bgcolor:,
                  height: '100%',
                }}
              >
                
              </Box> */}
          </Box>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box
            sx={[
              Style.table.tableWrapBox,
              // { display: empty ? 'none' : 'flex' },
            ]}
          >
            <Table sx={Style.table.tableBox} aria-labelledby="tableTitle">
              <EnhancedTableHead
                // numSelected={selected.length}
                display={'none'}
                columns={columns}
                setColumns={setColumns}
                action={true}
                // order={order}
                // orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                // onRequestSort={handleRequestSort}
                // rowCount={rows.length}
              />
              {/* //API_DATA// */}
              <TableBody>
                {console.log(measurementData)}
                {measurementData === undefined ||
                  (measurementData.length === 0 ? (
                    <TableRow>
                      {console.log('row')}
                      <TableCell></TableCell>
                      <TableCell sx={{textAlign:'center'}}>No Data Found</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ) : (
                    measurementData?.measurement_list?.map((row, ind) => (
                      <TableRow
                        key={row.users_id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          bgcolor: (ind + 1) % 2 === 0 ? '#EEF2FC' : 'white',
                        }}
                      >
                        {console.log(row)}
                        <TableCell align="left" sx={{...Style.table.tableCell}}>
                          {/* {loading && <Skeleton sx={{ width: '100px' }} />} */}
                          {row?.measure_date}
                        </TableCell>
                        <TableCell align="left" sx={{...Style.table.tableCell,textTransform:'lowercase'}}>
                          {/* {loading && <Skeleton sx={{ width: '100px' }} />} */}
                          {row?.height+ ' '}cm
                        </TableCell>
                        <TableCell align="left" sx={{...Style.table.tableCell,textTransform:'lowercase'}}>
                          {/* {loading && <Skeleton sx={{ width: '100px' }} />} */}
                          {row?.weight+ ' '}kg
                        </TableCell>
                      </TableRow>
                    ))
                  ))}
              </TableBody>

              <Toastify />
            </Table>
          </Box>
          {measpages > 1 && (
            <Pagination
              count={measpages}
              page={measpage}
              boundaryCount={1}
              onChange={handleMeasPageChange}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
            />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Table sx={Style.table.tableBox} aria-labelledby="tableTitle">
            <EnhancedTableHead
              // numSelected={selected.length}
              display={'none'}
              columns={columns1}
              setColumns={setColumns1}
              action={true}
              // order={order}
              // orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              // onRequestSort={handleRequestSort}
              // rowCount={rows.length}
            />
            {/* //API_DATA// */}
            <TableBody>
              {jumpResultData &&
                jumpResultData?.jump_results_list?.map((row, ind) => (
                  <TableRow
                    key={row.users_id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      bgcolor: (ind + 1) % 2 === 0 ? '#EEF2FC' : 'white',
                    }}
                  >
                    <TableCell align="left" sx={Style.table.tableCell}>
                      {/* {loading && <Skeleton sx={{ width: '100px' }} />} */}
                      {row?.jump_date}
                    </TableCell>
                    <TableCell align="left" sx={{...Style.table.tableCell,textTransform:'lowercase'}}>
                      {/* {loading && <Skeleton sx={{ width: '100px' }} />} */}
                      {row?.jump_height+ ' '}cm
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            {jumpResultData === undefined ||
              (jumpResultData.length == 0 && (
                <TableRow>
                  <TableCell width={'40%'}></TableCell>
                  <TableCell sx={{textAlign:'left',width:'60%'}}>No Data Found</TableCell>
                </TableRow>
              ))}
            <Toastify />
          </Table>
          {jumppages > 1 && (
            <Pagination
              count={jumppages}
              page={jumppage}
              boundaryCount={1}
              onChange={handleJumpPageChange}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
            />
          )}
        </CustomTabPanel>
        {total == 0 && (
          <Box
            sx={{
              display:
                measurementData?.length > 0 && jumpResultData?.length > 0
                  ? 'flex'
                  : 'none',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              p: 1,
            }}
          >
            {/* <Pagination
                count={pages}
                page={page}
                boundaryCount={1}
                sx={{
                  button: { fontSize: '16px', mr: 1 },
                  width: '100%',
                  display: 'flex',
                  justifyContent: { xs: 'center', md: 'flex-end' },
                }}
                onChange={handlePageChange}
                siblingCount={0}
              /> */}
            {/* <Pagination
              count={pages}
              page={page}
              boundaryCount={1}
              onChange={handlePageChange}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
            /> */}
            {/* )} */}
          </Box>
        )}
        <Box sx={{ mb: '50px' }}></Box>
      </Box>
    </>
  )
}

export default PlayerDetails
const style = {
  button_one: {
    bgcolor: '#CB2128',
    fontWeight: 400,
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      boxShadow: 'none',
    },
    '&.MuiButtonLabel': {
      transform: 'skew(-22deg) translate(-54%, -50%)',
    },
    // m: { xs: 1, sm: 2 },
    mt: '30px',
    boxShadow: 'none',
    p: '0px 50px 0px 50px',

    color: 'white',
    // width: "120px",
    textTransform: 'none',
    transform: 'skew(-22deg) translate(-54%, -50%)',
    height: '40px',
    fontSize: { xs: '12px', sm: '18px' },
  },
  headingText: {
    fontSize: { xs: '14px', sm: '31px' },
    fontWeight: '500',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },
  iconEye: {
    width: '19px',
    height: '19px',
    cursor: 'pointer',
    marginLeft: '10px',
    color: '#fff',
  },
}
