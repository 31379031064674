import { Box, Button, Typography, Select, MenuItem } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import '../../Admin/CSS/CustomerDetail.css'
import InputField from '../InputField'
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
  BULK_ORDERS_LISTING,
  LOG_EVENTS,
  PLAYER_LISTING,
  REVENUE_FAILED,
  REVENUE_REFUND,
  REVENUE_REPORTS,
} from '../../routes/constURL'
import TransformButton from '../CustomButton/TransformButton'
import Select1 from 'react-select'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const TopBox = ({
  headerText,
  button_one,
  button_two,
  searchValue,
  sortingText,
  onClick,
  // handleSearch,
  //   orders,
  //   deleteAPI,
  onSubmit,

  setSearch_val,
  sportdrop,
  value1,
  value3,
  value4,
  value2,
  button_one_onClick,
  handleChangeFirst,
  handleChangeSecond,
  handleChangeType,
  handleChangePayment,
  handleChangeSport,
  handleDateChange,
  initialValue,
  menuData,
  width,
  select_arr1,
  select_arr2,
  select_arr3,
  handleSearch,
  date,
  search_According_To_routes,
  search_According_To_routes1,
  onReset,
  disabledVal,
  disabledReset,
  search_val
}) => {
  const location = useLocation()
  const CustomDropdownIcon = (props) => {
    return <ExpandMoreIcon color="#757575" {...props} />
  }
  // console.log(search_val);
  return (
    <Box
      className="head-main"
      sx={{
        width: '100%',
        maxHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        // className="head-main2"
        sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'space-between',
          marginBottom: {xs:'inherit'}
        }}
      >
        <Typography sx={style.headingText}>{headerText}</Typography>
        {button_one ? (
          <Button
            className="btn-rt-skw"
            variant="contained"
            onClick={
              button_one_onClick
              // setDialog1(true);
            }
            // onClick={button_one_onClick}
            sx={style.button_one}
          >
            <Typography sx={{ transform: 'skew(22deg) ' }}>
              {button_one}{' '}
            </Typography>
            {/* <EditIcon
            style={style.iconEye}
           
          /> */}
          </Button>
        ) : (
          <></>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent:
              location.pathname === REVENUE_REPORTS || location.pathname === REVENUE_FAILED || location.pathname === REVENUE_REFUND
                ? 'flex-start'
                : { xs: 'center', sm: 'flex-end' },
            alignItems: 'center',
            columnGap: '15px',
            rowGap: '10px',
          }}
        >
          {sortingText && (
            <Typography sx={{ mb: '15px', mt: '15px', mr: '15px' }}>
              {sortingText}
            </Typography>
          )}
          {search_According_To_routes && (
            <>
              <Box
                sx={{
                  width: { xs: '47%', sm: '21%', md: '15%', xl: '15.5%' },
                }}
              >
                <InputField
                  type={'date'}
                  value={date}
                  edit_update={true}
                  mt={'0px'}
                  placeholder={'Date'}
                  name1={date}
                  onChange={handleDateChange}
                // className={'xyz'}
                //               sx={{
                //   '.xyz::placeholder': {
                //     textTransform: 'uppercase',
                //   },
                // }}
                />
              </Box>

              <Select
                variant="filled"
                value={value3}
                onChange={handleChangeType}
                displayEmpty
                disableUnderline
                IconComponent={CustomDropdownIcon}
                SelectDisplayProps={{
                  style: {
                    padding: 3,
                    marginLeft: '10px',
                    color: value3 == 'Select Type' ? '#757575' : '',
                  },
                }}
                MenuProps={{ disableScrollLock: true }}
                inputProps={{
                  style: {
                    paddingTop: '8px',
                    paddingBottom: '8px',
                  },
                }}
                sx={{
                  height: '41px',
                  minWidth: width
                    ? { xs: '47%', md: '180px', sm: '20%' }
                    : {
                      xs: '47%',
                      sm: '20%',
                      md: '15%',
                      lg: '15% ',
                      xl: '15.3%',
                    },
                  fontSize: '16px',
                  fontWeight: '400',
                  border: '1px solid #C8C8C8',
                  '& .MuiSelect-select:focus': {
                    backgroundColor: 'transparent',
                  },
                  '&.MuiInputBase-root': {
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                  },
                }}
              >
                {select_arr2 &&
                  select_arr2.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
              </Select>
              {select_arr3 && (
                <Select
                  variant="filled"
                  value={value4}
                  onChange={handleChangePayment}
                  displayEmpty
                  disableUnderline
                  IconComponent={CustomDropdownIcon}
                  SelectDisplayProps={{
                    style: {
                      padding: 3,
                      marginLeft: '10px',
                      color: value4 == 'Payment Mode' ? '#757575' : '',
                    },
                  }}
                  MenuProps={{ disableScrollLock: true }}
                  inputProps={{
                    style: {
                      paddingTop: '8px',
                      paddingBottom: '8px',
                    },
                  }}
                  sx={{
                    height: '41px',
                    minWidth: width
                      ? { xs: '47%', md: '20%', sm: '23%' }
                      : { xs: '47%', sm: '25%', md: '17%', lg: '17%', xl: '15%' },
                    fontSize: '16px',
                    fontWeight: '400',
                    border: '1px solid #C8C8C8',
                    '& .MuiSelect-select:focus': {
                      backgroundColor: 'transparent',
                    },
                    '&.MuiInputBase-root': {
                      borderTopLeftRadius: '0px',
                      borderTopRightRadius: '0px',
                    },
                  }}
                >
                  {select_arr3 &&
                    select_arr3.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                </Select>
              )}
            </>
          )}
          {console.log(value1)}
          {sportdrop && (
            <Box sx={{ minWidth: { xs: '47%', sm: '145px' } }}>
              <Select1
                className="basic-single"
                classNamePrefix="select"
                isClearable
                isSearchable
                name="sport"
                options={select_arr1}
                placeholder="Select Sport"
                value={
                  select_arr1.find((option) => option.value === value1) || null
                }
                onChange={handleChangeSport}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: '41px',
                    minWidth: '47%',
                    // maxWidth: '180px',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    border: '1px solid #C8C8C8',
                    background: 'rgb(242, 242, 242);',
                    borderRadius: '0px',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    zIndex: 99999,
                    marginTop: 0,
                    width: '100%',
                  }),
                  input: (provided) => ({
                    ...provided,
                    // paddingTop: '8px',
                    paddingBottom: '8px',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    marginTop: 0,
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                }}
              />
            </Box>
          )}
          {handleChangeFirst && (
            <Select
              variant="filled"
              value={value1}
              onChange={handleChangeFirst}
              displayEmpty
              disableUnderline
              SelectDisplayProps={{ style: { padding: 3, marginLeft: '10px' } }}
              IconComponent={CustomDropdownIcon}
              MenuProps={{ disableScrollLock: true }}
              inputProps={{
                style: {
                  paddingTop: '8px',
                  paddingBottom: '8px',
                },
              }}
              sx={{
                height: '41px',
                minWidth: width ? { xs: '256px', md: '150px' } : '167px',
                fontSize: '16px',
                fontWeight: '400',
                border: '1px solid #C8C8C8',
                '& .MuiSelect-select:focus': {
                  backgroundColor: 'transparent',
                },
                '&.MuiInputBase-root': {
                  borderTopLeftRadius: '0px',
                  borderTopRightRadius: '0px',
                },
                display: select_arr1 ? 'flex' : 'none',
              }}
            >
              {select_arr1 &&
                select_arr1.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
          )}
          {value2 && (
            <Select
              variant="filled"
              value={value2}
              onChange={handleChangeSecond}
              displayEmpty
              disableUnderline
              SelectDisplayProps={{ style: { padding: 3, marginLeft: '10px' } }}
              MenuProps={{ disableScrollLock: true }}
              IconComponent={CustomDropdownIcon}
              inputProps={{
                style: {
                  paddingTop: '8px',
                  paddingBottom: '8px',
                },
              }}
              sx={{
                height: '41px',
                minWidth: width ? '260px' : '200px',
                fontSize: '16px',
                fontWeight: '400',
                border: '1px solid #C8C8C8',
                // color: '#dddddd',
                '& .MuiSelect-select:focus': {
                  backgroundColor: 'transparent',
                },
                '&.MuiInputBase-root': {
                  borderTopLeftRadius: '0px',
                  borderTopRightRadius: '0px',
                },
              }}
            >
              <MenuItem value={initialValue}>{initialValue}</MenuItem>
              {menuData?.map((item, index) => (
                <MenuItem
                  key={index}
                  sx={{ textTransform: 'capitalize' }}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
              {/* <MenuItem value={'3 month'}>3 month</MenuItem> */}
            </Select>
          )}
          {/* {location.pathname === LOG_EVENTS && ( */}
          <Box
            sx={{
              width: width
                ? { xs: '90%', md: '30%', lg: '30%' }
                : { xs: '97%', md: '25%', lg: '29%' },
              mt: { xs: '12px', sm: 0 },
              display: "contents",

            }}
          >
            <Box sx={{
              display:
                search_According_To_routes || search_According_To_routes1
                  ? 'flex'
                  : 'none'
            }}>
              <InputField
                variant={'filled'}
                value={search_val}
                onChange={setSearch_val}
                placeholder="Search by name"
                edit_update={true}
                mt={'0px'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
              />
            </Box>

            {/* )} */}
            {/* {location.pathname === REVENUE_REPORTS && (
            <Box sx={{ width: width ? { xs: '76%', md: width } : '20%' }}>
              <InputField
                variant={'filled'}
                onChange={setSearch_val}
                placeholder="Search by name"
                edit_update={true}
                mt={'0px'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
              />
            </Box>
          )}
          {location.pathname === PLAYER_LISTING && (
            <Box sx={{ width: width ? width : '20%' }}>
              <InputField
                variant={'filled'}
                onChange={setSearch_val}
                placeholder="Search by name"
                edit_update={true}
                mt={'0px'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
              />
            </Box>
          )} */}

            <Button
              variant="contained"
              onClick={button_two}
              sx={style.button_two}
              disabled={disabledVal}
              //style={{ marginTop: '12px' }}
            >
              <SearchIcon />
            </Button>
            <Box >
              {/* <TransformButton
            button_name={'Reset'}
            handleClick={onReset}
            width
          /> */}

              <Button
                variant="contained"
                onClick={onReset}
                disabled={disabledReset}
                sx={{
                  ...style.button_two, backgroundColor: 'rgb(45, 142, 66)', '&.MuiButtonBase-root:hover': {
                    bgcolor: 'rgb(45, 142, 66)',
                    boxShadow: 'none',
                  },
                }}
              >
                <RotateLeftIcon />
              </Button>
            </Box>
          </Box>
          {location.pathname === REVENUE_REPORTS && (
            <Box sx={{ ml: { xs: 3.1 } }}>
              <TransformButton
                button_name={'Add Offline Payment'}
                handleClick={onClick}
                transform={'skew(-22deg) translate(0%, -50%)'}
              />
            </Box>
          )}
        </Box>

        {/* )} */}
      </Box>
    </Box>
  )
}

export default TopBox
const style = {
  button_one: {
    bgcolor: '#CB2128',
    fontWeight: 400,
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      boxShadow: 'none',
    },
    '&.MuiButtonLabel': {
      transform: 'skew(-22deg) translate(-54%, -50%)',
    },
    m: { xs: 1, sm: 2 },
    boxShadow: 'none',
    p: '0px 50px 0px 50px',

    color: 'white',
    // width: "120px",
    textTransform: 'none',
    transform: 'skew(-22deg) translate(-54%, -50%)',
    height: '40px',
    fontSize: { xs: '12px', sm: '18px' },
  },
  button_two: {
    bgcolor: '#CB2128',
    fontWeight: 400,
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      boxShadow: 'none',
    },
    mt: { xs: '0', md: 0 },
    boxShadow: 'none',
    color: 'white',
    width: '40px',
    height: '40px',
    textTransform: 'none',
    fontSize: '18px',
    ml: 0
  },
  headingText: {
    fontSize: { xs: '16px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },
  typographyStyle1: {
    fontFamily: 'Effra',
    fontSize: '22px',
    lineHeight: '26px',
    fontWeight: '400',
    marginRight: '10px',
    // marginBottom: "10px",
    // marginTop: "10px",
    // bgcolor: "red",
  },
}
