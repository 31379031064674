import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import MyReducer from './redux/login/reducer'
import createSagaMiddleware from '@redux-saga/core'
import App from './App'
import React from 'react'
import ReactDOM from 'react-dom/client'
import mySaga from './redux/login/saga'
import store from './redux/store'
import { BrowserRouter } from 'react-router-dom'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
)
