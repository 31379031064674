import { Api } from '../../config/request'

export const requestEventLogList = async (data) => {
  return Api.PostRequest('/log-activity/list', data)
}

const EventLogsApiRequests = {
    requestEventLogList,
 
}
export default EventLogsApiRequests
