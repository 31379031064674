import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import {
  ADMIN,
  ADMIN_ADD,
  ADMIN_EDIT,
  BULK_ORDERS,
  CLUB_PROFILE,
  CUSTOMERS,
  CUSTOMER_ACCOUNT,
  CUSTOMER_PROFILE,
  CUSTOMER_PROFILE_DETAIL,
  FEED,
  PLAYER,
  PLAYER_EDIT,
  PROFILE,
  PROFILE_UPDATE,
  PROGRAM,
  PROGRAM_ALL,
  PROGRAM_VIEW,
} from '../../routes/constURL'
import { useLocation, useNavigate } from 'react-router-dom'
import HeaderText from '../../Components/HeaderText'
import {
  tabsAdmin,
  tabsAdminBulkData,
  tabsClubAdminProfileData,
  tabsCustomerAccountData,
  tabsCustomerData,
  tabsCustomerProfile,
  tabsFeeds,
  tabsPlayerData,
  tabsProgramData,
  tabsProgramView,
} from '../../config/mockData'
import { tabsAdminProfileData } from '../../config/mockData'
import { storage } from '../../config/storage'
import ProfileTab from '../../Components/Tabs/ProfileTab'
import CustomerTab from '../../Components/Tabs/CustomerTab'
import BulkOrderTab from '../../Components/Tabs/BulkOrderTab'
import BackButton from '../../Components/CustomButton/BackButton'
import PlayerTab from '../../Components/Tabs/PlayerTab'
import Programs from '../../Customer/Pages/Programs'
import { getProgramListRequest } from '../../redux/program/programAction'
import { useDispatch } from 'react-redux'
import ViewProgram from '../../Customer/Pages/ViewProgram'
import { useSelector } from 'react-redux'
import TransformButton from '../../Components/CustomButton/TransformButton'
import Toastify from '../../Components/SnackBar/Toastify'
import WorkInProgress from './WorkInProgress'
import Payment from '../../Components/Payment'
import { getAdminDetailsRequest } from '../../redux/admins/adminAction'
import { getAdminProfileRequest } from '../../redux/customer_panel/actionCustomer'
import RevenueTab from '../../Components/Tabs/RevenueTab'

const Profile = () => {
  const { list } = useSelector((state) => state.ProgramData) || {}
  const { customerData } = useSelector((state) => state.CustomerPanelData) || {}
  const navigate = useNavigate()
  const myDispatch = useDispatch()
  const [e_mail, setEmail] = useState('')
  const [dataWeeks, setDataWeeks] = useState([])
  const location = useLocation()
  let URL = location.pathname
  const trimmedURL = URL.slice(0, 9)
  const trimmedURL1 = URL.slice(0, 12)
  const trimmedURL2 = URL.slice(0, 7)
  let id = storage.fetch.customerId()
  let adminid = storage.fetch.AdminId()
  let role = storage.fetch.role()
  let week = storage.fetch.week_number()
  console.log(trimmedURL1, trimmedURL, trimmedURL2)
  let weeksData = storage.fetch.program_weeks_list()

  useEffect(() => {
    if (trimmedURL1 === '/program/wee') {
      myDispatch(getProgramListRequest())
      myDispatch(getAdminProfileRequest())
      id = storage.fetch.customerId()
      // if (weeksData) {
    }
    // }
  }, [storage.fetch.customerId()])
  useEffect(() => {
    const newWeeksData =
      weeksData &&
      weeksData?.map((item, index) => {
        return {
          text: `Week ${index + 1}`,
          route: `${PROGRAM}/week/${index + 1}`,
          // route: `${PROGRAM}/week`,
          page: Programs,
        }
      })
    setDataWeeks(newWeeksData)
    if (
      location.pathname === PROFILE_UPDATE ||
      location.pathname === '/program/week/1'
    ) {
      storage.set.profileImage(
        customerData?.image == null
          ? require('./../../assets/profileRect.png')
          : customerData?.image,
      )
    }
  }, [list, customerData])

  // //console.log('dataWeeks', dataWeeks, weeksData)
  const getEmail = (email) => {
    setEmail(email)
  }
  const back =
    trimmedURL1 === '/program/wee' ||
    trimmedURL === FEED ||
    trimmedURL2 === '/accoun' ||
    trimmedURL1 == '/customer/pr'
  return (
    <>
      <Toastify />
      <Box
        sx={{
          display: { xs: 'flex', md: 'flex' },
          justifyContent: 'space-between',
        }}
      >
        <HeaderText
          headerText={
            trimmedURL1 === '/customer/pr'
              ? 'Update Profile'
              : trimmedURL2 === '/club/p'
                ? 'Club Admin Profile'
                : trimmedURL === CUSTOMERS
                  ? 'Customer Edit'
                  : trimmedURL === FEED
                    ? 'Feed'
                    : trimmedURL1 === BULK_ORDERS
                      ? 'Bulk Orders'
                      : trimmedURL2 === PLAYER
                        ? 'Player Edit'
                        : trimmedURL1 === '/program/wee'
                          ? 'Program Overview'
                          : trimmedURL1 === '/program/vie'
                            ? ''
                            : trimmedURL2 === '/accoun'
                              ? 'Account'
                              : 'Admin Profile'
          }
        />
        <Box sx={style.buttons}>
          {location.pathname === PROFILE_UPDATE && role === 'admin' ? (
            <TransformButton
              button_name={'Add Admin +'}
              transform={'skew(-22deg) translate(0%, -50%)'}
              color="rgb(45, 142, 66)"
              handleClick={() => navigate(ADMIN_ADD)}
            />
          ) : null}
          {back ? '' : <BackButton />}
        </Box>
      </Box>
      <Box sx={style.box}>
        {trimmedURL1 === '/customer/pr'
          ? tabsCustomerProfile.map((item, key) => {
              return (
                <ProfileTab
                  item_route={item.route}
                  URL={URL}
                  style={style.itembox}
                  item_text={item.text}
                />
              )
            })
          : trimmedURL === FEED
            ? tabsFeeds.map((item, key) => {
                return (
                  <ProfileTab
                    item_route={item.route}
                    URL={URL}
                    style={style.itembox}
                    item_text={item.text}
                  />
                )
              })
            : trimmedURL === CUSTOMERS
              ? tabsCustomerData.map((item, key) => {
                  return (
                    <CustomerTab
                      item_route={item.route}
                      id={id}
                      URL={URL}
                      style={style.itembox}
                      item_text={item.text}
                    />
                  )
                })
              : trimmedURL2 === PLAYER
                ? tabsPlayerData.map((item, key) => {
                    return (
                      <PlayerTab
                        item_route={item.route}
                        id={id}
                        URL={URL}
                        style={style.itembox}
                        item_text={item.text}
                      />
                    )
                  })
                : trimmedURL1 === BULK_ORDERS
                  ? tabsAdminBulkData.map((item, key) => (
                      <BulkOrderTab
                        item_route={item.route}
                        URL={URL}
                        style={style.itembox}
                        item_text={item.text}
                        id={id}
                      />
                    ))
                  : trimmedURL1 === '/club/profil'
                    ? tabsClubAdminProfileData.map((item, key) => (
                        <ProfileTab
                          item_route={item.route}
                          URL={URL}
                          style={style.itembox}
                          item_text={item.text}
                        />
                      ))
                    : trimmedURL2 === `${ADMIN}/`
                      ? tabsAdmin.map((item, key) => (
                          <BulkOrderTab
                            item_route={item.route}
                            id={adminid}
                            URL={URL}
                            style={style.itembox}
                            item_text={item.text}
                          />
                        ))
                      : trimmedURL2 === '/accoun'
                        ? tabsCustomerAccountData.map((item, key) => (
                            <ProfileTab
                              item_route={item.route}
                              URL={URL}
                              style={style.itembox}
                              item_text={item.text}
                            />
                          ))
                        : trimmedURL1 === '/program/wee'
                          ? dataWeeks &&
                            dataWeeks?.map((item, key) => (
                              <>
                                {/* {console.log('item', item)} */}
                                <ProfileTab
                                  item_route={item.route}
                                  URL={URL}
                                  style={style.itembox}
                                  item_text={item.text}
                                  index={week}
                                />
                              </>
                            ))
                          : trimmedURL1 === '/program/vie'
                            ? [
                                {
                                  text: `Week ${week} - Program`,
                                  route: PROGRAM_VIEW,
                                  page: ViewProgram,
                                },
                              ].map((item, key) => (
                                <ProfileTab
                                  item_route={item.route}
                                  // id={week}
                                  URL={PROGRAM_VIEW}
                                  style={style.itembox}
                                  item_text={item.text}
                                />
                              ))
                            : tabsAdminProfileData.map((item, key) => (
                                <ProfileTab
                                  item_route={item.route}
                                  URL={URL}
                                  style={style.itembox}
                                  item_text={item.text}
                                />
                              ))}
      </Box>
      {trimmedURL1 === '/customer/pr' ? (
        tabsCustomerProfile.map((Item, index) => {
          return URL === Item.route ? (
            <Item.page key={index} e_mail={e_mail} getEmail={getEmail} />
          ) : null
        })
      ) : trimmedURL === CUSTOMERS ? (
        tabsCustomerData.map((Item, index) => {
          return URL === Item.route + '/' + id ? (
            <Item.page key={index} e_mail={e_mail} getEmail={getEmail} />
          ) : null
        })
      ) : trimmedURL === FEED ? (
        tabsFeeds.map((Item, index) => {
          return URL === Item.route ? <Item.page key={index} /> : null
        })
      ) : trimmedURL1 === BULK_ORDERS ? (
        tabsAdminBulkData.map((Item, index) => {
          return URL === Item.route + '/' + id ? (
            <Item.page e_mail={e_mail} getEmail={getEmail} key={index} />
          ) : null
        })
      ) : trimmedURL1 === '/club/profil' ? (
        tabsClubAdminProfileData.map((Item, index) =>
          URL === Item.route ? (
            <Item.page e_mail={e_mail} getEmail={getEmail} key={index} />
          ) : null,
        )
      ) : trimmedURL2 === `${ADMIN}/` ? (
        tabsAdmin.map((Item, index) =>
          URL === Item.route + '/' + adminid ? (
            <Item.page e_mail={e_mail} getEmail={getEmail} key={index} />
          ) : null,
        )
      ) : trimmedURL2 === PLAYER ? (
        tabsPlayerData.map((Item, index) => {
          return URL === Item.route + '/' + id ? (
            <Item.page key={index} />
          ) : null
        })
      ) : trimmedURL2 === '/accoun' ? (
        tabsCustomerAccountData.map((Item, index) => {
          return URL === Item.route ? <Item.page key={index} /> : null
        })
      ) : trimmedURL1 === '/program/wee' ? (
        dataWeeks?.length !== 0 ? (
          dataWeeks?.map((Item, index) => {
            // //console.log('program')
            return URL === Item.route ? (
              <Item.page key={index} week={Item.text} />
            ) : null
          })
        ) : (
          <Payment />
        )
      ) : trimmedURL1 === '/program/vie' ? (
        tabsProgramView.map((Item, index) => {
          return PROGRAM_VIEW === Item.route ? <Item.page key={index} /> : null
        })
      ) : (
        tabsAdminProfileData.map((Item, index) =>
          URL === Item.route ? (
            <Item.page e_mail={e_mail} getEmail={getEmail} key={index} />
          ) : null,
        )
      )}
    </>
  )
}

export default Profile
const style = {
  box: {
    display: 'flex',
    width: '100%',
    bgcolor: '#F5F4FF',
    border: '1px solid #CDCAFC',
    flexWrap: { xs: 'wrap', md: 'unset' },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    width: { xs: 'auto', md: '40%' },
    gap: '30px',
    mr: { xs: 3, sm: 0 },
  },
  itembox: {
    fontSize: '12px',
    fontWeight: '600',
    boxShadow: 'none',
    p: '10px 25px 10px 25px',
    textAlign: { xs: 'center', sm: 'unset', md: 'center' },
  },
}
