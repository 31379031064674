import { Box, Button, Grid, Link, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import InputField from '../Components/InputField'
import TransformButton from '../Components/CustomButton/TransformButton'
import { storage } from '../config/storage'
import {
  getClubDetailsRequest,
  getClubUserDetailsRequest,
} from '../redux/clubs/clubAction'
import { useDispatch, useSelector } from 'react-redux'
import { getClubDashboardData, getExpiredLink } from '../redux/dashboard/dashboardAction'
import { getLoadingStatus } from '../redux/login/action'
import LoaderComponent from '../Components/Loader/LoaderComponent'
import { useFormik } from 'formik'
import { Schema } from '../Components/FormSchema/Schema'
import { toast } from 'react-toastify'
import Toastify from '../Components/SnackBar/Toastify'
import GridBox from '../Components/Box/GridBox'
import CustomFilledGrid from '../Components/CustomFilledGrid/CustomFilledGrid'
import AboutGrid from '../Components/CustomFilledGrid/AboutGrid'
import PaymentModel from '../Components/Modal/PaymentModel'
import { useNavigate } from 'react-router-dom'
import { CLUB_PROFILE, CLUB_PROFILE_UPDATE } from '../routes/constURL'
import CustomTableBody from '../Components/TableBox/CustomTableBody'
import { Table, TableBody } from '@mui/material'
import { EnhancedTableHead } from '../Components/TableHeader/TableHeader'
import { useReducer } from 'react'
import { reducerFunction, initialState } from '../config/mockData'
import CustomPagination from '../Components/Pagination'
import { alignProperty } from '@mui/material/styles/cssUtils'
import NoDataFound from '../Admin/Pages/NoDataFound'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
  background: '#EEF2FC',
  borderRadius: '10px',
  textAlign: 'center',
  padding: '20px',
  // paddingTop: '4%',
  height: '150px',
}))
const Item1 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
  background: '#F5F4FF',
  border: '1px solid #CDCAFC',
}))

const BulkDashboard = () => {
  const { clubDashboarData, clubExpiryLink , empty, loadingStatus} =
    useSelector((state) => state?.DashboardReducer) || {}
  const loading = useSelector((state) => state?.DashboardReducer?.loadingStatus)
  const name = storage.fetch.AdminName()
  const [data, setData] = useState()
  const [url, setUrl] = useState('')
  const [open, setOpen] = useState(false)
  const [list, setList] = useState([])
  const [aboutlist, setAboutlist] = useState([])
  const [linkData, setLinkData] = useState({
    email: '',
  })
  const myDispatch = useDispatch()
  const navigate = useNavigate()
  const payloadForOnlinePayment = {
    details: clubDashboarData?.userData,
  }
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  useEffect(() => {
    document.title = 'OG Sports - Dashboard'
    myDispatch(getExpiredLink())

    myDispatch(getClubDashboardData())
    myDispatch(getLoadingStatus(true))

    const headCells = [
      { label: 'Subscription Link', name: 'subscription_link' },
      { label: 'Expiry Date', name: 'expiry_date' },
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    setStateReducer({ columns: headCells })
  }, [])
  useEffect(() => {
    if (clubDashboarData != null) {
      setData(clubDashboarData?.userData)
      setUrl(data?.subscription_link)
      setList([
        { value: data?.num_of_licenses, name: 'Total licences purchased' },
        { value: data?.used_license, name: 'Used licences' },
        { value: data?.unused_license, name: 'Remaining licences' },
      ])
      setAboutlist([
        { value: data?.club_type, name: 'Type' },
        { value: data?.sports, name: 'Sport' },
      ])

      storage.set.profileImage(
        data?.image === null
          ? require('../assets/profileRect.png')
          : `${data?.image}`,
      )
    }
  }, [clubDashboarData, data])
  const formik = useFormik({
    initialValues: linkData,
    // validationSchema: Schema.ADMIN_PROFILE,
    onSubmit: (value) => {
      setLinkData(value)
      onSubmit(value)
    },
    enableReinitialize: true,
  })
  const onSubmit = (value) => {
    // ////console.log(value)
    let id = storage.fetch.customerId()
    let send = {
      email: value.email,
      users_id: id,
    }
    // myDispatch(getEmailSent(send))
    myDispatch(getLoadingStatus(false))
  }
  const copy = () => {
    try {
      // //console.log(url)
      navigator.clipboard.writeText(url)
      toast.success('URL copied Successfully.')
    } catch (err) {
      //console.error('Unable to copy to clipboard', err)
      toast.error('Error copying URL to clipboard.')
    }
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handlePay = () => {
    setOpen(true)
  }
  //console.log("Expiry Link--",clubExpiryLink);

  

  const handleRequestSort = (event, property) => {
    let isAsc = state.orderBy === property && state.order === 'asc'
    if (state.orderBy !== property) {
      setStateReducer({ order: 'desc', orderBy: property })
      data.sort_by = 'asc'
      data.sort_column = property
    } else {
      setStateReducer({ order: isAsc ? 'desc' : 'asc', orderBy: property })
      data.sort_by = state.order
      data.sort_column = property
    }
    if (state.search) {
      data.search_val = state.search
    }
    setStateReducer({ page: 1 })
  }


  const handlePageChange = (event, value) => {
    setStateReducer({ page: value })
    let a = (value - 1) * state.length

    data.start = a
    data.length = state.length

    myDispatch(getExpiredLink())
    myDispatch(getLoadingStatus(true))
  }

  return (
    <>
    <Grid container rowGap={3} sx={{ mb: 10 }}>
      <LoaderComponent open={loading} />
      <Grid>
        <Typography variant="h2" sx={Style.headingText}>
          Welcome, {data?.club_name} !
        </Typography>
      </Grid>
      <Grid container columnGap={3} rowGap={3}>
        <GridBox list={list} width={{ xs: '100%', md: '22%', lg: '22.64%', xl: '22.5%' }}/>
        <CustomFilledGrid
          list={[
            {
              title: 'Subscription Type',
              name: '',
              data:
                data?.membership_status === 'non-active'
                  ? 'No'
                  : data?.membership,
            },
          ]}
          radius={'10px'}
          width={'260px'}
          md={2.8}
        />
      </Grid>
      <PaymentModel
        club_admin_payment={true}
        handleClose={handleClose}
        open={open}
        details={payloadForOnlinePayment}
      />
      <Toastify/>
      <Grid xs={12}>
        <Item1 sx={Style.row}>
          <Box sx={Style.headertext}>Club Account Access</Box>
          <div
            onClick={() => {
              navigate(CLUB_PROFILE_UPDATE)
            }}
            style={Style.link}
          >
            View Profile
          </div>
        </Item1>
        {data?.membership_status === 'non-active' && (
          <Box sx={{ display: 'flex', mt: '20px' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              Pending payment :${data?.amount}
            </Typography>
            <Button
              sx={{
                border: '1px solid #CB2128',
                color: '#CB2128',
                textTransform: 'none',
                p: 0,
                ml: 2,
              }}
              onClick={() => handlePay()}
            >
              Pay
            </Button>
          </Box>
        )}

        {/* <Grid container columnGap={5} sx={{ pt: 2 }}>
          <Grid sx={Style.background}>
            <img
              style={{
                position: 'relative',
                left: '12%',
                top: '20px',
                width: '150px',
                height: '150px',
              }}
              src={data?.image}
            />
          </Grid>
          <Grid sx={Style.names} md={2}>
            <Typography sx={Style.club_name}>{data?.club_name}</Typography>
            <Typography sx={Style.name}>{data?.f_name}</Typography>
          </Grid>
          <Grid container columnGap={2} xs={12} md={7.57} sx={Style.outerbox}>
            <AboutGrid list={aboutlist} md={3} />
          </Grid>
        </Grid> */}
        <Grid>
          <Grid container md={12} sx={{ pt: 4 }}>
            <Grid
              xs={9}
              md={10.5}
              sx={{
                userSelect: 'none',
              }}
              className="dashboard"
            >
              <InputField
                value={data?.subscription_link}
                // onChange={(e) => {
                //   setUrl(e.target.value)
                // }}
                disabled={true}
                type={'text'}
                placeholder={'Club Login/Signup Link'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
                name1={'Club Login/Signup Link'}
                required={'*'}
                edit_update={true}
                formlabel={'Club Login/Signup Link'}
                mt={0}
              />
            </Grid>
            <Grid md={1.5} xs={3} sx={{...Style.copybutton,'&.MuiButton-root:hover': {
      bgcolor: '#2D8E42',
    }}}>
              <Button
                className="btn-rt-skw"
                variant="contained"
                disabled={
                  data?.membership_status === 'non-active' ? true : false
                }
                sx={{...Style.button_one,'&.MuiButton-root:hover': {
      bgcolor: '#2D8E42',
    }}}
                onClick={() => copy()}
              >
                <Typography sx={{'&.MuiButton-root:hover': {
      bgcolor: '#2D8E42',
    },}}>COPY</Typography>
              </Button>
            </Grid>
            {data?.membership_status === 'non-active' && (
              <Typography sx={{ color: '#CB2128', mt: 2 }}>
                Note: Please complete your Pending payment to Activate your
                membership
              </Typography>
            )}
          </Grid>
          {/* <form name="sendLink" onSubmit={formik.handleSubmit}>
            <Grid container columnGap={12} sx={{ mt: 3 }}>
              <Grid xs={12} md={4}>
                <InputField
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Enter email address'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'email'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Send Login/Signup Link'}
                  mt={0}
                />
              </Grid>

              <Grid sx={Style.button1}>
                <TransformButton typeSubmit={'submit'} button_name={'SEND'} />
              </Grid>
            </Grid>
          </form> */}
        </Grid>
      </Grid>
    </Grid>
    
    <Box sx={{...Style1.table.tableWrapBox,mb:'3%'}}>
          <Table sx={Style1.table.tableBox} aria-labelledby="tableTitle">
            <EnhancedTableHead
              columns={state.columns}
              setColumns={setStateReducer}
              order={state.order}
              orderBy={state.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={state.rows.length}
              action={true}
            />
            <TableBody sx={{ border: 0 }}>
            { clubExpiryLink?.total_records!==0 && clubExpiryLink &&           
              clubExpiryLink.list?.map((row, ind) => (
                <CustomTableBody
                  key={row?.id}
                  row={row}
                  // loadingStatus={loadingStatus}
                  ind={ind}
                  columns={[
                    row?.signup_link,
                    row?.expiry_date,
                  ]}
                  
                />
              ))}
            {/* {loadingStatus && (
              <ListSkelton
                loadingStatus={loadingStatus}
                rows={5}
                columns={4}
                width={'140px'}
              />
            )} */}
          </TableBody>
          {clubExpiryLink?.total_records===0 && (
            <TableRow>
                  <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                    No Data Found
                  </TableCell>
                </TableRow>
          )}
          </Table>
        </Box>
        <CustomPagination
        state={state}
        empty={empty}
        handlePageChange={handlePageChange}
        />
        </>
  )
}

export default BulkDashboard
const Style = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #CDCAFC',
    marginBottom: '5px',
    width: { xs: '100%', md: '98%' },
  },
  names: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    ml: { xs: 5, md: 0 },
  },
  name: {
    color: '#333',
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 500,
  },
  club_name: {
    color: '#27245E',
    fontSize: '26px',
    fontWeight: 700,
  },
  box: {
    display: 'flex',
    justifyContent: { xs: 'none', md: 'flex-end' },
    width: '100%',
    bgcolor: '#F5F4FF',
    border: '1px solid #CDCAFC',
    flexWrap: { xs: 'wrap', sm: 'unset' },
    mt: { xs: '10px', md: 0 },
  },
  outerbox: {
    display: 'flex',
    justifyContent: 'flex-end',
    mt: { xs: 5, md: 0 },
    p: 3,
  },
  headertext: {
    color: 'white',
    backgroundColor: 'rgb(216, 0, 39)',
    fontSize: '14px',
    fontWeight: '400',
    boxShadow: 'none',
    p: '5px',
    // p: '10px 25px 10px 25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: { xs: '40%', md: '20%' },
  },
  button: {
    background: 'red',
    color: 'white',
    textTransform: 'none',
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      boxShadow: 'none',
    },
  },
  button1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    pt: { xs: 0, md: 3 },
  },
  text: {
    color: 'red',
    fontSize: '20px',
    fontWeight: '700',
  },
  text1: {
    color: '#27245E',
  },
  text2: {
    color: 'rgb(216, 0, 39)',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: { xs: '100%', md: '23%' },
  },
  item1: {
    width: { xs: '100%' },
  },
  link: {
    textDecoration: 'none',
    border: 'none',
    color: '#27245E',
    textAlign: 'center',
    margin: '10px',
    cursor: 'pointer',
  },
  headingText: {
    fontSize: { xs: '14px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },
  background: {
    bgcolor: '#27245E',
    width: { xs: '35%', md: '12%' },
    minHeight: '150px',
  },

  button_one: {
    bgcolor: '#2D8E42',
    color: 'white',
    height: '60%',
    p: 0,
    transform: 'none',
  },
  copybutton: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'end',
    justifyContent: 'flex-end',
    '&.MuiButton-root:hover': {
      bgcolor: '#2D8E42',
    },
    
  },
  innerGrid: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    bgcolor: 'white',
    borderRadius: '10px',
    // padding: '5px',
    mt: 1,
    textAlign: 'center',
  },
  lastGrid: {
    bgcolor: '#27245E',
    borderRadius: '10px',
    color: 'white',
    width: { xs: '100%', md: '23%' },
    textAlign: 'center',
    padding: { xs: '50px', md: '15px' },
    pt: { xs: 10, md: 5 },
  },
  innerItem: {
    // padding: '2%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
}

const Style1 ={
  box: {
    textAlign: 'center',
    mt: '15%',
  },
  text: {
    fontSize: '24px',
    fontWeight: '700',
    color: 'rgb(39, 36, 94)',
  },
  table: {
    tableWrapBox: {
      overflow: 'auto',
      // p: { xs: 1, sm: 3 },
    },
    tableBox: {
      minWidth: { md: '100%' },
      // border: '1px solid #dddddd',
      borderRadius: '25px',
    },
    SubscriptiontableBox: {
      width: { xs: '775px', md: '800px', lg: '900px' },
      // border: '1px solid #dddddd',
      borderRadius: '25px',
    },
    tableCell: {
      width: '50px',
      maxWidth: '80px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: '13px',
      color: '#707070',
      fontWeight: '600',
      padding: '20px',
      border: '0px',
      textTransform: 'capitalize',
      textAlign:'right'
    },
    tableCell1: {
      minWidth: '131px',
      fontSize: '13px',
      color: '#707070',
      fontWeight: '600',
      justifyContent: 'center',
      border: '0px',
      display: 'flex',
      textTransform: 'capitalize',
      marginRight: '50px',
    },
    tableCell2: {
      width: '50px',
      maxWidth: '100px',
      textOverflow: 'ellipsis',
      display: 'flex',
      whiteSpace: 'nowrap',
      // overflow: 'hidden',
      fontSize: '13px',
      color: '#707070',
      fontWeight: '600',
      padding: '20px',
      border: '0px',
      textTransform: 'capitalize',
    },
    button: {
      backgroundColor: '#EEF2FC',
      minWidth: '40px',
      borderRadius: '20px 0px 0px 20px',
      color: '#2D8E42',
      padding: '3px 10px 3px 10px',
      maxWidth: '100%',
      margin: '0px',
      fontSize: '16px',
      border: '1px solid #C3CDFF',
    },
    button2: {
      backgroundColor: '#2D8E42',
      minWidth: '40px',
      borderRadius: '0px 20px 20px 0px',
      color: '#fff',
      padding: '3px 10px 3px 10px',
      maxWidth: '100%',
      margin: '0px',
      fontSize: '16px',
      border: '1px solid #2D8E42',
    },
    headingText: {
      fontSize: { xs: '14px', sm: '31px' },
      fontWeight: '700',
      color: '#000000',
      // p: { xs: 1, sm: 3 },
      display: 'flex',
      alignItems: 'center',
    },
  },
  button: {
    textTransform: 'none',
    borderRadius: '5px',
    pl: 4,
    pr: 4,
    bgcolor: '#F15F23',
    color: 'white',
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#F15F23',
    },
  },
  paginationBox: {
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'space-between',
    p: 1,
  },
  viewButton: {
    width: '19px',
    height: '19px',
    marginRight: '10px',
    cursor: 'pointer',
    marginBottom: '3px',
    color: '#CB2128',
  },
  editButton: {
    width: '19px',
    height: '19px',
    cursor: 'pointer',
    marginBottom: '3px',
    color: '#CB2128',
    marginRight:'7px'
  },

}
