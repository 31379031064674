import { Box, Table, TableBody } from '@mui/material'
import React, { useEffect, useLayoutEffect, useReducer, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { EnhancedTableHead } from '../../Components/TableHeader/TableHeader'
import { Style } from '../CSS/Table'
import Toastify from '../../Components/SnackBar/Toastify'
import { storage } from '../../config/storage'
import { getCustomerListRequest } from '../../redux/customer/customerAction'
import TopBox from '../../Components/TableHeader/TopBox'
import { useState } from 'react'
import {
  CUSTOMERS_ADD_NEW,
  CUSTOMERS_INFO_DETAILS,
  CUSTOMERS_INFO_EDIT,
} from '../../routes/constURL'
import { getLoadingStatus } from '../../redux/login/action'
import NoDataFound from './NoDataFound'
import ListSkelton from '../../Components/Loader/ListSkelton'
import CustomTableBody from '../../Components/TableBox/CustomTableBody'
import CustomPagination from '../../Components/Pagination'
import { status_arr } from '../../config/mockData'
import LoaderComponent from '../../Components/Loader/LoaderComponent'

//---State for handling data & payloads---//
const initialState = {
  order: 'asc',
  orderBy: '',
  page: 1,
  pages: 0,
  total: '',
  rows: [],
  status: 'Select Status',
  membership: 'Select Program',
  columns: [],
  startFrom: 0,
  length: 5,
  statusNew: 'Select Status',
  memberNew: 'Select Program',
  searched: false,
  search:''
}

//---Setter function---//
function reducerFunction(prevState, nextState) {
  return { ...prevState, ...nextState }
}
const CustomersList = () => {
  const { list, empty, loadingStatus } = useSelector(
    (state) => state?.CustomerData || {},
  )
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const navigate = useNavigate()
  const myDispatch = useDispatch()
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  // const [isResetDisabled, setResetDisabled] = useState(true);
  let data = {
    start: 0,
    length: state.length,
    sort_column: '',
    sort_by: '',
    search_val: '',
    role: 'player',
  }

  useEffect(() => {
    document.title = 'OG Sports - Customers'
    setStateReducer({ memberNew: state.membership, statusNew: state.status })
  }, [state.searched])
  ///---Side effect function when change in list length(Pagination)---///
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    myDispatch(getCustomerListRequest(data))
    myDispatch(getLoadingStatus(true))
    const headCells = [
      { label: 'Customer Name', name: 'customerName' },
      { label: 'Membership', name: 'Membership' },
      { label: 'Created On', name: 'created_on' },
      { label: 'Membership Status', name: 'Status' },
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    setStateReducer({ columns: headCells })
  }, [state.length])
  ///---Side effect function when change in list---///
  useLayoutEffect(() => {
    setStateReducer({ pages: list?.pages, total: list?.total_records })
  }, [list])
  ///---Sorting function---///
  const handleRequestSort = (event, property) => {

    setStateReducer({ page: state.page })
    let a = (state.page - 1) * state.length
    data = {
      start: a,
      length: state.length,
    }

    let isAsc = state.orderBy === property && state.order === 'asc'
    if (state.orderBy !== property) {
      setStateReducer({ order: 'desc', orderBy: property })
      data.sort_by = 'asc'
      data.sort_column = property
    } else {
      setStateReducer({ order: isAsc ? 'desc' : 'asc', orderBy: property })
      data.sort_by = state.order
      data.sort_column = property
    }
    if (state.search) {
      data.search_val = state.search
    }
    Object.assign(data, {
      membership: state.memberNew == 'Select Program' ? '' : state?.membership,
      membership_status: state.statusNew == 'Select Status' ? '' : state?.status,
    })
    myDispatch(getCustomerListRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  ///---Pagination Function---///
  const handlePageChange = (event, value) => {
    setStateReducer({ page: value })
    let a = (value - 1) * state.length
    data = {
      start: a,
      length: state.length,
    }
    if (state.order && state.orderBy) {
      data.sort_by = state.order === 'asc' ? 'desc' : 'asc'
      data.sort_column = state.orderBy
    }
    if (state.search) {
      data.search_val = state.search
    }
    Object.assign(data, {
      membership: state.memberNew == 'Select Program' ? '' : state?.memberNew,
      membership_status: state.statusNew == 'Select Status' ? '' : state?.statusNew,
    })
    myDispatch(getCustomerListRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  ///---Seach & Filter Function---///
  const handleChangeSelect = (e, type) => {
    switch (type) {
      case 'status':
        return setStateReducer({ status: e.target.value })
      case 'member':
        return setStateReducer({ membership: e.target.value })
      case 'search':
        return setStateReducer({ search: e.target.value })
      case 'search_click':
        setStateReducer({ memberNew: state.membership, statusNew: state.status })

        Object.assign(data, {
          search_val: state.search,
          membership:
            state.membership === 'Select Program' ? '' : state.membership,
          membership_status:
            state.status === 'Select Status' ? '' : state.status,
        })
        if (state.order && state.orderBy) {
          data.sort_by = state.order === 'asc' ? 'desc' : 'asc'
          data.sort_column = state.orderBy
        }
        myDispatch(getCustomerListRequest(data))
        myDispatch(getLoadingStatus(true))
        return setStateReducer({ page: 1 })
      default:
        return null
    }
  }
  ///---Navigation to edit page---///
  const navigateTo = (id) => {
    storage.set.customerId(id)
    navigate(CUSTOMERS_INFO_EDIT + '/' + id, {
      state: id,
    })
  }

  useEffect(() => {
    console.log("Useeffect1")
    // Check if any two drop-downs have default values
    const isDefaultStatus = state.status === 'Select Status';
    const isDefaultMembership = state.membership === 'Select Program';
    const isDefaultSearch = state.search === '';
    console.log("is",isDefaultSearch);
    // If any two drop-downs have default values, disable the button
    setButtonDisabled(isDefaultStatus && isDefaultMembership &&isDefaultSearch );
    console.log("hey",isButtonDisabled);
  }, [state.status, state.membership,state.search]);

  // useEffect(() => {
  //   console.log("Useeffect2")
  //   // Check if both drop-downs have default values
  //   const isDefaultStatus = state.status === 'Select Status';
  //   const isDefaultMembership = state.membership === 'Select Program';
  //   const isDefaultSearchR = state.search === '';
  //   // If both drop-downs have default values, disable the reset button
  //   setResetDisabled(isDefaultStatus && isDefaultMembership && isDefaultSearchR);
  // }, [state.status, state.membership,state.search]);

  const handleReset = () => {
    window.scrollTo(0, 0)
    myDispatch(getCustomerListRequest(data))
    myDispatch(getLoadingStatus(true))

    setStateReducer({
      status: 'Select Status',
      membership: 'Select Program', order: 'asc',
      orderBy: '',
      page: 1,
      pages: 0,
      statusNew: 'Select Status',
      memberNew: 'Select Program',
      search:''
    })
  }
  return (
    <>
      <TopBox
        headerText={'Customers'}
        button_one={'Add +'}
        searchText={'Search customer'}
        handleChangeFirst={(e) => handleChangeSelect(e, 'status')}
        handleChangeSecond={(e) => handleChangeSelect(e, 'member')}
        sortingText={'Filter by'}
        initialValue={'Select Program'}
        menuData={['1 Month Program', '3 Months Program']}
        value1={state.status}
        value2={state.membership}
        button_two={() => handleChangeSelect('', 'search_click')}
        button_one_onClick={() => {
          navigate(CUSTOMERS_ADD_NEW)
        }}
        width={'20%'}
        select_arr1={status_arr}
        onReset={handleReset}
        // disabledReset={isResetDisabled}
        disabledVal={isButtonDisabled}
        search_According_To_routes1={true}
        setSearch_val={(e) => handleChangeSelect(e, 'search')}
        search_val={state.search}
      />
      {empty && <NoDataFound />}
      <LoaderComponent open={loadingStatus} />
      <Box
        sx={[Style.table.tableWrapBox, { display: empty ? 'none' : 'flex' }]}
      >
        <Table sx={Style.table.tableBox} aria-labelledby="tableTitle">
          <EnhancedTableHead
            columns={state.columns}
            setColumns={setStateReducer}
            order={state.order}
            orderBy={state.orderBy}
            onRequestSort={handleRequestSort}
            rowCount={state.rows.length}
          />
          <TableBody sx={{ border: 0 }}>
            {list &&
              !loadingStatus &&
              list.user_list?.map((row, ind) => (
                <CustomTableBody
                  membership_status={true}
                  key={row?.users_id}
                  URL={CUSTOMERS_INFO_DETAILS}
                  navigateTo={navigateTo}
                  row={row}
                  loadingStatus={loadingStatus}
                  ind={ind}
                  columns={[
                    row?.user_name,
                    row?.user_membership.length != 0
                      ? row?.user_membership
                      : 'none',
                    row?.user_created_at,
                  ]}
                />
              ))}
            {loadingStatus && (
              <ListSkelton
                loadingStatus={loadingStatus}
                rows={5}
                columns={5}
                width={'100px'}
              />
            )}
          </TableBody>
        </Table>
      </Box>
      <Toastify />
      <CustomPagination
        state={state}
        empty={empty}
        handlePageChange={handlePageChange}
      />
    </>
  )
}

export default CustomersList