import { put, takeEvery } from 'redux-saga/effects'
import {
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  GET_EMAIL_SENT,
  SET_EMAIL_SENT,
  GET_CANCEL_SUBSCRIPTION,
  GET_UPGRADE_SUBSCRIPTION,
  SET_CANCEL_SUBSCRIPTION,
  SET_UPGRADE_SUBSCRIPTION,
  GET_SUBSCRIPTION_LIST,
  SET_SUBSCRIPTION_LIST,
  SET_REFER_LINK,
  SET_SEND_REFER_LINK,
  GET_REFER_LINK,
  GET_SEND_REFER_LINK,
} from '../ConstAction'
import { toast } from 'react-toastify'

import MyAccountApiRequests from '../../services/myaccount'
let data = {
  start: 0,
  length: 5,
  sort_column: '',
  sort_by: '',
  search_val: '',
  role: 'player',
}
///-----*Functions*-----///
function* HandleApiResponse(response, successAction, errorMessage, refresh) {
  console.log(response.data)
  if (response.data.status) {
    if (refresh) {
      yield put({
        type: refresh,
        data: data,
      })
    }
    yield put({
      type: successAction,
      response,
    })
  } else if (response.data.code === 303) {
    yield put({
      type: GET_EMPTY_STATUS,
      data: true,
    })

    console.log(response.data.message)
    toast.error(response?.data?.message || errorMessage)
    if (refresh) {
      console.log(refresh)
      yield put({
        type: refresh,
        data: data,
      })
    }
  } else {
    console.log('response', response.data.message)
    toast.error(response?.data?.errors[0] || errorMessage)
  }
  yield put({
    type: GET_LOADING_STATUS,
    data: false,
  })
}

function* getEmailSent(data) {
  try {
    let response = yield MyAccountApiRequests.requestEmailSent(data.data)
    yield HandleApiResponse(response, SET_EMAIL_SENT, 'Failed to fetch')
  } catch (err) {}
}
function* getCancelSubscription(data) {
  try {
    let response = yield MyAccountApiRequests.requestCancelSubscription(
      data.data,
    )
    yield HandleApiResponse(
      response,
      SET_CANCEL_SUBSCRIPTION,
      'Failed to fetch',
      GET_SUBSCRIPTION_LIST,
    )
  } catch (err) {}
}
function* getUpgradeMembership(data) {
  try {
    let response = yield MyAccountApiRequests.requestUpgradeMembership(
      data.data,
    )
    yield HandleApiResponse(
      response,
      SET_UPGRADE_SUBSCRIPTION,
      'Failed to fetch',
      GET_SUBSCRIPTION_LIST,
    )
  } catch (err) {}
}
function* getSubscriptionList(data) {
  try {
    let response = yield MyAccountApiRequests.requestSubscriptionList(data.data)
    yield HandleApiResponse(
      response,
      SET_SUBSCRIPTION_LIST,
      'Failed to fetch list',
    )
  } catch (err) {}
}
function* getReferLink(data) {
  try {
    let response = yield MyAccountApiRequests.requestGetReferalLink(data.data)
    yield HandleApiResponse(response, SET_REFER_LINK, 'Failed to get link')
  } catch (err) {}
}
function* sendReferLink(data) {
  try {
    let response = yield MyAccountApiRequests.requestSendReferalLink(data.data)
    toast.success(response?.data?.message)
    yield HandleApiResponse(
      response,
      SET_SEND_REFER_LINK,
      'Failed to send link',
    )
  } catch (err) {}
}

function* SagaMyAccount() {
  yield takeEvery(GET_EMAIL_SENT, getEmailSent)
  yield takeEvery(GET_CANCEL_SUBSCRIPTION, getCancelSubscription)
  yield takeEvery(GET_UPGRADE_SUBSCRIPTION, getUpgradeMembership)
  yield takeEvery(GET_SUBSCRIPTION_LIST, getSubscriptionList)
  yield takeEvery(GET_REFER_LINK, getReferLink)
  yield takeEvery(GET_SEND_REFER_LINK, sendReferLink)
}

export default SagaMyAccount
