import {
  Button,
  Grid,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useReducer, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Style } from '../../Admin/CSS/Table'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  ACCOUNT,
  ADMIN,
  BULK_ORDERS,
  BULK_ORDERS_LISTING,
  CUSTOMERS_JUMPING_EDIT,
  CUSTOMERS_SUBSCRIPTION,
  CUSTOMER_ACCOUNT,
  CUSTOMER_LISTING,
  DASHBOARD,
  LOG_EVENTS,
  PLAYER_LISTING,
  REVENUE,
  REVENUE_FAILED,
  REVENUE_REFUND,
  REVENUE_REPORTS,
  CLUBDASHBOARD
} from '../../routes/constURL'
import ModalBox from '../Modal/ModalBox'
import { useDispatch } from 'react-redux'
import {
  getRefundRequest,
  getRevenueDetailsRequest,
  getRevenueListRequest,
} from '../../redux/revenue/revenueAction'
import TransformButton from '../CustomButton/TransformButton'
import { getLoadingStatus } from '../../redux/login/action'
import { initialState, reducerFunction } from '../../config/mockData'
import AlertDialog from '../Dialog/Dialog'
let data = {
  start: 0,
  length: 5,
  sort_column: '',
  sort_by: '',
  search_val: '',
}
const CustomTableBody = ({
  row,
  ind,
  loadingStatus,
  columns,
  navigateTo,
  URL,
  handleDelete,
  handlePay,
  membership_status,
  page,
  payment_mode,
  date,
  sport,
  search_val,
  type
}) => {
  let user_id = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const myDispatch = useDispatch()
  const trimmedURL1 = location.pathname.slice(0, 27)
  const handleRefund = (id) => {
    //console.log('id', id)
    myDispatch(
      getRefundRequest({
        payment_id: id,
        page: page,
        users_id: user_id.id,
        active: state.checked,
        payment_mode: payment_mode === 'Payment Mode' ? "" :payment_mode === 'Online' ? 'online' : payment_mode === 'Offline' ? 'offline' : '',
        date:date,
        sport:sport === 'Select Sport' ? ""  : sport,
        search_val:search_val,
        type:type === 'Select Type' ? "" : type === 'Bulk Order' ? 'bulk' : type === 'Individual' ? 'individual' :'',
        navigate,
      }),
      myDispatch(getLoadingStatus(true)),
    )
    // setTimeout(() => {
    //   myDispatch(getRevenueDetailsRequest())
    // }, 1000)
  }
  const handleOpen = (id) => {
    setStateReducer({ id: id, alert: true })
  }
  //console.log(trimmedURL1)
  return (
    <>
      {' '}
      <AlertDialog
        title={'Are you sure you want to refund to this user?'}
        open={state.alert}
        onClickButton={() => handleRefund(state.id)}
        onClickButtonCancel={() => setStateReducer({ alert: false })}
        handlecheck={() => setStateReducer({ checked: !state.check })}
      />
      <TableRow
        sx={{
          border: 0,
          bgcolor: (ind + 1) % 2 === 0 ? '#EEF2FC' : 'white',
          maxWidth: '100%',
        }}
      >
        {columns.map((item, index) => (
          <React.Fragment key={index}>
            {location.pathname === BULK_ORDERS_LISTING && index === 3 ? (
              <TableCell align="left" sx={Style.table.tableCell1}>
                <p style={Style.table.button}>
                  {!loadingStatus && row?.used_license}
                </p>
                <p style={Style.table.button2}>
                  {!loadingStatus && row?.unused_license}
                </p>
                {/* </Box> */}
              </TableCell>
            ) : location.pathname === BULK_ORDERS_LISTING && index === 4 ? (
              <></>
            ) : location.pathname === REVENUE_REPORTS && index === 8 ? (
              <TableCell
                align="left"
                sx={Style.table.tableCell}
                style={{
                  color:
                    item === 'success'
                      ? '#2D8E42'
                      : item === 'refunded'
                        ? '#FF7100'
                        : '#CB2128',
                }}
              >
                {!loadingStatus && item}
              </TableCell>
            ) : location.pathname === REVENUE_REPORTS ||
              location.pathname === REVENUE_FAILED ||
              (location.pathname === REVENUE_REFUND && index === 7) ? (
              <TableCell
                align="left"
                sx={Style.table.tableCell}
                style={{
                  color: item === 'online' ? '#2D8E42' : '#27245E',
                }}
              >
                {!loadingStatus && item}
              </TableCell>
            ) : (location.pathname === CUSTOMER_ACCOUNT ||
                trimmedURL1 === CUSTOMERS_SUBSCRIPTION) &&
              index === 7 ? (
              <TableCell
                align="left"
                sx={Style.table.tableCell}
                style={{
                  color:
                    row.membership_status == 'non-active'
                      ? '#FFC700'
                      : '#2D8E42',
                }}
              >
                {!loadingStatus && row?.membership_status}
              </TableCell>
            ) : location.pathname === BULK_ORDERS_LISTING && index === 6 ? (
              <TableCell
                align="left"
                sx={Style.table.tableCell}
                style={{
                  color:
                    row.membership_status == 'non-active'
                      ? '#FFC700'
                      : '#2D8E42',
                }}
              >
                {!loadingStatus && row?.membership_status}
              </TableCell>
            ) : location.pathname === ADMIN && index === 3 ? (
              <TableCell
                align="left"
                sx={Style.table.tableCell}
                style={{
                  color: row.status == 'non-active' ? '#FFC700' : '#2D8E42',
                }}
              >
                {!loadingStatus && row?.status}
              </TableCell>
            ) : (
              <TableCell align="left" sx={Style.table.tableCell}>
                <Tooltip title={item}>
                  {!loadingStatus && item === null
                    ? '--'
                    : item === 'standard_admin'
                      ? 'Standard Admin'
                      : item}
                </Tooltip>
              </TableCell>
            )}
          </React.Fragment>
        ))}
        {membership_status && (
          <TableCell
            align="left"
            sx={Style.table.tableCell}
            style={{
              color:
                row.user_membership_status == 'non-active'
                  ? '#FFC700'
                  : '#2D8E42',
            }}
          >
            {!loadingStatus && row?.user_membership_status}
          </TableCell>
        )}
        {location.pathname === BULK_ORDERS_LISTING && (
          <TableCell align="left" sx={Style.table.tableCell}>
            {row?.membership_status === 'active' ? (
              <>
                <Typography
                  sx={{
                    color: '#2D8E42',
                  }}
                >
                  Paid
                </Typography>
              </>
            ) : (
              <Button
                sx={{
                  border: '1px solid #CB2128',
                  color: '#CB2128',
                  textTransform: 'none',
                  p: 0,
                  // mt: 2,
                }}
                onClick={() =>
                  handlePay({
                    details: {
                      id: row?.users_id,
                      email: row?.email,
                      amount: row?.amount,
                    },
                  })
                }
              >
                PAY
              </Button>
            )}
          </TableCell>
        )}
        {location.pathname !== DASHBOARD &&
          location.pathname !== REVENUE_REPORTS &&
          location.pathname !== CUSTOMER_ACCOUNT &&
          trimmedURL1 !== CUSTOMERS_SUBSCRIPTION &&
          location.pathname !== LOG_EVENTS &&
          location.pathname !== REVENUE_FAILED &&
          location.pathname !== REVENUE_REFUND && 
          location.pathname !== CLUBDASHBOARD &&(
            <TableCell align="left" sx={Style.table.tableCell}>
              {!loadingStatus && (
                <>
                  {location.pathname !== BULK_ORDERS_LISTING && (
                    <VisibilityIcon
                      style={{
                        width: '19px',
                        height: '19px',
                        marginRight: '10px',
                        cursor: 'pointer',
                        marginBottom: '3px',
                        color: '#CB2128',

                        display: location.pathname !== ADMIN ? 'unset' : 'none',
                      }}
                      onClick={() => {
                        navigate(URL + '/' + row?.users_id, {
                          state: row?.users_id,
                        })
                      }}
                    />
                  )}
                  <EditIcon
                    style={Style.editButton}
                    onClick={() => navigateTo(row?.users_id)}
                  />
                  <DeleteIcon
                    style={{
                      width: '19px',
                      height: '19px',
                      cursor: 'pointer',
                      marginBottom: '3px',
                      display:
                        location.pathname === PLAYER_LISTING ||
                        location.pathname === ADMIN ||
                        location.pathname === BULK_ORDERS_LISTING
                          ? ''
                          : 'none',
                      color: '#CB2128',
                    }}
                    onClick={() => handleDelete(row?.users_id)}
                  />
                </>
              )}
            </TableCell>
          )}
        {/* {location.pathname == CUSTOMER_ACCOUNT && (
        <TableCell align="left" sx={Style.table.tableCell}>
          {!loadingStatus && (
            <>
              <Grid
                sx={{
                  display: 'flex',
                }}
              >
                <Button variant='contained' sx={{bgcolor:'#27245E',textTransform:'none'}}>Upgrade</Button>
                <Button variant='contained' sx={{bgcolor:'#D80027',textTransform:'none'}}>Cancel</Button>
              </Grid>
            </>
          )}
        </TableCell>
      )} */}
        {location.pathname === REVENUE_REPORTS && (
          <TableCell align="left" sx={Style.table.tableCell}>
            <Button
              sx={{
                border: '1px solid #CB2128',
                color: '#CB2128',
                textTransform: 'none',
                display:
                  location.pathname === REVENUE_REPORTS &&
                  row?.user_payment_status === 'success'
                    ? 'flex'
                    : 'none',
                p: 0,
              }}
              onClick={() => handleOpen(row?.payment_id)}
            >
              Refund
            </Button>
          </TableCell>
        )}
        {trimmedURL1 === CUSTOMERS_SUBSCRIPTION && (
          <TableCell align="left" sx={Style.table.tableCell}>
            <Button
              sx={{
                border: '1px solid #CB2128',
                color: '#CB2128',
                textTransform: 'none',
                display:
                  trimmedURL1 === CUSTOMERS_SUBSCRIPTION &&
                  row?.user_payment_status === 'success'
                    ? 'flex'
                    : 'none',
                p: 0,
              }}
              onClick={() => handleRefund(row?.payment_id)}
            >
              Refund
            </Button>
          </TableCell>
        )}
      </TableRow>
    </>
  )
}

export default CustomTableBody
