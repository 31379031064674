import { put, takeEvery } from 'redux-saga/effects'
import {
  GET_EMPTY_STATUS,
  GET_EVENT_LOG_LIST,
  GET_LOADING_STATUS,
  SET_EVENT_LOG_LIST,
} from '../ConstAction'
import { toast } from 'react-toastify'
import EventLogsApiRequests from '../../services/event_logs'
///-----*Functions*-----///
function* HandleApiResponse(
  response,
  successAction,
  errorMessage,
  navigate,
  nav,
  message,
) {
  if (response.status) {
    if (navigate) {
      setTimeout(() => {
        nav.navigate(navigate)
        nav.handleClose()
      }, 2000)
    }
    if (message) {
      toast.success(message)
    }
    yield put({
      type: successAction,
      response,
    })
  } else if (response.code == 303) {
    yield put({
      type: GET_EMPTY_STATUS,
      data: true,
    })
    toast.error(response?.errors || errorMessage)
  } else {
    toast.error(response?.data?.errors[0] || errorMessage)
  }
  yield put({
    type: GET_LOADING_STATUS,
    data: false,
  })
}

function* getEventList(data) {
  try {
    let response = yield EventLogsApiRequests.requestEventLogList(data.data)
    yield HandleApiResponse(
      response,
      SET_EVENT_LOG_LIST,
      'Failed to fetch event log list',
    )
  } catch (err) {}
}

function* EventSaga() {
  yield takeEvery(GET_EVENT_LOG_LIST, getEventList)
}

export default EventSaga
