import React, { useEffect, useLayoutEffect, useReducer } from 'react'
import { EnhancedTableHead } from '../../Components/TableHeader/TableHeader'
import { Box, Table, TableBody } from '@mui/material'
import CustomTableBody from '../../Components/TableBox/CustomTableBody'
import ListSkelton from '../../Components/Loader/ListSkelton'
import Toastify from '../../Components/SnackBar/Toastify'
import CustomPagination from '../../Components/Pagination'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getLoadingStatus } from '../../redux/login/action'
import { initialState, reducerFunction } from '../../config/mockData'
import { Style } from '../CSS/Table'
import TopBox from '../../Components/TableHeader/TopBox'
import { getEventLogList } from '../../redux/event_logs/eventAction'
import { json } from 'react-router-dom'
import NoDataFound from './NoDataFound'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import { useState } from 'react'

const LogEvents = () => {
  // (function() {
  //   var message = "Hello, world!";
  //   ////console.log(message);
  // })();
  // ;(() => {
  //   var message = 'Hello, world'
  //   // //console.log(message);
  //   return () => {
  //     return () => {
  //       //console.log(message)
  //     }
  //   }
  // })()()()
  const { list, empty, loadingStatus } = useSelector(
    (state) => state?.EventData || {},
  )
  // //console.log('action', empty)
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const myDispatch = useDispatch()
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  // const [isResetDisabled, setResetDisabled] = useState(true)
  let data = {
    start: 0,
    length: state.length,
    sort_column: '',
    sort_by: '',
    search_val: '',
  }
  useEffect(() => {
    document.title = 'OG Sports - Event Log'
    setStateReducer({ event_typeNew: state.event_type })
  }, [state.searched])
  ///---Side effect function when change in list length(Pagination)---///
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    myDispatch(getEventLogList(data))
    myDispatch(getLoadingStatus(true))
    const headCells = [
      { label: 'Created By', name: 'created_by' },
      { label: 'Log Events Information', name: 'log_information_event' },
      { label: 'Log Date', name: 'log_date' },
      { label: 'Log Time', name: 'log_time' },
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    setStateReducer({ columns: headCells })
  }, [state.length])
  ///---Side effect function when change in list---///
  useLayoutEffect(() => {
    setStateReducer({ pages: list?.pages, total: list?.total_records })
  }, [list])

  ///---Sorting function---///
  const handleRequestSort = (event, property) => {

    setStateReducer({ page: state.page })
    let a = (state.page - 1) * state.length
    data = {
      start: a,
      length: state.length,
    }
    
    let isAsc = state.orderBy === property && state.order === 'asc'
    if (state.event_type !== 'Event type') {
      Object.assign(data, { type: state.event_type })
    }
    if (state.date !== '') {
      Object.assign(data, { date: state.date })
    }
    if (state.search !== '') {
      Object.assign(data, { search_val: state.search })
    }
    Object.assign(data, {
      page: state.page,
      type: state.event_type === 'Event type' ? '' : state?.event_type,
    })
    if (state.orderBy !== property) {
      setStateReducer({ order: 'desc', orderBy: property })
      data.sort_by = 'asc'
      data.sort_column = property
    } else {
      setStateReducer({ order: isAsc ? 'desc' : 'asc', orderBy: property })
      data.sort_by = state.order
      data.sort_column = property
    }
    Object.assign(data, {
      type: state.event_typeNew == 'Event type' ? '' : state?.event_type,
    })
    myDispatch(getEventLogList(data))
    myDispatch(getLoadingStatus(true))
  }
  ///---Pagination Function---///
  const handlePageChange = (event, value) => {
    setStateReducer({ page: value })
    let a = (value - 1) * state.length
    if (state.event_type !== 'Event type') {
      Object.assign(data, { type: state.event_type })
    }
    if (state.date !== '') {
      Object.assign(data, { date: state.date })
    }
    if (state.search !== '') {
      Object.assign(data, { search_val: state.search })
    }
    setStateReducer({ order: state.order, orderBy: state.orderBy })
    data.start = a
    data.length = state.length
    if (state.order && state.orderBy) {
      data.sort_by = state.order === 'asc' ? 'desc' : 'asc'
      data.sort_column = state.orderBy
    }
    Object.assign(data, {
      type: state.event_typeNew === 'Event type' ? '' : state?.event_typeNew,
    })
    myDispatch(getEventLogList(data))
    myDispatch(getLoadingStatus(true))
  }
  const handleChangeSelect = (e, type) => {
    switch (type) {
      case 'event_type':
        // //console.log(e.target.value)
        return setStateReducer({
          event_type: e.target.value,
        })
      case 'date':
        return setStateReducer({
          date: e.target.value,
        })
      case 'search':
        return setStateReducer({ search: e.target.value })
      case 'search_click':
        setStateReducer({ event_typeNew: state.event_type })
        Object.assign(data, {
          search_val: state.search,
          type: state.event_type === 'Event type' ? '' : state.event_type,
          date: state.date,
        })

        myDispatch(getEventLogList(data))
        myDispatch(getLoadingStatus(true))
        return setStateReducer({ page: 1 })
      default:
        return null
    }
  }
  //   Array.prototype.myCustomMap = function (param) {
  //     let newArray = []
  //     for (let index = 0; index < this.length; index++) {
  //       if (param(this[index], index, this))
  //         newArray.push(this[index])
  //     }
  //     return newArray
  //   }
  //   const val = [1, 2, 3, 4, 5]
  //   const val2 = val.myCustomMap((item, i) => {
  //     return item >2
  //   })
  //   //console.log('val2', val2)
  // const newNumber=[2, 4, 5, 6,2,3,4, 8]
  // const mapArray=newNumber.sort((a,b)=>a-b)
  // let prevNumber=Array.from(new Set(mapArray))

  // for(let i=0;i<newNumber.length;i++){

  // }
  // //console.log(prevNumber)

  useEffect(() => {
    const isDefaultDate = state.date === '' || state.date === null
    const isDefaultSearch = state.search === '' || state.search === undefined
    const isDefaultEvent = state.event_type === 'Event type'
    // If any two drop-downs have default values, disable the button
    setButtonDisabled(isDefaultDate && isDefaultSearch && isDefaultEvent)
  }, [state.date, state.search, state.event_type])

  // useEffect(() => {
  //   // Check if both drop-downs have default values
  //   // const isDefaultStatus = state.status === 'Select Status';
  //   // const isDefaultMembership = state.membership === 'Select Program';
  //   // If both drop-downs have default values, disable the reset button
  //   //console.log("date",state.date,state);
  //   const isDefaultDateR = state.date === '' || state.date === null
  //   const isDefaultSearchR = state.search === ''
  //   const isDefaultEventR = state.event_type === 'Event type'

  //   setResetDisabled(isDefaultDateR && isDefaultSearchR && isDefaultEventR)
  // }, [state.date, state.search, state.event_type])

  const handleReset = () => {
    window.scrollTo(0, 0)
    myDispatch(getEventLogList(data))
    myDispatch(getLoadingStatus(true))

    setStateReducer({
      // status: 'Select Status',
      // membership: 'Select Program', order: 'asc',
      orderBy: '',
      page: 1,
      pages: 0,
      date: '',
      search: '',
      event_type: 'Event type',
      event_typeNew: 'Event type'
    })
  }

  return (
    <div>
      <TopBox
        headerText={'Log Events'}
        search_According_To_routes={true}
        // button_one={'Add +'}
        searchText={'Search Player'}
        setSearch_val={(e) => handleChangeSelect(e, 'search')}
        search_val={state.search}
        search={true}
        date={state.date}
        handleChangeType={(e) => handleChangeSelect(e, 'event_type')}
        handleDateChange={(e) => handleChangeSelect(e, 'date')}
        // sortingText={'Filter by'}
        // width={'15%'}
        value3={state.event_type}
        value1={state.sport}
        // value2={select.membership}
        button_two={() => handleChangeSelect('', 'search_click')}
        button_one_onClick={() => {
          //   navigate(CUSTOMERS_ADD_NEW)
        }}
        // onClick={handleOpen}
        select_arr2={[
          'Event type',
          'User',
          'Payment',
          'Offline payment',
          'Refund',
        ]}
        // select_arr1={sportsData}

        onReset={handleReset}
        disabledVal={isButtonDisabled}
        // disabledReset={isResetDisabled}
      />

      <LoaderComponent open={loadingStatus} />
      <Box
        sx={[Style.table.tableWrapBox, { display: empty ? 'none' : 'flex' }]}
      >
        {list?.logs_list?.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <NoDataFound />
          </Box>
        ) : (
          <Table sx={Style.table.tableBox} aria-labelledby="tableTitle">
            <EnhancedTableHead
              columns={state.columns}
              setColumns={setStateReducer}
              order={state.order}
              orderBy={state.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={state.rows.length}
              action={true}
            />
            <TableBody sx={{ border: 0 }}>
              {console.log(empty)}

              {list &&
                !loadingStatus &&
                list?.logs_list?.map((row, ind) => (
                  <CustomTableBody
                    key={row?.users_id}
                    row={row}
                    loadingStatus={loadingStatus}
                    ind={ind}
                    columns={[
                      row?.created_by,
                      row?.subject,
                      row?.formate_date,
                      row?.time,
                    ]}
                  />
                ))}
              {loadingStatus && (
                <ListSkelton
                  loadingStatus={loadingStatus}
                  rows={5}
                  columns={4}
                  width={'140px'}
                />
              )}
            </TableBody>
          </Table>
        )}
      </Box>
      <Toastify />
      <CustomPagination
        state={state}
        empty={empty}
        handlePageChange={handlePageChange}
      />
    </div>
  )
}

export default LogEvents
