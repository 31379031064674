import {
  GET_ALL_LIST,
  GET_CUSTOMER_LIST,
  GET_REFUND,
  GET_REVENUE_ADD,
  GET_REVENUE_DETAILS,
  GET_REVENUE_LIST,
} from '../ConstAction'
export const getRevenueListRequest = (data) => {
  return {
    type: GET_REVENUE_LIST,
    data: data,
  }
}
export const getRevenueDetailsRequest = (data) => {
  return {
    type: GET_REVENUE_DETAILS,
    data: data,
  }
}

export const addrevenueoffline = (data, navigate, handleClose) => {
  return {
    type: GET_REVENUE_ADD,
    data: data,
    navigate: navigate,
    handleClose: handleClose,
  }
}
export const getCustomerListRequest = (data) => {
  return {
    type: GET_CUSTOMER_LIST,
    data: data,
  }
}
export const getRefundRequest = (data, navigate) => {
  return {
    type: GET_REFUND,
    data: data,
    navigate: navigate,
  }
}
export const getRevenueallListRequest = (data, navigate) => {
  return {
    type: GET_ALL_LIST,
    data: data,
    navigate: navigate,
  }
}
