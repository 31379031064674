import {
  GET_CLUB_ADD_UPDATE,
  SET_CLUB_DETAILS,
  GET_CLUB_LIST,
  GET_CLUB_DETAILS,
  GET_CLUB_USER_DETAILS,
  GET_EMAIL_SENT,
  GET_CLUB_DELETE,
  GET_BULK_PAYMENT,
  GET_CLUB_ADMIN_PAYMENT,
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_NUMBER,
} from '../ConstAction'

export const getClubListRequest = (data) => {
  return {
    type: GET_CLUB_LIST,
    data: data,
  }
}
export const getClubDetailsRequest = (data) => {
  return {
    type: GET_CLUB_DETAILS,
    data: data,
  }
}
export const getClubAddUpdateRequest = (data) => {
  return {
    type: GET_CLUB_ADD_UPDATE,
    data: data,
  }
}
export const getClubUserDetailsRequest = (data) => {
  return {
    type: GET_CLUB_USER_DETAILS,
    data: data,
  }
}

export const getClubDelete = (data) => {
  return {
    type: GET_CLUB_DELETE,
    data: data,
  }
}

export const bulkorederspayment = (
  data,
  navigate,
  handleClose,
  payloadForPagination,
) => {
  return {
    type: GET_BULK_PAYMENT,
    data: data,
    navigate: navigate,
    handleClose: handleClose,
    payloadForPagination: payloadForPagination,
  }
}
export const getnotifications = (data) => {
  return {
    type: GET_NOTIFICATIONS,
    data: data,
  }
}
export const getnotificationnum = (data) => {
  // console.log('data', data)
  return {
    type: GET_NOTIFICATION_NUMBER,
    data: data,
  }
}
