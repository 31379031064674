import {
  GET_ADMIN_DELETE,
  GET_ADMIN_ADD_UPDATE,
  GET_ADMIN_DETAILS,
  GET_ADMIN_LIST,
} from '../ConstAction'

export const getAdminListRequest = (data) => {
  return {
    type: GET_ADMIN_LIST,
    data: data,
  }
}
export const getAdminDetailsRequest = (data) => {
  return {
    type: GET_ADMIN_DETAILS,
    data: data,
  }
}
export const getAdminAddUpdateRequest = (data, navigate) => {
  return {
    type: GET_ADMIN_ADD_UPDATE,
    data: data,
    navigate: navigate,
  }
}
export const getAdminDelete = (data) => {
  return {
    type: GET_ADMIN_DELETE,
    data: data,
  }
}
