import * as yup from 'yup'
import { storage } from '../../config/storage'

var regExp = new RegExp(storage.fetch.PostalDetails()?.postal_length)
//console.log('String----', typeof regExp, regExp)
// const regExp = "^[0-9]{4}$";
export const Schema = {
  ADMIN_PROFILE: yup.object().shape({
    f_name: yup.string().required('Please enter your first name'),
    l_name: yup.string().required('Please enter your last name'),
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter valid email'),
    mobile: yup
      .string()
      .required('Please enter your phone number')
      .matches(/^[0-9]*$/, 'Please enter valid phone number')
      .min(10, `Enter minimum 10 numbers `)
      .max(10, `Enter maximum 10 numbers`),
    age: yup
      .number()
      .required('Please enter your age')
      .integer('Please enter a valid number')
      .positive('Please enter a positive integer')
      .min(10, 'Enter minimum 10')
      .max(100, 'Enter maximum 100')
      .typeError('Age must be a number'),
  }),
  CHANGE_PASS: yup.object().shape({
    password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Password is too short - should be 8 char minimum.'),
    password_confirmation: yup
      .string()
      .required('Confirm your password.')
      .min(8, 'Password is too short - should be 8 char minimum.')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  }),
  CUSTOMER_PROFILE: yup.object().shape({
    f_name: yup.string().required('Please enter your first name'),
    l_name: yup.string().required('Please enter your last name'),
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter valid email'),
    mobile: yup
      .string()
      .required('Please enter your phone number')
      .matches(/^[0-9]*$/, 'Please enter valid phone number')
      .min(10, `Enter minimum 10 numbers `)
      .max(10, `Enter maximum 10 numbers`),
    age: yup
      .number()
      .required('Please enter your age')
      .integer('Please enter a valid number')
      .positive('Please enter a positive integer')
      .min(10, 'Enter minimum 10')
      .max(100, 'Enter maximum 100')
      .typeError('Age must be a number'),
    sports: yup.string().required('Please select a sport'),
    team: yup.string().required('Please enter team name'),
    membership: yup
      .string()
      .notOneOf(['Select Program'], 'Please select a program')
      .required('Program is required'),
  }),
  PLAYER_PROFILE: yup.object().shape({
    f_name: yup.string().required('Please enter your first name'),
    l_name: yup.string().required('Please enter your last name'),
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter valid email'),
    mobile: yup
      .string()
      .required('Please enter your phone number')
      .matches(/^[0-9]*$/, 'Please enter valid phone number')
      .min(10, `Enter minimum 10 numbers `)
      .max(10, `Enter maximum 10 numbers`),
    age: yup
      .number()
      .required('Please enter your age')
      .integer('Please enter a valid number')
      .positive('Please enter a positive integer')
      .min(10, 'Enter minimum 10')
      .max(100, 'Enter maximum 100')
      .typeError('Age must be a number'),
    sports: yup.string().required('Please select a sport'),
    team: yup.string().required('Please enter team name'),
    // membership: yup
    //   .string()
    //   .notOneOf(['Select Program'], 'Please select a program')
    //   .required('Program is required'),
  }),
  CUSTOMER_ADD_PROFILE: yup.object().shape({
    f_name: yup.string().required('Please enter your first name'),
    l_name: yup.string().required('Please enter your last name'),
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter valid email'),
    mobile: yup
      .string()
      .required('Please enter your phone number')
      .matches(/^[0-9]*$/, 'Please enter valid phone number')
      .min(10, `Enter minimum 10 numbers `)
      .max(10, `Enter maximum 10 numbers`),
    age: yup
      .number()
      .required('Please enter your age')
      .integer('Please enter a valid number')
      .positive('Please enter a positive integer')
      .min(10, 'Enter minimum 10')
      .max(100, 'Enter maximum 100')
      .typeError('Age must be a number'),
    sports: yup.string().required('Please select a sport'),
    team: yup.string().required('Please enter team name'),
    membership: yup
      .string()
      .notOneOf(['Select Program'], 'Please select a program')
      .required('Program is required'),
    password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Password is too short - should be 8 char minimum.'),

    password_confirmation: yup
      .string()
      .required('Confirm your password.')
      .min(8, 'Password is too short - should be 8 char minimum.')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  }),
  ADMIN_LOGIN: yup.object().shape({
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter valid email'),
    password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Password is too short - should be 8 char minimum.'),
  }),
  MEASUREMENTUPDATE: yup.object().shape({
    armspan: yup.string().required('Please enter your armspan'),
    // .matches(/^[0-9]*$/, 'Please enter valid armspan'),
    init_height: yup.string().required('Please enter your initial height'),
    int_weight: yup.string().required('Please enter your initial weight'),
  }),

  MEASUREMENTADD: yup.object().shape({
    measure_date: yup
      .date('Please select a date')
      .required('Please enter a date'),
    height: yup
      .string()
      .required('Please enter your height')
      .matches(/^[0-9]*$/, 'Please enter valid height'),
    weight: yup
      .string()
      .required('please enter your weight')
      .matches(/^[0-9]*$/, 'Please enter valid weight'),
  }),

  JUMPING: yup.object().shape({
    init_jump_height: yup
      .string()
      .required('Please enter intial standing jumping result')
      .matches(/^[0-9]*$/, 'Please enter valid standing jumping result'),
    // select_date: yup.string().required('Please select the date'),
    // add_jumping_result: yup.string().required('Please add jumping Results'),
  }),
  JUMPING_ADD: yup.object().shape({
    // initial_standing: yup
    //   .string()
    //   .required('Please enter intial standing jumping results'),
    jump_date: yup.date('Please select a date').required('Please enter a date'),
    jump_height: yup
      .string()
      .required('Please add jumping Results')
      .matches(/^[0-9]*$/, 'Please enter valid standing jumping result'),
  }),
  BULK_ORDER: yup.object().shape({
    club_name: yup.string().required('Please enter name'),
    name: yup.string().required('Please enter owner name'),
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter valid email'),
    mobile: yup
      .string()
      .required('Please enter your phone number')
      .matches(/^[0-9]*$/, 'Please enter valid phone number')
      .min(10, `Enter minimum 10 numbers `)
      .max(10, `Enter maximum 10 numbers`),
    amount: yup
      .mixed()
      .transform((originalValue, originalObject) => {
        // Attempt to cast the value to a number
        const castedValue = Number(originalValue)

        // Check if the casted value is NaN (not a number)
        if (isNaN(castedValue)) {
          return undefined // Set it to undefined to trigger Yup's validation error
        }

        // Return the casted value
        return castedValue
      })
      .test(
        'is-valid-number',
        'Please enter a valid number',
        (value) => !isNaN(value),
      ),
    sports: yup.string().required('Please enter sport'),
    num_of_licenses: yup
      .number()
      .typeError('Please enter a valid number')
      .required('Please enter number of licences')
      .positive('Number of licenses must be a positive number')
      .integer('Number of licenses must be an integer'),
    membership: yup
      .string()
      .notOneOf(['Select Program'], 'Please select a program')
      .required('Program is required'),
  }),
  ADD_BULK_ORDER: yup.object().shape({
    club_name: yup.string().required('Please enter name'),
    name: yup.string().required('Please enter owner name'),
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter valid email'),
    mobile: yup
      .string()
      .required('Please enter your phone number')
      .matches(/^[0-9]*$/, 'Please enter valid phone number')
      .min(10, `Enter minimum 10 numbers `)
      .max(10, `Enter maximum 10 numbers`),
    amount: yup
      .mixed()
      .transform((originalValue, originalObject) => {
        // Attempt to cast the value to a number
        const castedValue = Number(originalValue)

        // Check if the casted value is NaN (not a number)
        if (isNaN(castedValue)) {
          return undefined // Set it to undefined to trigger Yup's validation error
        }

        // Return the casted value
        return castedValue
      })
      .test(
        'is-valid-number',
        'Please enter a valid number',
        (value) => !isNaN(value),
      ),
    // .integer('Please enter a valid number')
    // .positive('Please enter a positive integer')
    // .min(1, 'Enter minimum 1'),
    // sports: yup.string().required('Please enter'),
    num_of_licenses: yup
      .number()
      .typeError('Please enter a valid number')
      .required('Please enter number of licences')
      .positive('Number of licenses must be a positive number')
      .integer('Number of licenses must be an integer'),
    // subscription_link: yup.string(),
    password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Password is too short - should be 8 char minimum.'),

    password_confirmation: yup
      .string()
      .required('Confirm your password.')
      .min(8, 'Password is too short - should be 8 char minimum.')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    sports: yup.string().required('Please enter sports'),
    membership: yup
      .string()
      .notOneOf(['Select Program'], 'Please select a program')
      .required('Program is required'),
  }),
  EDIT_BULK_ORDER: yup.object().shape({
    club_name: yup.string().required('Please enter name'),
    name: yup.string().required('Please enter owner name'),
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter valid email'),
    mobile: yup
      .string()
      .required('Please enter your phone number')
      .matches(/^[0-9]*$/, 'Please enter valid phone number')
      .min(10, `Enter minimum 10 numbers `)
      .max(10, `Enter maximum 10 numbers`),
  }),
  CUSTOM_SIGNUP: yup.object().shape({
    f_name: yup.string().required('Please enter your first name'),
    // l_name: yup.string().required('Please enter your last name'),
    // address: yup.string().required('Please enter address'),
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter valid email'),
    password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Password is too short - should be 8 char minimum.'),

    password_confirmation: yup
      .string()
      .required('Confirm your password.')
      .min(8, 'Password is too short - should be 8 char minimum.')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    // name_on_card: yup.string().required('Please enter your Full name(on card)'),
    card_number: yup
      .string()
      .required('Please enter your card number')
      .test(
        'is-numeric',
        'Card number must be an integer and characters are not allowed',
        (value) => {
          if (!value) return false
          return /^\d+$/.test(value) // Check if the value consists of only digits
        },
      )
      .min(12, 'Card number must be at least 12 digits')
      .max(16, 'Card number must be at most 16 digits'),
    card_cvc: yup
      .string()
      .required('Please enter your CVV')
      .test(
        'is-numeric',
        'CVV must be an integer and characters are not allowed',
        (value) => {
          if (!value) return false
          return /^\d+$/.test(value)
        },
      )
      .min(3, 'CVV must be exactly 3 digits')
      .max(3, 'CVV must be exactly 3 digits'),
    // postal_code: yup
    //   .string()
    //   .required('Please enter Postal Code')
    //   .test(
    //     'is-number',
    //     'Postal code must be a number',
    //     (value) => /^\d+$/.test(value), // Check if the value consists of only digits
    //   )
    //   .min(5, 'Postal code must be at least 5 digits')
    //   .max(8, 'Postal code must be at most 8 digits'),
    postal_code: yup.string().required("Postal Code is Required").min(4).max(11),
    // .test(
    //   'matches-regexp',
    //   'Postal code must match the specified format',
    //   function (value) {
    //     // Test if the value matches the regular expression
    //     return regExp.test(value)
    //   },
    // )
    // city: yup.string().required('Please enter your city'),
    sports: yup.string().required('Sport is required'),
    country: yup
      .string()
      .notOneOf(['Select Country'], 'Please select a country')
      .required('Country is required'),
    // state: yup
    //   .string()
    //   .notOneOf(['Select State'], 'Please select a state')
    //   .required('Please enter your state'),
    exp: yup.string().required('Expiration is required'),
    check: yup.bool().oneOf([true], 'Please Accept Terms and Conditions'),
    check1: yup.bool().oneOf([true], 'Please Accept Privacy Policy'),
  }),
  CLUB_SIGNUP: yup.object().shape({
    f_name: yup.string().required('Please enter your first name'),
    l_name: yup.string().required('Please enter your last name'),
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter valid email'),
    password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Password is too short - should be 8 char minimum.'),

    password_confirmation: yup
      .string()
      .required('Confirm your password.')
      .min(8, 'Password is too short - should be 8 char minimum.')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    sports: yup.string().required('Please enter sports'),
  }),
  FORGOT_PASSWORD: yup.object().shape({
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter valid email'),
  }),

  RESET_PASSWORD: yup.object().shape({
    password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Password is too short - should be 8 char minimum.'),

    password_confirmation: yup
      .string()
      .required('Please Confirm your password.')
      .min(8, 'Password is too short - should be 8 char minimum.')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  }),
  SIGNUP_VIA_PAYMENT: yup.object().shape({
    f_name: yup.string().required('Please enter name'),
    l_name: yup.string().required('Please enter owner name'),
    address: yup.string().required('Please enter address'),
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter valid email'),
    mobile: yup
      .string()
      .required('Please enter your phone number')
      .matches(/^[0-9]*$/, 'Please enter valid phone number')
      .min(10, `Enter minimum 10 numbers `)
      .max(10, `Enter maximum 10 numbers`),
    name_on_card: yup.string().required('Please enter name on card'),
    card_number: yup
      .string()
      .required('Please enter your card number')
      .test(
        'is-numeric',
        'Card number must be an integer and characters are not allowed',
        (value) => {
          if (!value) return false
          return /^\d+$/.test(value) // Check if the value consists of only digits
        },
      )
      .min(12, 'Card number must be at least 12 digits')
      .max(16, 'Card number must be at most 16 digits'),
    password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Password is too short - should be 8 char minimum.'),

    password_confirmation: yup
      .string()
      .required('Confirm your password.')
      .min(8, 'Password is too short - should be 8 char minimum.')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  }),
  ADDOFFLINEPAYMENTS: yup.object().shape({
    amount: yup
      .mixed()
      .transform((originalValue, originalObject) => {
        // Attempt to cast the value to a number
        const castedValue = Number(originalValue)

        // Check if the casted value is NaN (not a number)
        if (isNaN(castedValue)) {
          return undefined // Set it to undefined to trigger Yup's validation error
        }

        // Return the casted value
        return castedValue
      })
      .test(
        'is-valid-number',
        'Please enter a valid number',
        (value) => !isNaN(value),
      ),
    date: yup
      .date('Please select a valid date')
      .required('Please select Payment Date'),
  }),
  ONLINEPAYMENTS: yup.object().shape({
    // amount: yup.string().required('Please enter your first name'),
    // l_name: yup.string().required('Please enter your last name'),?
    card_number: yup
      .string()
      .required('Please enter your card number')
      .test(
        'is-numeric',
        'Card number must be an integer and characters are not allowed',
        (value) => {
          if (!value) return false
          return /^\d+$/.test(value) // Check if the value consists of only digits
        },
      )
      .min(12, 'Card number must be at least 12 digits')
      .max(16, 'Card number must be at most 16 digits'),
    card_cvc: yup
      .string()
      .required('Please enter your CVV')
      .test(
        'is-numeric',
        'CVV must be an integer and characters are not allowed',
        (value) => {
          if (!value) return false
          return /^\d+$/.test(value)
        },
      )
      .min(3, 'CVV must be exactly 3 digits')
      .max(3, 'CVV must be exactly 3 digits'),
    // zip: yup.string().required('Please enter Postal Code').min(5).max(8),
    exp: yup.string().required('Expiration is required'),
  }),
  ADMIN_ADD_PROFILE: yup.object().shape({
    f_name: yup.string().required('Please enter your first name'),
    l_name: yup.string().required('Please enter your last name'),
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter valid email'),
    mobile: yup
      .string()
      .required('Please enter your phone number')
      .matches(/^[0-9]*$/, 'Please enter valid phone number')
      .min(10, `Enter minimum 10 numbers `)
      .max(10, `Enter maximum 10 numbers`),
    age: yup
      .number('Please enter a valid number')
      .required('Please enter your age')
      .integer('Please enter a valid number')
      .positive('Please enter a positive integer')
      .min(10, 'Enter minimum 10')
      .typeError('Age must be a number')
      .max(100, 'Enter maximum 100'),
    role: yup
      .string()
      .notOneOf(['Select'], 'Please select a role')
      .required('Role is required'),
    password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Password is too short - should be 8 char minimum.'),

    password_confirmation: yup
      .string()
      .required('Confirm your password.')
      .min(8, 'Password is too short - should be 8 char minimum.')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  }),
  ADMIN_EDIT_PROFILE: yup.object().shape({
    f_name: yup.string().required('Please enter your first name'),
    l_name: yup.string().required('Please enter your last name'),
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter valid email'),
    mobile: yup
      .string()
      .required('Please enter your phone number')
      .matches(/^[0-9]*$/, 'Please enter valid phone number')
      .min(10, `Enter minimum 10 numbers `)
      .max(10, `Enter maximum 10 numbers`),
    age: yup
      .number()
      .required('Please enter your age')
      .integer('Please enter a valid number')
      .positive('Please enter a positive integer')
      .min(10, 'Enter minimum 10')
      .max(100, 'Enter maximum 100')
      .typeError('Age must be a number'),
    role: yup
      .string()
      .notOneOf(['Select'], 'Please select a role')
      .required('Role is required'),
  }),
}
