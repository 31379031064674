import React, { useReducer } from 'react'
import TransformButton from '../CustomButton/TransformButton'
import {
  Box,
  Button,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import InputField from '../InputField'
import styled from '@emotion/styled'
import { CloseOutlined } from '@mui/icons-material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Schema } from '../FormSchema/Schema'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  initialState,
  months,
  reducerFunction,
  generateYears,
} from '../../config/mockData'
import SelectComponent from '../SelectComponent'
import { useDispatch, useSelector } from 'react-redux'
import { bulkorederspayment } from '../../redux/clubs/clubAction'
import { getLoadingStatus } from '../../redux/login/action'
import { useNavigate } from 'react-router-dom'
import { clubAdminpayment } from '../../redux/dashboard/dashboardAction'
import Toastify from '../SnackBar/Toastify'
import LoaderComponent from '../Loader/LoaderComponent'

const years = generateYears()
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const defaultValue = {
  amount: '',
  email: '',
  card_number: '',
  card_cvc: '',
  zip: '',
  exp: '',
}

const PaymentModel = ({
  open,
  handleClose,
  details,
  club_admin_payment,
  payloadForPagination,
}) => {
  const { loadingStatus } = useSelector((state) => state?.ClubData) || {}
  const myDispatch = useDispatch()
  const navigate = useNavigate()
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const { control, formState, handleSubmit, reset, setError } = useForm({
    mode: 'onChange',
    defaultValue,
    resolver: yupResolver(Schema.ONLINEPAYMENTS),
  })
  const CustomDropdownIcon = (props) => {
    return <ExpandMoreIcon {...props} />
  }
  const handleChangeSelect = (e, type) => {
    switch (type) {
      case 'month':
        return setStateReducer({
          month: e?.target?.value,
          monthserr:
            e?.target?.value === 'Select month' ? 'Please select month' : '',
        })
      case 'year':
        return setStateReducer({
          year: e?.target?.value,
          yearerr:
            e?.target?.value === 'Select year' ? 'Please select year' : '',
        })
      case 'state':
        return setStateReducer({ state: e?.target?.value, stateerr: '' })
      case 'country':
        //console.log(e.target.value)
        return setStateReducer({
          country: e?.target?.value,
          // country_code: e?.target?.value,
          countryerr: '',
        })
      default:
        return null
    }
  }
  const handleKeyDown = (event) => {
    console.log(event)
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']
    if (!allowedKeys.includes(event.key) && isNaN(Number(event.key))) {
      event.preventDefault()
    }
  }

  const handleChange = (event) => {
    let value = event.target.value
    value = value
      .replace(/^([1-9]\/|[2-9])$/g, '0$1/')
      .replace(/^(0[1-9]|1[0-2])$/g, '$1/')
      .replace(/^([0-1])([3-9])$/g, '0$1/$2')
      .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2')
      .replace(/^([0]+)\/|[0]+$/g, '0')
      .replace(/[^\d\/]|^[\/]*$/g, '')
      .replace(/\/\//g, '/')

    event.target.value = value
  }

  const { isValid, dirtyFields, errors } = formState
  const onSubmit = (value) => {
    const [month, year] = value.exp.split('/')

    Object.assign(value, {
      card_exp_month: month,
      card_exp_year: year,
      email: details?.details?.email,
      amount: details?.details?.amount,
    })
    if (club_admin_payment == true) {
      myDispatch(clubAdminpayment(value, handleClose))
      myDispatch(getLoadingStatus(true))
    } else {
      //console.log(payloadForPagination)
      myDispatch(
        bulkorederspayment(value, navigate, handleClose, payloadForPagination),
      )
      myDispatch(getLoadingStatus(true))
      reset(defaultValue)
      setStateReducer({ month: 'Select month', year: 'Select year' })
    }
  }
  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )

  const handleModalClose = () => {
    reset(defaultValue) // Reset form fields
    handleClose() // Call the original handleClose function
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={Style.modal}>
        {/* <Toastify /> */}
        <Box sx={Style.header}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: 'black', fontWeight: '700' }}
          >
            Payment
          </Typography>
          <Button onClick={handleModalClose}>
            <CloseOutlined sx={{ color: 'grey' }} />
          </Button>
        </Box>
        <LoaderComponent open={loadingStatus} />
        <form
          name="OfflineRevenue"
          style={{ minWidth: '360px' }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            sx={{
              width: '100%',
              p: 4,
              pt: 2,
              overflow: 'scroll',
            }}
            className="payment"
          >
            {/* <LoaderComponent open={newState?.loadingStatus} /> */}

            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} sm={6}>
                <Item>
                  <Box sx={{ mb: '0px' }}>
                    <InputField
                      value={details?.details?.email}
                      type={'text'}
                      disabled={true}
                      placeholder={'Please enter email'}
                      size={{ xs: '18px', md: '18px', lg: '18px' }}
                      color={'#333333'}
                      name1={'email'}
                      required={'*'}
                      formlabel={'Email'}
                      edit_update={true}
                    />
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <Box sx={{ mb: '0px' }}>
                    <InputField
                      value={details?.details?.amount}
                      type={'text'}
                      disabled={true}
                      placeholder={'Please enter email'}
                      size={{ xs: '18px', md: '18px', lg: '18px' }}
                      color={'#333333'}
                      name1={'amount'}
                      required={'*'}
                      formlabel={'Amount'}
                      edit_update={true}
                    />
                  </Box>
                </Item>
              </Grid>
              <Grid item order={{ xs: 6, sm: 0 }} xs={12} sm={6}>
                <Item>
                  <Box sx={{ mb: '0px' }}>
                    <InputField
                      control={control}
                      helperText={errors?.card_number?.message}
                      errors={!!errors.card_number}
                      type={'number'}
                      placeholder={'Please enter card number'}
                      size={{ xs: '18px', md: '18px', lg: '18px' }}
                      color={'#333333'}
                      name={'card_number'}
                      required={'*'}
                      formlabel={'Card Number'}
                    />
                  </Box>
                </Item>
              </Grid>
              <Grid
                sx={Style.mainGrid}
                item
                order={{ xs: 2, sm: 0 }}
                xs={12}
                sm={6}
              >
                <Item>
                  <InputField
                    control={control}
                    helperText={errors?.exp?.message}
                    errors={!!errors.exp}
                    type={'text'}
                    placeholder={'MM/YYYY'}
                    size={{ xs: '18px', md: '18px', lg: '18px' }}
                    color={'#333333'}
                    name={'exp'}
                    required={'*'}
                    formlabel={'Expiration'}
                    // max={7}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                  />
                </Item>
              </Grid>
              <Grid
                sx={Style.mainGrid}
                item
                order={{ xs: 2, sm: 0 }}
                xs={12}
                sm={6}
              >
                <Item>
                  <Box sx={{}}>
                    <InputField
                      control={control}
                      helperText={errors?.card_cvc?.message}
                      errors={!!errors.card_cvc}
                      type={'number'}
                      placeholder={'Please enter cvv'}
                      size={{ xs: '18px', md: '18px', lg: '18px' }}
                      color={'#333333'}
                      name={'card_cvc'}
                      required={'*'}
                      formlabel={'CVV'}
                    />
                  </Box>
                </Item>
              </Grid>
            </Grid>

            <Box sx={Style.buttonBox}>
              <TransformButton
                typeSubmit={'submit'}
                button_name={'Save'}
                transform={'skew(-22deg) translate(0%, -50%)'}
              />
            </Box>
          </Box>

          {/* <Toastify /> */}
        </form>
      </Box>
    </Modal>
  )
}

export default PaymentModel
const Style = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minwidth: '360px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 2,
    borderRadius: 2,
    '&.css-1oimnr0': {
      width: { xs: '360px' },
    },
  },
  header: {
    borderBottom: '1px solid gray',
    p: 2,
    pl: 4,
    display: 'flex',
    justifyContent: 'space-between',
  },
  Skeleton: {
    width: '100%',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  star: {
    color: '#d32f2f',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#333333',
  },
  headingText: {
    fontSize: { xs: '14px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },
  exp: {
    display: 'flex',
  },
  validationStyle: {
    color: '#d32f2f',
    margin: '0px',
    fontWeight: '500',
    fontSize: '12px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: { xs: 'flex-end' },
    width: '100%',
    // mt: 2,
    // pr: { xs: 1, sm: 10 },
    // pl: { xs: 1, sm: 10 },
  },
  row2: {
    display: 'flex',
  },
}
