import {
  Box,
  Button,
  FormLabel,
  Grid,
  Pagination,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import InputField from '../../Components/InputField'
import { Schema } from '../../Components/FormSchema/Schema'
import RowRadioButtonsGroup from '../../Components/RadioButtons/RadioButton'
import { useDispatch } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import {
  getAdminProfileUpdate,
  getLoadingStatus,
} from '../../redux/login/action'
import Toastify from '../../Components/SnackBar/Toastify'
import { Style } from '../../Admin/CSS/Table'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import MeasurementForm from '../../Components/Forms/MeasurementForm'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import { storage } from '../../config/storage'
import { EnhancedTableHead } from '../../Components/TableHeader/TableHeader'
import {
  getAdminProfileRequest,
  getMeasurementData,
  getMeasurementDelete,
  getMeasurementEdit,
} from '../../redux/customer_panel/actionCustomer'
import { initialState, reducerFunction } from '../../config/mockData'
import CustomPagination from '../../Components/Pagination'
import AlertDialog from '../../Components/Dialog/Dialog'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const EditMeasurement = () => {
  const navigate = useNavigate()
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const [columns, setColumns] = React.useState([])
  const [handed, setHanded] = useState('')
  const [columns1, setColumns1] = React.useState([])
  const { Measurement_list, loadingStatus, customerData, MeasureData, empty } =
    useSelector((state) => state?.CustomerPanelData) || {}
  const data = useSelector((state) => state)
  let email = { email: data?.LoginData?.adminData?.email }
  let newState = storage.fetch.user_details()
  const [customerData1, setCustomerData1] = useState({
    armspan: '',
    init_height: '',
    int_weight: '',
    handed: 'left',
  })
  let data1 = {
    date: '',
    start: 0,
    length: 5,
    sort_column: '',
    sort_by: '',
    search_val: '',

    page: state.page,
  }
  useEffect(() => {
    window.scroll(0, 0)
    callApi()
    const headCells = [
      { label: 'Date', name: 'date' },
      { label: 'Height', name: 'height' },
      { label: 'Weight', name: 'weight' },
      { label: 'Action', name: 'Action' },
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    const headCell1 = [
      { label: 'Date', name: 'date' },
      { label: 'Jump Height', name: 'height' },
      { label: 'Action', name: 'Action' },
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    setColumns1(headCell1)
    setColumns(headCells)
  }, [])
  useEffect(() => {
    setStateReducer({ pages: Measurement_list?.pages })
    if (customerData != null) {
      const data = storage.fetch.user_details()
      // const email = JSON.parse(data)
      setCustomerData1({
        armspan: customerData?.armspan == 0 ? '' : customerData?.armspan,
        init_height:
          customerData?.init_height == 0 ? '' : customerData?.init_height,
        int_weight:
          customerData?.int_weight == 0 ? '' : customerData?.int_weight,
        handed: customerData?.handed === null ? 'left' : customerData?.handed,
      })
      setHanded(customerData?.handed === null ? 'left' : customerData?.handed)
    }
  }, [Measurement_list, customerData])

  const callApi = () => {
    myDispatch(getAdminProfileRequest())
    myDispatch(getMeasurementData(data1))
    myDispatch(getLoadingStatus(true))
  }

  const onSubmit = (value) => {
    //  value.handed= value.handed === null ? value.handed = 'left' : value.handed;
    const data = storage.fetch.user_details()
    console.log('data', data)
    Object.assign(value, { email: data.email })
    myDispatch(getLoadingStatus(true))
    myDispatch(getAdminProfileUpdate(value))
  }
  const formik = useFormik({
    initialValues: customerData1,
    validationSchema: Schema.MEASUREMENTUPDATE,
    onSubmit: (value) => {
      setCustomerData1(value)
      setHanded(value.handed)
      onSubmit(value)
    },
    enableReinitialize: true,
  })
  let id = useLocation()

  // const { isValid, dirtyFields, errors } = formState
  const myDispatch = useDispatch()
  const handleHanded = (e) => {
    //console.log(e.target.value)
    // setCustomerData1((prevUserData) => ({
    //   ...prevUserData, // Shallow copy of the old object
    //   handed: e.target.value, // Update the specific key with the new value
    // }))
    setHanded(e.target.value)
    formik.setFieldValue('handed', e.target.value)
  }
  const handleEdit = (id) => {
    myDispatch(getMeasurementEdit({ measurement_id: id }))
    myDispatch(getLoadingStatus())
  }
  const handleDelete = (id) => {
    setStateReducer({ id: id })
    setStateReducer({ alert: true })
  }

  // const confirmDelete = async (id) => {
  //   if (state.total - data['length'] === 1) {
  //     setStateReducer({ page: state.page - 1 })
  //   }
  //   //console.log('page', state.page)
  //   myDispatch(getMeasurementDelete({ measurement_id: id, page: state.page }))
  //   myDispatch(getLoadingStatus(true))
  //   setStateReducer({ alert: false })
  // }
  const confirmDelete = async (id) => {
    if (Measurement_list?.filtered_records === 1) {
      Object.assign(Measurement_list, { page: state.page - 1 })
      setStateReducer({ page: state.page - 1 })
      myDispatch(
        getMeasurementDelete(
          { measurement_id: id, page: state.page - 1 },
          navigate,
        ),
      )
    } else {
      //console.log('page', state.page)
      myDispatch(
        getMeasurementDelete(
          { measurement_id: id, page: state.page },
          navigate,
        ),
      )
    }

    myDispatch(getLoadingStatus(true))
    setStateReducer({ alert: false })
  }
  const handlePageChange = (event, value) => {
    setStateReducer({ page: value })
    let a = (value - 1) * state.length
    data1.start = a
    data1.length = state.length

    if (state.order && state.orderBy) {
      data1.sort_by = state.order === 'asc' ? 'desc' : 'asc'
      data1.sort_column = state.orderBy
    }
    myDispatch(getMeasurementData(data1))
    myDispatch(getLoadingStatus(true))
  }
  const Label = ({ name }) => (
    <FormLabel sx={style.label}>
      {name}
      <span style={style.star}>*</span>
    </FormLabel>
  )
  return (
    <>
      <form name="MeasurementEditForm" onSubmit={formik.handleSubmit}>
        <Grid
          sx={{ mt: 3 }}
          rowSpacing={2}
          columnSpacing={3}
          container
          spacing={2}
        >
          <LoaderComponent open={loadingStatus} />
          <AlertDialog
            title={'Are you sure you want to delete this Measurement Result?'}
            open={state.alert}
            onClickButton={() => confirmDelete(state.id)}
            onClickButtonCancel={() => setStateReducer({ alert: false })}
          />
          <Grid item xs={12} sm={3}>
            <Item>
              <InputField
                value={formik.values.armspan}
                onChange={formik.handleChange}
                type={'number'}
                placeholder={'Please enter Arm Span'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
                name1={'armspan'}
                required={'*'}
                formlabel="Arm Span"
                edit_update={true}
              />
              {formik.errors.armspan && formik.touched.armspan ? (
                <p style={style.validationStyle}>{formik.errors.armspan}</p>
              ) : null}
            </Item>
            <Toastify />
          </Grid>
          <Grid xs={12} item sm={4}>
            <Item>
              <Label name={'Handed'} />
              <div>
                <RowRadioButtonsGroup
                  labelItems={[
                    { label: 'Left', value: 'left' },
                    { label: 'Right', value: 'right' },
                  ]}
                  defaultValue={handed}
                  handleChange={handleHanded}
                />
              </div>
            </Item>
          </Grid>
        </Grid>
        <Grid sx={{ mt: 3 }} container spacing={2}>
          <Grid xs={12} item sm={3}>
            <Item>
              <InputField
                value={formik.values.init_height}
                onChange={formik.handleChange}
                type={'number'}
                placeholder={'Please enter initial height'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
                name1={'init_height'}
                required={'*'}
                formlabel="Initial Height(cm)"
                edit_update={true}
              />
              {formik.errors.init_height && formik.touched.init_height ? (
                <p style={style.validationStyle}>{formik.errors.init_height}</p>
              ) : null}
            </Item>
          </Grid>
          <Grid xs={12} item sm={3}>
            <Item>
              <InputField
                value={formik.values.int_weight}
                onChange={formik.handleChange}
                type={'number'}
                placeholder={'Please enter initial weight'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
                name1="int_weight"
                required={'*'}
                edit_update={true}
                formlabel="Initial Weight(kg)"
              />
              {formik.errors.int_weight && formik.touched.int_weight ? (
                <p style={style.validationStyle}>{formik.errors.int_weight}</p>
              ) : null}
            </Item>
          </Grid>

          <Grid xs={12} item sm={3}>
            <Item>
              <Button
                className="btn-rt-skw"
                variant="contained"
                type={'submit'}
                sx={style.button_one}
              >
                <Typography sx={{ transform: 'skew(22deg) ', fontWeight: 500 }}>
                  Update
                </Typography>
              </Button>
            </Item>
          </Grid>
        </Grid>
      </form>
      {/* {console.log(MeasureData)} */}
      <MeasurementForm
        loading={loadingStatus}
        type_case={'user_edit'}
        Style={style}
        val={MeasureData && MeasureData}
        page={state.page}
      />
      <Box sx={Style.table.tableWrapBox}>
        <Table sx={Style.table.tableBox} aria-labelledby="tableTitle">
          <EnhancedTableHead
            // numSelected={selected.length}
            display={'none'}
            columns={columns}
            setColumns={setColumns}
            action={true}
            // order={order}
            // orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            // onRequestSort={handleRequestSort}
            // rowCount={rows.length}
          />
          {/* //API_DATA// */}
          <TableBody>
            {Measurement_list &&
              Measurement_list?.measurement_list?.map((row, index) => (
                <TableRow
                  key={row.users_id}
                  sx={{
                    bgcolor: (index + 1) % 2 === 0 ? '#EEF2FC' : 'white',
                    border: 0,
                  }}
                >
                  <TableCell align="left" sx={Style.table.tableCell}>
                    {row?.formate_measure_date}
                  </TableCell>
                  <TableCell align="left" sx={Style.table.tableCell}>
                    {row?.height}cm
                  </TableCell>
                  <TableCell align="left" sx={Style.table.tableCell}>
                    {row?.weight}kg
                  </TableCell>
                  <TableCell align="left" sx={Style.table.tableCell}>
                    <EditIcon
                      style={Style.editButton}
                      onClick={() => handleEdit(row?.id)}
                    />
                    <DeleteIcon
                      style={{
                        width: '19px',
                        height: '19px',
                        cursor: 'pointer',
                        marginBottom: '3px',
                        // display:
                        //   location.pathname === PLAYER_LISTING ||
                        //   location.pathname === BULK_ORDERS_LISTING
                        //     ? ''
                        //     : 'none',
                        color: '#CB2128',
                      }}
                      onClick={() => handleDelete(row?.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          {Measurement_list === undefined ||
            (Measurement_list?.measurement_list?.length == 0 && (
              <TableRow>
                <TableCell
                  colSpan={4}
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  No Data Found
                </TableCell>
              </TableRow>
            ))}

          <Toastify />
        </Table>
        {/* {measpages > 1 && ( */}
        {/* <Pagination
                count={measpages}
                page={measpage}
                boundaryCount={1}
                onChange={handleMeasPageChange}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
              /> */}
        {/* )} */}
        {/* {//console.log('pagination', state)} */}

        {/* <CustomPagination
          state={Measurement_list && Measurement_list}
          page={state.page}
          empty={empty}
          handlePageChange={handlePageChange}
        /> */}
      </Box>
      {state.pages > 1 && (
        <Box
          sx={[
            Style.paginationBox,
            {
              display: empty ? 'none' : 'flex',
              justifyContent: 'flex-end',
              mt: 2,
              mb: 2,
            },
          ]}
        >
          <Pagination
            count={state.pages}
            page={state?.page}
            boundaryCount={1}
            onChange={handlePageChange}
            siblingCount={0}
            variant="outlined"
            shape="rounded"
            sx={{
              '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                  background: '#27245E',
                  color: 'white',
                },
              },
            }}
          />
        </Box>
      )}
    </>
  )
}

export default EditMeasurement
const style = {
  star: {
    color: 'red',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#333333',
  },
  rowBoxStyle: {
    width: '100%',
    display: 'flex',
    fontSize: '18px',
    fontWeight: '500',
    flexDirection: { xs: 'column', md: 'row' },
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  validationStyle: {
    color: 'red',
    fontWeight: 400,
  },
  button_one: {
    bgcolor: '#CB2128',
    fontWeight: 400,
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#CB2128',
      boxShadow: 'none',
    },

    // m: { xs: 1, sm: 2 },
    mt: '50px',
    borderRadius: '0px',

    boxShadow: 'none',
    p: '0px 50px 0px 50px',

    color: 'white',
    // width: "120px",
    textTransform: 'none',
    transform: {
      xs: 'skew(-22deg) translate(0%, -48%)',
      sm: 'skew(-22deg) translate(10%, -48%)',
    },
    height: '40px',
    fontSize: { xs: '12px', sm: '18px' },
  },
}
