import {
  Box,
  FormLabel,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import InputField from '../../Components/InputField'
import { useFormik } from 'formik'
import { Schema } from '../../Components/FormSchema/Schema'
import styled from '@emotion/styled'
import TransformButton from '../../Components/CustomButton/TransformButton'
import { useDispatch } from 'react-redux'
import { getAdminPassUpdate } from '../../redux/login/action'
import Toastify from '../../Components/SnackBar/Toastify'
import { useSelector } from 'react-redux'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const AdminPassword = () => {
  const myDispatch = useDispatch()
  const newState = useSelector((state) => state)
  const [loading, setLoading] = useState()
  let email = { email: newState?.LoginData?.adminData?.email }
  //console.warn(newState)
  const defaultValue = {
    password: '',
    password_confirmation: '',
  }
  const formik = useFormik({
    initialValues: defaultValue,
    validationSchema: Schema.CHANGE_PASS,
    onSubmit: (value) => {
      onSubmit(value)
    },
    enableReinitialize: true,
  })
  const onSubmit = (value) => {
    Object.assign(value, email)
    myDispatch(getAdminPassUpdate(value))
  }
  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )
  return (
    <form name="ChangePass" onSubmit={formik.handleSubmit}>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid sx={Style.mainGrid} xs={12} item sm={6}>
          <Item sx={{
    '& input[type="password"]::-ms-reveal': { display: 'none' }
  }}>
            <LoaderComponent open={loading} />

            <Box sx={{ mt: '10px' }}>
              <InputField
                value={formik.values.password}
                onChange={formik.handleChange}
                type={'password'}
                placeholder={'Please enter new password'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
                name1={'password'}
                required={'*'}
                edit_update={true}
                formlabel={' Password'}
              />
            </Box>
            <p style={Style.validationStyle}>{formik.errors.password}</p>
            <Box sx={{ mt: '30px' }}>
              <InputField
                value={formik.values.password_confirmation}
                onChange={formik.handleChange}
                type={'password'}
                placeholder={'Please re-enter password'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
                name1={'password_confirmation'}
                required={'*'}
                edit_update={true}
                formlabel={'Confirm password'}
                
              />
            </Box>
            <p style={Style.validationStyle}>
              {formik.errors.password_confirmation}
            </p>
          </Item>
          <Box sx={Style.buttonBox}>
            <TransformButton typeSubmit={'submit'} button_name={'Update'} />
          </Box>
        </Grid>
      </Grid>
      <Toastify />
    </form>
  )
}

export default AdminPassword
const Style = {
  star: {
    color: 'red',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    color: '#333333',
  },

  validationStyle: {
    color: 'red',
    margin: '10px',
  },
  mainGrid: {
    mt: '20px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: { md: 'flex-start' },
    mt: 2,
    mb: 2,
    pr: { xs: 1, sm: 10 },
    pl: { xs: 1, sm: 10 },
  },
}
