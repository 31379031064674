import {
  Box,
  Grid,
  Modal,
  Table,
  TableBody,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useLayoutEffect, useReducer } from 'react'
import TopBox from '../../Components/TableHeader/TopBox'
import { EnhancedTableHead } from '../../Components/TableHeader/TableHeader'
import { Style } from '../CSS/Table'
import ModalBox from '../../Components/Modal/ModalBox'
import ListSkelton from '../../Components/Loader/ListSkelton'
import CustomTableBody from '../../Components/TableBox/CustomTableBody'
import { RevenueData, sportsData } from '../../config/mockData'
import { initialState, reducerFunction } from '../../config/mockData'
import {
  getRevenueDetailsRequest,
  getRevenueListRequest,
  getRevenueallListRequest,
} from '../../redux/revenue/revenueAction'
import { useDispatch } from 'react-redux'
import { getLoadingStatus } from '../../redux/login/action'
import { useSelector } from 'react-redux'
import NoDataFound from './NoDataFound'
import CustomPagination from '../../Components/Pagination'
import Toastify from '../../Components/SnackBar/Toastify'
import { REVENUE_REFUND, REVENUE_REPORTS } from '../../routes/constURL'
import { useState } from 'react'

const Revenue_all = ({ call }) => {
  const { list, alllist, allrefundlist, allfaillist, empty, loadingStatus } =
    useSelector((state) => state?.RevenueData || {})
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const myDispatch = useDispatch()
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  //const [isResetDisabled, setResetDisabled] = useState(true)
  // const [columns, setColumns] = React.useState([])
  const handleOpen = () => setStateReducer({ open: true })
  let data = {
    start: 0,
    length: state.length,
    sort_column: 'payment_id',
    sort_by: 'desc',
    search_val: '',
    is_export: false,
  }
  const handleClose = () =>
    setStateReducer({
      open: false,
      type: 'Select Type',
      membership: '',
      sport: 'Select Sport',
    })
  useEffect(() => {
    window.scrollTo(0, 0)
    // myDispatch(getRevenueDetailsRequest())
    call()
    Object.assign(data, { page: state.page })
    //Object.assign(data, { payment_mode : state.payment_mode})
    myDispatch(getRevenueListRequest(data))
    // myDispatch(getLoadingStatus(true))
    const headCells = [
      { label: 'Customer Name', name: 'name' },
      { label: 'Membership', name: 'membership' },
      { label: 'Sport', name: 'sports' },
      { label: 'Payment date', name: 'subscribe_at' },
      { label: 'Payment type', name: 'unsubscribe_at' },
      { label: 'Tenure (Days)', name: 'tenure' },
      { label: 'Revenue (ex GST)', name: 'revenue_with_tax' },
      { label: 'Mode', name: 'payment_mode' },
      { label: 'Status', name: 'Status' },
      // {label:"Action",name:"Action"},
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    setStateReducer({ columns: headCells })
  }, [state.length])
  useLayoutEffect(() => {
    setStateReducer({ pages: list?.pages, total: list?.total_records })
  }, [list, loadingStatus])
  useEffect(() => {
    if (URL === REVENUE_REPORTS) {
      setStateReducer({ list: alllist?.subscriptions })
    } else if (URL === REVENUE_REFUND)
      setStateReducer({ list: allrefundlist?.subscriptions })
    else {
      setStateReducer({ list: allfaillist?.subscriptions })
    }
  }, [alllist, allfaillist, allrefundlist])
  ///---Seach & Filter Function---///
  const handleChangeSelect = (e, type) => {
    switch (type) {
      case 'type':
        return setStateReducer({
          type: e.target.value,
        })
      case 'payment':
        return setStateReducer({
          payment: e.target.value,
        })
      case 'sport':
        return setStateReducer({
          sport: e?.value,
        })
      case 'date':
        return setStateReducer({
          date: e.target.value,
        })
      case 'search':
        return setStateReducer({ search: e.target.value })
      case 'search_click':
        setStateReducer({
          typeNew: state.type,
          paymentNew: state.payment,
          sportNew: state.sport,
        })
        Object.assign(data, {
          search_val: state.search,
          type:
            state.type === 'Bulk Order'
              ? 'bulk'
              : state.type === 'Individual'
                ? 'individual'
                : '',
          payment_mode:
            state.payment === 'Online'
              ? 'online'
              : state.payment === 'Offline'
                ? 'offline'
                : '',
          sport: state.sport === 'Select Sport' ? '' : state.sport,
          date: state.date,
        })
        Object.assign(data, { page: state.page })

        myDispatch(getRevenueListRequest(data))
        myDispatch(getLoadingStatus(true))
        Object.assign(data, { is_export: true })
        delete data.length
        delete data.start

        myDispatch(getRevenueallListRequest(data))

        return setStateReducer({ page: 1 })
      default:
        return null
    }
  }
  ///---Pagination Function---///
  const handlePageChange = (event, value) => {
    setStateReducer({ page: value })
    let a = (value - 1) * state.length
    data.start = a
    data.length = state.length

    if (state.order && state.orderBy) {
      data.sort_by = state.order === 'asc' ? 'desc' : 'asc'
      data.sort_column = state.orderBy
    }
    if (state.search) {
      data.search_val = state.search
    }
    if (state.typeNew) {
      data.type =
        state.typeNew === 'Bulk Order'
          ? 'bulk'
          : state.typeNew === 'Individual'
            ? 'individual'
            : ''
    }
    if (state.sport) {
      data.sport = state.sport === 'Select Sport' ? '' : state.sport
    }
    if (state.payment) {
      data.payment_mode =
        state.payment === 'Online'
          ? 'online'
          : state.payment === 'Offline'
            ? 'offline'
            : ''
    }
    if (state.date) {
      data.date = state.date
    }
    //console.log('type', state.typeNew)
    Object.assign(data, {
      page: state.page,
      payment_mode:
        state.paymentNew === 'Online'
          ? 'online'
          : state.paymentNew === 'Offline'
            ? 'offline'
            : '',
      type:
        state?.typeNew === 'Bulk Order'
          ? 'bulk'
          : state.typeNew === 'Individual'
            ? 'individual'
            : '',
      sport: state.sportNew == 'Select Sport' ? '' : state?.sport,
    })
    myDispatch(getRevenueListRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  const handleRequestSort = (event, property) => {

    setStateReducer({ page: state.page })
    let a = (state.page - 1) * state.length
    data = {
      start: a,
      length: state.length,
    }

    let isAsc = state.orderBy === property && state.order === 'asc'
    if (state.orderBy !== property) {
      setStateReducer({ order: 'desc', orderBy: property })
      data.sort_by = 'asc'
      data.sort_column = property
    } else {
      setStateReducer({ order: isAsc ? 'desc' : 'asc', orderBy: property })
      data.sort_by = state.order
      data.sort_column = property
    }
    if (state.search) {
      data.search_val = state.search
    }
    if (state.type) {
      data.type =
        state.type === 'Bulk Order'
          ? 'bulk'
          : state.type === 'Individual'
            ? 'individual'
            : ''
    }
    if (state.sport) {
      data.sport = state.sport === 'Select Sport' ? '' : state.sport
    }
    if (state.payment) {
      data.payment_mode =
        state.payment === 'Online'
          ? 'online'
          : state.payment === 'Offline'
            ? 'offline'
            : ''
    }
    if (state.date) {
      data.date = state.date
    }
    console.log("Revenue Data",data);
    Object.assign(data, { page: state.page,
      type:state.typeNew == 'Select Type' ? '' : state?.type,
      payment_mode:state.paymentNew == 'Payment Mode' ? '' : state?.paymentNew,
      sport:state.sportNew == 'Select Sport' ? '' : state?.sportNew
     })
    myDispatch(getRevenueListRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  // //console.log(empty)

  useEffect(() => {
    // Check if any two drop-downs have default values
    // const isDefaultStatus = state.status === 'Select Status';
    // const isDefaultMembership = state.membership === 'Select Program';
    const isDefaultDate = state.date === '' || state.date === null
    const isDefaultType = state.type === 'Select Type'
    const isDefaultPayment = state.payment === 'Payment Mode'
    const isDefaultSport =
      state.sport === 'Select Sport' || state.sport === undefined
    const isDefaultSearch = state.search === '' || state.search === undefined
    // If any two drop-downs have default values, disable the button
    setButtonDisabled(
      isDefaultDate &&
        isDefaultPayment &&
        isDefaultSport &&
        isDefaultSearch &&
        isDefaultType,
    )
  }, [state.date, state.type, state.payment, state.sport, state.search])

  // useEffect(() => {
  //   // Check if both drop-downs have default values
  //   // const isDefaultStatus = state.status === 'Select Status';
  //   // const isDefaultMembership = state.membership === 'Select Program';
  //   // If both drop-downs have default values, disable the reset button
  //   console.log('date', state.date, state)
  //   const isDefaultDateR = state.date === '' || state.date === null
  //   const isDefaultTypeR = state.type === 'Select Type'
  //   const isDefaultPaymentR = state.payment === 'Payment Mode'
  //   const isDefaultSportR =
  //     state.sport === 'Select Sport' || state.sport === undefined
  //   const isDefaultSearchR = state.search === ''

  //   setResetDisabled(
  //     isDefaultDateR &&
  //       isDefaultPaymentR &&
  //       isDefaultSearchR &&
  //       isDefaultSportR &&
  //       isDefaultTypeR,
  //   )
  // }, [state.date, state.type, state.payment, state.sport, state.search])

  const handleReset = () => {
    window.scrollTo(0, 0)
    myDispatch(getRevenueListRequest(data))
    myDispatch(getLoadingStatus(true))

    setStateReducer({
      // status: 'Select Status',
      // membership: 'Select Program', order: 'asc',
      orderBy: '',
      page: 1,
      pages: 0,
      date: '',
      type: 'Select Type',
      payment: 'Payment Mode',
      sport: 'Select Sport',
      search: '',
      typeNew:'Select Type',
      paymentNew:'Payment Mode',
      sportNew:'Select Sport',
    })
  }

  return (
    <Grid sx={{ mt: '1.5%' }}>
      <TopBox
        // headerText={'Players'}
        // button_one={'Add +'}
        search_According_To_routes={true}
        searchText={'Search Player'}
        setSearch_val={(e) => handleChangeSelect(e, 'search')}
        search_val={state.search}
        search={true}
        date={state.date}
        // width={'10%'}
        // handleChangeFirst={(e) => handleChangeSelect(e, 'sport')}
        handleChangeSport={(selectedOption) => {
          handleChangeSelect(selectedOption, 'sport')
        }}
        sportdrop={true}
        handleChangeType={(e) => handleChangeSelect(e, 'type')}
        handleChangePayment={(e) => handleChangeSelect(e, 'payment')}
        handleDateChange={(e) => handleChangeSelect(e, 'date')}
        // sortingText={'Filter by'}
        // width={'15%'}
        value3={state.type}
        value1={state.sport}
        value4={state.payment}
        // value2={select.membership}
        button_two={() => handleChangeSelect('', 'search_click')}
        button_one_onClick={() => {
          //   navigate(CUSTOMERS_ADD_NEW)
        }}
        onClick={handleOpen}
        select_arr2={['Select Type', 'Individual', 'Bulk Order']}
        select_arr3={['Payment Mode', 'Online', 'Offline']}
        select_arr1={sportsData}
        onReset={handleReset}
        disabledVal={isButtonDisabled}
        // disabledReset={isResetDisabled}
      />
      <ModalBox
        handleClose={handleClose}
        open={state.open}
        type={state.type}
        membership={state.membership}
      />
      {empty ? (
        <NoDataFound />
      ) : (
        <Box sx={[Style.table.tableWrapBox]}>
          <Table
            sx={Style.table.tableBox}
            aria-labelledby="tableTitle"
            id="divId2"
          >
            <EnhancedTableHead
              // numSelected={selected.length}
              columns={state.columns}
              setColumns={state.setColumns}
              order={state?.order}
              orderBy={state?.orderBy}
              revenue={true}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              //   rowCount={rows.length}
            />
            {/* //API_DATA// */}
            <Toastify />
            <TableBody sx={{ border: 0 }}>
              {list &&
                !loadingStatus &&
                list?.subscriptions?.map((row, ind) => (
                  <CustomTableBody
                    key={row?.users_id}
                    // URL={CUSTOMERS_INFO_DETAILS}
                    // navigateTo={navigateTo}
                    row={row}
                    // loadingStatus={loadingStatus}
                    ind={ind}
                    columns={[
                      row?.user_name,
                      row?.user_membership,
                      row?.user_sports,
                      row?.user_subscribe_at,
                      row?.payment_type,
                      row?.user_tenure_days,
                      `$${row?.user_revenue_without_tax}`,
                      row?.user_mode_of_payment,
                      row?.user_payment_status,
                    ]}
                    page={state.page}
                    payment_mode={state.payment}
                    date={state.date}
                    sport={state.sport}
                    search_val={state.search_val}
                    type={state.type}
                  />
                ))}
              {loadingStatus && (
                <ListSkelton
                  loadingStatus={loadingStatus}
                  rows={4}
                  columns={10}
                  width={'100px'}
                />
              )}
            </TableBody>
          </Table>
        </Box>
      )}
      <CustomPagination
        state={state}
        empty={empty}
        handlePageChange={handlePageChange}
      />
      {/* )} */}
    </Grid>
  )
}

export default Revenue_all
const style = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
}
