import { useDispatch, useSelector } from 'react-redux'
import { getRequest } from './redux/login/action'
import { getSagaRequest } from './redux/signup/action'
import Drawer from '../src/Components/Drawer/Drawer'
import { ThemeProvider, createTheme } from '@mui/material'
import { useLocation } from 'react-router-dom'
import packageJson from '../package.json'
// import { getBuildDate } from './utils/utils'
import withClearCache from './ClearCache'
// import Index from './routes'
import {
  CLUBSIGNUP,
  CLUB_LOGIN,
  CUSTOMERSIGNUP,
  DEMOSIGNUP,
  CUSTOMER_LOGIN,
  FORGOT_PASSWORD,
  LOGIN,
  RESET_PASSWORD,
  THANKYOU,
  VERIFICATION,
} from './routes/constURL'
import React, { Suspense } from 'react'
const Index = React.lazy(() => import('./routes'))
const THEME = createTheme({
  typography: {
    fontFamily: 'Mulish, sans-serif', // Default font family for normal text
    h1: {
      fontFamily: 'Hind, sans-serif', // Font family for h1
    },

    h2: {
      fontFamily: 'Hind, sans-serif', // Font family for h2
    },
    // Add more heading styles as needed
  },
  overrides: {
    MuiSelect: {
      select: {
        '&:focus': {
          background: 'transparent',
        },
      },
    },
  },
  TextField: {
    paddingLeft: '0px',
  },
})
const ClearCacheComponent = withClearCache(MainApp)
function App() {
  return <ClearCacheComponent />
}
function MainApp() {
  // const myDispatch = useDispatch();
  // const abc={
  //   a:1,
  //   b:2
  const location = useLocation()
  const trimmedURL = location.pathname.slice(0, 15)

  // //console.log(trimmedURL)
  return (
    <ThemeProvider theme={THEME}>
      {location.pathname !== LOGIN &&
      location.pathname !== FORGOT_PASSWORD &&
      location.pathname !== CUSTOMER_LOGIN &&
      location.pathname !== CLUB_LOGIN &&
      location.pathname !== VERIFICATION &&
      trimmedURL !== RESET_PASSWORD &&
      location.pathname !== CLUBSIGNUP &&
      location.pathname !== DEMOSIGNUP &&
      location.pathname !== THANKYOU &&
      location.pathname !== CUSTOMERSIGNUP ? (
        <Drawer />
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <Index />
        </Suspense>
      )}
    </ThemeProvider>
  )
}

export default App
