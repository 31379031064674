import styled from '@emotion/styled'
import {
  Badge,
  Button,
  CardMedia,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material'

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PROGRAM, PROGRAM_VIEW } from '../../routes/constURL'
import CheckIcon from '@mui/icons-material/Check'
import { makeStyles } from '@mui/styles'
import Thumbnail from './Thumbnail'
import ReactPlayer from 'react-player'

const useStyles = makeStyles((theme) => ({
  badge: {
    fontSize: 50,
    // height: '75px',
    width: '30px',
    borderRadius: '50%',
    '&.MuiBadge-badge': {
      height: '30px',
      borderRadius: '175px',
    },
  },
}))

const VideoPlayer = ({ count, width, height, id, loading, week }) => {
  const classes = useStyles()
  const Style = {
    main: {
      mt: 3,
    },

    badge: {
      '&.MuiBadge-badge': {
        // height: '65px',
        display: 'none',

        borderRadius: '175px',
      },
    },
  }
  //console.log('count', count)
  const currentDate = new Date()
  const formattedDate = currentDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
  count?.map((item, index) => {
    const [day1, month1, year1] = item?.active_date?.split('/').map(Number)
    const date1 = new Date(year1, month1 - 1, day1) // Note: month is 0-indexed
    count.active_date = date1
  })
  const [day2, month2, year2] = formattedDate?.split('/').map(Number)
  const date2 = new Date(year2, month2 - 1, day2)
  //console.log('currentDate', date1, date2, date1 <= date2)
  return (
    <Grid container columnGap={2} rowGap={3} sx={Style.main}>
      {!loading &&
        count &&
        count?.map((item) =>
          item.is_watched ? (
            <Badge
              badgeContent={<CheckIcon sx={{ mt: 2, mb: 2 }} />}
              size={'large'}
              // height={'35px'}
              sx={{
                // height: '65px',
                '&.css-uxp1ge-MuiBadge-badge': {
                  // height: '65px',
                  borderRadius: '175px',
                  display: 'none',
                },
              }}
              color="success"
              classes={{ badge: classes.badge }}
            >
              <Thumbnail
                week={week}
                width={width}
                height={height}
                item={item}
              />
            </Badge>
          ) : count.active_date <= date2 ? (
            <Thumbnail week={week} width={width} height={height} item={item} />
          ) : (
            <Thumbnail
              week={week}
              width={width}
              height={height}
              item={item}
              fade={true}
            />
          ),
        )}
      {loading &&
        ['', '', '', '', '', '']?.map((item) => (
          <Skeleton
            sx={{
              transform: 0.8,
              '&.MuiSkeleton-root ': {
                transform: 'scale(1, 0.9);',
              },
            }}
            classes={{ badge: classes.badge }}
            width={'340px'}
            height="250px"
          />
        ))}
    </Grid>
  )
}

export default VideoPlayer
