import {
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  SET_CANCEL_SUBSCRIPTION,
  SET_REFER_LINK,
  SET_SUBSCRIPTION_LIST,
  SET_UPGRADE_SUBSCRIPTION,
} from '../ConstAction'
const initialState = {}
export const AccountData = (state = initialState, action) => {
  switch (action.type) {
    case SET_CANCEL_SUBSCRIPTION:
      return { cancel_subscription: action.response.data.data }
    case SET_UPGRADE_SUBSCRIPTION:
      return { upgrade_subscription: action.response.data.data }
    case SET_SUBSCRIPTION_LIST:
      return { list: action.response.data.data }
    case SET_REFER_LINK:
      return { link: action.response.data.data }

    case GET_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: action.data,
      }
    case GET_EMPTY_STATUS:
      return {
        ...state,
        empty: action.data,
      }

    default:
      return { ...state }
  }
}
