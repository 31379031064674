import { Api } from '../../config/request'

export const requestAdminList = async (data) => {
  return Api.PostRequest('/standard/list', data)
}
export const requestAdminUpdate = async (data) => {
  return Api.PostRequest('/standard/user', data)
}
export const requestAdminDelete = async (data) => {
  return Api.PostRequest('/standard/delete', data)
}
export const requestAdminDetails = async (data) => {
  return Api.PostRequest('/standard/view', data)
}
const AdminApiRequests = {
  requestAdminList,
  requestAdminUpdate,
  requestAdminDelete,
  requestAdminDetails,
}
export default AdminApiRequests
