/* eslint-disable no-useless-concat */
const LS_KEY = {
  auth_token: 'jwt_access_token_jho',
  forgot_token: 'jwt_forgot_token_jho',
  userId_token: 'jwt_userId_jho',
  customer_Id: 'customer_id',
  admin_Id: 'admin_id',
  image: 'profile_pic',
  role: 'role',
  AdminName: 'adminName',
  subs_id: 'subs_id',
  oneTime_id: 'oneTime_id',
  details: 'user_details',
  weeks: 'weeks',
  week_number: 'week_number',
  notification: 'notification',
  posts: 'posts',
  PostalDetails : 'postalDetails'
}

const set = {
  authToken: (data) => {
    localStorage.setItem(LS_KEY.auth_token, 'Bearer' + ' ' + data)
  },
  user_details: (data) => {
    localStorage.setItem(LS_KEY.details, JSON.stringify(data))
  },
  program_weeks_list: (data) => {
    localStorage.setItem(LS_KEY.weeks, JSON.stringify(data))
  },
  forgotToken: (data) => {
    localStorage.setItem(LS_KEY.forgot_token, data)
  },
  userId: (data) => {
    localStorage.setItem(LS_KEY.userId_token, data)
  },
  AdminId: (data) => {
    localStorage.setItem(LS_KEY.admin_Id, data)
  },
  week_number: (data) => {
    localStorage.setItem(LS_KEY.week_number, data)
  },
  customerId: (data) => {
    localStorage.setItem(LS_KEY.customer_Id, data)
  },
  profileImage: (data) => {
    localStorage.setItem(LS_KEY.image, data)
  },
  role: (data) => {
    localStorage.setItem(LS_KEY.role, data)
  },
  AdminName: (data) => {
    localStorage.setItem(LS_KEY.AdminName, data)
  },
  subs_id: (data) => {
    localStorage.setItem(LS_KEY.subs_id, data)
  },
  oneTime_id: (data) => {
    localStorage.setItem(LS_KEY.oneTime_id, data)
  },
  notification: (data) => {
    localStorage.setItem(LS_KEY.notification, data)
  },
  posts: (data) => {
    localStorage.setItem(LS_KEY.posts, JSON.stringify(data))
  },
  PostalDetails: (data) => {
    localStorage.setItem(LS_KEY.PostalDetails, JSON.stringify(data))
  },
}

const fetch = {
  authToken: () => {
    const data = localStorage.getItem(LS_KEY.auth_token)
    if (data) {
      try {
        const decoded = data
        return decoded
      } catch (err) {}
    }
  },
  user_details: () => {
    const data = localStorage.getItem(LS_KEY.details)
    if (data) {
      try {
        const decoded = JSON.parse(data)
        return decoded
      } catch (err) {}
    }
  },
  program_weeks_list: () => {
    const data = localStorage.getItem(LS_KEY.weeks)
    if (data) {
      try {
        const decoded = JSON.parse(data)
        return decoded
      } catch (err) {}
    }
  },
  role: () => {
    const data = localStorage.getItem(LS_KEY.role)
    if (data) {
      try {
        const decoded = data
        return decoded
      } catch (err) {}
    }
  },
  AdminName: () => {
    const data = localStorage.getItem(LS_KEY.AdminName)
    if (data) {
      try {
        const decoded = data
        return decoded
      } catch (err) {}
    }
  },
  customerId: () => {
    const data = localStorage.getItem(LS_KEY.customer_Id)
    if (data) {
      try {
        const decoded = data
        return decoded
      } catch (err) {}
    }
  },
  AdminId: () => {
    const data = localStorage.getItem(LS_KEY.admin_Id)
    if (data) {
      try {
        const decoded = data
        return decoded
      } catch (err) {}
    }
  },
  fetchImage: () => {
    const data = localStorage.getItem(LS_KEY.image)
    if (data) {
      try {
        const decoded = data
        return decoded
      } catch (err) {}
    }
  },
  subs_id: () => {
    const data = localStorage.getItem(LS_KEY.subs_id)
    if (data) {
      try {
        const decoded = data
        return decoded
      } catch (err) {}
    }
  },
  oneTime_id: () => {
    const data = localStorage.getItem(LS_KEY.oneTime_id)
    if (data) {
      try {
        const decoded = data
        return decoded
      } catch (err) {}
    }
  },
  week_number: () => {
    const data = localStorage.getItem(LS_KEY.week_number)
    if (data) {
      try {
        const decoded = data
        return decoded
      } catch (err) {}
    }
  },
  notification: () => {
    const data = localStorage.getItem(LS_KEY.notification)
    if (data) {
      try {
        const decoded = data
        return decoded
      } catch (err) {}
    }
  },
  posts: () => {
    const data = localStorage.getItem(LS_KEY.posts)
    if (data) {
      try {
        const decoded = JSON.parse(data)
        return decoded
      } catch (err) {}
    }
  },
  PostalDetails: () => {
    const data = localStorage.getItem(LS_KEY.PostalDetails)
    if (data) {
      try {
        const decoded = JSON.parse(data)
        return decoded
      } catch (err) {}
    }
  },
}

const fetchForForgot = {
  forgotToken: () => {
    const data = localStorage.getItem(LS_KEY.forgot_token)
    if (data) {
      try {
        const decoded = data
        return decoded
      } catch (err) {}
    }
  },
}

const fetchForUserId = {
  userId: () => {
    const data = localStorage.getItem(LS_KEY.userId_token)
    if (data) {
      try {
        const decoded = data
        return decoded
      } catch (err) {}
    }
  },
}

const destroy = {
  authToken: () => {
    localStorage.removeItem(LS_KEY.auth_token)
  },
}

export const storage = {
  set,
  fetch,
  destroy,
  fetchForForgot,
}
