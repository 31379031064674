import { Api } from '../../config/request'

export const requestClubSignUp = async (data) => {
  //console.log(data)
  return Api.PostRequest('/user/joinClub', data)
}
// export const requestRevenueDetails = async (data) => {
//   return Api.PostRequest('/revenue/revenueDetails', data)
// }
const SignupApiRequests = {
  requestClubSignUp,
  //   requestRevenueDetails,
}
export default SignupApiRequests
