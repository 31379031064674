import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { storage as LocalStorage } from '../config/storage'
import { toast } from 'react-toastify'
import Toastify from '../Components/SnackBar/Toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CLUB_LOGIN, LOGIN } from './constURL'

const AdminProtected = ({ children }) => {
  const navigate = useNavigate()
  const acc_Token = LocalStorage.fetch.authToken()
  const type = LocalStorage.fetch.role()
  if (acc_Token) {
    if (type !== 'admin' && type !== 'standard_admin') {
      //console.log('acc_Token')
      navigate(-1)
      return <Navigate to={-1} replace />
    }
  } else {
    setTimeout(() => {
      localStorage.clear()
    }, 2000)

    return <Navigate to={type === 'club_admin' ? CLUB_LOGIN : LOGIN} replace />
  }
  return children
}

export default AdminProtected
