import { Api } from '../../config/request'

export const requestTimeline = async (data) => {
  return Api.PostRequest('/post/timeline', data)
  // return Api.PostRequest(
  //   'https://backend-staging.overnight-gains.com/test-upload.php',
  //   data,
  // )
}
export const requestTimelinePosted = async (data) => {
  return Api.PostRequest('/post/create', data)
  // return Api.PostRequest1(
  //   'https://backend-staging.overnight-gains.com/test-upload.php',
  //   data,
  // )
}
export const requestJumping = async (data) => {
  return Api.PostRequest('/jumpresult/list', data)
}
export const requestMeasurement = async (data) => {
  return Api.PostRequest('/measurement/list', data)
}
export const requestMeasurementModify = async (data) => {
  return Api.PostRequest('/measurement/modify', data)
}
export const requestMeasurementEdit = async (data) => {
  ////consoless.log(data)
  return Api.PostRequest('/measurement/view', data)
}
export const requestMeasurementDelete = async (data) => {
  //consoles.log(data)
  return Api.PostRequest('/measurement/delete', data)
}
export const requestJumpingResultUpdate = async (data) => {
  return Api.PostRequest('/jumpresult/modify', data)
}
export const requestJumpingResultDelete = async (data) => {
  return Api.PostRequest('/jumpresult/delete', data)
}
export const requestJumpingResultEdit = async (data) => {
  return Api.PostRequest('/jumpresult/view', data)
}
const CustomerPanelApiRequests = {
  requestTimeline,
  requestTimelinePosted,
  requestJumping,
  requestMeasurement,
  requestMeasurementModify,
  requestJumpingResultUpdate,
  requestJumpingResultDelete,
  requestJumpingResultEdit,
  requestMeasurementEdit,
  requestMeasurementDelete,
}
export default CustomerPanelApiRequests
