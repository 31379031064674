import { Grid } from '@mui/material'
import React, { useState } from 'react'
import TransformButton from './CustomButton/TransformButton'
import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom'
import { CUSTOMER_LOGIN } from '../routes/constURL'

import { Menu, MenuItem, IconButton, Box } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { motion } from 'framer-motion'

const Header = ({ email }) => {
  const navigate = useNavigate()

  const doCall1 = () => {
    window.location.href = 'https://overnight-gains.com'
  }

  const doCall2 = () => {
    navigate(CUSTOMER_LOGIN + `?email=${email}`)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: '100%' }}
    >
      <Grid item>
        <img
          src={require('../assets/logo-11.png')}
          width={'200px'}
          style={{ cursor: 'pointer' }}
          onClick={() => doCall1()}
        />
      </Grid>
      <Grid
        item
        sx={{
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Grid container alignItems="center" spacing={18} sx={{mr:'32px'}}>
          <Grid item sx={{ pb: '2.5%', pl: 2 }}>
            <Link
              href="https://overnight-gains.com/"
              underline="hover"
              sx={{
                color: '#27245E',
                fontSize: '16px',
                fontFamily: 'mulish',
                fontWeight: '550',
              }}
            >
              {'Home'}
            </Link>
          </Grid>
          <Grid item sx={{ '&.MuiGrid-root': { pl: 5 } }}>
            <TransformButton
              typeSubmit={'submit'}
              button_name={'Login'}
              transform={'skew(-22deg) translate(-10%, -50%)'}
              handleClick={() => doCall2()}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem>
            <Grid item>
              <Link
                href="https://overnight-gains.com/"
                underline="hover"
                sx={{
                  color: '#27245E',
                  fontSize: '16px',
                  fontFamily: 'mulish',
                  fontWeight: '550',
                }}
              >
                {'Home'}
              </Link>
            </Grid>
          </MenuItem>
          <MenuItem>
            <Grid item sx={{ pb: '2.5%' }}>
              <Link
                href={CUSTOMER_LOGIN}
                typeSubmit={'submit'}
                button_name={'Login'}
                handleClick={() => doCall2()}
                underline="hover"
                sx={{
                  color: '#27245E',
                  fontSize: '16px',
                  fontFamily: 'mulish',
                  fontWeight: '550',
                }}
              >
                {'Login'}
              </Link>
            </Grid>
            {/* <Grid item>
              <TransformButton typeSubmit={'submit'} button_name={'Login'} handleClick={()=> doCall2()} />
            </Grid> */}
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  )
}

export default Header
