import { Box, FormLabel, Grid, Paper, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputField from '../Components/InputField'
import { Schema } from '../Components/FormSchema/Schema'
import RowRadioButtonsGroup from '../Components/RadioButtons/RadioButton'
import { useDispatch } from 'react-redux'
import {
  getPlayerAddUpdateRequest,
  getPlayerDetailsRequest,
} from '../redux/players/playerAction'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import Toastify from '../Components/SnackBar/Toastify'
import TransformButton from '../Components/CustomButton/TransformButton'
import EditIcon from '@mui/icons-material/Edit'
import { getLoadingStatus } from '../redux/login/action'
import SelectComponent from '../Components/SelectComponent'
import { sportsData } from '../config/mockData'
import LoaderComponent from '../Components/Loader/LoaderComponent'
import Select2 from '../Components/Select2'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const PlayerEdit = () => {
  const location = useLocation()
  const [gender, setGender] = useState('')
  const [sport, setSport] = useState('')
  const navigate = useNavigate()
  const myDispatch = useDispatch()
  const newState = useSelector((state) => state?.PlayerData)
  const { details } = useSelector((state) => state?.PlayerData) || {}

  //console.warn(details)
  const [image_uploaded, setImage_uploaded] = useState(
    '../assets/profileRect.png',
  )
  const [image_binary, setImage_binary] = useState()
  const [sportserror, setsportserror] = useState(null)
  let user_id = useParams()
  let data = {
    users_id: user_id.id,
  }
  const [PlayerData, setPlayerData] = useState({
    f_name: '',
    l_name: '',
    age: '',
    email: '',
    mobile: '',
    team: '',
    sports: '',
  })
  useEffect(() => {
    window.scroll(0, 0)
    callApi()
  }, [])
  const callApi = () => {
    myDispatch(getPlayerDetailsRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  useEffect(() => {
    if (details) {
      setPlayerData({
        f_name: details?.playerData?.f_name,
        l_name: details?.playerData?.l_name,
        email: details?.playerData?.email,
        mobile: details?.playerData?.mobile,
        gender: details?.playerData?.gender,
        age: details?.playerData?.age,
        team: details?.playerData?.team,
        sports: details?.playerData?.sports,
      })
      setGender(details?.playerData?.gender)
    }
    setImage_uploaded(
      details?.playerData?.image
        ? details?.playerData?.image
        : require('../assets/profileRect.png'),
    )
  }, [newState])

  const formik = useFormik({
    initialValues: PlayerData,
    validationSchema: Schema.PLAYER_PROFILE,
    onSubmit: (value) => {
      setPlayerData(value)
      // //console.log(sportserror)
      setGender(value?.gender)
      onSubmit(value)
    },
    enableReinitialize: true,
  })
  console.log(formik.errors, formik.values, 'value')
  const onSubmit = (value) => {
    console.log('value', value)
    Object.assign(value, data)
    const formData = new FormData()
    formData.append('f_name', value.f_name)
    formData.append('l_name', value.l_name)
    formData.append('email', value.email)
    formData.append('mobile', value.mobile)
    formData.append('gender', gender)
    formData.append('age', value.age)
    formData.append('team', value.team)
    formData.append('sports', value.sports)
    formData.append('users_id', data.users_id)

    if (image_binary) formData.append('image', image_binary)
    myDispatch(getPlayerAddUpdateRequest(formData, navigate))
    myDispatch(getLoadingStatus(true))
    // setTimeout(() => {
    //   callApi()
    // }, 2000)
  }
  // //console.log(image_uploaded)
  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )
  const handleChangeGender = (e) => {
    // setPlayerData((prevUserData) => ({
    //   ...prevUserData, // Shallow copy of the old object
    //   gender: e.target.value, // Update the specific key with the new value
    // }))
    setGender(e.target.value)
  }
  const handleFileChange = (e) => {
    // //console.log('hii', e.target.files[0])
    setImage_binary(e.target.files[0])
    const binary = URL.createObjectURL(e.target.files[0])
    // //console.log(binary)
    setImage_uploaded(binary)
    // //console.log(image_uploaded)
  }
  return (
    <form name="CustomerProfile" onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid
            sx={Style.mainGrid1}
            item
            order={{ xs: 2, sm: 0 }}
            xs={12}
            sm={6}
          >
            {/* {//console.warn(newState?.loadingStatus)} */}
            <LoaderComponent open={newState?.loadingStatus} />
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.f_name}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter first name'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'f_name'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'First Name'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.f_name && formik.touched.f_name ? (
                  <p style={Style.validationStyle}>{formik.errors.f_name}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
              <Box sx={{}}>
                <InputField
                  value={formik.values.l_name}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter last name'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'l_name'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Last Name'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.l_name && formik.touched.l_name ? (
                  <p style={Style.validationStyle}>{formik.errors.l_name}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid
            order={{ xs: 1, sm: 0 }}
            sx={Style.mainGrid}
            item
            xs={12}
            sm={6}
          >
            <Item
              sx={{
                boxShadow: 'none',
                cursor: 'pointer',
                borderRadius: '100%',
                maxWidth: { xs: '145px', sm: '170px' },
                p: 1,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  width: '30px',
                  height: '30px',
                  bgcolor: '#d80027',
                  color: 'white',
                  borderRadius: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  right: '14px',
                }}
              >
                <EditIcon
                  fontSize="30px"
                  sx={{ position: 'absolute', width: '1.5em', height: '1.5em' }}

                  // style={style.iconEye}
                />
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleFileChange}
                  style={{
                    position: 'absolute',
                    opacity: 0,
                    height: '30px',
                    width: '30px',
                  }}
                  // Ref for triggering file input click
                />
              </Box>
              <img
                alt="profileimage"
                className="profileImage"
                // src={vehicData?.photo_links[selected]?.image_url}
                src={image_uploaded}

                // onClick={() => setOpen(true)}
              />
            </Item>
          </Grid>
          <Grid order={{ xs: 3, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.age}
                  onChange={formik.handleChange}
                  type={'number'}
                  placeholder={'Please enter age'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'age'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Age'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.age && formik.touched.age ? (
                  <p style={Style.validationStyle}>{formik.errors.age}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 4, sm: 0 }} item xs={12} sm={6}>
            <Label name={'Gender'} />
            <div>
              <RowRadioButtonsGroup
                labelItems={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                  { label: 'Others', value: 'others' },
                ]}
                defaultValue={gender}
                handleChange={handleChangeGender}
              />
            </div>
          </Grid>
          <Grid order={{ xs: 5, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Box sx={{}}>
                <InputField
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter email'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'email'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Email Address'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.email && formik.touched.email ? (
                  <p style={Style.validationStyle}>{formik.errors.email}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 6, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Box sx={{}}>
                <InputField
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  type={'number'}
                  placeholder={'Please enter phone number'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'mobile'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Phone Number'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.mobile && formik.touched.mobile ? (
                  <p style={Style.validationStyle}>{formik.errors.mobile}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 7, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  value={formik.values.team}
                  onChange={formik.handleChange}
                  type={'text'}
                  placeholder={'Please enter current team'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'team'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Current Team'}
                  displayForSkelton={newState?.loadingStatus ? 'none' : 'flex'}
                />
                {formik.errors.team && formik.touched.team ? (
                  <p style={Style.validationStyle}>{formik.errors.team}</p>
                ) : null}
                <Skeleton
                  style={Style.Skeleton}
                  sx={{
                    display: newState?.loadingStatus ? 'flex' : 'none',
                  }}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 7, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Label name={'Sport'} />
              <Box sx={{ mb: '20px' }}>
                <Select2
                  name="sports"
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      'sports',
                      selectedOption ? selectedOption.value : null,
                    )
                  }
                  options={sportsData}
                  mt={'7px'}
                  edit={true}
                  value={formik.values.sports}
                  placeholder={'Select Sport'}
                  error={formik.errors.sports}
                  errorMessage={formik?.sports?.message}
                />
                {formik.errors.sports && formik.touched.sports ? (
                  <p style={Style.validationStyle}>{formik.errors.sports}</p>
                ) : null}
                {/* <Skeleton
                    style={Style.Skeleton}
                    sx={{
                      display: newState?.loadingStatus ? 'flex' : 'none',
                    }}
                  /> */}
              </Box>
            </Item>
          </Grid>
        </Grid>

        <Box sx={Style.buttonBox}>
          <TransformButton typeSubmit={'submit'} button_name={'Update'} />
        </Box>
      </Box>

      <Toastify />
    </form>
  )
}

export default PlayerEdit
const Style = {
  Skeleton: {
    width: '100%',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  star: {
    color: 'red',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#333333',
  },
  headingText: {
    fontSize: { xs: '14px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },

  validationStyle: {
    color: 'red',
    fontWeight: 400,
  },
  mainGrid: {
    mt: '20px',
  },
  mainGrid1: {
    mt: { xs: 0, sm: '20px' },
  },
  buttonBox: {
    display: 'flex',
    justifyContent: { md: 'flex-start' },
    mt: 2,
    mb: 2,
    pr: { xs: 1, sm: 10 },
    pl: { xs: 1, sm: 10 },
  },
}
