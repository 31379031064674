import { Api } from '../../config/request'

export const requestSignupStripePayment = async (data) => {
  return Api.PostRequest('/stripe/payment', data)
}
export const requestCountries = async (data) => {
  return Api.PostRequest('/countryList', data)
}
export const requestStates = async (data) => {
  return Api.PostRequest('/stateList', data)
}
export const requestbulkpayments = async (data) => {
  return Api.PostRequest('/stripe/onlineBulkPayment', data)
}
export const requestExpiryLink = async (data) => {
  return Api.PostRequest('/user/clubSignupList',data);
}
const PaymentApiRequests = {
  requestSignupStripePayment,
  requestCountries,
  requestStates,
  requestbulkpayments,
  requestExpiryLink
}
export default PaymentApiRequests
