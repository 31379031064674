import { Api } from '../../config/request'

export const requestAdminDashboard = async (data) => {
  return Api.PostRequest('/admin/dashboard', data)
}
export const requestClubAdminDashboard = async (data) => {
  return Api.PostRequest('/club/admin/dashboard', data)
}

const DashboardApiRequests = {
  requestAdminDashboard,
  requestClubAdminDashboard,
}
export default DashboardApiRequests
