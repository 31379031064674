import React from 'react'
import { Style } from '../../Admin/CSS/Table'
import { Skeleton, TableCell, TableRow } from '@mui/material'

const ListSkelton = ({ loadingStatus, columns, width,rows }) => {
  const newArray = new Array(rows).fill('')
  const totalColumns = new Array(columns).fill('')
  return (
    <>
      {newArray.map((item,i) => (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={i}>
          {totalColumns.map((i,index) => (
            <TableCell align="left" sx={Style.table.tableCell} key={index}>
              {loadingStatus && <Skeleton sx={{ width: width}} />}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}
export default ListSkelton
