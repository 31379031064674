/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Index from '../../routes/index.js'
import {
  AccountData,
  BulkData,
  PlayerData,
  StandardAdminData,
  initialState,
  reducerFunction,
} from '../../config/mockData.js'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
  Badge,
  CircularProgress,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@emotion/react'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import './drawer.css'
import Dropdown from 'react-bootstrap/Dropdown'
import NotificationsIcon from '@mui/icons-material/Notifications'
import CheckIcon from '@mui/icons-material/Check'
import {
  CLUBDASHBOARD,
  CLUB_LOGIN,
  CLUB_PROFILE_UPDATE,
  CUSTOMER_LOGIN,
  LOGIN,
  PLAYER,
  PLAYER_LISTING,
  PROFILE,
  PROFILE_UPDATE,
} from '../../routes/constURL.js'
import AlertDialog from '../Dialog/Dialog.js'
import { useDispatch } from 'react-redux'
import {
  getAdminLogout,
  getAdminProfileRequest,
  getLoadingStatus,
} from '../../redux/login/action.js'
import { useSelector } from 'react-redux'
import { storage } from '../../config/storage.js'
import Footer from '../Footer/Footer.js'
import { Notifications } from '@mui/icons-material'
import { getClubDashboardData } from '../../redux/dashboard/dashboardAction.js'
import { getPlayerListRequest } from '../../redux/players/playerAction.js'
import {
  getnotificationnum,
  getnotifications,
} from '../../redux/clubs/clubAction.js'

let drawerWidth = 280

const PermanentDrawerRight = () => {
  const newState = useSelector((state) => state?.LoginData?.adminData)
  const { notilist, notify_number, loadingStatus } =
    useSelector((state) => state?.ClubData) || {}
  const [state, setStateReducer] = React.useReducer(
    reducerFunction,
    initialState,
  )
  const useStyles = makeStyles((theme) => ({
    badge: {
      fontSize: 50,
      height: '22px',
      width: '22px',
      borderRadius: '50px',
      '&.MuiBadge-badge': {
        top: '21px',
        right: '11px',
      },
      '&.css-a9bv5z': {
        top: '20px',
        right: '11px',
      },
    },
  }))
  const classes = useStyles()
  const Style = {
    main: {
      mt: 3,
    },

    badge: {
      // '&.css-6rgeva-MuiBadge-badge': {
      height: '25px',
      borderRadius: '175px',
      top: '11px',
      right: '7px',
      // },
    },
  }
  const theme = useTheme()
  const divRef = React.useRef(null)
  const divRef1 = React.useRef(null)
  const divRef2 = React.useRef(null)
  const notiRef1 = React.useRef(null)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'))
  isMobile ? (drawerWidth = 230) : (drawerWidth = 300)
  const navigate = useNavigate()
  const myDispatch = useDispatch()
  const login_user = storage.fetch.role()
  const location = useLocation()
  const [open, setOpen] = React.useState(isIpad ? false : true)
  const [openLogoutAlert, setOpenLogoutAlert] = React.useState(false)
  const [notification, setNotifications] = React.useState(notilist)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [underProgress, setUnderProgress] = React.useState(false)
  const [data, setData] = React.useState(
    login_user == 'club_admin'
      ? BulkData
      : login_user == 'player'
        ? PlayerData
        : login_user == 'standard_admin'
          ? StandardAdminData
          : AccountData,
  )

  let data1 = {
    start: 0,
    length: 5,
    sort_column: '',
    sort_by: '',
    search_val: '',
  }
  const [image, setImage] = React.useState(storage.fetch.fetchImage())
  const not =
    notify_number?.data?.unread_notification_count === 0 ||
    notify_number?.data?.unread_notification_count === undefined
  const dropdown = login_user === 'admin' || login_user === 'club_admin'
  //console.log('list', notify_number)
  let URL = location.pathname
  const trimmedURL = URL.slice(0, 15)
  React.useEffect(() => {
    const trimmedURL = URL.slice(0, 6)
    if (notilist !== undefined) setNotifications(notilist)
    data.map((item, index) => {
      let trimmedRoute = item?.routes?.slice(0, 6)
      console.log(trimmedRoute, trimmedURL)
      if (item) {
        trimmedURL === trimmedRoute
          ? (item.isActive = true)
          : (item.isActive = false)
      }
    })
    setData([...data])

    const handleClickOutside = (event) => {
      if (
        divRef.current &&
        !divRef.current.contains(event.target) &&
        event.target.id !== 'profile'
      ) {
        setStateReducer({ open: false })
      }
      if (
        divRef1.current &&
        !divRef1.current.contains(event.target) &&
        event.target.id !== 'notify'
      ) {
        setStateReducer({ open1: false })
      }
      if (
        (divRef2.current &&
          !divRef2.current.contains(event.target) &&
          event.target.id !== 'drawer' &&
          isMobile) ||
        isIpad
      ) {
        setOpen(false)
      }
    }

    // Attach event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside)

    // Detach event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [location.pathname, underProgress, notilist, notify_number])
  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1200) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    }

    // Attach the event listener to the window object
    window.addEventListener('resize', handleResize)

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  React.useEffect(() => {
    if (login_user === 'club_admin' || login_user === 'admin')
      myDispatch(getnotificationnum())
    setTimeout(() => {
      setImage(storage.fetch.fetchImage())
    }, 2000)
    console.log('data', data)
  }, [location, newState, storage.fetch.fetchImage(), notification])
  const handleScroll = () => {
    const container = notiRef1.current
    // console.log(container)

    // Calculate the distance scrolled
    const scrolled = container.scrollTop

    // Calculate the total height of the content
    const totalHeight = container.scrollHeight

    // Calculate the height of the visible portion
    const visibleHeight = container.clientHeight

    // Calculate the remaining space
    const remainingSpace = totalHeight - (scrolled + visibleHeight)

    console.log(remainingSpace)
    // if (scrolled > 300) {
    //   setVisible(true)
    // } else if (scrolled <= 300) {
    //   setVisible(false)
    // }
    if (remainingSpace < 10) {
      // If the remaining space is less than 10 pixels, fetch more data
      // console.log(details)
      fetchMoreData()
    }
  }
  const fetchMoreData = async () => {
    const all = storage.fetch.posts()
    // console.log(all)
    if (all.filtered_records < all.total_records) {
      data1['length'] = data1['length'] + 5
      myDispatch(getnotifications(data1))

      // myD100)
    }
  }
  React.useEffect(() => {
    const notiElement = notiRef1.current
    console.log('notiElement', notiElement)
    storage.set.posts(notification?.data)

    if (notiElement) {
      notiElement.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (notiElement) {
        notiElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [notification])
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  const Logout = (e) => {
    setOpenLogoutAlert(true)
    setStateReducer({ open: false })
  }
  // //console.log('no', notification)
  const logOutAdmin = () => {
    setOpenLogoutAlert(false)
    myDispatch(getAdminLogout())
    if (login_user === 'club_admin') {
      navigate(CLUB_LOGIN)
    } else if (login_user === 'standard_admin') {
      navigate(LOGIN)
    } else if (login_user === 'admin') {
      navigate(LOGIN)
    } else {
      navigate(CUSTOMER_LOGIN)
    }
    // setTimeout(() => {
    localStorage.clear()
    // }, 2000)
  }
  const openMenu = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: '100%' }}>
        <CssBaseline />
        <AppBar
          className={open && isIpad ? 'hightlight-body' : ''}
          sx={{
            bgcolor: '#ffffff',
            display: 'flex',
            boxShadow: 'none',
            borderBottom: '2px solid rgba(0, 0, 0, 0.06)',
            pt: 1.5,
            pb: 1.5,
            '&.MuiAppBar-root': {
              position: 'unset',
            },
          }}
        >
          <Toolbar>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: {
                  xs: open ? 'flex-end' : 'space-between',
                  lg: 'flex-end',
                },
                alignItems: 'center',
                gap: '10px',
              }}
            >
              {!open && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    color: '#B2C1F0',
                    display: { xs: 'flex', lg: 'none' },
                    width: '50px',
                    height: '50px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <MenuIcon sx={{ fontSize: '35px' }} />
                </IconButton>
              )}
              <Box sx={{ display: 'flex' }}>
                {login_user === 'club_admin' && not && (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => {
                      login_user === 'club_admin' &&
                        setStateReducer({ open1: !state.open1 })
                      myDispatch(getnotifications(data1))
                      myDispatch(getnotificationnum())
                      getLoadingStatus(true)

                      // if (location.pathname === CLUBDASHBOARD)
                      //   // myDispatch(getClubDashboardData())
                      // // else if (location.pathname === PLAYER_LISTING)
                      //   myDispatch(getPlayerListRequest(data1))
                      // else myDispatch(getAdminProfileRequest())
                    }}
                    edge="start"
                    sx={{
                      color: '#B2C1F0',
                      display: { xs: 'flex' },
                      mr: '18px',
                      height: '10px',
                      width: '10px',
                      mt: '22px',
                    }}
                  >
                    <NotificationsIcon id="notify" />
                  </IconButton>
                )}
                :
                {login_user === 'admin' && not && (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => {
                      login_user === 'admin' &&
                        setStateReducer({ open1: !state.open1 })
                      myDispatch(getnotifications(data1))
                      myDispatch(getnotificationnum())
                      getLoadingStatus(true)
                      // if (location.pathname === CLUBDASHBOARD)
                      //   // myDispatch(getClubDashboardData())
                      // // else if (location.pathname === PLAYER_LISTING)
                      //   myDispatch(getPlayerListRequest(data1))
                      // else myDispatch(getAdminProfileRequest())
                    }}
                    edge="start"
                    sx={{
                      color: '#B2C1F0',
                      display: { xs: 'flex' },
                      mr: '18px',
                      height: '10px',
                      width: '10px',
                      mt: '22px',
                    }}
                  >
                    <NotificationsIcon id="notify" />
                  </IconButton>
                )}
                {login_user === 'club_admin' && !not && (
                  <Badge
                    badgeContent={
                      <Typography>
                        {notify_number?.data?.unread_notification_count}
                      </Typography>
                    }
                    size={'small'}
                    // height={'35px'}
                    color="error"
                    classes={{ badge: classes.badge }}
                  >
                    {/* {//console.log(not)} */}
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => {
                        login_user === 'club_admin' &&
                          setStateReducer({ open1: !state.open1 })
                        myDispatch(getnotifications(data1))
                        myDispatch(getLoadingStatus(true))
                        // if (location.pathname === CLUBDASHBOARD) {
                        // }
                        // // myDispatch(getClubDashboardData())
                        // else if (location.pathname === PLAYER_LISTING)
                        //   myDispatch(getPlayerListRequest(data1))
                        // else myDispatch(getAdminProfileRequest())
                      }}
                      edge="start"
                      sx={{ color: '#B2C1F0', display: { xs: 'flex' } }}
                    >
                      <NotificationsIcon id="notify" />
                    </IconButton>
                    {/* {storage.fetch.notification() === 0
                  ? null
                  :  */}
                    {/* {storage.fetch.notification()} */}
                  </Badge>
                )}
                :
                {login_user === 'admin' && !not && (
                  <Badge
                    badgeContent={
                      <Typography>
                        {notify_number?.data?.unread_notification_count}
                      </Typography>
                    }
                    size={'small'}
                    height={'35px'}
                    color="error"
                    classes={{ badge: classes.badge }}
                  >
                    {/* {//console.log(not)} */}
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => {
                        login_user === 'admin' &&
                          setStateReducer({ open1: !state.open1 })
                        myDispatch(getnotifications(data1))
                        myDispatch(getnotificationnum())
                        getLoadingStatus(true)
                        // if (location.pathname === CLUBDASHBOARD) {
                        // }
                        // // myDispatch(getClubDashboardData())
                        // else if (location.pathname === PLAYER_LISTING)
                        //   myDispatch(getPlayerListRequest(data1))
                        // else myDispatch(getAdminProfileRequest())
                      }}
                      edge="start"
                      sx={{ color: '#B2C1F0', display: { xs: 'flex' } }}
                    >
                      <NotificationsIcon id="notify" />
                    </IconButton>
                    {/* {storage.fetch.notification() === 0
                  ? null
                  :  */}
                    {/* {storage.fetch.notification()} */}
                  </Badge>
                )}
                {/* <Dropdown className="d-inline mx-2">
                <Dropdown.Toggle id="dropdown-autoclose-true">
                  drop
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#">Players</Dropdown.Item>
                  <Dropdown.Item href="#">Profile</Dropdown.Item>
                  <Dropdown.Item href="#">Sign out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
                {login_user === 'club_admin' || login_user === 'admin' ? (
                  <Button
                    onClick={() => {
                      dropdown && setStateReducer({ open: !state.open })
                    }}
                    style={{ display: 'flex', textDecoration: 'none' }}
                  >
                    <Box
                      sx={{
                        border: 1,
                        borderRadius: '60px',
                        borderColor: 'black',
                        width: '50px',
                        height: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        style={{
                          height: '42px',
                          width: '42px',
                          borderRadius: '60px',
                          // filter: 'invert(100%)',
                        }}
                        id="profile"
                        alt="profile"
                        src={storage.fetch.fetchImage()}
                      />
                    </Box>
                  </Button>
                ) : (
                  <Box
                    sx={{
                      border: 1,
                      borderRadius: '60px',
                      borderColor: 'black',
                      width: '50px',
                      height: '50px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      style={{
                        height: '42px',
                        width: '42px',
                        borderRadius: '60px',
                        // filter: 'invert(100%)',
                      }}
                      id="profile"
                      alt="profile"
                      src={storage.fetch.fetchImage()}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        {state.open && login_user === 'club_admin' ? (
          <Box
            sx={{
              color: 'black',
              zIndex: 99999,
              // width: '97%',
              display: 'flex',
              justifyContent: 'end',
              // position: 'relative',
              // '&.Mui-Box': {
              position: 'absolute',
              background: '#fff',
              left: 'auto',
              right: '37px',
              border: 'none',
              top: '78px',
              borderRadius: '20px',
              border: '2px solid #E0E0E0',
              // },
            }}
          >
            <List
              ref={divRef}
              sx={{
                p: 0,
                borderRadius: '20px',
                width: '220px',
              }}
            >
              <ListItem
                sx={{
                  padding: '0px',
                  borderBottom: '1px solid #E0E0E0',
                  '&.MuiList-root:hover': {
                    background: '#E9E8FF',
                  },
                }}
              >
                <Button
                  onClick={() => {
                    navigate(PLAYER_LISTING)
                    setStateReducer({ open: false })
                  }}
                  style={{
                    textDecoration: 'none',
                    textTransform: 'none',
                    padding: '10px 30px 10px 20px',
                    fontWeight: 600,
                    maxWidth: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    color: 'black',
                  }}
                >
                  Player
                </Button>
              </ListItem>
              <ListItem
                sx={{
                  padding: '0px',
                  borderBottom: '1px solid #E0E0E0',
                  '&.MuiList-root:hover': {
                    background: '#E9E8FF',
                  },
                }}
              >
                <Button
                  onClick={() => {
                    navigate(CLUB_PROFILE_UPDATE)
                    setStateReducer({ open: false })
                  }}
                  style={{
                    textDecoration: 'none',
                    textTransform: 'none',
                    padding: '10px 30px 10px 20px',
                    fontWeight: 600,
                    maxWidth: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    color: 'black',
                  }}
                >
                  Profile
                </Button>
              </ListItem>
              <ListItem
                sx={{
                  padding: '0px',
                  borderBottom: '1px solid #E0E0E0',
                  '&.MuiList-root:hover': {
                    background: '#E9E8FF',
                  },
                }}
              >
                <Button
                  onClick={() => Logout()}
                  style={{
                    textDecoration: 'none',
                    textTransform: 'none',
                    fontWeight: 600,
                    maxWidth: '100%',
                    width: '100%',
                    color: ' #D80027',
                    padding: '10px 30px 10px 20px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  Sign out
                </Button>
              </ListItem>
            </List>
          </Box>
        ) : (
          state.open && (
            <Box
              sx={{
                color: 'black',
                zIndex: 99999,
                // width: '97%',
                display: 'flex',
                justifyContent: 'end',
                // position: 'relative',
                // '&.Mui-Box': {
                position: 'absolute',
                background: '#fff',
                left: 'auto',
                right: '37px',
                border: 'none',
                top: '78px',
                borderRadius: '20px',
                border: '2px solid #E0E0E0',
                // },
              }}
            >
              <List
                ref={divRef}
                sx={{
                  p: 0,
                  borderRadius: '20px',
                  width: '220px',
                }}
              >
                {AccountData.slice(0, AccountData.length - 1).map((item, i) => (
                  <ListItem
                    sx={{
                      padding: '0px',
                      borderBottom: '1px solid #E0E0E0',
                      '&.MuiList-root:hover': {
                        background: '#E9E8FF',
                      },
                    }}
                  >
                    <Button
                      onClick={() => {
                        navigate(item.routes)
                        setStateReducer({ open: false })
                      }}
                      style={{
                        textDecoration: 'none',
                        textTransform: 'none',
                        padding: '10px 30px 10px 20px',
                        fontWeight: 600,
                        maxWidth: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        color: 'black',
                      }}
                    >
                      {item.val}
                    </Button>
                  </ListItem>
                ))}
                <Button
                  onClick={(e) => Logout(e)}
                  style={{
                    textDecoration: 'none',
                    textTransform: 'none',
                    fontWeight: 600,
                    maxWidth: '100%',
                    width: '100%',
                    color: ' #D80027',
                    padding: '10px 30px 10px 20px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  Sign out
                </Button>
              </List>
            </Box>
          )
        )}
        {state.open1 && (
          <Box
            sx={{
              color: 'black',
              zIndex: 99999,
              // width: '97%',
              display: 'flex',
              justifyContent: 'end',
              // position: 'relative',
              // '&.Mui-Box': {
              position: 'absolute',
              background: '#fff',
              width: 'auto',
              left: 'auto',
              right: { xs: '60px', md: '100px' },
              border: 'none',
              top: '78px',
              // },
            }}
          >
            <List
              ref={divRef1}
              sx={{
                p: 0,
                border: '1px solid #CDCAFC',
                width: '300px',
              }}
            >
              <Typography
                sx={{ borderBottom: '2px solid black', p: 1, fontWeight: 700 }}
              >
                Notifications
              </Typography>
              {notification?.data?.notification_list.length === 0 && (
                <ListItem
                  sx={{
                    padding: '0px',
                    display: 'flex',
                  }}
                >
                  <Box sx={{ display: 'flex', p: 2, width: '100%' }}>
                    <Typography>No Notifications</Typography>
                  </Box>
                </ListItem>
              )}
              {loadingStatus &&
                notification &&  (
                  <ListItem
                    sx={{
                      padding: '0px',
                      display: 'flex',
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress
                        sx={{
                          display: 'flex',
                          overflowX: 'hidden',
                          height: '60px',
                          width: '60px',
                        }}
                      />
                    </Box>
                  </ListItem>
                )}
              <Box
                ref={notiRef1}
                sx={{
                  maxHeight: '300px',
                  overflow: 'scroll',
                }}
                className="timeline"
              >
                {notification?.data?.notification_list.map((row) => (
                  <ListItem
                    sx={{
                      padding: '0px',
                      display: 'flex',
                      borderBottom: '1px solid gray',
                    }}
                  >
                    <Box sx={{ display: 'flex', p: 2, width: '100%' }}>
                      <img
                        src={
                          row?.created_by_image
                            ? row?.created_by_image
                            : require('../../assets/profileRect.png')
                        }
                        style={{
                          borderRadius: '50%',
                          width: '50px',
                          height: '50px',
                          marginRight: '10px',
                        }}
                      />
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: '#1F1F1F',
                              fontWeight: 700,
                            }}
                          >
                            {row?.created_by}
                          </Typography>
                          <Typography
                            sx={{ fontSize: '14px', color: '#767676' }}
                          >
                            {row?.formatted_date}
                          </Typography>
                        </Box>
                        <Typography sx={{ fontSize: '14px', color: '#767676' }}>
                          {row?.data}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
                {notification?.data?.total_records != 0 &&
                  notification?.data?.filtered_records !==
                    notification?.data?.total_records && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress
                        sx={{
                          display: 'flex',
                          overflowX: 'hidden',
                          height: '60px',
                          width: '60px',
                        }}
                      />
                    </Box>
                  )}
              </Box>
            </List>
          </Box>
        )}
        <Drawer
          sx={{
            minWidth: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
            ml: '30px',
            mr: '30px',
          }}
          ref={divRef2}
          id="drawer"
          variant="persistent"
          anchor="left"
          open={open}
        >
          <Box
            className="side-logo"
            sx={{
              p: { xs: 1.8, sm: '30px' },
              display: 'flex',
              mt: '20px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Link
              to={PROFILE_UPDATE}
              style={{
                textDecoration: 'none',
                display: 'flex',
              }}
            >
              <Box>
                {isMobile && (
                  <img
                    alt="logo"
                    className="logoSize"
                    src={require('../../assets/logo-11.png')}
                    style={{ width: '150px' }}
                  />
                )}
                {!isMobile && (
                  <img
                    alt="logo"
                    className="logoSize"
                    src={require('../../assets/logo-11.png')}
                    style={{ width: '200px' }}
                  />
                )}
              </Box>
            </Link>
            {open && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerClose}
                edge="start"
                sx={{
                  color: '#B2C1F0',
                  display: { xs: 'flex', lg: 'none' },
                  ml: '5px',
                }}
              >
                <CloseIcon
                  sx={{ fontSize: { xs: '20px', sm: '20px', md: '35px' } }}
                />
              </IconButton>
            )}
          </Box>

          <List
            className="drawerItem"
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              '& .MuiListItemButton-root:hover': {
                // bgcolor: "white",
                borderRadius: '8px',
                fontWeight: '400',

                '&, & .MuiListItemIcon-root': {
                  // color: "#3D2E57",
                  fontWeight: '400',
                  borderRadius: '8px',
                },
              },
              p: { xs: '0px 10px 0px 10px', sm: '0px 40px 8px 40px' },
            }}
          >
            {data.map((text, index) => (
              <ListItem
                sx={{
                  bgcolor: text?.isActive
                    ? '#27245E'
                    : index === data.length - 1
                      ? '#FDF0EC'
                      : '#EEF2FC',
                  borderRadius: text?.isActive ? '8px' : '8px',
                  border: '1px solid #E0E0E0',
                  mb: '15px',
                }}
                key={index}
                disablePadding
              >
                <Link
                  className="drawerItemLinks"
                  style={{
                    color: text?.isActive
                      ? 'white'
                      : index === data.length - 1
                        ? '#81290E'
                        : '#27245E',
                    width: '100%',
                  }}
                  to={text?.routes}
                  onClick={() => (
                    index === data.length - 1 ? Logout() : '',
                    isMobile || isIpad ? setOpen(false) : null
                  )}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '35px',
                    }}
                  >
                    <img
                      style={{
                        width: '18px',
                        filter: text?.isActive
                          ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)'
                          : '',
                      }}
                      alt="profile"
                      src={text?.src}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontWeight: '400',
                      fontSize: '16px',
                    }}
                    primary={text?.val}
                  />
                </Link>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box
          sx={{
            width: {
              lg: open ? `calc(100% - ${drawerWidth}px)` : '100%',
              xs: '100%',
            },
            ml: open ? 'auto' : '',
          }}
          className={open && isIpad ? 'hightlight-body' : ''}
        >
          <Box
            sx={{ p: { xs: '40px 15px 40px 15px', sm: '40px 40px 40px 40px' } }}
          >
            <Index />
          </Box>
        </Box>
        {/* //footer html// */}
        <Footer />

        <AlertDialog
          title={'Are you sure you want to logout?'}
          open={openLogoutAlert}
          onClickButtonCancel={() => setOpenLogoutAlert(false)}
          onClickButton={() => logOutAdmin()}
        />
      </Box>
    </Box>
  )
}
export default PermanentDrawerRight
