import { put, takeEvery } from 'redux-saga/effects'
import LoginApi from '../../services/login'
import {
  GET_CLUB_SIGNUP,
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  GET_NOTIFICATION_NUMBER,
  GET_SAGA_CHECK,
  GET_USERS_FAILURE,
  SET_CLUB_SIGNUP,
} from '../ConstAction'
import { toast } from 'react-toastify'
import SignupApiRequests from '../../services/signup'
import { CUSTOMER_LOGIN } from '../../routes/constURL'
// import { GET_USERS_FETCH, GET_USERS_SUCCESS } from "./action";
function* HandleApiResponse(response, successAction, errorMessage, data) {
  ////console.log('response', response)
  if (response.data.status) {
    // //console.log(response.data.message)

    // yield put({
    //   type: successAction,
    //   response,
    // })
    toast.success(response.data.message)
    setTimeout(() => {
      data.navigate(CUSTOMER_LOGIN)
    }, 1000)
  } else {
    //console.log('response', response)

    // toast.error(response.message)
    toast.error(response.data.errors[0])
    yield put({
      type: GET_LOADING_STATUS,
      data: false,
    })
  }
  // toast.error(response.data.message[0])
  yield put({
    type: GET_LOADING_STATUS,
    data: false,
  })
}
function* getCallApi(data) {
  try {
    let response = yield SignupApiRequests.requestClubSignUp(data.data)
    yield HandleApiResponse(
      response,
      SET_CLUB_SIGNUP,
      'Failed to Sign up',
      data,
    )
  } catch (err) {}
}

function* Saga2() {
  yield takeEvery(GET_CLUB_SIGNUP, getCallApi)
}
export default Saga2
