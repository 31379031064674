import {
  Box,
  FormLabel,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputField from '../../Components/InputField'
import { Schema } from '../../Components/FormSchema/Schema'
import RowRadioButtonsGroup from '../../Components/RadioButtons/RadioButton'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import Toastify from '../../Components/SnackBar/Toastify'
import TransformButton from '../../Components/CustomButton/TransformButton'
import EditIcon from '@mui/icons-material/Edit'
import { getLoadingStatus } from '../../redux/login/action'
import { BULK_ORDERS_LISTING } from '../../routes/constURL'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import SelectComponent from '../../Components/SelectComponent'
import { getClubAddUpdateRequest } from '../../redux/clubs/clubAction'
import { BulkData, programs, sportsData } from '../../config/mockData'
import Select2 from '../../Components/Select2'
import LoaderComponent from '../../Components/Loader/LoaderComponent'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))

const AddBulkOrder = () => {
  const [club_type, setClubType] = useState()
  const [image_uploaded, setImage_uploaded] = useState(
    require('../../assets/profilepic.png'),
  )
  const [clubData, setClubData] = useState({
    club_name: '',
    name: '',
    email: '',
    mobile: '',
    sports: '',
    num_of_licenses: '',
    membership: 'Select Program',
    club_type: 'club',
    amount: '',
    subscription_link: '',
  })
  const [image_binary, setImage_binary] = useState('')
  const [membershiperror, setmembershiperror] = useState('')
  const [membership, setMembership] = useState('Select Program')
  const newState = useSelector((state) => state?.ClubData)
  const navigate = useNavigate()

  useEffect(() => {
    // ////console.log(newState)
    if (newState?.URL) {
      setTimeout(() => {
        navigate(BULK_ORDERS_LISTING)
      }, 1000)
    }
  }, [newState?.URL])

  const { control, formState, handleSubmit, setError } = useForm({
    mode: 'onChange',
    clubData,
    resolver: yupResolver(Schema.ADD_BULK_ORDER),
  })
  const { isValid, dirtyFields, errors } = formState
  console.log(errors)
  const onSubmit = (value) => {
    const formData = new FormData()
    // ////console.log()
    formData.append('club_name', value.club_name)
    formData.append('f_name', value.name)
    formData.append('email', value.email)
    formData.append('mobile', value.mobile)
    formData.append('num_of_licenses', value.num_of_licenses)
    formData.append('membership', value.membership)
    formData.append('sports', value.sports)
    formData.append('amount', value.amount)
    // formData.append('users_id', value.users_id)
    formData.append('password', value.password)
    formData.append('password_confirmation', value.password_confirmation)
    formData.append('role', 'club_admin')
    formData.append('club_type', clubData.club_type)
    // formData.append('subscription_link', value.subscription_link)
    if (image_binary) formData.append('image', image_binary)
    myDispatch(getClubAddUpdateRequest(formData))
    myDispatch(getLoadingStatus(true))
  }
  const myDispatch = useDispatch()

  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )
  const handleChangeType = (e) => {
    setClubData((prevUserData) => ({
      ...prevUserData, // Shallow copy of the old object
      club_type: e.target.value, // Update the specific key with the new value
    }))
  }
  const handleFileChange = (e) => {
    // ////console.log('hii', e.target.files[0])
    setImage_binary(e.target.files[0])
    setImage_uploaded(URL.createObjectURL(e.target.files[0]))
    // //console.log(image_uploaded)
  }

  return (
    <form name="bulkOrder" onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Typography variant="h2" sx={Style.headingText}>
          {'Add New Bulk Order'}
        </Typography>
        <LoaderComponent open={newState?.loadingStatus} />
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid
            sx={Style.mainGrid}
            item
            order={{ xs: 2, sm: 0 }}
            xs={12}
            sm={6}
          >
            <Item>
              <Box sx={{ mb: '20px' }}>
                <InputField
                  control={control}
                  helperText={errors?.club_name?.message}
                  errors={!!errors.club_name}
                  type={'text'}
                  placeholder={'Please enter club name'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'club_name'}
                  required={'*'}
                  formlabel={'Club Name'}
                />
              </Box>
              <Box sx={{}}>
                <InputField
                  control={control}
                  helperText={errors?.name?.message}
                  errors={!!errors.name}
                  type={'text'}
                  placeholder={'Please enter name'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'name'}
                  required={'*'}
                  formlabel={'Club Owner Name'}
                />
              </Box>
            </Item>
          </Grid>
          <Grid
            order={{ xs: 1, sm: 0 }}
            sx={Style.mainGrid}
            item
            xs={12}
            sm={6}
          >
            <Item
              sx={{
                boxShadow: 'none',
                cursor: 'pointer',
                borderRadius: '100%',
                maxWidth: { xs: '145px', sm: '170px' },
                p: 1,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  width: '30px',
                  height: '30px',
                  bgcolor: '#d80027',
                  color: 'white',
                  borderRadius: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  right: '14px',
                }}
              >
                <EditIcon
                  fontSize="30px"
                  sx={{ position: 'absolute', width: '1.5em', height: '1.5em' }}
                  // style={style.iconEye}
                />
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleFileChange}
                  style={{
                    position: 'absolute',
                    opacity: 0,
                    height: '30px',
                    width: '30px',
                  }}
                  // Ref for triggering file input click
                />
              </Box>
              <img
                alt="profileimage"
                className="profileImage"
                // src={vehicData?.photo_links[selected]?.image_url}
                src={image_uploaded}

                // onClick={() => setOpen(true)}
              />
            </Item>
          </Grid>
          <Grid order={{ xs: 3, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Box sx={{}}>
                <InputField
                  control={control}
                  helperText={errors?.email?.message}
                  errors={!!errors.email}
                  type={'text'}
                  placeholder={'Please enter email'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'email'}
                  required={'*'}
                  formlabel={'Email'}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 3, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Box sx={{}}>
                <InputField
                  control={control}
                  helperText={errors?.mobile?.message}
                  errors={!!errors.mobile}
                  type={'number'}
                  placeholder={'Please enter phone'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'mobile'}
                  required={'*'}
                  formlabel={'Phone'}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 4, sm: 0 }} item xs={12} sm={6}>
            <Label name={'Club type'} />
            <div>
              <RowRadioButtonsGroup
                labelItems={[
                  { label: 'Club', value: 'club' },
                  { label: 'School', value: 'school' },
                  { label: 'Others', value: 'others' },
                ]}
                defaultValue={clubData.club_type}
                handleChange={handleChangeType}
              />
            </div>
          </Grid>
          <Grid order={{ xs: 5, sm: 0 }} item xs={12} sm={6}>
            <Item>
              <Label name={'Club Sport'} />
              <Box sx={{ mb: '20px' }}>
                <Select2
                  name="sports"
                  control={control}
                  options={sportsData}
                  placeholder={'Select Sport'}
                  mt={'7px'}
                  // defaultValue={Allcountries[0]}
                  error={!!errors.sports}
                  errorMessage={errors?.sports?.message}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 6, sm: 0 }} item xs={12} sm={3}>
            <Item>
              <Box sx={{}}>
                <InputField
                  control={control}
                  helperText={errors?.num_of_licenses?.message}
                  errors={!!errors.num_of_licenses}
                  type={'number'}
                  placeholder={'Please enter licences'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'num_of_licenses'}
                  required={'*'}
                  formlabel={'Number of licences'}
                />
              </Box>
            </Item>
          </Grid>
          <Grid
            order={{ xs: 7, sm: 0 }}
            item
            xs={12}
            sm={9}
            sx={{ mt: { sm: '-0.5%', md: '-0.38%' } }}
          >
            <Item>
              {/* <Label name={'Program'} />
              <div>
                <Controller
                  name="membership"
                  control={control}
                  defaultValue={clubData.membership}
                  render={({ field }) => (
                    <SelectComponent
                      mt={'10px'}
                      error={!!errors.membership}
                      errorMessage={errors?.membership?.message}
                      value={field.value}
                      handleChangeForSelect={field.onChange}
                      menuData={programs}
                    />
                  )}
                />
              </div> */}

              <Label name={'Program'} />
              <Box sx={{ mb: '20px' }}>
                <Select2
                  name="membership"
                  control={control}
                  options={programs}
                  mt={'7px'}
                  // defaultValue={Allcountries[0]}
                  error={!!errors.membership}
                  errorMessage={errors?.membership?.message}
                />
              </Box>
            </Item>
          </Grid>
          <Grid order={{ xs: 8, sm: 0 }} item xs={12} sm={3}>
            <Item>
              <InputField
                control={control}
                helperText={errors?.amount?.message}
                errors={!!errors.amount}
                type={'number'}
                amount={true}
                placeholder={'Please enter amount'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
                name={'amount'}
                required={'*'}
                formlabel={'Amount'}
              />
            </Item>
          </Grid>
          <Grid order={{ xs: 8, sm: 0 }} item xs={12} sm={9}></Grid>
          <Grid order={{ xs: 8, sm: 0 }} item xs={12} sm={6}>
            <Item
              sx={{
                '& input[type="password"]::-ms-reveal': { display: 'none' },
              }}
            >
              <InputField
                control={control}
                helperText={errors?.password?.message}
                errors={!!errors.password}
                type={'password'}
                placeholder={'Please enter password'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
                name={'password'}
                required={'*'}
                formlabel={'Password'}
              />
            </Item>
          </Grid>
          <Grid order={{ xs: 8, sm: 0 }} item xs={12} sm={6}>
            <Item
              sx={{
                '& input[type="password"]::-ms-reveal': { display: 'none' },
              }}
            >
              <InputField
                control={control}
                helperText={errors?.password_confirmation?.message}
                errors={!!errors.password_confirmation}
                type={'password'}
                placeholder={'Please confirm your password'}
                size={{ xs: '18px', md: '18px', lg: '18px' }}
                color={'#333333'}
                name={'password_confirmation'}
                required={'*'}
                formlabel={'Confirm Password'}
              />
            </Item>
          </Grid>
        </Grid>

        <Box sx={Style.buttonBox}>
          <TransformButton typeSubmit={'submit'} button_name={'Submit'} />
        </Box>
      </Box>

      <Toastify />
    </form>
  )
}

export default AddBulkOrder
const Style = {
  Skeleton: {
    width: '100%',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  star: {
    color: '#d32f2f',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#333333',
  },
  headingText: {
    fontSize: { xs: '14px', sm: '31px' },
    fontWeight: '700',
    color: '#000000',
    // p: { xs: 1, sm: 3 },
    display: 'flex',
    alignItems: 'center',
  },

  validationStyle: {
    color: '#d32f2f',
    margin: '10px',
    fontWeight: '500',
  },
  mainGrid: {
    mt: '20px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: { md: 'flex-start' },
    mt: 2,
    mb: 2,
    pr: { xs: 1, sm: 10 },
    pl: { xs: 1, sm: 10 },
  },
}
