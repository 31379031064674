import { Box } from '@mui/material'
import React from 'react'

const Footer = () => {
  return (
    <Box
      sx={{
        width: '100%',
        color: '#fff',
        backgroundColor: '#27245E',
        position: 'fixed',
        bottom: '0',
        zIndex: '9999',
        textAlign: 'center',
        fontSize: '12px',
      }}
    >
      <footer>
        <p>
        © Copyright 2024 - OG Sports is Property of OG Sports Group Pty Ltd.
        </p>
      </footer>
    </Box>
  )
}

export default Footer
