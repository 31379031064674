import { Grid, Modal } from '@mui/material'
import React from 'react'
import JumpingForm from '../Forms/JumpingForm'

const MeasureandJumpingModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* <JumpingForm Style={Style} data={data} customer={true}/>  */}
    </Modal>
  )
}

export default MeasureandJumpingModal
