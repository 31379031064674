import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Grid, Paper, useMediaQuery } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  borderRadius: 'unset',
}))
const ProfileTab = ({ item_route, item_text, URL, style }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('xs'))
  const isdes = useMediaQuery(theme.breakpoints.up('md'))
  console.log(URL, item_route)
  const route = URL.slice(0, 13)
  console.log(route)
  return (
    <Grid>
      <Link style={{ textDecoration: 'none' }} to={item_route}>
        <Item
          sx={style}
          style={{
            color: item_route === URL ? 'white' : 'black',
            backgroundColor: item_route === URL ? '#D80027' : '#F5F4FF',
            width: isdes
              ? '100%'
              : route === '/program/week'
                ? '114px'
                : '171px',
            fontSize: '12px',
          }}
        >
          {item_text}
        </Item>
      </Link>
    </Grid>
  )
}

export default ProfileTab
