import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect, useLayoutEffect, useReducer, useState } from 'react'
import {
  generateYears,
  initialState,
  months,
  reducerFunction,
  sportsData,
} from '../../config/mockData'
import Select2 from '../Select2'
import { styled } from '@mui/styles'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import {
  getSignupPayment,
  getcountries,
  getstates,
} from '../../redux/payments/paymentAction'
import { getLoadingStatus } from '../../redux/login/action'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { storage } from '../../config/storage'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LoaderComponent from '../Loader/LoaderComponent'
import InputField from '../InputField'
import SelectComponent from '../SelectComponent'
import Toastify from '../SnackBar/Toastify'
import TransformButton from '../CustomButton/TransformButton'
import { yupResolver } from '@hookform/resolvers/yup'
import { Schema } from '../FormSchema/Schema'
import Subscription from '../Subscription/Subscription'
import Footer from '../Footer/Footer'
import { BorderRight, DisabledByDefault } from '@mui/icons-material'
import { useTheme } from '@emotion/react'
const years = generateYears()

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
  '&.css-1ps6pg7-MuiPaper-root ': {
    boxShadow: 'none',
  },
  '&.css-aoeo82': {
    boxShadow: 'none',
  },
}))
const Label = ({ name }) => (
  <FormLabel sx={{ ...Style.label }}>
    {name}
    <span style={Style.star}>{name === 'Country' ? '*' : ''}{name === 'Sport' ? '*' : ''}</span>
  </FormLabel>
)
const defaultValue = {
  f_name: '',
  l_name: '',
  address: '',
  email: '',
  password: '',
  password_confirmation: '',
  name_on_card: '',
  card_number: '',
  exp: '',
  card_cvc: '',
  postal_code: '',
  city: '',
  sports: '',
  country: '',
  state: '',
  check: '',
  check1: '',
}

const Signup = () => {
  const { countries, states, loadingStatus } = useSelector(
    (state) => state?.PaymentData || {},
  )
  const postalDetails = storage.fetch.PostalDetails()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const [Allcountries, setallCountries] = useState([])
  const [Allstates, setAllstates] = useState([])
  useEffect(() => {
    console.log('Allcountries', Allcountries, countries)
    //console.log(states,state.country);
    const transformedCountries = countries?.map((value) => ({
      value: `${value.country_id}`,
      label: value.name,
      postal_length: value.postal_code_regex,
    }))
    setallCountries(transformedCountries)

    console.log(Allcountries)
  }, [countries])
  const handleKeyDown = (event) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']
    if (!allowedKeys.includes(event.key) && isNaN(Number(event.key))) {
      event.preventDefault()
    }
  }

  const handleChange = (event) => {
    let value = event.target.value
    if (value.length > 7) {
      value = value.slice(0, 7)
    }
    value = value
      .replace(/^([1-9]\/|[2-9])$/g, '0$1/')
      .replace(/^(0[1-9]|1[0-2])$/g, '$1/')
      .replace(/^([0-1])([3-9])$/g, '0$1/$2')
      .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2')
      .replace(/^([0]+)\/|[0]+$/g, '0')
      .replace(/[^\d\/]|^[\/]*$/g, '')
      .replace(/\/\//g, '/')

    event.target.value = value
  }

  useEffect(() => {
    console.log('states', states)
    if (states && states.length === 0) {
      setStateReducer({ state: null })
    }
    const transformedCountries = states?.map((value) => ({
      value: `${value.id}`,
      label: value.name,
    }))
    setAllstates(transformedCountries)

    console.log('////', Allstates)
  }, [states])
  const handleCountryChange = (e) => {
   // console.log('///', e.value)
    const countryId = e.target.value
    dispatch(getstates({ country_id: countryId }))
  }
  const url = new URL(window.location.href)
  const type = url.searchParams.get('type')

  // //console.log(type)
  // const [check, setCheck] = useState(false)
  // const [checkError, setCheckError] = useState(null)
  const { control, formState, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValue,
    resolver: yupResolver(Schema.CUSTOM_SIGNUP),
  })

  useEffect(() => {
    console.log('PostalDetails', postalDetails)
  })

  const urlParams = new URLSearchParams(window.location.search)
  const referral = urlParams.get('referral')

  console.log(referral) // Output the user_id to the console

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isValid, dirtyFields, errors } = formState
  useLayoutEffect(() => {
    dispatch(getcountries())
    // if (state.country !== 'Select Country')
    //   dispatch(getstates({ country_id: state.country }))
  }, [])
  useLayoutEffect(() => {
    setStateReducer({ plan: type })
    var token = storage.fetch.authToken()
    if (token) {
      setStateReducer({ show: true })
    }
    // setStateReducer({ })
  }, [])
  const CustomDropdownIcon = (props) => {
    return <ExpandMoreIcon {...props} />
  }
  console.log(errors)
  const onSubmit = async (value) => {
    const [month, year] = value.exp.split('/')
    console.log(month, year)
    Object.assign(value, {
      referral_code: referral,
      card_exp_month: month,
      sports: value.sports,
      card_exp_year: year,
      // state_id: value.state, //state.state,
      country_id: value.country, //state.country,
      product_name: type == 'basic' ? '1 Month Program' : '3 Months Program', //state.country,
      type: type,
    })

    // delete value.country
    delete value.check
    delete value.check1

    console.log('Values:', value)
    dispatch(getSignupPayment(value, navigate))
    dispatch(getLoadingStatus(true))
  }

  return (
    <Grid>
      <form name="signup" onSubmit={handleSubmit(onSubmit)}>
        <Grid container xs={12} sx={Style.Grid}>
          <Grid xs={12} md={5.5}>
            <LoaderComponent open={loadingStatus} />

            <Box
              sx={{
                display: { xs: 'block', md: 'none' },
                ml: { xs: '2%' },
              }}
            >
              <Subscription type={type} />
            </Box>

            <Box
              sx={{
                width: '100%',
                pb: 0,
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '16px', sm: '18px' },
                  // mt: { xs: 4, sm: 10 },
                  mb: { xs: 0, sm: 2 },
                  display: { xs: 'none', md: 'flex' },
                  fontStyle: 'semiBold',
                  fontWeight: '500',
                  color: '#27245E',
                }}
              >
                Personal Details
              </Typography>
              <Accordion
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
                defaultExpanded
              >
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {' '}
                  <Typography>Personal Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {' '}
                  <Grid container rowSpacing={2} columnSpacing={3}>
                    <Grid sx={Style.mainGrid} item xs={12} sm={6}>
                      <Item>
                        <Box>
                          <InputField
                            control={control}
                            helperText={errors?.f_name?.message}
                            errors={!!errors.f_name}
                            type={'text'}
                            placeholder={'Please enter first name'}
                            size={{ xs: '18px', md: '18px', lg: '18px' }}
                            color={'#333333'}
                            name={'f_name'}
                            required={'*'}
                            formlabel={'First Name'}
                          />
                        </Box>
                      </Item>
                    </Grid>
                    <Grid sx={Style.mainGrid} item xs={12} sm={6}>
                      <Item>
                        <Box sx={{}}>
                          <InputField
                            control={control}
                            helperText={errors?.l_name?.message}
                            errors={!!errors.l_name}
                            type={'text'}
                            placeholder={'Please enter last name'}
                            size={{ xs: '18px', md: '18px', lg: '18px' }}
                            color={'#333333'}
                            name={'l_name'}
                            // required={'*'}
                            formlabel={'Last Name'}
                          />
                        </Box>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Item>
                        <Box sx={{}}>
                          <InputField
                            control={control}
                            helperText={errors?.address?.message}
                            errors={!!errors.address}
                            type={'text'}
                            placeholder={'Please enter address'}
                            size={{ xs: '18px', md: '18px', lg: '18px' }}
                            color={'#333333'}
                            name={'address'}
                            formlabel={'Address'}
                          />
                        </Box>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Item>
                        <Box sx={{}}>
                          <InputField
                            control={control}
                            helperText={errors?.city?.message}
                            errors={!!errors.city}
                            type={'text'}
                            placeholder={'Please enter your city'}
                            size={{ xs: '18px', md: '18px', lg: '18px' }}
                            color={'#333333'}
                            name={'city'}
                            formlabel={'City'}
                          />
                        </Box>
                      </Item>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <Label name={'State'} />
                      <Select2
                        name="state"
                        control={control}
                        options={Allstates}
                        // defaultValue={Allcountries[0]}
                        error={!!errors.state}
                        errorMessage={errors?.state?.message}
                      />
                    </Grid> */}
                    {/* <Item> */}
                    <Grid item xs={12} sm={6}>
                      <InputField
                        control={control}
                        helperText={errors?.state?.message}
                        errors={!!errors.state}
                        type={'text'}
                        placeholder={'Please enter your state'}
                        size={{ xs: '18px', md: '18px', lg: '18px' }}
                        color={'#333333'}
                        name={'state'}
                        formlabel={'State'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ width: '100%' }}>
                      <Label name={'Country'} />
                      <Select2
                        name="country"
                        control={control}
                        options={Allcountries}
                        mt={'7px'}
                        placeholder={'Select Country'}
                        // defaultValue={Allcountries[0]}
                        error={!!errors.country}
                        errorMessage={errors?.country?.message}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{ alignItems: 'center', gap: '17px' }}
                    >
                      <InputField
                        control={control}
                        helperText={errors?.postal_code?.message}
                        errors={!!errors.postal_code}
                        type={'number'}
                        placeholder={'Please enter Postal code'}
                        size={{ xs: '18px', md: '18px', lg: '18px' }}
                        color={'#333333'}
                        name={'postal_code'}
                        required={'*'}
                        formlabel={'Postal Code'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box sx={{}}>
                        <InputField
                          control={control}
                          helperText={errors?.email?.message}
                          errors={!!errors.email}
                          type={'text'}
                          placeholder={'Please enter email'}
                          size={{ xs: '18px', md: '18px', lg: '18px' }}
                          color={'#333333'}
                          name={'email'}
                          required={'*'}
                          formlabel={'Email'}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {console.log(sportsData)}
                      <Box sx={{}}>
                        <Label name={'Sport'} />
                        <Select2
                          name="sports"
                          control={control}
                          options={sportsData}
                          placeholder={'Select Sport'}
                          // defaultValue={sportsData[0]}
                          error={!!errors.sports}
                          errorMessage={errors?.sports?.message}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        '& input[type="password"]::-ms-reveal': {
                          display: 'none',
                          border: '1px solid rgb(200, 200, 200)',
                        },
                      }}
                    >
                      <InputField
                        control={control}
                        helperText={errors?.password?.message}
                        errors={!!errors.password}
                        type={'password'}
                        placeholder={'Please enter password'}
                        size={{ xs: '18px', md: '18px', lg: '18px' }}
                        color={'#333333'}
                        name={'password'}
                        required={'*'}
                        formlabel={'Password'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        control={control}
                        helperText={errors?.password_confirmation?.message}
                        errors={!!errors.password_confirmation}
                        type={'password'}
                        placeholder={'Please confirm your password'}
                        size={{ xs: '18px', md: '18px', lg: '18px' }}
                        color={'#333333'}
                        name={'password_confirmation'}
                        required={'*'}
                        formlabel={'Confirm Password'}
                        style={{
                          'input::-ms-reveal': { display: 'none' },
                          border: '1px solid rgb(200, 200, 200)',
                        }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {/* {checkError !== null ? (
                <p style={Style.validationStyle}>{checkError}</p>
              ) : null} */}
            </Box>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={3}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              <Grid sx={Style.mainGrid} item xs={12} sm={6}>
                <Item>
                  <Box>
                    <InputField
                      control={control}
                      helperText={errors?.f_name?.message}
                      errors={!!errors.f_name}
                      type={'text'}
                      placeholder={'Please enter first name'}
                      size={{ xs: '18px', md: '18px', lg: '18px' }}
                      color={'#333333'}
                      name={'f_name'}
                      required={'*'}
                      formlabel={'First Name'}
                    />
                  </Box>
                </Item>
              </Grid>
              <Grid sx={Style.mainGrid} item xs={12} sm={6}>
                <Item>
                  <Box sx={{}}>
                    <InputField
                      control={control}
                      helperText={errors?.l_name?.message}
                      errors={!!errors.l_name}
                      type={'text'}
                      placeholder={'Please enter last name'}
                      size={{ xs: '18px', md: '18px', lg: '18px' }}
                      color={'#333333'}
                      name={'l_name'}
                      // required={'*'}
                      formlabel={'Last Name'}
                    />
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Item>
                  <Box sx={{}}>
                    <InputField
                      control={control}
                      helperText={errors?.address?.message}
                      errors={!!errors.address}
                      type={'text'}
                      placeholder={'Please enter address'}
                      size={{ xs: '18px', md: '18px', lg: '18px' }}
                      color={'#333333'}
                      name={'address'}
                      formlabel={'Address'}
                    />
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  control={control}
                  helperText={errors?.city?.message}
                  errors={!!errors.city}
                  type={'text'}
                  placeholder={'Please enter your city'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'city'}
                  formlabel={'City'}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} >
                <Label name={'State'} />
                <Select2
                  name="state"
                  control={control}
                  options={Allstates}
                  // defaultValue={Allcountries[0]}
                  error={!!errors.state}
                  errorMessage={errors?.state?.message}
                />
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <InputField
                  control={control}
                  helperText={errors?.state?.message}
                  errors={!!errors.state}
                  type={'text'}
                  placeholder={'Please enter your state'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'state'}
                  // required={'*'}
                  formlabel={'State'}
                />
              </Grid>
              {/* <Item> */}
              <Grid item xs={12} sm={6}>
                <Label name={'Country'} />
                <Select2
                  name="country"
                  control={control}
                  options={Allcountries}
                  mt={'7px'}
                  placeholder={'Select Country'}
                  // defaultValue={Allcountries[0]}
                  error={!!errors.country}
                  errorMessage={errors?.country?.message}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                sx={{ alignItems: 'center', gap: '17px' }}
              >
                <InputField
                  control={control}
                  helperText={errors?.postal_code?.message}
                  errors={!!errors.postal_code}
                  type={'number'}
                  placeholder={'Please enter Postal code'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'postal_code'}
                  required={'*'}
                  formlabel={'Postal Code'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{}}>
                  <InputField
                    control={control}
                    helperText={errors?.email?.message}
                    errors={!!errors.email}
                    type={'text'}
                    placeholder={'Please enter email'}
                    size={{ xs: '18px', md: '18px', lg: '18px' }}
                    color={'#333333'}
                    name={'email'}
                    required={'*'}
                    formlabel={'Email'}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{}}>
                  <Label name={'Sport'} />
                  <Select2
                    name="sports"
                    control={control}
                    mt={'7px'}
                    options={sportsData}
                    // defaultValue={sportsData[0]}
                    placeholder={'Select Sport'}
                    error={!!errors.sports}
                    errorMessage={errors?.sports?.message}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  '& input[type="password"]::-ms-reveal': {
                    display: 'none',
                    border: '1px solid rgb(200, 200, 200)',
                  },
                }}
              >
                <InputField
                  control={control}
                  helperText={errors?.password?.message}
                  errors={!!errors.password}
                  type={'password'}
                  placeholder={'Please enter password'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'password'}
                  required={'*'}
                  formlabel={'Password'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  control={control}
                  helperText={errors?.password_confirmation?.message}
                  errors={!!errors.password_confirmation}
                  type={'password'}
                  placeholder={'Please confirm your password'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name={'password_confirmation'}
                  required={'*'}
                  formlabel={'Confirm Password'}
                  style={{
                    'input::-ms-reveal': { display: 'none' },
                    border: '1px solid rgb(200, 200, 200)',
                  }}
                />
              </Grid>
            </Grid>
            <Toastify />
          </Grid>

          <Grid
            xs={12}
            md={5}
            sx={{
              boxShadow: '2px 2px 2px 2px #F5F5F5',
              borderRadius: '10px',
              display: { xs: 'block', md: 'none' },
              mt: '10px',
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '16px', sm: '18px' },
                // mt: { xs: 4, sm: 10 },
                mb: { xs: 0, sm: 2 },
                display: { xs: 'none', md: 'flex' },
                fontStyle: 'semiBold',
                fontWeight: '500',
                width: '100%',
                color: '#27245E',
              }}
            >
              Card Details
            </Typography>
            <Accordion
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
              defaultExpanded
            >
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {' '}
                <Typography>Card Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {' '}
                <Grid container rowSpacing={2}>
                  <Box
                    sx={{
                      width: '100%',
                      pr: 0,
                      pb: 0,
                      pt: 1.5,
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Item>
                          <Box sx={{ border: 'none', pr: '0px' }}>
                            <InputField
                              control={control}
                              helperText={errors?.card_number?.message}
                              errors={!!errors.card_number}
                              type={'number'}
                              placeholder={'Please enter Card Number'}
                              size={{ xs: '18px', md: '18px', lg: '18px' }}
                              color={'#333333'}
                              name={'card_number'}
                              required={'*'}
                              formlabel={'Card Number'}
                            />
                          </Box>
                        </Item>
                      </Grid>

                      <Grid
                        sx={{
                          ...Style.mainGrid,
                          alignItems: 'center',
                          gap: '17px',
                          width: '100%',
                          display: { xs: 'block', md: 'flex' },
                          mt: '1.9%',
                        }}
                        item
                        xs={12}
                        sm={12}
                      >
                        <Grid xs={12} sm={12} md={12}>
                          {/* <Label name={'Expiration'} />
                      <Box sx={Style.exp}> */}
                          {/* <Box> */}
                          <InputField
                            control={control}
                            helperText={errors?.exp?.message}
                            errors={!!errors.exp}
                            type={'text'}
                            placeholder={'MM/YYYY'}
                            size={{ xs: '18px', md: '18px', lg: '18px' }}
                            color={'#333333'}
                            name={'exp'}
                            required={'*'}
                            formlabel={'Expiration'}
                            onKeyDown={handleKeyDown}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid
                          sx={{ ...Style.mainGrid, maxWidth: '100%' }}
                          xs={12}
                          sm={12}
                        >
                          <Item>
                            <Box>
                              <InputField
                                control={control}
                                helperText={errors?.card_cvc?.message}
                                errors={!!errors.card_cvc}
                                type={'number'}
                                inputMode={'numeric'} // Set input mode to numeric to prevent non-numeric characters on mobile devices
                                onInput={(e) => {
                                  e.target.value = e.target.value
                                    .replace(/[^\d]/, '')
                                    .slice(0, 3) // Allow only numeric characters and limit to 3 digits
                                }}
                                placeholder={'Please enter cvv'}
                                size={{ xs: '18px', md: '18px', lg: '18px' }}
                                color={'#333333'}
                                name={'card_cvc'}
                                required={'*'}
                                formlabel={'CVV'}
                              />
                            </Box>
                          </Item>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid
            xs={12}
            md={6}
            sx={{
              padding: '5px',
              boxShadow: '2px 2px 2px 2px #F5F5F5',
              borderRadius: '10px',
              mt: '-3%',
            }}
          >
            <Box
              sx={{
                display: { xs: 'none', md: 'block' },
              }}
            >
              <Subscription type={type} />
            </Box>

            <Grid
              container
              rowSpacing={2}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              <Box
                sx={{
                  width: '100%',
                  pr: 0,
                  pb: 0,
                  pt: 1.5,
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Item>
                      <Box sx={{ border: 'none', pr: '0px' }}>
                        <InputField
                          control={control}
                          helperText={errors?.card_number?.message}
                          errors={!!errors.card_number}
                          type={'number'}
                          placeholder={'Please enter Card Number'}
                          size={{ xs: '18px', md: '18px', lg: '18px' }}
                          color={'#333333'}
                          name={'card_number'}
                          required={'*'}
                          formlabel={'Card Number'}
                        />
                      </Box>
                    </Item>
                  </Grid>

                  <Grid
                    sx={{
                      ...Style.mainGrid,
                      alignItems: 'center',
                      gap: '17px',
                      width: '100%',
                      display: { xs: 'block', md: 'flex' },
                      mt: '1.9%',
                    }}
                    item
                    xs={12}
                    sm={12}
                  >
                    <Grid xs={12} sm={12} md={12}>
                      {/* <Label name={'Expiration'} />
                      <Box sx={Style.exp}> */}
                      {/* <Box> */}
                      <InputField
                        control={control}
                        helperText={errors?.exp?.message}
                        errors={!!errors.exp}
                        type={'text'}
                        placeholder={'MM/YYYY'}
                        size={{ xs: '18px', md: '18px', lg: '18px' }}
                        color={'#333333'}
                        name={'exp'}
                        required={'*'}
                        formlabel={'Expiration'}
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                      />
                      {/* </Box> */}
                      {/* <Box sx={{ minWidth: { xs: '180px', md: '130px' } }}>
                          <Controller
                            name="year"
                            control={control}
                            defaultValue={'Select year'}
                            render={({ field }) => (
                              <SelectComponent
                                mt={'0px'}
                                error={!!errors.year}
                                errorMessage={errors?.year?.message}
                                value={field.value}
                                handleChangeForSelect={field.onChange}
                                menuData={years}
                              />
                            )}
                          />
                        </Box>
                      </Box> */}
                    </Grid>

                    <Grid
                      sx={{ ...Style.mainGrid, maxWidth: '100%' }}
                      xs={12}
                      sm={12}
                    >
                      <Item>
                        <Box>
                          <InputField
                            control={control}
                            helperText={errors?.card_cvc?.message}
                            errors={!!errors.card_cvc}
                            type={'number'}
                            inputMode={'numeric'} // Set input mode to numeric to prevent non-numeric characters on mobile devices
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(/[^\d]/, '')
                                .slice(0, 3) // Allow only numeric characters and limit to 3 digits
                            }}
                            placeholder={'Please enter cvv'}
                            size={{ xs: '18px', md: '18px', lg: '18px' }}
                            color={'#333333'}
                            name={'card_cvc'}
                            required={'*'}
                            formlabel={'CVV'}
                          />
                        </Box>
                      </Item>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              container
              xs={12}
              sx={{
                '&.MuiGrid-root': {
                  p: 0,
                },
              }}
            >
              <Grid xs={12} md={7.5}>
                <Box
                  sx={{
                    display: 'flex',
                    pt: 1,
                    marginLeft: '15px',
                    marginTop: '2%',
                  }}
                >
                  <label style={{ cursor: 'pointer' }}>
                    <Controller
                      control={control}
                      name="check"
                      defaultValue={false}
                      render={({ field }) => (
                        <FormControl
                          error={!!errors.check}
                          sx={{ width: '100%' }}
                        >
                          <Checkbox
                            sx={{
                              pl: 0,
                              color: '#D9D9D9',
                              '&.Mui-checked': {
                                color: '#27245E',
                              },
                              width: '15px',
                              height: '15px',
                              mt: 1.5,
                              ml: -1,
                              mr: 1,
                            }}
                            value={field.value}
                            id="termsAndConditions"
                            onChange={field.onChange}
                          >
                            {' '}
                          </Checkbox>
                          <Typography
                            sx={{ mt: '-20px', ml: '14px', fontSize: '14px' }}
                          >
                            I agree with
                            <a style={{ color: '#CB2128', fontSize: '14px' }}>
                              {' '}
                              Terms & Conditions
                            </a>
                          </Typography>
                          {!!errors.check ? (
                            <p style={Style.validationStyle}>
                              {errors.check.message}
                            </p>
                          ) : null}
                        </FormControl>
                      )}
                    />
                  </label>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    pt: 1,
                    marginLeft: '15px',
                  }}
                >
                  <label style={{ cursor: 'pointer' }}>
                    <Controller
                      control={control}
                      name="check1"
                      defaultValue={false}
                      render={({ field }) => (
                        <FormControl
                          error={!!errors.check1}
                          sx={{ width: '100%' }}
                        >
                          <Checkbox
                            sx={{
                              pl: 0,
                              color: '#D9D9D9',
                              '&.Mui-checked': {
                                color: '#27245E',
                              },
                              width: '15px',
                              height: '15px',
                              mt: 0.5,
                              ml: -1,
                              mr: 1,
                              float: 'left',
                            }}
                            value={field.value}
                            id="privacyPolicy"
                            onChange={field.onChange}
                          >
                            {' '}
                          </Checkbox>
                          <Typography
                            sx={{
                              mt: '-20px',
                              ml: '14px',
                              fontSize: '14px',
                              float: 'left',
                            }}
                          >
                            I agree with
                            <a style={{ color: '#CB2128', fontSize: '14px' }}>
                              {' '}
                              Privacy Policy
                            </a>
                          </Typography>
                          {!!errors.check1 ? (
                            <p style={Style.validationStyle1}>
                              {errors.check1.message}
                            </p>
                          ) : null}
                        </FormControl>
                      )}
                    />
                  </label>
                </Box>
              </Grid>
            </Grid>
            <Grid sx={{ ...Style.buttonBox }} md={6} lg={8} xl={12}>
              <TransformButton
                typeSubmit={'submit'}
                transform={'skew(-22deg) translate(50%, -45%)'}
                button_name={'SUBSCRIBE'}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}

export default Signup
const Style = {
  mainGrid: {
    p: 0,
  },
  upper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  validationStyle: {
    color: '#d32f2f',
    fontFamily: 'Mulish,sans-serif',
    // margin: '10px',
    fontWeight: 400,
    fontSize: '11.5px',
  },
  validationStyle1: {
    color: '#d32f2f',
    fontFamily: 'Mulish,sans-serif',
    // margin: '20px',
    fontWeight: 400,
    fontSize: '11.5px',
  },
  state: {
    ml: 3,
    mt: 2,
  },
  Stripe: {
    border: '1px solid gray',
    borderRadius: '5px 0px 0px 5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    p: 2,
    maxHeight: '67%',
  },
  Stripe1: {
    border: '1px solid gray',
    borderRadius: '0px 5px 5px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxHeight: '67%',
    p: 2,
  },
  subscription: {
    color: '#27245E',
    fontSize: '24px',
    fontWeight: '600',
  },
  gst: {
    color: '#ACACAC',
    fontSize: '18px',
    fontWeight: '400',
  },
  table: {
    border: 'none',
    width: '37%',
  },
  TableCell: {
    width: '47%',
    border: 'none',
  },
  TableCell1: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    minHeight: '50px',
    border: 'none',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#333333',
  },
  star: {
    color: '#d32f2f',
  },
  exp: {
    display: 'flex',
    mt: '2.5%',
  },
  Grid: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  // buttonBox: {
  //   // display: 'flex',
  //   // // width: { xs: '70%', md: '50%' },
  //   // width:'100%',
  //   // ml: { xs: -12, md: 35 },
  //   // justifyContent: { xs: '', md: 'flex-end' },
  //   // mb: 0,
  //   // mt: { xs: '20px', md: -6 },

  //   display: 'flex',
  //   // width: { xs: '70%', md: '50%' },
  //   width:'100%',
  //   justifyContent: { xs: '', md: 'flex-end' },
  //   mb: 0,
  //   mt: { xs: '20px', md: -6 },
  // },
  buttonBox: {
    display: 'flex',
    width: { xs: '70%', md: '50%' },
    ml: { xs: -12, md: 25, xl: 30 },
    justifyContent: { xs: '', md: 'flex-end' },
    mb: 0,
    mt: { xs: '20px', md: -7 },
  },
}
