import styled from '@emotion/styled'
import { Box, Button, FormLabel, Grid, Paper, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Schema } from '../../Components/FormSchema/Schema'
import InputField from '../../Components/InputField'
import AddIcon from '@mui/icons-material/Add'
import TransformButton from '../../Components/CustomButton/TransformButton'
import {
  getCustomerAddUpdateRequest,
  getCustomerDetailsRequest,
} from '../../redux/customer/customerAction'
import { useDispatch } from 'react-redux'
import { getLoadingStatus } from '../../redux/login/action'
import { useNavigate, useParams } from 'react-router-dom'
import {
  CUSTOMERS_INFO_DETAILS,
  CUSTOMERS_JUMPING_EDIT,
} from '../../routes/constURL'
import { getPlayerAddUpdateRequest } from '../../redux/players/playerAction'
import { useSelector } from 'react-redux'
import LoaderComponent from '../Loader/LoaderComponent'
import {
  getCustomerJumpingUpdate,
  getJumpDatabyid,
} from '../../redux/customer_panel/actionCustomer'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const JumpingForm = ({
  Style,
  data,
  playerEdit,
  customer,
  val,
  loading,
  page,
}) => {
  const newState = useSelector((state) => state?.CustomerData) || {}
  const navigate = useNavigate()
  const myDispatch = useDispatch()
  const [jumpingData, setJumpingData] = useState({
    jump_date: '',
    jump_height: '',
  })

  useEffect(() => {
    const date = new Date(val?.jump_date)
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
    setJumpingData({
      jump_date: formattedDate === 'NaN-NaN-NaN' ? '' : formattedDate,
      jump_height: val?.jump_height,
    })
  }, [val])

  const formik = useFormik({
    initialValues: jumpingData,
    validationSchema: Schema.JUMPING_ADD,
    onSubmit: (value) => {
      setJumpingData(value)
      onSubmit(value)
    },
    enableReinitialize: true,
  })
  let user_id = useParams()
  let data_ = {
    users_id: user_id.id,
  }
  const onSubmit = (value) => {
    // const formData = new FormData()
    if (customer) {
      if (val) {
        Object.assign(value, { jump_result_id: val.id })
      }
      Object.assign(value, { page: page })
      myDispatch(getCustomerJumpingUpdate(value, navigate))
    } else {
      Object.assign(value, data_)
      if (playerEdit) {
        myDispatch(getPlayerAddUpdateRequest(value, navigate))
      } else {
        myDispatch(getCustomerAddUpdateRequest(value, navigate))
      }
    }
    myDispatch(getLoadingStatus(true))
    setJumpingData({
      jump_date: '',
      jump_height: '',
    })
    val = {
      jump_date: '',
      jump_height: '',
    }
  }
  const Label = ({ name }) => (
    <FormLabel sx={Style.label}>
      {name}
      <span style={Style.star}>*</span>
    </FormLabel>
  )
  return (
    <>
      <form name="jumpingAddNew" onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <LoaderComponent open={newState?.loadingStatus || loading} />
          <Grid container columnSpacing={2}>
            <Grid sx={Style.mainGrid} item xs={12} sm={4}>
              <Item>
                <InputField
                  value={formik.values.jump_date}
                  onChange={formik.handleChange}
                  type={'date'}
                  placeholder={'Please select a date '}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'jump_date'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Select Date'}
                  max={new Date().toISOString().split('T')[0]}
                />
                {formik.errors.jump_date && formik.touched.jump_date ? (
                  <p style={Style.validationStyle}>{formik.errors.jump_date}</p>
                ) : null}
              </Item>
            </Grid>
            <Grid sx={Style.mainGrid} xs={12} item sm={4}>
              <Item>
                <InputField
                  value={formik.values.jump_height}
                  onChange={formik.handleChange}
                  type={'number'}
                  placeholder={'Please add jumping results'}
                  size={{ xs: '18px', md: '18px', lg: '18px' }}
                  color={'#333333'}
                  name1={'jump_height'}
                  required={'*'}
                  edit_update={true}
                  formlabel={'Add Jumping Results(cm)'}
                />
                {formik.errors.jump_height && formik.touched.jump_height ? (
                  <p style={Style.validationStyle}>
                    {formik.errors.jump_height}
                  </p>
                ) : null}
              </Item>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item>
                <Button
                  className="btn-rt-skw"
                  variant="contained"
                  type="submit"
                  endIcon={<AddIcon sx={{ transform: 'skew(22deg) ' }} />}
                  sx={Style.button_one}
                >
                  <Typography
                    sx={{ transform: 'skew(22deg) ', fontWeight: 500 }}
                  >
                    {jumpingData.jump_height !== undefined &&
                    jumpingData.jump_date !== ''
                      ? 'Update'
                      : 'Add new'}
                  </Typography>
                </Button>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  )
}
export default JumpingForm
