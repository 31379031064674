import React from 'react'
import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
// import CustomButton from '../Custom/Buttons/CustomButton';

const Error404 = () => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        width: '100%',
        height: '530px',
        // backgroundColor: '#F5F5F5',
      }}
    >
      <Box
        sx={{
          pt: 15,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            fontSize: 50,
            fontWeight: '900',
            color: 'gray',
            textShadow: '10px 10px 5px rgba(0, 0, 0, 0.5)',
          }}
        >
          {' '}
          We’re Sorry!{' '}
        </Box>
        <Box
          sx={{
            fontSize: 26,
            fontWeight: '900',
            color: 'gray',
            mt: 4,
            mb: 10,
          }}
        >
          {' '}
          We’re sorry, the page you’re looking for doesn’t exist.
        </Box>

        {/* <CustomButton 
       variant="contained"
       default_bgcolor={"gray"}
       onclick_bgcolor={"gray"}
       item={"Home"}
       textColor="white"
       borderRadius={"8px"}
       pr={3}
       pl={3}
       fontWeight={"800"}
       onClickButton={()=>navigate("/")}
       /> */}
      </Box>
    </Box>
  )
}

export default Error404
