import {
  GET_EMPTY_STATUS,
  GET_LOADING_STATUS,
  SET_EVENT_LOG_LIST,
} from '../ConstAction'
const initialState = {}
export const EventData = (state = initialState, action) => {
  // console.log('action', action)
  switch (action.type) {
    case SET_EVENT_LOG_LIST:
      return {
        ...state,
        list: action.response.data.data,
      }
    case GET_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: action.data,
      }
    case GET_EMPTY_STATUS:
      return {
        ...state,
        empty: action.data,
      }

    default:
      return { ...state }
  }
}
