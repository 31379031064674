import { Box, Grid, Table, TableBody } from '@mui/material'
import React, { useEffect, useLayoutEffect, useReducer } from 'react'
import TopBox from '../../Components/TableHeader/TopBox'
import { initialState, reducerFunction } from '../../config/mockData'
import { EnhancedTableHead } from '../../Components/TableHeader/TableHeader'
import { Style } from '../CSS/Table'
import CustomTableBody from '../../Components/TableBox/CustomTableBody'
import { storage } from '../../config/storage'
import { ADMIN_ADD, ADMIN_EDIT } from '../../routes/constURL'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  getAdminDelete,
  getAdminListRequest,
} from '../../redux/admins/adminAction'
import { getLoadingStatus } from '../../redux/login/action'
import CustomPagination from '../../Components/Pagination'
import ListSkelton from '../../Components/Loader/ListSkelton'
import NoDataFound from './NoDataFound'
import AlertDialog from '../../Components/Dialog/Dialog'
import Toastify from '../../Components/SnackBar/Toastify'
import LoaderComponent from '../../Components/Loader/LoaderComponent'
import { useState } from 'react'

const Admins = () => {
  let data = {
    start: 0,
    length: 5,
    sort_column: '',
    sort_by: '',
    search_val: '',
    listType: 'standard_admins',
  }
  const { list, empty, loadingStatus, error } = useSelector(
    (state) => state.AdminData || {},
  )
  const myDispatch = useDispatch()
  const navigate = useNavigate()
  const [state, setStateReducer] = useReducer(reducerFunction, initialState)
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  //const [isResetDisabled, setResetDisabled] = useState(true);
  useEffect(() => {
    document.title = 'OG Sports - Admins'
  })
  useLayoutEffect(() => {
    // ////////console.log(list)
    window.scrollTo(0, 0)
    myDispatch(getAdminListRequest(data))
    myDispatch(getLoadingStatus(true))
    const headCells = [
      { label: 'Admin Name', name: 'name' },
      { label: 'Role', name: 'role' },
      { label: 'Created On', name: 'created_at' },
      { label: 'Status', name: 'status' },
      //   { label: 'Action', name: 'action' },
    ].map((item, index) => ({
      id: item === '' ? 'none' : item.name,
      numeric: false,
      disablePadding: true,
      label: item.label,
    }))
    setStateReducer({ columns: headCells })
  }, [state.length])
  useLayoutEffect(() => {
    setStateReducer({ pages: list?.pages, total: list?.total_records })
  }, [list])
  // //////////console.log(list)
  const handleRequestSort = (event, property) => {
    setStateReducer({ page: state.page })
    let a = (state.page - 1) * state.length
    data = {
      start: a,
      length: state.length,
    }

    let isAsc = state.orderBy === property && state.order === 'asc'
    if (state.orderBy !== property) {
      setStateReducer({ order: 'desc', orderBy: property })
      data.sort_by = 'asc'
      data.sort_column = property
    } else {
      setStateReducer({ order: isAsc ? 'desc' : 'asc', orderBy: property })
      data.sort_by = state.order
      data.sort_column = property
    }
    if (state.search) {
      data.search_val = state.search
    }
    //setStateReducer({ page: 1 })
    myDispatch(getAdminListRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  ///---Pagination Function---///
  const handlePageChange = (event, value) => {
    setStateReducer({ page: value })
    let a = (value - 1) * state.length
    data.start = a
    data.length = state.length
    if (state.order && state.orderBy) {
      data.sort_by = state.order === 'asc' ? 'desc' : 'asc'
      data.sort_column = state.orderBy
    }
    myDispatch(getAdminListRequest(data))
    myDispatch(getLoadingStatus(true))
  }
  const handleChangeSelect = (e, type) => {
    switch (type) {
      case 'search':
        return setStateReducer({ search: e.target.value })
      case 'role':
        return setStateReducer({ role: e.target.value })
      case 'search_click':
        Object.assign(data, {
          search_val: state.search,
        })
        myDispatch(getAdminListRequest(data))
        myDispatch(getLoadingStatus(true))
        return setStateReducer({ page: 1 })
      default:
        return null
    }
  }
  ///---Navigation to edit page---///
  const navigateTo = (id) => {
    // ////////console.log('...', id)
    storage.set.AdminId(id)
    navigate(ADMIN_EDIT + '/' + id, {
      state: id,
    })
  }
  const handleDelete = (id) => {
    setStateReducer({ id: id })
    setStateReducer({ alert: true })
  }
  const confirmDelete = async (id) => {
    myDispatch(getAdminDelete({ id: id }))
    myDispatch(getLoadingStatus(true))
    myDispatch(getAdminListRequest(data))
    setStateReducer({ alert: false })
    // myDispatch({type: "NAVIGATE", navigate,URL:CUSTOMER_LISTING})
  }

  useEffect(() => {
    
    const isDefaultSearch = state.search === '' || state.search === undefined;
    // If any two drop-downs have default values, disable the button
    setButtonDisabled( isDefaultSearch );
  }, [state.search]);

  // useEffect(() => {
    
  //   const isDefaultSearchR = state.search === '';

  //   setResetDisabled(isDefaultSearchR);
  // }, [state.search]);

  const handleReset = () => {
    window.scrollTo(0, 0)
    myDispatch(getAdminListRequest(data))
    myDispatch(getLoadingStatus(true))

    setStateReducer({
      // status: 'Select Status',
      // membership: 'Select Program', order: 'asc',
      orderBy: '',
      page: 1,
      pages: 0,
      date:'',
      type:'Select Type',
      payment:'Payment Mode',
      sport:"Select Sport",
      search:'',
    })
  }
  return (
    <Grid>
      <TopBox
        headerText={'Admins'}
        button_one={'Add +'}
        button_one_onClick={() => {
          navigate(ADMIN_ADD)
        }}
        width={'20%'}
        search_According_To_routes1={true}
        searchText={'Search Admins'}
        setSearch_val={(e) => handleChangeSelect(e, 'search')}
        search_val={state.search}
        button_two={() => handleChangeSelect('', 'search_click')}
        handleChangeFirst={(e) => handleChangeSelect(e, 'role')}
        menuData={['Admin', 'Standard Admin']}
        onReset={handleReset}
        disabledVal={isButtonDisabled}
        //disabledReset={isResetDisabled}
      />
      {state.total === 0 && <NoDataFound />}
      {/* <LoaderComponent open={loadingStatus} /> */}
      <AlertDialog
        title={'Are you sure you want to delete this Admin?'}
        open={state.alert}
        onClickButton={() => confirmDelete(state.id)}
        onClickButtonCancel={() => setStateReducer({ alert: false })}
      />
      <LoaderComponent open={loadingStatus} />
      <Toastify />
      {state.total !== 0 && (
        <Box sx={Style.table.tableWrapBox}>
          <Table sx={Style.table.tableBox} aria-labelledby="tableTitle">
            <EnhancedTableHead
              columns={state.columns}
              setColumns={setStateReducer}
              order={state.order}
              orderBy={state.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={state.rows.length}
            />
            {!loadingStatus && (
              <TableBody sx={{ border: 0 }}>
                {list &&
                  list?.standard_admin_list?.map((row, ind) => (
                    <CustomTableBody
                      key={row?.id}
                      URL={ADMIN_EDIT}
                      navigateTo={() => navigateTo(row?.id)}
                      row={row}
                      loadingStatus={loadingStatus}
                      ind={ind}
                      columns={[
                        row?.name,
                        row?.role,
                        row?.formatted_date,
                        row?.status,
                      ]}
                      handleDelete={() => handleDelete(row?.id)}
                    />
                  ))}
              </TableBody>
            )}
            {loadingStatus && (
              <ListSkelton
                loadingStatus={loadingStatus}
                rows={5}
                columns={5}
                width={'100px'}
              />
            )}
          </Table>
        </Box>
      )}
      <Toastify />

      <CustomPagination
        state={state}
        empty={empty}
        handlePageChange={handlePageChange}
      />
    </Grid>
  )
}

export default Admins
