import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import {
  TextField,
  FormLabel,
  InputAdornment,
  Box,
  IconButton,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
const InputField = ({
  formlabel,
  name,
  errors,
  helperText,
  control,
  placeholder,
  max,
  amount,
  type,
  value,
  required,
  rows,
  height,
  fullWidth,
  multiline,
  min,
  lineHeight,
  disabled,
  color,
  size,
  displayForSkelton,
  textTransform,
  IconName,
  onChange,
  edit_update,
  name1,
  mt,
  maxRows,
  onKeyDown,
}) => {
  const IconComponent = IconName
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  return (
    <>
      {formlabel && (
        <FormLabel
          sx={{
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            color: '#333333',
          }}
        >
          {formlabel}
          <span
            style={{
              color: 'red',
            }}
          >
            {required}
          </span>
        </FormLabel>
      )}
      {edit_update ? (
        <TextField
          name={name1}
          disabled={disabled}
          value={value}
          onChange={onChange}
          autoComplete="false"
          type={showPassword ? 'text' : type}
          variant="filled"
          // autoFocus={true}
          InputProps={{
            disableUnderline: true,
            pt: '6px',
            endAdornment: type === 'password' && (
              <InputAdornment>
                <IconButton
                  sx={{
                    // border: '1px solid #C8C8C8',
                    '&.MuiIconButton-root': {
                      borderRadius: '0%',
                      borderLeft: '0px',
                      // bgcolor: '#F2F2F2',
                    },
                  }}
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <React.Fragment>
                {amount && (
                  <Box
                    sx={{
                      bgcolor: '#27245E',
                      fontSize: '18px',
                      fontWeight: '700',
                      color: 'white',
                      p: '4% 6%',
                      position: 'relative',
                      right: '12px',
                    }}
                  >
                    $
                  </Box>
                )}
              </React.Fragment>
            ),
          }}
          inputProps={{
            max: max,
            min: 0,
            style: {
              paddingTop: '10px',
              paddingBottom: '10px',
              fontSize: '14px',
              textTransform: type == 'date' ? 'uppercase' : 'none',
            },
          }}
          color="primary"
          placeholder={placeholder}
          sx={{
            width: '100%',
            border: '1px solid #C8C8C8',
            mt: mt ? mt : '6px',
            display: displayForSkelton,
            '& input[type=number]': {
              '-moz-appearance': 'textfield',
              '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
              '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
            },
            '&.MuiFilledInput-input': {
              textTransform: type == 'date' ? 'uppercase' : 'none',
            },
          }}
          max={max}
        />
      ) : (
        <Controller
          name={name}
          control={control}
          sx={{
            // mt: 10,
            pb: 0,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={disabled}
              onKeyDown={onKeyDown}
              autoComplete="false"
              variant="filled"
              onChange={
                name == 'exp'
                  ? (e) => {
                      let value = e.target.value
                      value = value
                        .replace(/^([1-9]\/|[2-9])$/g, '0$1/')
                        .replace(/^(0[1-9]|1[0-2])$/g, '$1/')
                        .replace(/^([0-1])([3-9])$/g, '0$1/$2')
                        .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2')
                        .replace(/^([0]+)\/|[0]+$/g, '0')
                        .replace(/[^\d\/]|^[\/]*$/g, '')
                        .replace(/\/\//g, '/')

                      e.target.value = value
                      field.onChange(e.target.value)
                    }
                  : (e) => {
                      field.onChange(e.target.value)
                    }
              }
              // onChange={onChange}
              InputProps={{
                endAdornment:
                  type === 'password' ? (
                    <InputAdornment>
                      <IconButton
                        sx={{
                          border: '1px solid #C8C8C8',
                          '&.MuiIconButton-root': {
                            borderRadius: '0%',
                            borderLeft: '0px',
                            p: '8.5px',
                            bgcolor: '#F2F2F2',
                          },
                        }}
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    name === 'card_number' && (
                      <InputAdornment>
                        <IconButton
                          sx={{
                            border: '1px solid #C8C8C8',
                            '&.MuiIconButton-root': {
                              borderRadius: '0%',
                              borderLeft: '0px',
                              p: '7.6px',
                              bgcolor: '#F2F2F2',
                            },
                          }}
                          edge="end"
                        >
                          <img src={require('../assets/maestro.png')} />
                          <img src={require('../assets/MasterCard.png')} />
                          <img src={require('../assets/visa.png')} />
                        </IconButton>
                      </InputAdornment>
                    )
                  ),
                disableUnderline: true,
                startAdornment: (
                  <React.Fragment>
                    {IconComponent && (
                      <IconComponent
                        sx={{
                          width: '25px',
                          pl: '15px',
                          pr: '15px',
                          height: '43px',
                          bgcolor: '#27245E',
                          color: 'white',
                        }}
                      />
                    )}
                    {amount && (
                      <Box
                        sx={{
                          bgcolor: '#27245E',
                          fontSize: '18px',
                          fontWeight: '700',
                          color: 'white',
                          p: '1% 6%',
                          lineHeight: '36px',
                        }}
                      >
                        $
                      </Box>
                    )}
                  </React.Fragment>
                ),
                // endAdornment: name === 'card_number' && (
                //   <>
                //     <img src={require('../assets/maestro.png')} />
                //     <img src={require('../assets/MasterCard.png')} />
                //     <img src={require('../assets/visa.png')} />
                //   </>
                // ),
              }}
              type={showPassword ? 'text' : type}
              error={errors}
              helperText={helperText}
              color="primary"
              inputProps={{
                max: max,
                min: 0,
                maxLength: name == 'exp' ? 7 : undefined,
                style: {
                  paddingTop: '9px',
                  paddingBottom: '9px',
                  paddingLeft: '10px',
                  height: { height },
                  fontSize: '16px',
                  textTransform: textTransform,
                  color: '#000',
                  backgroundColor: '#F2F2F2',
                  border: '1px solid #C8C8C8',
                  borderRight:
                    type === 'password' || name === 'card_number'
                      ? '0px'
                      : '1px solid #C8C8C8',
                  borderRadius: '0px',
                  textTransform: type == 'date' ? 'uppercase' : 'none',
                },
              }}
              placeholder={placeholder}
              rows={rows}
              fullWidth={fullWidth}
              multiline={multiline}
              sx={{
                width: '100%',
                pb: 0,
                mt: '7px',
                '& input[type=number]': {
                  '-moz-appearance': 'textfield',
                  '&::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                  '&::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                },
                '& .MuiInputBase-root': {
                  // Style the root element of MuiInputBase
                  //  pl:0
                },
                '& .MuiFilledInput-root': {
                  textTransform: type == 'date' ? 'uppercase' : 'none',
                  // Style the root element of MuiFilledInput
                  pl: 0,
                },
                '& .MuiFormHelperText-root': {
                  // Style the root element of MuiFormHelperText
                  margin: 0,
                  marginTop: 0,
                },
                // '&.css-1eidioi-MuiGrid-root>.MuiGrid-item': {
                //   pt: 0,
                // },
              }}
            />
          )}
        />
      )}
    </>
  )
}

export default InputField
