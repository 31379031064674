import { Accordion, AccordionSummary, Grid, Typography } from '@mui/material'
import React from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Signup from '../../Components/Forms/Signup'
import Footer from '../../Components/Footer/Footer'

const DemoSignup = () => {
  return (
    <>
    <Grid sx={{ padding: '50px 80px' }}>
      <Typography
        variant="h2"
        sx={{
          fontSize: { xs: '16px', sm: '30px' },
          // mt: { xs: 4, sm: 10 },
          mb: { xs: 0, sm: 2 },

          fontStyle: 'Bold',
          fontWeight: '600',
          color: '#27245E',
        }}
      >
        CHECKOUT
      </Typography>
      <Grid>
        <Accordion
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Signup />
          </AccordionSummary>
        </Accordion>
        <Grid
          sx={{
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Signup />
        </Grid>
      </Grid>
      <Grid></Grid>
    </Grid>
    <Footer/>
    </>
  )
}

export default DemoSignup
