import styled from '@emotion/styled'
import { Margin, Padding } from '@mui/icons-material'
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // border: '1px solid grey',
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: '600',
}))
const Subscription = ({ type, success, width }) => {
  const Style = {
    subs: {
      bgcolor: '#F5F4FF',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '10px',
      marginBottom: { xs: '5%', md: '4%' },
      marginTop: { xs: '3%', md: '0%' },
    },
    substext: {
      p: 2,
      fontWeight: 'bolder',
      fontSize: '20px',
      color: '#27245E',
    },
    upper: {
      display: 'flex',
      // justifyContent: 'center',
      width: '100%',
      flexDirection: 'column',
      mb: { xs: 5, md: 3 },
    },
    subscription: {
      color: '#27245E',
      fontSize: '18px',
      fontWeight: '600',
    },
    gst: {
      color: '#ACACAC',
      fontSize: '12px',
      fontWeight: '400',
      marginLeft:'2px'
    },
    table: {
      border: 'none',
      width: { xs: '100%', md: width },
      // p: '0px 16px',
      marginLeft: '-3%',
    },
    TableCell: {
      '&.MuiTableCell-root': {
        ml: 2,
        p: 0,
        pl: 2,
      },
      width: { xs: '65%', md: '65%' },
      border: 'none',
      // mr: '70%',
      // pr: '10%',
    },
    TableCell1: {
      '&.MuiTableCell-root': {
        p: 0,
      },
      width: { xs: '450px', md: '400px' },
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      minHeight: '50px',
      border: 'none',
      paddingRight: '-100px',
      width: '100%',
      fontWeight:'bold'
    },
    label: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      color: '#333333',
    },
  }
  return (
    <Grid container xs={12}>
      <Item sx={{ ...Style.upper, maxWidth: '100%' }}>
        {' '}
        {/* Ensure the item expands to fill its container */}
        {!success ? (
          <Box>
            <Box sx={Style.subs}>
              <Typography sx={Style.substext}>
                OG Sport Subscription
              </Typography>
            </Box>
          </Box>
        ) : (
          ''
        )}
        <Table sx={{ ...Style.table, width: '100%' }}>
          {' '}
          {/* Ensure the table fills its container */}
          <TableBody>
            <TableRow>
              <TableCell sx={{ ...Style.TableCell }}>
                <Typography sx={Style.subscription}>
                  {type === 'basic'
                    ? '1 Month Jump Program'
                    : '3 Month Jump Program'}{' '}
                </Typography>
              </TableCell>
              <TableCell sx={Style.TableCell1}>
                <Typography style={Style.gst}>(inclusive of GST)</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ ...Style.TableCell, pt: '-10px' }}>
                <b>Setup Fee - </b>This is a one-time charge for accessing our
                platform
              </TableCell>
              <TableCell sx={Style.TableCell1}>
                {type === 'basic' ? '$90' : '$130'} AUD
              </TableCell>
            </TableRow>
            <TableRow>
              {' '}
              {/* No border will be added to the bottom of this row */}
              <TableCell sx={Style.TableCell}>
                <b> Monthly Fee -</b> Access ongoing program changes, hints and
                tips
              </TableCell>
              <TableCell sx={Style.TableCell1}>$2 AUD</TableCell>
            </TableRow>
            <TableRow
              sx={{
                borderTop: '1px solid #C6C6C6',
                borderBottom: '1px solid #C6C6C6',
                ml: 2,
              }}
            >
              <TableCell sx={Style.TableCell}><span style={{fontWeight:'bold'}}>Total</span></TableCell>
              <TableCell sx={Style.TableCell1}>
                {type === 'basic' ? '$92' : '$132'} AUD{' '}
                <span style={Style.gst}>(Inc. GST)</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Item>
    </Grid>
  )
}

export default Subscription
