import React from 'react'
import '../../App.css'
const SomethingWrong = () => {
  return (
    <div className="errordiv">
      <img
        alt="error500"
        className="error500"
        // src={vehicData?.photo_links[selected]?.image_url}
        src={require('../../assets/no connection.png')}

        // onClick={() => setOpen(true)}
      />
    </div>
  )
}

export default SomethingWrong
