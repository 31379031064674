export const Style = {
  box: {
    textAlign: 'center',
    mt: '15%',
  },
  text: {
    fontSize: '24px',
    fontWeight: '700',
    color: 'rgb(39, 36, 94)',
  },
  table: {
    tableWrapBox: {
      overflow: 'auto',
      // p: { xs: 1, sm: 3 },
    },
    tableBox: {
      minWidth: { md: '100%' },
      // border: '1px solid #dddddd',
      borderRadius: '25px',
    },
    SubscriptiontableBox: {
      width: { xs: '775px', md: '800px', lg: '900px' },
      // border: '1px solid #dddddd',
      borderRadius: '25px',
    },
    tableCell: {
      width: '50px',
      maxWidth: '80px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: '13px',
      color: '#707070',
      fontWeight: '600',
      padding: '20px',
      border: '0px',
      textTransform: 'capitalize',
    },
    tableCell1: {
      minWidth: '131px',
      fontSize: '13px',
      color: '#707070',
      fontWeight: '600',
      justifyContent: 'center',
      border: '0px',
      display: 'flex',
      textTransform: 'capitalize',
      marginRight: '50px',
    },
    tableCell2: {
      width: '50px',
      maxWidth: '100px',
      textOverflow: 'ellipsis',
      display: 'flex',
      whiteSpace: 'nowrap',
      // overflow: 'hidden',
      fontSize: '13px',
      color: '#707070',
      fontWeight: '600',
      padding: '20px',
      border: '0px',
      textTransform: 'capitalize',
    },
    button: {
      backgroundColor: '#EEF2FC',
      minWidth: '40px',
      borderRadius: '20px 0px 0px 20px',
      color: '#2D8E42',
      padding: '3px 10px 3px 10px',
      maxWidth: '100%',
      margin: '0px',
      fontSize: '16px',
      border: '1px solid #C3CDFF',
    },
    button2: {
      backgroundColor: '#2D8E42',
      minWidth: '40px',
      borderRadius: '0px 20px 20px 0px',
      color: '#fff',
      padding: '3px 10px 3px 10px',
      maxWidth: '100%',
      margin: '0px',
      fontSize: '16px',
      border: '1px solid #2D8E42',
    },
    headingText: {
      fontSize: { xs: '14px', sm: '31px' },
      fontWeight: '700',
      color: '#000000',
      // p: { xs: 1, sm: 3 },
      display: 'flex',
      alignItems: 'center',
    },
  },
  button: {
    textTransform: 'none',
    borderRadius: '5px',
    pl: 4,
    pr: 4,
    bgcolor: '#F15F23',
    color: 'white',
    '&.MuiButtonBase-root:hover': {
      bgcolor: '#F15F23',
    },
  },
  paginationBox: {
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'space-between',
    p: 1,
  },
  viewButton: {
    width: '19px',
    height: '19px',
    marginRight: '10px',
    cursor: 'pointer',
    marginBottom: '3px',
    color: '#CB2128',
  },
  editButton: {
    width: '19px',
    height: '19px',
    cursor: 'pointer',
    marginBottom: '3px',
    color: '#CB2128',
    marginRight:'7px'
  },
}
