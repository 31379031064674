import { combineReducers } from 'redux'
import { LoginData } from './login/reducer'
import { CustomerData } from './customer/customerReducer'
import { ClubData } from './clubs/clubReducer'
import { DashboardReducer } from './dashboard/dashboardReducer'
import { PlayerData } from './players/playerReducer'
import { PaymentData } from './payments/paymentReducer'
import { RevenueData } from './revenue/revenueReducer'
import { SignupData } from './signup/reducer'
import { EventData } from './event_logs/eventReducer'
import {} from './customer_panel/reducerCustomer'
import { ProgramData } from './program/programReducer'
import { CustomerPanelData } from './customer_panel/reducerCustomer'
import { AccountData } from './myaccount/myaccountReducer'
import { AdminData } from './admins/adminReducer'

export default combineReducers({
  LoginData,
  CustomerData,
  ClubData,
  DashboardReducer,
  PlayerData,
  PaymentData,
  RevenueData,
  SignupData,
  EventData,
  ProgramData,
  CustomerPanelData,
  AccountData,
  AdminData,
})
