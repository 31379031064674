import styled from '@emotion/styled'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect } from 'react'
import PostedVideo from '../Media/Video'
import NoDataFound from '../../Admin/Pages/NoDataFound'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getTimeline } from '../../redux/customer_panel/actionCustomer'
import { useDispatch } from 'react-redux'
import { getLoadingStatus } from '../../redux/login/action'
import { useSelector } from 'react-redux'
import { useTheme } from '@emotion/react'

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: '#27245E',
  marginTop: '25px',
  alignItems: 'center',
}))

const FeedBox = ({ count, loading, total, user_filter }) => {
  const { list, loadingStatus } = useSelector(
    (state) => state?.CustomerPanelData,
  )
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  let data = {
    user_filter: user_filter,
    date: '',
    start: 0,
    length: 10,
    sort_column: '',
    sort_by: '',
    search_val: '',
  }

  const myDispatch = useDispatch()
  const fetchMoreData = () => {
    if (total !== 0) {
      data['length'] = data['length'] + 10
      // setTimeout(() => {
      myDispatch(getTimeline(data))
      myDispatch(getLoadingStatus(true))
    }
    // }, 1500)
  }
  useEffect(() => {
    count?.map((item, index) => {
      const video = document.getElementById(`video-${index}`)
      if (video) {
        video.load()
        // video.play()
      }
    })
  }, [count, list])
  return (
    <Grid container>
      <InfiniteScroll
        dataLength={total ? total : 5}
        next={fetchMoreData}
        style={{ overflowX: 'hidden', width: '100%' }}
        hasMore={true}
        loader={
          total !== 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress
                sx={{
                  display: 'flex',
                  overflowX: 'hidden',
                  height: '60px',
                  width: '60px',
                }}
              />
            </Box>
          )
        }
        scrollThreshold={2}
        // endMessage={
        //   <p style={{ textAlign: 'center' }}>
        //     <b>You've seen it all!</b>
        //   </p>
        // }
        scrollableTarget="scrollableDiv"
      >
        {count?.map((item, index) => (
          <Grid sx={Style.main} xs={12} key={index}>
            <Item sx={Style.heading}>
              <Grid sx={Style.content}>
                {loading ? (
                  <Skeleton style={Style.imgskeleton} />
                ) : (
                  <Box sx={Style.imgbox}>
                    <img
                      width={'50px'}
                      height={'50px'}
                      style={Style.img}
                      src={
                        item?.user_image
                          ? item?.user_image
                          : require('../../assets/profileRect.png')
                      }
                    />
                  </Box>
                )}
                <Box sx={Style.text}>
                  {loading ? (
                    <Skeleton style={Style.nameskeleton} />
                  ) : (
                    <Typography sx={Style.name}>{item.user_name}</Typography>
                  )}
                  {loading ? (
                    <Skeleton style={Style.nameskeleton} />
                  ) : (
                    <Typography sx={Style.date}>
                      {item.created_at_formatted}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Item>
            {loading ? (
              <Skeleton
                sx={Style.video}
                style={{ height: '400px', width: '100%' }}
              />
            ) : (
              <>
                {item.video_file && (
                  <>
                    <Grid sx={Style.video}>
                      <PostedVideo src={item.video_file} index={index} />
                    </Grid>
                  </>
                )}
                {item.image_file && (
                  <Grid
                    sx={Style.video}
                    style={{ height: '400px', width: '100%' }}
                  >
                    <img
                      src={item.image_file}
                      width="100%"
                      style={{
                        borderRadius: '20px',
                        width: isMobile ? 'none' : 'auto',
                      }}
                    />
                  </Grid>
                )}
              </>
            )}
            {/* {loading ? (
              <>
                <Skeleton sx={Style.text} />
                <Skeleton sx={Style.text} />
              </>
            ) : ( */}
            {item.text && (
              <Grid xs={12}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: item.text.replace(/\r\n/g, '<br />'),
                  }}
                  sx={{ mt: 4 }}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </InfiniteScroll>

      {total == 0 && (
        <Box sx={{ width: '100%' }}>
          {' '}
          <NoDataFound />
        </Box>
      )}
    </Grid>
  )
}

export default FeedBox
const Style = {
  main: {
    borderBottom: '1px solid #C8C8C8',
    // pb: 2,
  },
  heading: {
    '&.MuiPaper-root': {
      borderRadius: 'none',
    },
  },
  content: {
    display: 'flex',
    ml: 1,
    bgcolor: 'white',
    pl: 2,
  },
  img: {
    borderRadius: '50%',
    m: 2,
  },
  imgbox: {
    border: '1px solid #27245E',
    borderRadius: '50%',
    width: '80px',
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgskeleton: {
    border: '1px solid #27245E',
    borderRadius: '50%',
    width: '70px',
    height: '110px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    ml: 2,
    mt: 1.2,
    width: '100%',
  },
  name: {
    color: '#27245E',
    fontWeight:'bold'
  },
  nameskeleton: {
    mt: '28px',
    width: '20%',
  },
  date: {
    color: '#C8C8C8',
    fontSize: '14px',
  },
  video: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '10px',
    mt: 4,
    mb: 2,
  },
}
